import moment from "moment";


export const statusMapping = {
  1: { text: "Created", color: "grey" },
  2: { text: "Ready", color: "green" },
  3: { text: "Deleted", color: "red" }
};
export const paymentStatusMapping = {
  0: { text: "Unpaid", color: "grey" },
  1: { text: "Partial Paid", color: "blue" },
  2: { text: "Paid", color: "green" },
  3: { text: "Partial Wrote off", color: "orange" },
  4: { text: "Full Wrote off", color: "orange" }
};
export const GridColumns = (({ insidePane = false }) => {
  return (
    {
      version: 1,
      columns: [
        {
          title: "Invoice Number",
          accessor: "invoiceNo",
          show: true,
        },
        {
          title: "Client",
          accessor:"clientName",
          show: true,
          width:200,
          renderer: (dataObj, rowIndex, pageSize) => {
            
            return <div>
              {dataObj?.client?.org_id ? dataObj?.clientName :
                <div className="d-flex justify-content-center align-content-center" style={{ fontStyle: "italic" }}>
                  <div className="mx-1 mt-1" style={{ background: "#df6463", width: "10px", height: "10px", borderRadius: "50%" }}></div>
                  <div>Walk In ({dataObj?.client?.fullName})</div>
                </div>

              }
            </div>


          },
        },
      //   {
      //     title: "Tags",
      //     accessor: "tags",
      //     width: 200,
      //     renderer: (dataObj, rowIndex, pageSize) => {
      //         return (!!dataObj?.tags ? <CustomTooltip>
      //             <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
      //                 <div
      //                     className='d-flex'
      //                 >
      //                     {dataObj?.tagValues?.map((tag, index) => (
      //                         <div onClick={(e) => e.stopPropagation()} key={index} className={` tag-item-inline `} style={{ color: tag?.color, backgroundColor: `${tag.color}22`, fontSize:  11, fontWeight: 500 }}>
      //                             {tag.label}
                  
      //                         </div>
      //                     ))}

      //                 </div>
      //             </CustomTooltip.Body>
      //             <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
      //                 <div
      //                     className='d-flex'
      //                 >
      //                     {dataObj?.tagValues?.map((tag, index) => (
      //                         <div onClick={(e) => e.stopPropagation()} key={index} className={` tag-item-inline `} style={{ color: tag?.color, backgroundColor: `${tag.color}22`, fontSize: 11, fontWeight: 500 }}>
      //                             {tag.label}

      //                         </div>
      //                     ))}
      //                 </div>
      //             </CustomTooltip.ToolTip>
      //         </CustomTooltip>
      //             : <>{"--"}</>
      //         );
      //     },
      //     show: true,
      // },
        {
          title: "Client Company",
          accessor:"clientCompanyName",
          show: true,
          renderer: (dataObj, rowIndex, pageSize) => {

            return <div>
              {dataObj?.clientCompanyName}
            </div>
          },
        },
        {
          title: "Serving Company",
          accessor:"companyName",
          show: true,
          renderer: (dataObj, rowIndex, pageSize) => {
            return <div>
              {dataObj?.companyName}
            </div>
          },
        },
        {
          title: "Created By",
          accessor: "createdByName",
          show: true,
          renderer: (dataObj) => {
            return <div>
              {dataObj?.createdByName}
            </div>;
          },
        },
        {
          title: "Created At",
          accessor: "createdAt",
          renderer: (dataObj) => {
              return <div>{moment.unix(dataObj?.createdAt).format("DD-MMM HH:mm")}</div>;
          },
          show: true,

      },
      {
        title: "Type",
        accessor: "type",
        show: true,
        renderer: (dataObj) => {
          return <div>
            {(dataObj?.invoiceExtn?.items[0]?.manual) ? "Manual" : "Booking"}
          </div>;
        },
      },

        
        {
          title: "Status ",
          accessor:"status",
          renderer: (dataObj) => {
            const statusInfo = statusMapping[dataObj?.status || 1] || { text: "Unknown", color: "#000000" };
            return (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: statusInfo.color,
                    marginRight: '5px',
                  }}
                ></div>
                <div>{statusInfo.text}  <i>{dataObj?.isProforma?"(Proforma)":""}</i> </div>
              </div>
            );
          },
          show: true,
        },
        {
          title: "Invoice Date",
          accessor:"invoiceDate",
          renderer: (dataObj) => {
            return (
              <div><strong><span>{
                dataObj.invoiceDate != undefined ? (
                  (dataObj.invoiceDate?.toString())?.slice(6, 8) +
                  '-' +
                  (dataObj.invoiceDate?.toString())?.slice(4, 6) +
                  '-' +
                  (dataObj.invoiceDate?.toString())?.slice(0, 4)
                ) : (
                  '--'
                )}</span></strong>
              </div>
            );
          },
          show: true,
        },
        {
          title: "Due Date",
          accessor:"dueDate",
          renderer: (dataObj) => {
            return (
              <div><strong><span>{
                dataObj.dueDate != undefined ? (
                  (dataObj.dueDate?.toString())?.slice(6, 8) +
                  '-' +
                  (dataObj.dueDate?.toString())?.slice(4, 6) +
                  '-' +
                  (dataObj.dueDate?.toString())?.slice(0, 4)
                ) : (
                  '--'
                )}</span></strong>
              </div>
            );
          },
          show: true,
        },
        {
          title: "Total Amount",
          accessor: "total",
          renderer: (dataObj) => {
            return (
              <div>
                {dataObj?.amountPayable ? ((dataObj?.amountPayable) / 100).toFixed(2) : 0}
              </div>
            );
          },
          show: true,
        },
        {
          title: "Payment Status ",
          accessor:"paymentStatus",
          renderer: (dataObj) => {
            const statusInfo = paymentStatusMapping[dataObj?.paymentStatus || 0] || { text: "Unknown", color: "#000000" };
            return (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: statusInfo.color,
                    marginRight: '5px',
                  }}
                ></div>
                <div>{statusInfo.text}</div>
              </div>
            );
          },
          show: true,
        },
        {
          title: "Action",
          accessor: "action",
          fixed: "right",
          width: insidePane ? 120 : 90,
          show: true,
          required: true,
        },
      ],
    })
})