
export const MASK={
    'dd-mm-yyyy': {
        mask:'**-**-****',
        format:'DD-MM-YYYY',
        separator:'-',
        numeric:true
    },
    'dd/mm/yyyy':  {
        mask:'**/**/****',
        separator:'/',
        format:'DD/MM/YYYY',
        numeric:true
    },
    'dd.mm.yyyy': {
        mask:'**.**.****',
        separator:'.',
        format:'DD.MM.YYYY',
        numeric:true
    },
    'dd-mm-yyyy HH:mm': {
        mask:'**-**-**** **:**',
        separator:'-',
        format:'DD-MM-YYYY HH:mm',
        numeric:true
    },
}