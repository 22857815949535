import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./GroupTourConstant";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";
import ItinerarySidebarService from "./sideBar/GroupTourSidebarService";
import UIPrefService from "../../components/DataGrid/UIPrefService";
import { GridColumns } from "./GroupTourGridColumns";
import { mapColumnsToAccessor, setFirstUIPreference } from "../../util/Util";
class Service {
    records = [];
    STRUCTURE = [];
    page = 1;
    rowsPerPage = null;
    filterOption = "";
    total = -1;
    constructor() {
        makeAutoObservable(this);
    }

    fetch = async (filterUrl) => {
        const uiPref = "tour.grid"
        let withColumns = [];

        let uiPrefresponse = UIPrefService?.uiPrefs[uiPref]
            ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPref] } }
            : await doGET('/api/ui-preference/' + uiPref + '/detail');

        let result = await setFirstUIPreference(uiPrefresponse?.data?.value, GridColumns?.columns, uiPref)
        if (result) {
            uiPrefresponse = UIPrefService?.uiPrefs[uiPref]
                ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPref] } }
                : await doGET('/api/ui-preference/' + uiPref + '/detail');
        }

        if (!UIPrefService?.uiPrefs[uiPref])
            UIPrefService?.updatePrefs(uiPref, uiPrefresponse?.data?.value ?? '[]');

        if (uiPrefresponse?.status === 200 && uiPrefresponse?.data?.value) {
            const availableColumns = JSON.parse(uiPrefresponse?.data?.value);
            let columnMap = {};
            columnMap= mapColumnsToAccessor(GridColumns?.columns)??{}

            Object.entries(columnMap).forEach(([key, value]) => {
                if (availableColumns?.includes(key)) {
                    Array.isArray(value) ? withColumns.push(...value) : withColumns.push(value);
                }
            });
        }
        if (!this?.rowsPerPage) {
            // const rows = await getUiPrefRows("tour.grid")
            this.rowsPerPage = 40
        }



        if (filterUrl) {
            this.filterOption = filterUrl;
        }
        if (withColumns?.length) {
            filterUrl += `&withColumns=${(withColumns ?? []).join(",")}`;
        }
        const response = await doGET(
            ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl, ItinerarySidebarService.selectedFolder?._id)
        );

        if (response?.status === 200) {
            this.records = response.data.rows;
            this.page = response?.data?.page;
            if (this.page === 1) this.total = response?.data?.total;
        }
    };

    edit = async function (data, id) {
        return await doPUT(ENDPOINTS?.update, data);
    };

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));

        if (response.status === 200) {
            return response.data ? response.data : response;
        }
    };

    save = async (data) => {
        this.records.push(data);
        const response = await doPOST(ENDPOINTS.save, data);
        return response?.data?._id;
    };

    delete = async (id) => {
        const response = await doDELETE(ENDPOINTS.delete(id));
        if (response.status === 200) {
            this.fetch();
        }
    };

    bulkDelete = async (ids = []) => {
        const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
        if (response.status === 200) {
            this.fetch();
        }
    };

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);

    };

}

const GroupTourService = new Service();
export default GroupTourService;

