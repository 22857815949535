export const ENDPOINTS = {
  getCitiesByCountryAndStateOld: (country, state) =>
    country && state
      ? `/api/o/country/${country}/state/${state}/city/list`
      : `/api/o/country/${country}/city/list`,
  getCitiesByCountryAndState: (country, state) =>
    country && state
      ? `/api/o/city/grid?country=${country}&state=${state}&rows=-1`
      :country? `/api/o/city/grid?country=${country}&rows=-1`:  `/api/o/city/grid?rows=-1`,
};
