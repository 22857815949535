export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/stay/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/stay/${id}/detail`,
  save: `/api/stay/create`,
  delete: (id) => `/api/stay/${id}/delete`,
  bulkDelete: (id) => `/api/stay/delete`,
  update: `/api/stay/update`,
};

export const STRUCTURE = [
  {
    label: "Hotel Name",
    filterLabel: "Hotel Name",
    filterName: "hotelName",
    type: "text",
    description: "Filter by Hotel Name",
    icon: "uil-plane font-size-16 "
  },
  {
    label: "Booking No",
    filterLabel: "Booking No",
    filterName: "bookingNo",
    type: "text",
    description: "Filter by Booking No",
    icon: "uil-plane font-size-16 "
  },
];
export const SELECTSTRUCTURE = {
  hotels: [
    { label: "Taj", value: "Taj" },
    { label: "De Merilian", value: "De Merilianigo" },
    { label: "JW Marriot", value: "JW Mariot" },
  ],
  roomTypes: [
    { label: "Standard", value: "STANDARD" },
    { label: "Deluxe", value: "DELUXE" },
    { label: "Super Deluxe", value: "SUPER_DELUXE" },
    { label: "Luxury", value: "LUXURY" },
    { label: "Suit", value: "SUIT" },
  ],
  status: [
    { label: "Booked", value: 1 },
    { label: "CheckedIn", value: 2 },
    { label: "CheckedOut", value: 3 },
    { label: "Cancelled", value: 4 },
  ],
}
export const STAYS_STATUS_MAP = {
  0: { label: "Planned", color: "#2980B9" },
  1: { label: "Booked", color: "#2980B9" },
  2: { label: "CheckedIn", color: "#A569BD" },
  3: { label: "CheckedOut", color: "#CB4335" },
  4: { label: "Cancelled", color: "#CCCCFF" },
}

export const STAY_TOGGLE_DATA = (t) => ({
  module: [
    { label: t("Planned"), value: 0 },
    { label: t("Booked"), value: 1 },
    { label: t("Checked in"), value: 2 },
    { label: t("Checked out"), value: 3 },
    { label: t("Cancelled"), value: 4 },
  ],
})