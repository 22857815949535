import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    Col,
    Form,
    Row
} from "reactstrap";
import {
    AddressField,
    AmountField,
    CurrencySelect,
    InputField,
    PhoneNum,
    SelectField,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import HotelService from "./HotelService";
import "react-calendar/dist/Calendar.css";
import { SELECTSTRUCTURE } from "./HotelConstant";
import { UserContext } from "../../store/context/UserContext";

const EditHotel = ({ editId, onChange = () => { }, hasErr }) => {
    const labelStyle = {
        width: 120,
        fontSize: 12,
        fontWeight: "500",
        marginRight: 0
    };

    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const { userContent } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        name: "",
        code: "",
        address: {
            addressLine: "",
            city: "",
            state: "",
            country: "",
            pinCode: ""
        },
        starRating: 0,
        kind: "",
        hotelExtn: {
            contactEmail: "",
            contactNos: [
                {
                    countryCode: userContent?.countryCode ? userContent?.countryCode : 91,
                    phone: null
                }
            ],
            roomPrices: [
                {
                    roomType: "", // STANDARD, DELUXE, SUPER_DELUXE, LUXURY, SUIT
                    price: 0
                }
            ],
            notes: "",
            currency: "INR",
            properties: []
        }
    });

    const addRoom = () => {
        const newRoom = {
            roomType: "",
            price: 0
        }
        const updatedRooms = data?.hotelExtn?.roomPrices ? [...data?.hotelExtn?.roomPrices, newRoom] : [newRoom];
        setData({
            ...data,
            hotelExtn: {
                ...data?.hotelExtn,
                roomPrices: updatedRooms,
            }
        })
    };

    const handleRemoveRoom = (index) => {
        const updatedRooms = data?.hotelExtn?.roomPrices.filter((_, i) => i !== index);
        setData({
            ...data,
            hotelExtn: {
                ...data?.hotelExtn,
                roomPrices: updatedRooms,
            }
        })
    };

    const addContactDetails = () => {
        const newContact = {
            countryCode: userContent?.countryCode ? userContent?.countryCode : 91,
            phone: null
        }
        const updatedContacts = data?.hotelExtn?.contactNos ? [...data?.hotelExtn?.contactNos, newContact] : [newContact];
        setData({
            ...data,
            hotelExtn: {
                ...data?.hotelExtn,
                contactNos: updatedContacts,
            }
        })
    };

    const handleRemoveContactDetails = (index) => {
        const updatedContacts = data?.hotelExtn?.contactNos.filter((_, i) => i !== index);
        setData({
            ...data,
            hotelExtn: {
                ...data?.hotelExtn,
                contactNos: updatedContacts,
            }
        })
    };

    const fetch = async () => {
        try {
            if (editId) {
                let hotel = await HotelService.get(editId);
                setData({ ...hotel });
            }
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        setData({
            name: "",
            code: "",
            address: {
                addressLine: "",
                city: "",
                state: "",
                country: "",
                pinCode: ""
            },
            starRating: 0,
            kind: "",
            hotelExtn: {
                contactEmail: "",
                contactNos: [
                    {
                        countryCode: userContent?.countryCode ? userContent?.countryCode : 91,
                        phone: null
                    }
                ],
                roomPrices: [
                    {
                        roomType: "", // STANDARD, DELUXE, SUPER_DELUXE, LUXURY, SUIT
                        price: 0
                    }
                ],
                notes: "",
                currency: "INR",
                properties: []
            }
        });
        setLoading(true);
        fetch();
        setLoading(false);
    }, [editId, HotelService?.version]);

    useEffect(() => {
        onChange(data);
    }, [data]);

    return (
        <React.Fragment>
            {loading ? (
                <ThreeBounce />
            ) : (
                <Form className="p-3  pt-2">
                    <Row>
                        <Col className="col-6">
                            <InputField
                                placeholder={t("Hotel Name")}
                                value={data?.name}
                                showErr={hasErr}
                                required={true}
                                label={t("Hotel Name")}
                                onChange={(v) => setData({ ...data, name: v })}
                                error={data?.name?.length === 0 ? t("Please enter name") : ""}
                                type="text"
                            />
                        </Col>
                        <Col className="col-6">
                            <InputField
                                placeholder={t("Code")}
                                value={data?.code}
                                required={false}
                                label={t("Code")}
                                onChange={(v) => setData({ ...data, code: v })}
                                error={data?.code?.length === 0 ? t("Please enter code") : ""}
                                type="text"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <AddressField
                            required={true}
                            placeholder={t("Address")}
                            label={t("Address")}
                            value={data?.address}
                            onChange={(v) => setData({ ...data, address: v })}
                            error={data?.address?.length === 0 ? t("Please enter address") : ""}
                            showErr={hasErr}
                        />
                    </Row>

                    <Row>
                        <Col className="col-6">
                            <SelectField
                                data={SELECTSTRUCTURE.starRatings}
                                required={false}
                                value={data?.kind}
                                onChange={(v) => {
                                    setData({
                                        ...data,
                                        starRating: v.value,
                                    });
                                }}
                                showErr={hasErr}
                                label={t("Star Rating")}
                                placeholder={t("Star Rating")}
                            />
                        </Col>
                        <Col className="col-6">
                            <SelectField
                                data={SELECTSTRUCTURE.kinds}
                                required={false}
                                value={data?.kind}
                                onChange={(v) => {
                                    setData({
                                        ...data,
                                        kind: v.value,
                                    });
                                }}
                                showErr={hasErr}
                                label={t("Kind")}
                                placeholder={t("Kind")}
                            />
                        </Col>
                    </Row>

                    <Card className="mt-3 p-3">
                        <h4>{t("Contact Details")}</h4>
                        {
                            data?.hotelExtn?.contactNos?.map((e, index) => (
                                <div key={index} className="d-flex align-items-center">
                                    <div style={{ flex: 1 }}>
                                        <PhoneNum
                                            placeholder={t("Phone No")}
                                            value={e}
                                            label={t("Phone")}
                                            error={data?.hotelExtn?.contactNos?.[0]?.phone === 0 ? "" : t("Please enter Phone Number")}
                                            showErr={hasErr}
                                            className="col-12"
                                            onChange={(v) => {
                                                const newContacts = data?.hotelExtn?.contactNos?.map((e, i) => {
                                                    if (i === index) {
                                                        return v
                                                    }
                                                    else {
                                                        return e
                                                    }
                                                })
                                                setData({
                                                    ...data, hotelExtn: { ...data?.hotelExtn, contactNos: newContacts }
                                                })
                                            }}
                                            type="text"
                                        />
                                    </div>
                                    {data?.hotelExtn?.contactNos?.length === 1 ? null : <div onClick={() => handleRemoveContactDetails(index)} className="mx-2" style={{ cursor: "pointer" }}>
                                        <i
                                            style={{
                                                fontSize: "20px",
                                                border: "1px solid red",
                                                borderRadius: "20px",
                                                color: "red",
                                            }}
                                            className="dripicons-cross"
                                        />
                                    </div>}
                                    <div
                                        style={{
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                            width: "22px",
                                        }}
                                    >
                                        {index === data?.hotelExtn?.contactNos?.length - 1 ? <div style={{ cursor: "pointer" }} onClick={addContactDetails}>
                                            <i id={`not-clickable-add_passenger`}
                                                style={{
                                                    fontSize: "20px",
                                                    border: "1px solid black",
                                                    borderRadius: "20px",
                                                    color: "black",
                                                }}
                                                className="dripicons-plus"
                                            />
                                        </div> : null}
                                    </div>
                                </div>
                            ))
                        }
                        <Row>
                            <Col className="col-12">
                                <InputField
                                    placeholder={t("Email address")}
                                    value={data?.hotelExtn?.contactEmail}
                                    required={false}
                                    label={t("Contact Email")}
                                    onChange={(v) => {
                                        setData({ ...data, hotelExtn: { ...data?.hotelExtn, contactEmail: v } });
                                    }}
                                    error={data?.code?.length === 0 ? t("Please enter code") : ""}
                                    type="text"
                                />
                            </Col>
                        </Row>
                    </Card>

                    <Card className="mt-3 p-3">
                        <h4>{t("Rooms Detail")}</h4>
                        <Row>
                            <Col className="col-6">
                                <CurrencySelect
                                    placeholder={t("Currency")}
                                    required={false}
                                    value={data?.hotelExtn?.currency}
                                    label={t("Currency")}
                                    onChange={(v) => setData({ ...data, hotelExtn: { ...data?.hotelExtn, currency: v.value } })}
                                    type="text"
                                />
                            </Col>
                        </Row>
                        {
                            data?.hotelExtn?.roomPrices?.map((e, index) => (
                                <div key={index} className="d-flex align-items-center">
                                    <Row style={{ flex: 1 }}>
                                        <Col className="col-6">
                                            <SelectField
                                                data={SELECTSTRUCTURE.roomTypes}
                                                required={false}
                                                value={e?.roomType}
                                                onChange={(v) => {
                                                    const newRooms = data?.hotelExtn?.roomPrices?.map((e, i) => {
                                                        if (i === index) {
                                                            return { ...e, roomType: v?.value }
                                                        }
                                                        else return e
                                                    })
                                                    setData({
                                                        ...data,
                                                        hotelExtn: { ...data?.hotelExtn, roomPrices: newRooms },
                                                    });
                                                }}
                                                showErr={hasErr}
                                                label={t("Room type")}
                                                placeholder={t("Room type")}
                                            />
                                        </Col>
                                        <Col className="col-6 d-flex align-items-center">
                                            <div style={labelStyle}>Price</div>
                                            <AmountField
                                                outline={false}
                                                className="zr_normal_fee-field"
                                                value={e?.price}
                                                onChange={(v) => {
                                                    const newRooms = data?.hotelExtn?.roomPrices?.map((e, i) => {
                                                        if (i === index) {
                                                            return { ...e, price: v }
                                                        }
                                                        else return e
                                                    })
                                                    setData({
                                                        ...data,
                                                        hotelExtn: { ...data?.hotelExtn, roomPrices: newRooms },
                                                    });
                                                }}
                                                type="number"
                                            />
                                        </Col>
                                    </Row>
                                    {data?.hotelExtn?.roomPrices?.length === 1 ? null : <div onClick={() => handleRemoveRoom(index)} className="mx-2" style={{ cursor: "pointer" }}>
                                        <i
                                            style={{
                                                fontSize: "20px",
                                                border: "1px solid red",
                                                borderRadius: "20px",
                                                color: "red",
                                            }}
                                            className="dripicons-cross"
                                        />
                                    </div>}
                                    <div
                                        style={{
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                            width: "22px",
                                        }}
                                    >
                                        {index === data?.hotelExtn?.roomPrices?.length - 1 ? <div style={{ cursor: "pointer" }} onClick={addRoom}>
                                            <i id={`not-clickable-add_passenger`}
                                                style={{
                                                    fontSize: "20px",
                                                    border: "1px solid black",
                                                    borderRadius: "20px",
                                                    color: "black",
                                                }}
                                                className="dripicons-plus"
                                            />
                                        </div> : null}
                                    </div>
                                </div>
                            ))
                        }
                    </Card>

                    <div className="mt-3">
                        <div style={{ color: "#000", marginBottom: "0.5rem", }}>{t("Notes")}</div>
                        <textarea
                            className="inputfield"
                            rows={3}
                            style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", outline: "none" }}
                            value={data?.hotelExtn?.notes}
                            onChange={(e) => {
                                setData({ ...data, hotelExtn: { ...data?.hotelExtn, notes: e.target.value } });
                            }}
                        >
                        </textarea>
                    </div>
                </Form>
            )}
        </React.Fragment>
    );
};

export default observer(EditHotel);