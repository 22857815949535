export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/vehicle-type/grid?`;
    if (sizePerPage) {
      url += `&rows=${sizePerPage}`;
    } else {
      url += '&rows=-1';
    }    
    // if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `&${filterUrl}`;
    if (page) url += `&page=${page}`
    return url;
  },
  get: (id) => `/api/vehicle-type/${id}/detail`,
  save: `/api/vehicle-type/create`,
  delete: (id) => `/api/vehicle-type/${id}/delete`,
  update: `/api/vehicle-type/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Vehicle Type",
    filterName: "name",
    type: "text",
  },
];

