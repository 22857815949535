import { observer } from 'mobx-react-lite';
import React from 'react';
import { FaRegStar } from 'react-icons/fa';
import { ListGroupItem } from 'reactstrap';
import EmailTags from './EmailTags';
import SupervisorSelect from '../../../components/Dropdowns/SupervisorSelect/SupervisorSelect';
import { formatDateWithConditions } from '../../../util/Util';

const EmailList = ({ showBody, inbound,  emails, onSelectEmail, selectedMail,expand=false }) => {
    const openEmail = (email) => {
        onSelectEmail(email);
    };
    const isSelected = (email) => {
        return selectedMail && selectedMail === email._id;
    };

    const truncateContent = (content, maxLength) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;
        const textContent = tempDiv.textContent || tempDiv.innerText || "";
        return textContent.length > maxLength ? textContent.substring(0, maxLength) + '...' : textContent;
    };


    return (
        <div className="zr_crm_email-list">
            {emails.map(email => (
                expand ? (
                    <ListGroupItem
                        onClick={() => openEmail(email)}
                        key={email._id}
                        style={{ maxHeight: "50px" }}

                        className={`zr_crm_email-item d-flex align-items-center   ${isSelected(email) ? 'zr_crm_selected-email' : ''}`}
                    >
                        <div className="row w-100 py-2 gap-2 d-flex justify-content-between  ">
                            {/* Checkbox Column */}
                            <div className="col-auto d-flex align-items-center   py-2">
                                <input
                                    type="checkbox"
                                    className="zr_crm_email-checkbox"
                                    onClick={(e) => e?.stopPropagation()}
                                />
                            </div>

                            <div className="col-2 d-flex text-truncate align-items-center   ">
                                {inbound ? <span className="text-truncate">
                                    {email.from}
                                </span> : (
                                    <span className='text-truncate'>
                                        {email.to[0]}
                                    </span>
                                )}
                            </div>

                            {/* Subject and Body Column */}
                            <div className="col-6 d-flex flex-column   justify-content-center">
                                <div className="zr_crm_email-details d-flex align-items-center text-truncate">
                                    {email?.trips?.length > 0 && <EmailTags type={"trips"} className="mt-1" />}
                                    <b className="zr_crm_email-subject mx-2 text-truncate">{email.subject}</b>
                                    {showBody && <span className="zr_crm_email-preview text-truncate">{truncateContent(email.body, 40)}</span>}
                                </div>
                            </div>

                            {/* Supervisor Column */}
                            <div className="col-1 d-flex align-items-center justify-content-center  ">
                                {email?.supervisors?.length > 0 &&
                                    <SupervisorSelect
                                        className={"mt-2"}
                                        value={email?.supervisors}
                                        asLabel
                                        showName={false}
                                    />
                                }
                            </div>

                            {/* Date Column */}
                            <div className="col-auto d-flex align-items-center  ">
                                {(email?.createdAt || email?.sentAt) ?
                                    formatDateWithConditions(email.createdAt ?? email.sentAt)
                                    : ""
                                }
                            </div>
                        </div>
                    </ListGroupItem>
                ) : (
                    <ListGroupItem
                        onClick={() => openEmail(email)}
                        key={email._id}

                        className={`  zr_crm_email-item d-flex align-items-center ${isSelected(email) ? 'zr_crm_selected-email' : ''}`}
                    >
                        <input
                            type="checkbox"
                            className="zr_crm_email-checkbox"
                            onClick={(e) => e?.stopPropagation()}
                        />

                            <div className="d-flex flex-column   w-100">
                                {/* Email Info */}
                                <div className="zr_crm_email-info">
                                    <div className="zr_crm_email-to"> {inbound ? email.from : (
                                        <>
                                            {email.to[0]}
                                            {/* {email.to.length > 1 && (
                                            <span className="badge rounded-circle ms-2 d-flex align-items-center justify-content-center font-size-10" style={{ width: '24px', height: '24px' }}>
                                                +{email.to.length - 1}
                                            </span>
                                        )} */}
                                        </>
                                    )}
                                </div>
                                    <div className="zr_crm_email-details">
                                        <span className="zr_crm_email-subject w-100">{email.subject}</span>
                                        {showBody && <span className="zr_crm_email-preview">{truncateContent(email.body, 50)}</span>}
                                    </div>

                                </div>
                                {/* Email Date */}
                                <div className="zr_crm_email-date   d-flex justify-content-between">
                                    {/* Trips Column */}
                                    {email?.trips?.length > 0 && (
                                        <div className="col-auto d-flex justify-content-center align-items-center">
                                            <EmailTags type={"trips"} className="mt-1" />
                                        </div>
                                    )}

                                    {/* Supervisors and Date Column */}
                                    <div className="d-flex flex-grow-1 justify-content-end">
                                        {email?.supervisors?.length > 0 && (
                                            <div className="d-flex align-items-center">
                                                <SupervisorSelect
                                                    className=""
                                                    value={email.supervisors}
                                                    asLabel
                                                    showName={false}
                                                />
                                            </div>
                                        )}
                                        <div className="mx-1 d-flex align-items-center">
                                            {formatDateWithConditions(email.createdAt ?? email.sentAt) ?? ""}
                                        </div>
                                    </div>
                                </div>

                            </div>

                    </ListGroupItem>
                )
            ))}
        </div>


    );
};

export default observer(EmailList);
