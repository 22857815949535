import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import { ModalComponent } from '../../components';
import EditStays from '../Stay/EditStay';
import StayService from '../Stay/StayService';
import GroupTourSidebarService from './sideBar/GroupTourSidebarService';
import "./style.css";
import { STAYS_STATUS_MAP } from '../Stay/StayConstant';
import { FaCheckCircle } from "react-icons/fa";
import { convertYYYYMMDDtoDDMMMM } from '../../util/DateUtil';

const StayGrid = ({
    getStaysRecords = () => { },
    hideAdd,
    passengers = [],
    selectedStays = [],
    completeObject = false,
    onChange = () => { },
    fixedStays = [],
    tour_id = null
}) => {
    const [loading, setLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [modal, setModal] = useState(false);
    const [editId, setEditID] = useState("");
    const [stays, setStays] = useState("");

    const navigate = useNavigate();

    const fetchStays = async (passengers) => {
        setLoading(true);
        try {
            const passengersId = passengers?.map(passenger => passenger.customer_id)?.join(",");
            const staysRecords = await StayService.fetch(`guests.customer_id=in[${passengersId}]${!GroupTourSidebarService.selectedFolder._id ? "" : `&space_id=${GroupTourSidebarService.selectedFolder._id}`}`, true);
            getStaysRecords(staysRecords)
            setStays(staysRecords)
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStaysUsingTourId = async (tour_id) => {
        setLoading(true);
        try {
            const staysRecords = await StayService.fetch(`tour_id=${tour_id}`, true);
            getStaysRecords(staysRecords);
            setStays(staysRecords);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     if (passengers?.length) {
    //         fetchStays(passengers);
    //     }
    // }, [passengers]);

    useEffect(() => {
        if (tour_id) {
            fetchStaysUsingTourId(tour_id);
        }
    }, [tour_id]);

    const toggleRow = (index, _id) => {
        setExpandedRow(expandedRow === index ? null : index);
        setEditID(_id);
        setModal(true);
    };

    const handleCheckboxClick = (stay) => {
        let updatedStays;
        if ((selectedStays ?? []).some(s => completeObject ? s?._id === stay?._id : s === stay?._id)) {
            updatedStays = (selectedStays ?? []).filter(s => completeObject ? s?._id !== stay?._id : s !== stay?._id);
        } else {
            updatedStays = completeObject ? [...(selectedStays ?? []), stay] : [...(selectedStays ?? []), stay?._id];
        }
        onChange(updatedStays, stay);
    };

    const getColorStatus = (status = 0) => ({
        height: 10,
        width: 10,
        borderRadius: 20,
        backgroundColor: STAYS_STATUS_MAP[status].color
    })

    // if ((fixedStays?.length ? fixedStays : stays).length === 0) {
    //     return <div>No Stays found.</div>;
    // }

    const renderGuestInfo = (passenger, index) => (
        <div className="d-flex align-items-center " key={passenger.customer_id}>
            <div className="d-flex align-items-center">
                {index >= 0 && <span className="larger-bullet">&#8226;</span>}
                <div>{passenger.name}</div>
            </div>
            <div style={{ marginLeft: '8px' }}>{passenger.phone?.countryCode ? passenger.phone?.countryCode : ''}-{passenger?.phone?.phone}</div>
            <div style={{ marginLeft: '8px' }}>{passenger.email}</div>
        </div>
    );

    return (
        <>
            <Card className="pb-3">
                <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                    <div>Hotels</div>
                    {hideAdd ? null : (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={() => setModal(true)}
                                className="d-flex align-items-center p-1 me-2">
                                <FaPlus />
                            </Button>
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className='p-3'>
                        loading
                    </div>
                ) : (
                    <div className="px-2 overflow-y-auto">
                        {(fixedStays?.length ? fixedStays : stays)?.length ? (
                            <>
                                <table>
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                            <th className='zr-thead'>Status</th>
                                            <th className='zr-thead'>Name</th>
                                            <th className='zr-thead'>City</th>
                                            <th className='zr-thead'>Check-In</th>
                                            <th className='zr-thead'>Check-Out</th>
                                            <th className="zr-thead text-center">{"Complementary"}<br />{"Pick & Drop"}</th>
                                            <th className='zr-thead'>Breakfast Included</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(fixedStays?.length ? fixedStays : stays).map((data, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="zr_flight_accordion_row" onClick={() => toggleRow(index, data?._id)} style={{ cursor: 'pointer' }}>
                                                    <td className='d-flex align-items-center'>
                                                        <div style={getColorStatus(data?.status)} />
                                                        <div className="font-size-13 ms-2 mb-1 fw-normal">{STAYS_STATUS_MAP[data?.status]?.label ?? "--"}</div>
                                                    </td>
                                                    <td>
                                                        <div className="font-size-13 mb-1 fw-normal"> {data.hotelName ?? "-"}</div>
                                                    </td>
                                                    <td>
                                                        <>{data?.city}</>
                                                    </td>
                                                    <td>
                                                        <>{convertYYYYMMDDtoDDMMMM(data?.checkInDate)}</>
                                                    </td>
                                                    <td>
                                                        <>{convertYYYYMMDDtoDDMMMM(data?.checkOutDate)}</>
                                                    </td>
                                                    <td className='text-center'>
                                                        <>{data?.complementaryPickNDrop && <FaCheckCircle color="green" size={15} />}</>
                                                    </td>
                                                    <td className='text-center'>
                                                        <>{data?.breakfast && <FaCheckCircle color="green" size={15} />}</>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <div>No Hotels found.</div>
                        )}
                    </div>
                )}
            </Card>

            {modal && (
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={() => {
                        setEditID("");
                        setModal(false);
                    }}
                    isOpen={modal}
                    modalSizeStyle={{
                        maxHeight: "calc(100vh - 80px)"
                    }}
                >
                    <ModalComponent.Body>
                        <EditStays
                            insidePane={true}
                            editId={editId}
                            tour_id={tour_id}
                            onSave={() => {
                                setModal(false);
                                fetchStaysUsingTourId(tour_id);
                            }}
                            onDelete={() => {
                                setModal(false);
                                fetchStaysUsingTourId(tour_id);
                            }}
                            formConfig={{
                                showPassengers: false
                            }}
                            prePopulatedData={{
                                guests: passengers
                            }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            )}
        </>
    );
};

export default observer(StayGrid);
