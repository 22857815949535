import { Tooltip } from 'react-tooltip';
import { SupplierSelect } from '../../components';

export const GridColumns = {
  version: 1,
  columns: [

    {
      title: "Name",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.address?.name}
        </div>

      },
      show: true,
    },
    {
      title: "Code",
      accessor: "code",
      show: true,
    },

    {
      title: "Supplier",
      accessor: "supplier",
      renderer: (dataObj) => {
          return (
              dataObj?.supplier_id ? <SupplierSelect
                  asLabel
                  value={dataObj?.supplier_id}
                  className=""
              /> : <div>--</div>
          )
      },
      show: true,
  },

    {
      title: "Address",
      renderer: (dataObj) => {
        const truncateString = (inputString, maxLength) => {
          return inputString.length <= maxLength ? inputString : inputString.slice(0, maxLength) + "...";
        }

        return <div>
          {dataObj?.address?.addressLine?.length > 20 ?
            <div className="row">

              <Tooltip noArrow style={{
                zIndex: 999999,
                maxWidth: '300px',
                height: "auto",
                backgroundColor: '#F2F2F2',
                color: "black",
                whiteSpace: 'pre-wrap',
                overflow: 'auto',
                fontWeight: 'bold'
              }} anchorSelect={`#not-clickable-${dataObj?._id}`}>
                {dataObj?.address?.addressLine}
              </Tooltip>
              <div id={`not-clickable-${dataObj?._id}`}>
                {truncateString(dataObj?.address?.addressLine, 20)}
              </div>
            </div> :
            dataObj?.address?.addressLine
          }
        </div >
      },
      show: true,
    },

    {
      title: "City",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.address?.city}
        </div>

      },
      show: true,
    },

    {
      title: "Country",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.address?.country}
        </div>

      },
      show: true,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
    {
      title: "Has SelfDrive",
      accessor: "hasSelfDrive",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.hasSelfDrive == true ?
            <i
              className="mdi mdi-checkbox-marked font-size-20 mx-2"
              style={{ zIndex: "999" }}
            />
            : <i
              className="mdi mdi-checkbox-blank-outline font-size-20 mx-2"
              style={{ zIndex: "999" }}
            />}
        </div>
      },
      show: true,
    },
  ],
};
