import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { FaBuilding, FaExchangeAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { Checkbox, CitySelect, CountrySelect, DeleteButton, InputField, SaveButton, SelectField, StateSelect, ToggleSelector } from "../../components";
import HourMinutePicker from "../../components/DateSelects/HourMinutePicker";
import DateSelectCustom from "../../components/DateTimePickerWithInterval/DateSelectCustom";
import ActionMenu from "../../components/Dropdowns/ActionMenu/ActionMenu";
import ReminderButton from "../../components/Reminders/ReminderButton";
import TagPicker from "../../components/TagSelector/TagPicker";
import Fee from "../../components/dnd/forms/itinerary/components/SupplierFare/Fee";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import PassengerSelect from "../Booking/PassengerSelect";
import GroupTourSidebarService from "../GroupTour/sideBar/GroupTourSidebarService";
import ChangeSpaceModal from "../GroupTour/sideBar/changeSpaceModal/ChangeSpaceModal";
import { SELECTSTRUCTURE, STAY_TOGGLE_DATA } from "./StayConstant";
import StayService from "./StayService";
import DateTimePickerWithInterval from "../../components/DateTimePickerWithInterval";
import { joinToUnixTimestamp } from "../Flight/helper";
import moment from "moment";
import TimeLine from "../../components/Common/TimeLine";
import HotelSelect from "../../components/Dropdowns/HotelSelect/HotelSelect";
import PassengersNew from "../Booking/PassengersNew";

const EditStays = ({
  formConfig = {
    showPassengers: true
  },
  editId,
  onSave,
  onDelete,
  insidePane,
  prePopulatedData = null,
  tour_id = null
}) => {
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);

  let navigate = useNavigate();

  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [hasErr, setHasErr] = useState(false)
  const [changeSpaceModal, setChangeSpaceModal] = useState(false);

  const fetch = async () => {
    try {
      setLoading(true);
      let data = await StayService.get(editId);
      setData({
        ...data,
        rooms: data?.rooms ? data?.rooms : [],
        guests: data?.guests ? data?.guests : [
          {
            index: 0,
            anonymous: false,
            salutation: "Mr",
            phone: {
              countryCode: 91,
            },
          },
        ],
        // customer: data?.customer ? data?.customer : [
        //   {
        //     index: 0,
        //     anonymous: false,
        //     salutation: "Mr",
        //     phone: {
        //       countryCode: 91,
        //     },
        //   },
        // ],
      })

    } catch (error) {
      showError(error)
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editId) {
      fetch()
    } else {
      setData({
        ...data,
        units: 1,
        complementaryPickNDrop: false,
        status: 0,
        rooms: data?.rooms ? data?.rooms : [],
        guests: data?.guests ? data?.guests : [
          {
            index: 0,
            anonymous: false,
            salutation: "Mr",
            phone: {
              countryCode: 91,
            },
          },
        ],

      })
    }
    setLoading(false);
  }, [editId, StayService?.version]);



  const handleEditSpace = async ({ payload }) => {
    setLoading(true);
    try {
      if (editId) {
        await StayService.edit(payload, editId);
        setLoading(false);
        showMessage(t('Space changed successfully'));
      }
      onSave();
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false)
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (data?.status === 0) {
      delete data?.checkInTime
      delete data?.checkOutTime
    }
    try {
      const payload = {
        ...data,
        ...(tour_id && { tour_id })
      }
      if (editId) {
        await StayService.edit(payload, editId);
        setLoading(false);
        showMessage(t("Stay updated successfully."));
      } else {
        const stay = await StayService.save({
          ...payload,
          space_id: GroupTourSidebarService?.selectedFolder?._id
        });
        setLoading(false);
        showMessage(t("Stay saved successfully."));
        if (!insidePane) navigate(`/stay/edit/${stay}`);
      }
      onSave();
    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const handleDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await StayService.delete(id);
        setLoading(false);
        onDelete();
        showMessage("Stay Deleted SuccessFully", "Deleted");
        navigate("/stay");
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  useEffect(() => {
    if (prePopulatedData) {
      setData(prev => ({ ...prev, ...prePopulatedData }))
    }
  }, [prePopulatedData])

  const handleChangeSpace = () => {
    setChangeSpaceModal(true)
  };

  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div className="zr_layout-header p-2 d-flex justify-content-between" style={{ flexShrink: 0 }} >

            <div className="d-flex w-100 justify-content-between align-items-center">

              <div className="d-flex align-items-center px-2">
                {data?._id ? t("Edit Stay") : t("Add Stay")}
                <TagPicker
                  className="ms-3"
                  kind={"stay"}
                  onChange={(v) => setData(prev => ({
                    ...prev,
                    tags: v
                  }))}
                  value={data?.tags}
                />
              </div>


              {editId ? <div className="d-flex align-items-center">

                <ReminderButton
                  record_id={editId}
                  module={"stay"}
                  className={"me-0"}
                  space_id={GroupTourSidebarService?.selectedFolder?._id}
                />

                <ActionMenu
                  data={[
                    { label: 'Change Space', icon: <FaExchangeAlt />, onClick: handleChangeSpace },
                  ]}
                  menuPosition="right"
                  className="zr_action_menu me-3 ms-2"
                />

              </div> : null}


            </div>


            {/* <CrossButton  onClick={onBack} style={{position:"relative !important"}}/> */}
          </div>
          <Form
            className="p-3"
            style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
          >
            <ToggleSelector
              style={{
                fontSize: '12px',
                maxWidth: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                width: 'max-content',
                marginLeft: 8,
                marginBottom: 15,
              }}
              compact
              zeroMargin
              data={STAY_TOGGLE_DATA(t).module}
              value={data?.status}
              onChange={(selectedValue) => setData({ ...data, status: selectedValue })}
            />
            <Row className="mb-2">
              <Col className="col-6">
                <CountrySelect
                  label={"Country"}
                  placeholder={"Country"}
                  value={data?.country}
                  onChange={(v) => {
                    setData({
                      ...data,
                      country: v?.value,
                    })
                  }}
                  innerContainerClassName="input-group-pre"
                  className="w-100 "
                  outline={true}
                />
              </Col>
              <Col className="col-6">
                <CitySelect
                  onlyCountry
                  label={"City"}
                  placeholder={"City"}
                  country={data?.country}
                  value={data?.city}
                  onChange={(v) => {
                    setData({
                      ...data,
                      city: v?.value,
                    })
                  }}
                  innerContainerClassName="input-group-pre"
                  className="w-100 "
                  outline={true}
                />
              </Col>
            </Row>

            <Row className="">
              <Col className="col-4">
                <DateTimePickerWithInterval
                  noLabel
                  placeholder="Check in time"
                  className=""
                  outline
                  timestamp
                  showTimeSelect={data?.status !== 0}
                  format={data?.status === 0 ? "dd-mm-yyyy" : "dd-mm-yyyy HH:mm"}
                  value={joinToUnixTimestamp(data?.checkInDate, data?.checkInTime)}
                  onChange={(v) => {
                    const momentObj = moment.unix(v)
                    setData({
                      ...data,
                      checkInDate: momentObj.format('YYYYMMDD'),
                      checkInTime: data?.status === 0 ? "0000" : momentObj.format('HHmm')
                    });
                  }}
                />
              </Col>
              <Col className="col-4 mt-3">
                <TimeLine
                  icon={<FaBuilding style={{ fontSize: 18, color: '#9E9E9E' }} />}
                  boardingDate={data?.checkInDate}
                  boardingTime={data?.checkInTime}
                  landingDate={data?.checkOutDate}
                  landingTime={data?.checkOutTime}
                  byHours={false}
                />
              </Col>
              <Col className="col-4">
                <DateTimePickerWithInterval
                  noLabel
                  placeholder="Check out time"
                  className=""
                  outline
                  timestamp
                  showTimeSelect={data?.status !== 0}
                  format={data?.status === 0 ? "dd-mm-yyyy" : "dd-mm-yyyy HH:mm"}
                  value={joinToUnixTimestamp(data?.checkOutDate, data?.checkOutTime)}
                  onChange={(v) => {
                    const momentObj = moment.unix(v)
                    setData({
                      ...data,
                      checkOutDate: momentObj.format('YYYYMMDD'),
                      checkOutTime: data?.status === 0 ? "0000" : momentObj.format('HHmm')
                    });
                  }}
                />
              </Col>
            </Row>

            {data?.status === 0 ? null : (
              <Row>
                <Col>
                  <HotelSelect
                    value={data?.hotelId}
                    onChange={(v) => {
                      setData({
                        ...data,
                        hotelName: v?.name,
                        address: v?.address?.addressLine,
                        hotelId: v?._id
                      })
                    }}
                    innerContainerClassName="input-group-pre"
                    className="w-100 "
                    outline={true}
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-2 align-items-center">
              <Col className="col-6">
                <SelectField
                  data={SELECTSTRUCTURE.roomTypes}
                  outline
                  required={false}
                  value={data?.roomType}
                  onChange={(v) => {
                    setData({ ...data, roomType: v.value })
                  }}
                  showErr={hasErr}
                  label={t("Room type")}
                  placeholder={t("Room type")}
                />
              </Col>
              <Col className="col-6">
                <InputField
                  min={"1"}
                  outline
                  placeholder="Numbor of Rooms"
                  label="Numbor of Rooms"
                  value={data?.units}
                  onChange={(v) => setData({ ...data, units: v })}
                  type="number"
                  className="input-group-prepend"
                />
              </Col>
            </Row>

            {data?.status === 0 ? null : <Row className="d-flex align-items-center">
              <Col className="col-3">
                <Checkbox
                  leftAlign
                  label={t("Has Pickup & Drop")}
                  checked={data?.complementaryPickNDrop}
                  className="my-3 col-4"
                  onClick={(v) => {
                    setData((data) => ({
                      ...data,
                      complementaryPickNDrop: !data?.complementaryPickNDrop
                    }));
                  }}
                />
              </Col>
              <Col className="col-3">
                <Checkbox
                  leftAlign
                  label={t("Breakfast Included")}
                  checked={data?.breakfast}
                  className="my-3 col-4"
                  onClick={(v) => {
                    setData((data) => ({
                      ...data,
                      breakfast: !data?.breakfast
                    }));
                  }}
                />
              </Col>
            </Row>}

            {/* <Row className="mt-3 mb-3">

              <TokenInput
                label={t("Rooms")}
                type="text"
                value={data?.rooms ?? []} // [] array of strings
                onChange={(v) => {
                  setData({ ...data, rooms: v })  // v should be [] array of strings
                  console.log(data)
                }}
                placeholder={t("Add Room")}
                outline
                className="col-12 flex-1"
              />

            </Row> */}

            {formConfig?.showPassengers ? <Row className="mt-3 mb-3">
              <div><b>Guests</b></div>
              <PassengersNew
                hideAnonymousCheck={true}
                passengers={data?.guests ?? []}
                onChange={(v) => {
                  setData({ ...data, guests: v })
                }}
              />
              {/* <PassengerSelect
                space_id={GroupTourSidebarService?.selectedFolder?._id}
                passengers={data?.guests ?? []}
                onChange={(v) => {
                  setData({ ...data, guests: v })
                }}
              // label={"Select Customer"}
              /> */}
            </Row> : null}

            <Row>
              <Col className="col-6">
                <DateSelectCustom
                  outline={true}
                  placeholder={("Booked Date")}
                  label="Booked Date"
                  value={data?.bookingDate}
                  onChange={(v) => {
                    setData({ ...data, bookingDate: v });
                  }}
                />
              </Col>
              <Col className="col-6">
                <InputField
                  outline
                  placeholder="Booking No."
                  label="Booking No."
                  value={data?.bookingNo}
                  onChange={(v) => setData({ ...data, bookingNo: v })}
                  type="text"
                  className="input-group-prepend"
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <InputField
                  outline
                  placeholder="Contact Detail"
                  label="Contact Detail"
                  value={data?.contactDetail}
                  onChange={(v) => setData({ ...data, contactDetail: v })}
                  type="text"
                  className="input-group-prepend"
                />
              </Col>
            </Row>

            <Row className=" mb-3" style={{ marginTop: "1rem" }}>
              <div>
                <div style={{ color: "#000", marginBottom: "0.2rem", }}>{t("Notes")}</div>
                <textarea
                  className="inputfield"
                  rows={5}
                  style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", outline: "none" }}
                  value={data?.notes}
                  onChange={(e) => {
                    setData({ ...data, notes: e.target.value });
                  }}
                >
                </textarea>
              </div>
            </Row>

            <Row className="justify-content-between d-flex flex-1 ">

              <Col className="col-12  card rounded-2 py-2 ">
                <Fee
                  showDetail={false}
                  label={"Client Billing"}
                  onChange={(updatedFee) => {
                    setData({ ...data, clientFee: updatedFee });
                  }}
                  fee={data?.clientFee}
                />
              </Col>

              <Col className="col-12  card rounded-2 py-2 ">
                <Fee
                  showDetail={false}
                  label={"Supplier Billing"}
                  onChange={(updatedFee) => {
                    setData({ ...data, supplierFee: updatedFee });
                  }}
                  fee={data?.supplierFee}
                />
              </Col>
            </Row>
          </Form>
          <div className="zr_layout-footer d-flex justify-content-end align-items-center pe-4" style={{ flexShrink: 0, marginBottom: insidePane ? "20px" : "0px" }}>
            {data?._id && userContent?.rights?.includes(1503) && (data?._id) ? <DeleteButton onClick={(e) => handleDelete(e, data?._id)} loading={loading} /> : null}
            {userContent?.rights?.includes(1502) ? <SaveButton onClick={handleSave} loading={loading} /> : null}
          </div>
        </div>
      }
      {changeSpaceModal ?
        < ChangeSpaceModal
          isOpen={changeSpaceModal}
          selectedSpaceId={data?.space_id}
          toggleModal={() => { setChangeSpaceModal(false) }}
          onSave={(space_id) => {
            handleEditSpace({ payload: { ...data, space_id } })
            setData({ ...data, space_id })
          }}
        /> : null}

    </React.Fragment >
  );
};

export default observer(EditStays);
