import CustomTooltip from "../Booking/components/CustomTooltip";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Title",
            accessor: "title",
            show: true,
            width: 200,
        },
        // {
        //     title: "Title",
        //     accessor: "title",
        //     show: true,
        //     width: 100,
        // },

        {
            title: "Duration",
            show: true,
            required: true,
            renderer: (dataObj) => {
                return (
                    <div>
                        <b className='font-size-14'>{dataObj?.days}</b>D   /   <b className='font-size-14'>{dataObj?.nights}</b>N

                    </div>
                )
            },
            // width: 500, // Adjusted for maximum width
        },
        {
            title: "Persons",
            accessor: "persons",
            show: true,
            required: true,
            renderer: (dataObj) => {
                return (
                    <div>
                        <div>{dataObj?.persons}</div>
                    </div>
                )
            },
        },
        {
            title: "Country",
            accessor: "country",
            renderer: (dataObj) => {
                return (

                    <div>{dataObj?.country ?? ""}</div>
                );
            },
            show: true,
        },
        {
            title: "Visiting Cities",
            accessor: "cities",
            renderer: (dataObj) => {
                const cities = dataObj?.cities ?? [];
                return (
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {cities.length > 0 ? (
                            cities.join(', ')
                        ) : (
                            "No cities listed"
                        )}
                    </div>
                );
            },
            show: true,
        }
        
        ,
        
        // {
        //     title: "Src",
        //     accessor: "src",
        //     show: true,
        //     required: true,
        //     renderer: (dataObj) => {
        //         return (
        //             <div>
        //                 <div>{dataObj?.src}</div>
        //             </div>
        //         )
        //     },
        //     width: 700, // Adjusted for maximum width
        // },
        // {
        //     title: "Dst",
        //     accessor: "dst",
        //     show: true,
        //     required: true,
        //     renderer: (dataObj) => {
        //         return (
        //             <div>
        //                 <div>{dataObj?.dst}</div>
        //             </div>
        //         )
        //     },
        //     width: 700, // Adjusted for maximum width
        // },
        // {
        //     title: "Status",
        //     accessor: "status",
        //     show: true,
        //     required: true,
        //     renderer: (dataObj) => {
        //         return (
        //             <div className="d-flex align-items-center">
        //                 <div style={{
        //                     backgroundColor:
        //                         dataObj.status === 0
        //                             ? "Orange"
        //                             : dataObj.status === 1
        //                                 ? "Green"
        //                                 : dataObj.status === 2
        //                                     ? "skyblue"
        //                                     : null,
        //                     width: "1.4rem", // Adjust the size of the circle
        //                     height: "1.4rem", // Adjust the size of the circle
        //                     borderRadius: "50%", // Makes it a circle
        //                     marginRight: "0.5rem", // Add some space between the circle and the text
        //                 }}></div>
        //                 <div>
        //                     {dataObj.status === 0 ? "Open" : dataObj.status === 1 ? "Resolved" : dataObj.status === 2 ? "Assigned" : null}
        //                 </div>
        //             </div>
        //         )
        //     },
        //     // width: "200", // Adjusted width
        // },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90, // Adjusted for minimum width
            show: true,
        },
    ],
};
