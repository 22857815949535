import _ from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataGrid, Layout } from '../../components';
import ActionMenu from '../../components/BookingNew/ActionMenu';
import LastColumn from '../../components/BookingNew/LastColumn';
import LayoutActionMenu from '../../components/BookingNew/LayoutActionMenu';
import DownloadService from '../../components/Download/DowloadService';
import Space from '../../components/space/Space';
import SpaceService from '../../components/space/SpaceService';
import useBookingManagement from '../../hooks/useBookingManagement';
import { DialogContext } from '../../store/context/DialogContext';
import { UserContext } from '../../store/context/UserContext';
import { I18nContext } from '../../store/context/i18nContext';
import { prepareFilterArtifacts } from '../../util/FilterUtil';
import { STRUCTURE } from '../Booking/BookingConstant';
import { GridColumns } from '../Booking/BookingGridColumns';
import BookingService from '../Booking/BookingService';
import EditBooking from './EditBooking';
import { TOUR_TOGGLE_DATA, statusToggleData } from './utils/constants';
import { formatTripStatus } from './utils/helper';
import EmailDetails from '../CRM/Email/EmailDetails';
import EmailService from '../CRM/Email/EmailService';
import '../CRM/Email/styles.scss'
import ConditionalWrapper from '../../components/ConditionalWrapper';

function Booking({ clientCompany_id, value, insidePane, multiMode, onSelect = () => { }, bookedBy, passengerTrip, clientId, customerType = "walkIn", excludedIds, excludedTripStatuses,clientInvoice_id=null,defaultFilters=[],selectedTripIds=[],statuses=[] }) {//TODO remove statuses when multi status is implemented
  const { edit_id } = useParams();
  const space_id = SpaceService?.selectedFolder?._id
  const isSpaceRendered = SpaceService?.isRendered
  const { userContent } = useContext(UserContext);
  const { showError } = useContext(DialogContext);
  const isTour = useMemo(() => _.includes(window.location.pathname, "trips"), [window.location.pathname]);
  const module = isTour ? "trips" : "booking"
  const { t } = useContext(I18nContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  // states
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === `/${module}/create` || edit_id);
  const [loading, setLoading] = useState(false);
  const [selectedEmail, setselectedEmail] = useState();
  //data
  const [fromDate, setFromDate] = useState(queryParams.get('fromDate') ? queryParams.get('fromDate') : moment().subtract(1, 'day').startOf('day').format('YYYYMMDD'));
  const [tillDate, setTillDate] = useState(queryParams.get('tillDate') ? queryParams.get('tillDate') : moment().add(6, 'day').endOf('day').format('YYYYMMDD'));
  const [filterObject, setFilterObject] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showSpaceAsDropdown, setShowSpaceAsDropdown] = useState(true);
  const [tripStatus, setTripStatus] = useState({ label: t("All"), value: 100 });
  const [selectedIDs, setSelectedIDs] = useState([]);
  const queryParamsRef = useRef({ prev: new URLSearchParams(), current: new URLSearchParams(location.search) });
  const [selectedTripsIdsForInvoice, setSelectedTripIdsForInvoice] = useState([]);
  const [data, setData] = useState([...BookingService?.records]);
  const { updateBookingInGrid } = useBookingManagement()
  const bulkInvoiceRef = useRef(null)

  const [prefetchValue, setPrefetchValue] = useState({
    passengers: [{ email: '' }],
    email_id: null,
  });

  const navigateWithNewParams = useCallback((queryParams) => {
    navigate({
      pathname: location.pathname,
      search: `?${queryParams.toString()}`,
      replace: true,
    });
  }, [location.pathname, navigate]);

  const isParamsUpdated = useCallback(() => {
    const pathname = location.pathname;
    if (pathname === `/${module}` || pathname === `/${module}/` || insidePane) {
      const currentQuerySearch = new URLSearchParams(location.search);
      if ((queryParamsRef.current?.prev?.toString() !== currentQuerySearch.toString()) || (!currentQuerySearch?.size && !queryParamsRef.current.prev?.size)) {
        queryParamsRef.current.prev = currentQuerySearch;
        queryParamsRef.current.current = currentQuerySearch;
        return true;
      }
    }
    return false;
  }, [location.pathname, location.search]);

  const handleNavigation = useCallback((bookingID, navigateBack = false) => {
    //if returnurl is coming navigate to it else navigate to edit page
    if (navigateBack) {
      const returnUrl = window.location.search ? new URLSearchParams(window.location.search).get("return") : "";
      navigate(returnUrl ? returnUrl : (isTour ? `/trips` : `/booking`));
      setShowDetailPage(false);
      setEditID(null);
      return;
    }
    setEditID(bookingID);
    if (!insidePane) navigate(isTour ? `/trips/edit/${bookingID}` : `/booking/edit/${bookingID}`);
  }, [insidePane, isTour, navigate]);


  const fetchGrid = useCallback(async (filterUrl) => {
    setLoading(true);
    try {
      let params = new URLSearchParams(filterUrl);
      if (params.get('status') === '100') {
        params.delete('status')
      }
      const fromDateQuery = params.get('fromDate');
      const tillDateQuery = params.get('tillDate');
      if (fromDateQuery && tillDateQuery) {
        params.delete('fromDate');
        params.delete('tillDate');
        params = params.toString() + `&tripDate=btw[${fromDateQuery},${tillDateQuery}]`
      }
      if(statuses?.length){
        params = params.toString() + `&status=in[${statuses.join(",")}]`
      }
      params += "&withExtn=true";
      await BookingService.fetch(params, excludedIds, excludedTripStatuses);
      setLoading(false);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const postSave = useCallback((updatedBookings, callGrid = false) => {
    if (callGrid) {
      fetchGrid(BookingService.filterOption);
      return;
    }
    const bookings = updateBookingInGrid(updatedBookings, data);
    setData(bookings);
    if (selectedEmail) {
      queryParams.delete("email")
      navigateWithNewParams(queryParams);
    }
  }, [fetchGrid, updateBookingInGrid, data]);


  const fetchEmailById = async (_id) => {
    const qParams = new URLSearchParams(window.location.search)
    const email_id = qParams.get("email");
    if (email_id || _id ) {
      try {
        const emailData = await EmailService.fetchEmailById(email_id??_id);
        if (emailData) {
          setselectedEmail(emailData);
        } else {
          qParams.delete("email")
          navigateWithNewParams(qParams);
        }
      } catch (error) {
        qParams.delete("email")
        navigateWithNewParams(qParams);
      }
    } else {
      setselectedEmail(null);
    }
  }

  //first time
  const formatInitialFilterUrl = useCallback(() => {
    let { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane);
    if (bookedBy) {
      filterObject = {
        ...filterObject,
        bookedBy: { customer_id: bookedBy },
      };
      filterUrl += `&bookedBy.customer_id=${bookedBy}`;
    }
    if (passengerTrip) {
      filterObject = {
        ...filterObject,
        passengers: { 0: { customer_id: passengerTrip } },
      };
      filterUrl += `&passengers.0.customer_id=${passengerTrip}`;
    }
    if (clientId) {
      filterObject = {
        ...filterObject,
        "client.client_id": clientId,
      };
      filterUrl += `&client.client_id=${clientId}`;
    }
    if (clientCompany_id) {
      filterObject = {
        ...filterObject,
        "client.company_id": clientCompany_id,
      };
      filterUrl += `&client.company_id=${clientCompany_id}`;
    }
    if (clientInvoice_id) {
      filterObject = {
        ...filterObject,
        "clientInvoice_id": clientInvoice_id,
      };
      filterUrl += `&clientInvoice_id=${clientInvoice_id}`;
    }

    if (queryParams.has('status')) {
      const status = queryParams.get('status');
      filterUrl += `&status=${status}`
      const decodedStatus = decodeURI(status)
      setTripStatus(formatTripStatus(decodedStatus?.includes("|") ? decodedStatus : parseInt(decodedStatus)));
    }
    if (space_id && space_id != "null") {
      const supSpaces = SpaceService?.getChildrens(space_id);
      if (supSpaces?.length) {
        filterUrl += `&space_id=in[${space_id},${supSpaces?.map(v => v?._id)?.join(',')}]`;
      } else {
        filterUrl += `&space_id=${space_id}`;
      }
    }

    return { filterUrl, filterObject };


  }, [queryParams, bookedBy, passengerTrip, clientId, clientCompany_id, space_id,clientInvoice_id]);

  //pass new filter url in it
  const prepareFilter = useCallback((filterURL) => {
    try {
      const prevFilterUrl = new URLSearchParams(queryParams.toString());//queryParams for first time
      //original filter url is stored in BookingService.filterURL so just update it with new filter url whenever anything changes
      // const currentQueryParams = new URLSearchParams(BookingService.filterURL);
      const currentQueryParams = new URLSearchParams(prevFilterUrl);
      const newFilterUrlParams = new URLSearchParams(filterURL);//this one is updated this may contain status or sortBy anything
      // Iterate over newFilterUrlParams and update/append values in currentQueryParams
      for (const [key, value] of newFilterUrlParams.entries()) {
        currentQueryParams.set(key, value);
      }
      //check missing and req values and populate them like sorting and date
      if (!currentQueryParams.has('sortBy') && !currentQueryParams.has('sortAsc')) {
        currentQueryParams.set('sortBy', 'startTime');
        currentQueryParams.set('sortAsc', false);
      }
      let fromDateQuery = currentQueryParams?.get('fromDate')
      //if fromDate or tillDate in query is not present // or not same to fromDate or tillDate  then first check fromDate,tillDate state if yes then pick up those else default (as maybe we have changed fromdate in local and not searched yet)
      if (!fromDateQuery || fromDateQuery !== fromDate) fromDateQuery = fromDate ?? moment().subtract(1, 'day').startOf('day').format('YYYYMMDD');
      let tillDateQuery = currentQueryParams?.get('tillDate');
      if (!tillDateQuery || tillDateQuery !== tillDate) tillDateQuery = tillDate ?? moment().subtract(1, 'day').startOf('day').format('YYYYMMDD');
      currentQueryParams.set('fromDate', fromDateQuery);
      currentQueryParams.set('tillDate', tillDateQuery);

      return currentQueryParams.toString();
    } catch (error) {
      showError(error)
    }
  }, [fromDate, tillDate, queryParams]);

  //call this function everytime if whether we are applying status, sorting or anything or reloading
  const onApplyFilter = useCallback((filterURL) => {
    if(insidePane){//if inside pane just apply filter
      fetchGrid(filterURL);
      return;
    }

    const newFilterUrlParams = prepareFilter(filterURL);

    if (queryParams.toString() !== newFilterUrlParams) {
      navigateWithNewParams(newFilterUrlParams)//BookingService.filterURL
      return;
    }

    //in this case directly call fetchGrid to manually call api on press of filter search button 
    fetchGrid(newFilterUrlParams) //BookingService.filterURL
  }, [queryParams, prepareFilter, fetchGrid, navigateWithNewParams]);

  const preFetchGrid = async () => {
    const { filterUrl, filterObject } = formatInitialFilterUrl();
    setFilterObject(filterObject);
    onApplyFilter(filterUrl)
  }

  const printSlip = async (templateName, bookingId) => {
    // setLoading(true);
    try {
        await BookingService.print([bookingId] || [], { ...{ templateName: templateName } } || {})
        // onSuccess()
    } catch (e) {
        showError(e);
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    if (isSpaceRendered) {
      if (isParamsUpdated()) {
        preFetchGrid()
      }
    }
    fetchEmailById()
  }, [isParamsUpdated, formatInitialFilterUrl, isSpaceRendered, onApplyFilter]);

  useEffect(() => {
    const qParams = new URLSearchParams(window.location.search)
    if (space_id) {
      const supSpaces = SpaceService?.getChildrens(space_id);
      if (supSpaces?.length) {
        qParams.set("space_id", `in[${space_id},${supSpaces?.map(v => v?._id)?.join(',')}]`)
        navigateWithNewParams(qParams)
      } else {
        qParams.set("space_id", space_id)
        navigateWithNewParams(qParams)
      }
    } else {
      qParams.delete("space_id")
      navigateWithNewParams(qParams)
    }
  }, [space_id])

  useEffect(() => {
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
  }, [value, multiMode]);

  useEffect(() => {
    if (BookingService.records) {
      setData(toJS(BookingService.records ?? []));
    }
  }, [BookingService.records]);

  useEffect(() => {
    if (selectedEmail && selectedEmail._id !== prefetchValue.email_id) {
      setPrefetchValue({
        passengers: [{ email: selectedEmail?.from }],
        email_id: selectedEmail?._id,
      });
    }
  }, [selectedEmail]);


  useEffect(() => {
    return () => {
      BookingService.resetState();
    }
  }, [])

  return (

    <React.Fragment key={userContent?.rights}>
      <>
        <div className={"d-flex"}>
          {showSpaceAsDropdown ? null : <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
            <Space
              showEverything
              isCollapsable
              toggleCollapse={() => setShowSpaceAsDropdown(true)}
              module="booking"
              filterStructure={STRUCTURE}
            />
          </div>}

          <div className="flex-1" style={{ width: insidePane ?"100%":`calc(100vw - 250px)` }}>
            <Layout
              compact
              hideheader={true}
              grouping={selectedEmail ? true : false}
              hidefooter={true}
              dateRangeClass="ms-2"
              hideAdd={!userContent?.rights?.includes(6001)}
              fromDate={fromDate}
              tillDate={tillDate}
              defaultValue={[fromDate, tillDate].filter(date => date).map(date => moment(parseInt(date), 'YYYYMMDD'))}
              setFromDate={setFromDate}
              setTillDate={setTillDate}
              data={data}
              setData={setData}
              spaceConfig={{
                show: showSpaceAsDropdown,
                showEverything: true,
                module: "booking",
                isCollapsable: true,
                filterStructure: STRUCTURE,
                toggleCollapse: (v) => { setShowSpaceAsDropdown(false) }
              }}
              large
              showDateRange
              toggleData={isTour ? TOUR_TOGGLE_DATA(t, "trips") : statusToggleData(t, tripStatus?.value)}
              showToggle
              toggleType={isTour ? "" : "dropdown"}
              onToggle={(selectedValue) => {
                if (isTour) {
                  if (selectedValue === "tours") {
                    navigate(`/tour`);
                  } else if (selectedValue === "trips") {
                    navigate(`/trips`);
                  } else if (selectedValue === "stay") {
                    navigate("/stay");
                  } else if (selectedValue === "flights") {
                    navigate("/flight");
                  } else if (selectedValue === "guests") {
                    navigate("/guests");
                  }
                } else {
                  onApplyFilter(`status=${selectedValue}`)
                }
              }}
              showDetailPage={showDetailPage}
              showViews={true}
              gridLoading={loading}
              title={t("Bookings")}
              filterValues={filterObject}
              filterStructure={STRUCTURE}
              onApplyFilter={onApplyFilter}
              onAddClick={() => {
                const encodedUrl = decodeURI(`/${module}/create?return=${encodeURIComponent(`${window.location?.pathname}${window.location.search}`)}`);
                if (!insidePane) navigate(isTour ? `/trips/create` : encodedUrl);
                setShowDetailPage(true);
                setEditID(null);
              }}
              backDetailPage={() => {
                setShowDetailPage(false);
                queryParams.delete('clone_trip_id');
                const returnUrl = window.location.search ? new URLSearchParams(window.location.search).get("return") : "";
                if (!insidePane) navigate(isTour ? `/trips` : (returnUrl ? returnUrl : `/${module}`));
                setEditID(null);
              }}
              insidePane={insidePane}
              page={BookingService.page}
              rows={BookingService.rows}
              total={BookingService.total}
              fetch={BookingService.fetch}
              showTimeFilter={true}
              onDownloadClick={() => DownloadService.toggleModal("Trip")}
              onTimeSortOrderChange={(selectedValue) => {
                onApplyFilter(`sortBy=startTime&sortAsc=${selectedValue}`);
              }}
              navigateOnFilter={false}
              module={isTour ? TOUR_TOGGLE_DATA(t, "trips").toggleValue :'booking'}
              defaultFilters={defaultFilters}
            >
              <Layout.ActionMenu>
                <LayoutActionMenu
                  ref={bulkInvoiceRef}
                  filterObject={filterObject}
                  selectedIDs={selectedIDs}
                  selectedTripsIdsForInvoice={selectedTripsIdsForInvoice}
                  setSelectedIDs={setSelectedIDs}
                  setSelectedTripIdsForInvoice={setSelectedTripIdsForInvoice}
                  fetchGrid={onApplyFilter}
                  tripStatus={tripStatus}
                />
              </Layout.ActionMenu>
              <Layout.Table>
                <DataGrid
                  data={data?.filter(booking => !selectedTripIds?.includes(booking?._id))}//hiding already selected data
                  gridLoading={loading}
                  total={BookingService.total}
                  uiPreference="Booking.grid"
                  headers={GridColumns({ insidePane })}
                  hideCheckboxes={insidePane}
                  selectedIDs={Array.from(new Set([...selectedIDs, ...selectedTripsIdsForInvoice]))}
                  onSelectChange={async (v) => {
                    onSelect(v);
                    setSelectedIDs(v);
                    bulkInvoiceRef.current?.updateSelectedIdsForInvoice(v);
                  }}
                  onSaveUiPref={preFetchGrid}
                  page={BookingService.page}
                  rowsPerPage={BookingService.rowsPerPage}
                  onPaginationChange={BookingService.onPaginationChange}
                  renderLastCol={(booking) => (
                    <LastColumn
                      booking={booking}
                      insidePane={insidePane}
                      multiMode={multiMode}
                      onEdit={() => {
                        setShowDetailPage(true);
                        if (!insidePane)
                          navigate(isTour ? `/trips/edit/${booking?._id}` : `/${module}/edit/${booking?._id}?return=${encodeURIComponent(`/${module}?${queryParamsRef.current.current?.toString()}`)}`);
                        setEditID(booking?._id);
                      }}
                      onSelect={() => onSelect(booking?._id)}
                    />
                  )}
                  showActionMenu={(booking) => {
                    return !insidePane && ((!(booking?.status == 3 || booking?.status == 4)) || (booking?.status != 3 && booking?.status != 4 && booking?.status != 5 && booking?.status != 6 && userContent?.rights?.includes(6003)))
                  }}
                  renderActionMenu={(booking) => (
                    <ActionMenu
                      booking={booking}
                      onDeleteCallback={() => navigate(isTour ? `/trips` : `/booking`)}
                      onCloneClick={(booking) => {
                        navigate(`/${module}/edit/${booking?._id}?clone_trip_id=${booking?._id}&return=${encodeURIComponent(`${window.location?.pathname}${window.location.search}`)}`)
                        setEditID(booking?._id);
                        setShowDetailPage(true);
                      }}
                      onPrint={(templateName,bookingId)=>{
                        printSlip(templateName,bookingId)
                      }}
                      postSave={postSave}
                    />
                  )}
                />
              </Layout.Table>
              <Layout.DetailPageBody>
                <ConditionalWrapper condition={!!selectedEmail} className='d-flex flex-row row '>
                  {selectedEmail && (
                    <div className="col-4  zr_crm_email d-flex crm_email_body border border-0 mt-2">
                      <EmailDetails
                        inbound
                        email={selectedEmail}
                        asDisplay
                      />
                    </div>
                  )}
                  <ConditionalWrapper condition={!!selectedEmail} className={`${selectedEmail ? 'vertical-divider-left col-8 booking_grouped_column ' : 'col-12'}`}>
                    <EditBooking
                      prefetchValue={prefetchValue}
                      editID={editID}
                      defaultCustomerType={customerType}
                      postSave={postSave}
                      handleNavigation={handleNavigation}
                      space_id={space_id}
                      onChange={(v)=>{
                        fetchEmailById(v?.email_id)
                      }}
                    />
                  </ConditionalWrapper>
                </ConditionalWrapper>
              </Layout.DetailPageBody>
            </Layout>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}

export default observer(Booking);
