import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Tax from "../../../pages/Tax/Tax";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, SearchButton, ModalComponent } from "../../index";
import TaxSelectService from "./TaxSelectService";
import TaxService from "../../../pages/Tax/TaxService";

const TaxSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  asLabel,
  noLabel,
  outline,
  selectFirst,
  selectZeroTax = true
}) => {
  const [taxes, setTaxes] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const [gridLoading, setGridLoading] = useState(false);

  const { t } = useContext(I18nContext)

  const fetchTaxes = async () => {
    setGridLoading(true);

    try {
      const response = await TaxService.fetchAll();
      setTaxes(TaxService.taxCache)
      setGridLoading(false);
    } catch (error) {
      showError("Error", error)
    }
    setGridLoading(false);

  };


  useEffect(() => {
    if (TaxService.taxCache.length === 0) fetchTaxes();
  }, []);

  useEffect(() => {
    setTaxes(TaxService.taxCache);
  }, [TaxService.taxCache]);

  // useEffect(() => {
  //   if (selectFirst && taxes?.length) {
  //     onChange({ ...taxes[0], label: taxes[0]?.name, value: taxes[0]?._id })

  //   }
  // }, [taxes])


  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectTax = (selectedValue) => {
    //check all modules
    onChange( multi ? selectedValue[0] : selectedValue);
    closeModal();
  };

  const selectTaxes = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };
  const selectZeroTaxValue = () => {
    const tax = taxes?.find(row =>
      row.items.some(item => item.percent === 0)
    )
    return tax?._id;
  }

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Taxes")} />
      )}
      // showBottomBar={true}
      className={className}
      noLabel={noLabel}
      onChange={onChange}
      outline={outline}
      data={taxes}
      required={required}
      value={value ? value : (selectFirst ? (taxes && taxes[0]?._id) : selectZeroTax ? selectZeroTaxValue() : null)}
      // value={value}
      label={label || (!isDisabled && t("Tax"))}
      error={!value ? t(`Please Select ${label || "Tax"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Taxes")} />
      )}
      noLabel={noLabel}
      // showBottomBar={true}
      className={className}
      onChange={selectTaxes}
      outline={outline}
      data={taxes}
      value={value ? taxes.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Taxes"}`) : ""}
      required={required}
      label={t("Select Taxes")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel ? multi ?
        value?.length > 0 ?
          (<div>
            {value?.map((selectedValue, index) => (
              <span key={selectedValue}>
                {taxes.find((tax) => tax.value === selectedValue)?.label}
                {index < value.length - 1 && ", "}
              </span>
            ))}</div>) : <div style={{ color: "grey" }}>No Record Found</div>


        : (<div>{taxes?.find((tax) => tax?.value == value)?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
        <>
          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Tax")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Tax
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectTaxes : selectTax}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}
          {taxes.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }
    </>
  );
};

export default observer(TaxSelect);
