import { ClientSelect } from "../../components";
export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Country",
            accessor: "country",
            show: true,
        },
        {
            title: "Clients",
            renderer: (dataObj) => {
                return <div>
                    <ClientSelect
                        multi
                        wrapping={2}
                        asLabel
                        value={dataObj?.clients}
                        onChange={(v) => { }}
                    />
                </div>;
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
