import { makeAutoObservable, toJS } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "./CitySelectConstant";

class CitySelect {
  cities = {};
  constructor() {
    makeAutoObservable(this);
  }

  getCityLabel = (a) => {
    return <div className="d-flex align-items-start gap-2">
      {`${a.name}`}
      <span className="pt-1"  style={{ fontSize: "10px" }}>{` (${a.state}, ${a.country})`}</span>
    </div>
  }

  fetch = async function (country, state) {
    const key=country && state? country + "/" + state:'all';

    if (this.cities[key]) return;
    try {
      const response = await doGET(
        ENDPOINTS.getCitiesByCountryAndState(country, state)
      );
      if (response.status === 200) {
        this.cities = {
          ...this.cities,
          [key]: response.data.rows.map((a) => {
            return { label: this.getCityLabel(a), value: a.name ,state:a.state,country:a.country};
          }),
        };
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
  getCityByCountry = async function (country) {
    if (this.cities[country]) return;
    const response = await doGET(ENDPOINTS.getCitiesByCountryAndState(country));
    if (response.status === 200) {
      this.cities = {
        ...this.cities,
        [country]: response.data?.rows?.map((a) => {
          return { label: a.name, value: a.name };
        }),
      };
    } else {
      this.error = response.data;
    }
  };
}

const CitySelectService = new CitySelect();
export default CitySelectService;
