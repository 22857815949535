import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import { IoSend } from "react-icons/io5";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  DownloadByTemplate,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import MessageDialog from "./Component/MessageDialog";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import BulkPrintConfiguration from "./Component/BulkPrintConfiguration";
import { STRUCTURE } from "./InvoiceConstant";
import {STRUCTURE as BOOKING_STRUCTURE} from "../Booking/BookingConstant"
import InvoiceForm from "./InvoiceForm";
import { GridColumns } from "./InvoiceGridColumns";
import Invoiceservice from "./InvoiceService";
import { MdPrint } from "react-icons/md";
import { UserContext } from "../../store/context/UserContext";
import moment from "moment";
import { toJS } from "mobx";
import BookingService from "../Booking/BookingService";
import { IoMdDownload } from "react-icons/io";
import { FaCloudDownloadAlt, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";

const Invoices = ({excludedIds=null, value,clientCompany_id,statuses=null, insidePane, multiMode, excludedPaymentStatuses=null,client_id=null, onSelect = () => { }, customerInvoice, clientInvoice }) => {
  let { edit_id } = useParams();
  const location = useLocation();


  const { userContent } = useContext(UserContext);
  let navigate = useNavigate();
  const queryParams = useMemo(()=>new URLSearchParams(location.search),[location.search]);
  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [updatedRecords,setUpdatedRecords]=useState([])
  const [showDownloadSelection, setshowDownloadSelection] =useState(false)
  const [templateType,setTemplateType]= useState("");
  const [loading, setLoading] = useState(false);
  const [editID, setEditID] = useState(edit_id);
  const [downloadType, setDownloadType] =useState(null);
  const [idInGrid,setIdInGrid] =useState(null);
  const[ orgid,setOrgId] =useState(null);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane
      ? false
      : window.location.pathname === "/invoice/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [messageModal, setMessageModal] = useState({
    visible: false,
  });
  const dropdownRef = useRef(null);
  useEffect(() => {
      const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setshowDownloadSelection(false); 
          }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false);
  // const [invoice, setInvoice] = useState({
  //   paymentTerms: "Due on Receipt",
  //   status: 1,
  //   paymentStatus: 0,
  //   invoiceExtn: {
  //     properties: [{}],
  //     items: [
  //       {
  //         fee: {
  //           feeGroups: [
  //             {
  //               name: "Group 1",
  //               charges: [{}],
  //               discounts: [{}],
  //               taxItem: {
  //                 taxes: [{}],
  //               },
  //             },
  //           ],
  //         },
  //         invoiceTrip: {},
  //       },
  //     ],
  //   },
  // });
  const [modal, setModal] = useState(false);
  const [fromDate, setFromDate] = useState(queryParams.get('fromDate') ? queryParams.get('fromDate') : null);
  const [tillDate, setTillDate] = useState(queryParams.get('tillDate') ? queryParams.get('tillDate') :null);
  const [showDownload, setShowDownload] = useState(false);
  const queryParamsRef = useRef({ prev: new URLSearchParams(), current: new URLSearchParams(location.search) ,initialLoad:true});//initalLoad to determine if it is first time or not (to stop extra api call with no queryParams)

  const isParamsUpdated = useCallback(() => {
    const pathname = location.pathname;
    if (pathname === `/invoice` || pathname === `/invoice/` || insidePane) {
      const currentQuerySearch = new URLSearchParams(location.search);
      if ((queryParamsRef.current?.prev?.toString() !== currentQuerySearch.toString()) || (!currentQuerySearch?.size && !queryParamsRef.current.prev?.size && queryParamsRef.current.initialLoad)) {
        queryParamsRef.current.prev = currentQuerySearch;
        queryParamsRef.current.current = currentQuerySearch;
        queryParamsRef.current.initialLoad=false;
        return true;
      }
    }
    return false;
  }, [location.pathname, location.search]);

  const preFetchGrid = async () => {
    // const { filterUrl, filterObject } = prepareFilterArtifacts();
    // setFilterObject(filterObject);
    fetchData({filterUrl:filterURL,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses});
    
  }


  const fetchData = useCallback(
    async ({filterUrl,client_id=null,clientCompany_id=null,excludedIds=null,excludedPaymentStatuses=null,statuses=null}) => {
      setLoading(true);
      try {
        const query=new URLSearchParams(filterUrl);
        const queryParams = new URLSearchParams(location.search);
        if(!query.has('invoiceDate')){  
            const fromDateQuery=queryParams.get('fromDate');
            const tillDateQuery=queryParams.get('tillDate');

            if(fromDateQuery && tillDateQuery ){
              if(fromDate && tillDate && (fromDateQuery!==fromDate || tillDateQuery!==tillDate)){
                navigate({
                  pathname: location.pathname,
                  search: `?${filterUrl}&fromDate=${fromDate}&tillDate=${tillDate}`,
                  replace:true
                });
                return;
              }
              filterUrl += `&invoiceDate=btw[${fromDateQuery + `,` + tillDateQuery}]`;
              setFromDate(fromDateQuery);
              setTillDate(tillDateQuery); 
            }else{
              if(fromDate && tillDate && fromDate!==fromDateQuery || tillDate!==tillDateQuery){
                navigate({
                  pathname: location.pathname,
                  search: `?${filterUrl}&fromDate=${fromDate}&tillDate=${tillDate}`,
                  replace:true
                });
                return; 
              }
            } 
        }
        if (clientInvoice && !filterUrl?.includes("client.org_id")) {
          filterUrl += `&client.org_id=${clientInvoice}`;
        }
        let withExtn=true;
        await Invoiceservice.fetch(filterUrl,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses,withExtn);
        setFilterURL(filterUrl)
        // if (editID) {
        //   const inv = await Invoiceservice.get(editID);
        //   if (inv) {
        //     // setInvoice(inv);
        //   }
        // } else {
        //   // setInvoice({});
        // }
      } catch (e) {
        showError(e);
      } finally {
        setLoading(false);
      }
    },
    [editID, showError,fromDate,tillDate,location.search]
  );

  useEffect(() => {
    if(isParamsUpdated()){
      let { filterUrl, filterObject } = prepareFilterArtifacts(
        queryParams,
        STRUCTURE
      );
      // preFetchGrid()
      if (queryParams.has("fromDate") && queryParams.has("tillDate")) {
        filterUrl += `&invoiceDate=btw[${queryParams.get("fromDate") + `,` + queryParams.get("tillDate")}]`;
       setFromDate(queryParams.get("fromDate")); 
       setTillDate(queryParams.get("tillDate"));
      }else{
        setFromDate(queryParams.get(null)); 
        setTillDate(queryParams.get(null));
      }
  
      if (clientInvoice) {
        filterObject = {
          ...filterObject,
          client: {
            corp_id: clientInvoice
          }
        }
        filterUrl += `client.org_id=${clientInvoice}`;
      }
  
      setFilterURL(filterUrl);
      setFilterObject(filterObject);
      fetchData({filterUrl,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses});
      multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
    }
  }, [queryParams,isParamsUpdated,clientInvoice]);

  useEffect(() => {
    setEditID(edit_id);
  }, [edit_id]);



  const toggleMessageModal = (e, invoice_id) => {
    setMessageModal({
      ...messageModal,
      visible: !messageModal?.visible,
      _id: invoice_id,
    });
    e?.stopPropagation();
  };

  const validateFeegroup = (items) => {
    let hasError = false;
    items?.forEach(item => {
      if (item.manual) {
        item?.fee?.feeGroups?.forEach(feeGroup => {
          if (!feeGroup?.charges || feeGroup?.charges?.length === 0) {
            setHasErr(true)
            showError(t("Please add charge"));
            hasError = true;
            return;
          }

          feeGroup?.charges?.forEach(charge => {
            if (!charge?.name || charge?.name?.trim() === '') {
              setHasErr(true)
              hasError = true;
              showError(t("Please fill charge type"));
              return;
            }
            if (!charge?.unitCost || isNaN(charge?.unitCost)) {
              setHasErr(true)
              showError(t("Please fill charge unit cost"));
              hasError = true;
              return;
            }
            if (/* !charge?.amount ||  */isNaN(charge?.amount)) {
              setHasErr(true)
              showError(t("Please fill amount"));
              hasError = true;
              return;
            }
            if (/* !charge?.units ||  */isNaN(charge?.units)) {
              setHasErr(true)
              showError(t("Please fill units"));
              hasError = true;
              return;
            }
          });
        });
      }
    });
    return hasError;
  }
  const extractClientOrgId = (url) => {
    const match = url.match(/client\.org_id=([^&]*)/);
    return match ? match[1] : null;
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Invoiceservice.delete(id);
        showMessage("Deleted", "Deleted");
        navigate(`/invoice`);
        setShowDetailPage(false);
      } catch (e) {
        showError(e);
      }
    }
  };

  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await Invoiceservice.bulkDelete(selectedIDs);
        showMessage("Deleted", "Deleted Successfully");
      } catch (e) {
        showError(e);
      }
    }
  };

  const renderLastCol = useCallback(
    (invoice) => {
      return (
        <>
          {userContent?.rights?.includes(4502) && <EditLink
            onClick={() => {
              // if (!insidePane) navigate(`/invoice/edit/${invoice?._id}`);
              if (!insidePane)
                navigate(`/invoice/edit/${invoice?._id}?return=${encodeURIComponent(`/invoice?${queryParamsRef.current.current?.toString()}`)}`);
              setEditID(invoice?._id);
              setShowDetailPage(true);
            }}
          />
          }

          {(!multiMode && insidePane) ? (
            <CheckButton
              onClick={() => {
                onSelect(invoice);
              }}
            />
          ) : null}
        </>
      );
    },
    [insidePane, multiMode, userContent]
  );

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("invoice");
    } catch (error) { }
  };


  const printSlip = async (templateName, bookingId, invoice_id) => {
    try {
      await BookingService.print(
        Array.isArray(bookingId) ? bookingId : [bookingId],  
        { templateName: templateName },                      
        invoice_id ? [invoice_id] : []
      );
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  };
  const printCoversheet= async (orgID, isPDF,_id) =>{
    try {
      await Invoiceservice.printCoversheet(orgID, isPDF, _id)
    } catch (error) {
      showError(error)
    }finally{
      setLoading(false);
    }
  }
  

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch();
    }
  }, [SetupProgressService?.records]);


  const toggleDownload = (type=null, invoiceID=null) => {
    setShowDownload(!showDownload);
    if(type!=null && invoiceID!=null){
      setDownloadType(type)
      setIdInGrid(invoiceID)
      if(type=="trip"){
        BookingService.setDownloadFilter(`&clientInvoice_id=${invoiceID}`)
      }
      else{
         Invoiceservice.setDownloadFilter(`&_id=${invoiceID}`);
      }
    }
    else{
      Invoiceservice.setDownloadFilter(filterURL)
    }
   
  };

  useEffect(() => {
    setUpdatedRecords(()=>{
      return toJS(Invoiceservice?.records)?.filter(record => !excludedIds?.includes(record._id))
    })
  }, [excludedIds, Invoiceservice?.records])
  

  return (
    <>
        <DownloadByTemplate
        filter={(downloadType == null) ? filterObject : (downloadType == "invoice" ? {_id: idInGrid } : { clientInvoice_id: idInGrid })}
        download={async (filter, selectedTemplate) => {
          if (downloadType == null) {
            return await Invoiceservice.downloadByTemplate('', selectedTemplate);
          } else if (downloadType === "invoice") {
            return await Invoiceservice.downloadByTemplate('', selectedTemplate);
          } else {
            return await BookingService.downloadByTemplate('', selectedTemplate);
          }
        }}
        kind={(() => {
          if (downloadType == null) {
            return "Invoice";
          } else if (downloadType === "invoice") {
            return "Invoice";
          } else {
            return "Trip";
          }
        })()}
        
        onClose={(v)=>
        {
          setShowDownload(v);
          setIdInGrid(null);
          setDownloadType(null);
        }
        }
        show={showDownload}
        filterStructure={STRUCTURE}
        onApplyFilter={() => {}}
      />
      <Layout
        hideAdd={!userContent?.rights?.includes(4501)}
        large
        showDateRange
        fromDate={fromDate}
        tillDate={tillDate}
        setFromDate={setFromDate}
        setTillDate={setTillDate}
        showDetailPage={showDetailPage}
        defaultValue={[fromDate, tillDate].filter(date => date).map(date => moment(parseInt(date), 'YYYYMMDD'))}
        backDetailPage={async () => {
          setShowDetailPage(false);
          const returnUrl = window.location.search ? new URLSearchParams(window.location.search).get("return") : "";
          if (!insidePane) navigate( (returnUrl ? returnUrl : `/invoice`));
          // if (!insidePane) navigate("/invoice");
          setEditID(null);
        }}
        title={t("Invoices")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={(url)=>fetchData({filterUrl:url,client_id,clientCompany_id,excludedIds,excludedPaymentStatuses,statuses})}
        onAddClick={() => {
          // if (!insidePane) navigate(`/invoice/create`);
          const encodedUrl = decodeURI(`/invoice/create?return=${encodeURIComponent(`${window.location?.pathname}${window.location.search}`)}`);
          if (!insidePane) navigate(encodedUrl);
          setShowDetailPage(true);
          setEditID(null);
          // fetchInvoiceNumber();
        }}
        insidePane={insidePane}
        page={Invoiceservice.page}
        rows={Invoiceservice.rows}
        total={Invoiceservice.total}
        fetch={Invoiceservice.fetch}
        hideheader={true}
      >
        <Layout.OnAlert>
          {!SetupProgressService?.records?.setUpStatus?.["invoice"] ? (
            <Alert color="danger" role="alert">
              <div className="d-flex justify-content-between align-items-center">
                <div>Set up your invoice configuration here.</div>
                <div>
                  <Button onClick={markCompletedFunc}>Mark Complete</Button>
                </div>
              </div>{" "}
            </Alert>
          ) : (
            <></>
          )}
        </Layout.OnAlert>
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem
                  onClick={() => {
                    onBulkDelete();
                  }}
                >
                  {t("Bulk Delete")}
                </DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem
                  onClick={() => {
                    setModal(!modal);
                  }}
                >
                  {t("Print in Bulk")}
                </DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem onClick={()=>toggleDownload(null,null)}>{t("Download")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem
                  onClick={() => {
                    const clientOrgId= extractClientOrgId(filterURL);
                    if(!clientOrgId){
                      showError("Select Client for Coversheet")
                      return;
                    }
                    else{
                      printCoversheet(clientOrgId,true,null)
                    }
                  }}
                >
                  {t("Download Coversheet PDF")}
                </DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem
                  onClick={() => {
                    const clientOrgId= extractClientOrgId(filterURL);
                    if(!clientOrgId){
                      showError("Select Client for Coversheet")
                    }
                    else{
                      printCoversheet(clientOrgId,false,null)
                    }
                  }}
                >
                  {t("Download Coversheet Excel")}
                </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>

        <Layout.Table>
          <DataGrid
              gridLoading={loading}
            data={updatedRecords}
            total={Invoiceservice.total}
            uiPreference="Invoice.grid"
            headers={GridColumns({insidePane})}
            selectedIDs={selectedIDs}
            onSelectChange={(v) => {
              // onSelect(v);
              setSelectedIDs(v);
            }}
            onSaveUiPref={()=>{
              preFetchGrid()
            }}
            page={Invoiceservice.page}
            rowsPerPage={Invoiceservice.rowsPerPage}
            onPaginationChange={Invoiceservice.onPaginationChange}
            renderLastCol={renderLastCol}
            showActionMenu={() => { return true }}
            renderActionMenu={(invoice) => {
              return (
                <>
                  <div className="d-flex flex-column">
                  {userContent?.rights?.includes(4503) && <Button
                      onClick={() => {
                        setSelectedIDs([invoice?._id])
                        setModal(!modal)
                      }}
                      className="d-flex w-100 actionButton"
                      style={{ height: "28px", padding: "3px 10px 2px 4px", color: "black", border: "none", }}
                      color="black" >
                      <div className="mx-2">
                        <MdPrint fontSize={16} />
                      </div>
                      {t(`Print Invoice`)}
                    </Button>}
                    {userContent?.rights?.includes(4503) && <Button
                      onClick={() => {
                        printSlip("DutySlip",[],invoice?._id)
                      }}
                      className="d-flex w-100 actionButton"
                      style={{ height: "28px", padding: "3px 10px 2px 4px", color: "black", border: "none", }}
                      color="black" >
                      <div className="mx-2">
                        <MdPrint fontSize={16} />
                      </div>
                      {t(`Print Duty Slip`)}
                    </Button>}
                    {userContent?.rights?.includes(4503) && <Button
                      onClick={() => {
                        printSlip("TripSummary",[],invoice?._id)
                      }}
                      className="d-flex w-100 actionButton"
                      style={{ height: "28px", padding: "3px 10px 2px 4px", color: "black", border: "none", }}
                      color="black" >
                      <div className="mx-2">
                        <MdPrint fontSize={16} />
                      </div>
                      {t(`Print Trip Summary`)}
                    </Button>}
                    {(invoice?.client?.org_id && invoice?._id) ?
                      <Button

                        onClick={()=> printCoversheet(invoice?.client?.org_id,true,invoice?._id)}
                        className="d-flex w-100 actionButton"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                        color="black"
                      >
                        <div className="mx-2">
                          <MdPrint fontSize={14} color="black" />
                      
                        </div>
                        {t(`Print Coversheet`)}
                      </Button> : null}
                                      {invoice?._id ?
                      <Button
                        onClick={()=>toggleDownload("trip",invoice?._id)}
                        className="d-flex w-100 actionButton"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                        color="black"
                      >
                        <div className="mx-2">
                          <FaFileExcel fontSize={14} color="black" />
                      
                        </div>
                        {t(`Download Trip Sheet`)}
                      </Button> : null}
                      {/* Add here */}
                      {(invoice?.client?.org_id && invoice?._id )?
                      <Button
                      onClick={()=> printCoversheet(invoice?.client?.org_id,false,invoice?._id)}
                        className="d-flex w-100 actionButton"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                        color="black"
                      >
                        <div className="mx-2">
                          <FaFileExcel fontSize={14} color="black" />
                      
                        </div>
                        {t(`Download Coversheet`)}
                      </Button> : null}

                      {/*  */}
                  {invoice?._id ?
                      <Button
                        onClick={()=>toggleDownload("trip",invoice?._id)}
                        className="d-flex w-100 actionButton"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                        color="black"
                      >
                        <div className="mx-2">
                          <FaFileExcel fontSize={14} color="black" />
                      
                        </div>
                        {t(`Download Trip Sheet`)}
                      </Button> : null}
                      {invoice?._id ?
                      <Button
                        onClick={ ()=>toggleDownload("invoice",invoice?._id)}
                        className="d-flex w-100 actionButton"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                        color="black"
                      >
                        <div className="mx-2">
                        <FaFileExcel fontSize={14} color="black" />
                        </div>
                        {t(`Download Invoice Sheet`)}
                      </Button> : null}
                    {invoice?._id ?
                      <Button
                        onClick={(e) => { toggleMessageModal(e, invoice?._id) }}
                        className="d-flex w-100 actionButton"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                        color="black"
                      >
                        <div className="mx-2">
                          <IoSend fontSize={14} color="black" />
                        </div>
                        {t(`Send Invoice`)}
                      </Button> : null}
                    <Button
                      onClick={(event) => onDelete(event, invoice._id)}
                      className="d-flex w-100 actionButton"
                      style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none", }}
                      color="black" >
                      <div className="mx-2">
                        <RiDeleteBin5Line fontSize={16} color="red" />
                        {/* <DeleteLink style={{ color: "black" }}/> */}
                      </div>
                      {t(`Delete Invoice`)}
                    </Button>

                    {/* } */}
                  </div >
                </>
              )
            }}
          />
        </Layout.Table>


        <Layout.DetailPageBody>
          <InvoiceForm
            loading={loading}
            insidePane={insidePane}
            editId={editID}
            validateFeegroup={validateFeegroup}
            onSave={() => {
              fetchData({filterURL});
            }}
            getOrgID={(v)=>setOrgId(v)}
            onDelete={() => {
              showMessage("Deleted", "Deleted");
              navigate(`/invoice`);
              setShowDetailPage(false);
            }}
            callingType="invoice"
            disableInnerSave={false}
          />
          <DownloadByTemplate
            filter={templateType === "invoice" ? { _id: editID } : { clientInvoice_id: editID }}
            download={async (filter, selectedTemplate) => {
              return templateType === "invoice"
                ? await Invoiceservice.downloadByTemplate(`_id=${editID}`, selectedTemplate)
                : await BookingService.downloadByTemplate(`clientInvoice_id=${editID}`, selectedTemplate);
            }}
            kind={templateType === "invoice" ? "Invoice" : "Trip"}
            position={"center"}
            onClose={(v) => {
              if (v === false) setTemplateType(null);
            }}
            show={templateType}
            onApplyFilter={templateType == "invoice" ? Invoiceservice.setDownloadFilter(`&_id=${editID}`) :(templateType == "trip")? BookingService.setDownloadFilter(`&clientInvoice_id=${editID}`):null}

          />


          {/* <EditInvoices
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          /> */}
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          <div className="d-flex justify-content-end align-items-center w-100">
            <div>
              {editID ? (<DeleteButton onClick={(e) => onDelete(e, editID)} />
              ) : null}
            </div>
            <div className="d-flex align-items-center">

            { editID && <div style={{ position: 'relative' }}>
                <Button
                  onClick={() => { setshowDownloadSelection(!showDownloadSelection); }}
                  className="mx-2 my-1"
                  style={{ height: "30px", padding: "2px 4px", backgroundColor: "#448ccd", border:"none" }}
                >
                  <div className="d-flex justify-content-center" style={{ margin: "2px" }}>
                  <FaCloudDownloadAlt fontSize={20} className="me-1" color="white" />
                    {t(`Download`)}
                  </div>
                </Button>

                {showDownloadSelection && (
                  <div
                    className="d-flex flex-column"
                    ref={dropdownRef}
                    style={{
                      position: 'absolute',
                      bottom: '100%',
                      right: 10,
                      zIndex: 1,
                      backgroundColor: "white",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      width: 'max-content',
                      padding: "0px"
                    }}
                  >
                    <div className="bg-lightest_blue">
                      <div className="d-flex mx-2 font-size-16 cursor-pointer bg-lightest_blue" ><FaFilePdf fontSize={16} className="mt-1 me-1" color="black" />Download Pdf</div>

                      <Button
                    onClick={(e) => {
                      setSelectedIDs([editID])
                      setModal(!modal)
                    }}
                        className="d-flex w-100 actionButton align-items-center"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                      >
                        <div className="mx-2">
                        <GoDotFill fontSize={14} color="black" />
                        </div>
                        {t(`Print Invoice`)}
                      </Button>
                      <Button
                        onClick={() => { printSlip("DutySlip",[],editID) } }
                        className="d-flex w-100 actionButton align-items-center"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                      >
                        <div className="mx-2">
                        <GoDotFill fontSize={14} color="black" />
                        </div>
                        {t(`Print Duty Slip`)}
                      </Button>
                      <Button
                        onClick={() => {printSlip("TripSummary",[],editID)}}
                        className="d-flex w-100 actionButton align-items-center"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                      >
                        <div className="mx-2">
                        <GoDotFill fontSize={14} color="black" />
                        </div>
                        {t(`Print Trip Summary`)}
                      </Button>
                      {orgid != null && <Button
                        onClick={() =>printCoversheet(orgid, true,editID)}
                        className="d-flex w-100 actionButton align-items-center"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                      >
                        <div className="mx-2">
                          <GoDotFill fontSize={14} color="black" />
                        </div>
                        {t(`Print Coversheet`)}
                      </Button>}
                    </div>
                    <div className="bg-lightest_blue">
                    <div className="d-flex mx-2 font-size-16 cursor-pointer bg-lightest_blue"><FaFileExcel fontSize={16} className="mt-1 me-1" color="black" />Download Excel</div>
                    
                      <Button
                        onClick={() => {
                          setTemplateType("trip")
                        }
                        }
                        className="d-flex w-100 actionButton align-items-center"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                      >
                        <div className="mx-2">
                        <GoDotFill fontSize={14} color="black" />
                        </div>
                        {t(`Trip Sheet`)}
                      </Button>
                      <Button
                        onClick={() => {
                          setTemplateType("invoice")
                        }
                        }
                        className="d-flex w-100 actionButton align-items-center"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", color: "black", border: "none" }}
                      >
                        <div className="mx-2">
                        <GoDotFill fontSize={14} color="black" />
                        </div>
                        {t(`Invoice Sheet`)}
                      </Button>

                      {orgid != null && <Button
                        onClick={() => printCoversheet(orgid, false, editID)}
                        className="d-flex w-100 actionButton align-items-center"
                        style={{ height: "28px", padding: "2px 10px 2px 4px", backgroundColor: "white", color: "black", border: "none" }}
                      >
                        <div className="mx-2">
                          <GoDotFill fontSize={14} color="black" />
                        </div>
                        {t(`Cover Sheet`)}
                      </Button>}
                    </div>
                  </div>
                )}
                </div>}
              {editID ? (
                <AuditLogDialog module="Invoice" id={editID} />
              ) : null}
              {/* {userContent?.rights?.includes(4502) && <SaveButton onClick={onSave} />} */}
            </div>

          </div>

        </Layout.DetailPageFooter>
      </Layout >
      {
        messageModal?._id ?
          <MessageDialog invoice_id={messageModal?._id} modal={messageModal?.visible} toggleModal={(e) => { toggleMessageModal(e, editID) }} />
          : null}
      <BulkPrintConfiguration
        modal={modal}
        setModal={setModal}
        invoice_ids={selectedIDs}
        loading={loading}
        onSuccess={() => {
          setSelectedIDs([])
          setModal(false)
        }}
        setLoading={setLoading}
      />
    </>
  );
};
export default observer(Invoices);
