import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FaPlus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import { ModalComponent } from '../../components';
import EditFlight from '../Flight/EditFlight';
import FlightService from '../Flight/FlightService';
import GroupTourSidebarService from './sideBar/GroupTourSidebarService';
import "./style.css";
import { FLIGHT_STATUS_MAP } from '../Flight/FlightConstant';
import { convertYYYYMMDDtoDDMMMM } from '../../util/DateUtil';

const FlightGrid = ({
    getFlightRecords = () => { },
    hideAdd, passengers = [],
    selectedFlights = [],
    completeObject = false,
    onChange = () => { },
    fixedFlights = [],
    tour_id = null
}) => {
    const [loading, setLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [editId, setEditID] = useState("");
    const [modal, setModal] = useState(false);
    const [flights, setFlights] = useState(false);
    const navigate = useNavigate();

    const fetchFlights = async (passengers) => {
        setLoading(true);
        try {
            const passengersId = passengers?.map(passenger => passenger.customer_id)?.join(",");
            const fetchedFlights = await FlightService.fetch(`passengers.customer_id=in[${passengersId}]${!GroupTourSidebarService.selectedFolder._id ? "" : `&space_id=${GroupTourSidebarService.selectedFolder._id}`}`, true);
            getFlightRecords(fetchedFlights);
            setFlights(fetchedFlights);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFlightUsingTourId = async (tour_id) => {
        setLoading(true);
        try {
            const fetchedFlights = await FlightService.fetch(`tour_id=${tour_id}`, true);
            getFlightRecords(fetchedFlights);
            setFlights(fetchedFlights);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     if (passengers?.length) {
    //         fetchFlights(passengers);
    //     }
    // }, [passengers]);

    useEffect(() => {
        if (tour_id) {
            fetchFlightUsingTourId(tour_id);
        }
    }, [tour_id]);

    const toggleRow = (index, id) => {
        setExpandedRow(expandedRow === index ? null : index);
        setEditID(id);
        setModal(true);
    };

    const handleCheckboxClick = (flight) => {
        let updatedFlights;
        if ((selectedFlights ?? []).some(f => completeObject ? f?._id === flight?._id : f === flight?._id)) {
            updatedFlights = (selectedFlights ?? []).filter(f => completeObject ? f?._id !== flight?._id : f !== flight?._id);
        } else {
            updatedFlights = completeObject ? [...(selectedFlights ?? []), flight] : [...(selectedFlights ?? []), flight?._id];
        }
        onChange(updatedFlights, flight);
    };

    const isFlightSelected = (flight) => {
        return (selectedFlights ?? []).some(f => completeObject ? f?._id === flight?._id : f === flight?._id);
    };

    // if ((fixedFlights?.length ? fixedFlights : flights).length === 0) {
    //     return <div>No Flights found.</div>;
    // }

    const getColorStatus = (status = 0) => ({
        height: 10,
        width: 10,
        borderRadius: 20,
        backgroundColor: FLIGHT_STATUS_MAP[status].color
    })

    return (
        <>
            <Card className="pb-3">
                <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                    <div> Flights</div>
                    {hideAdd ? null : (
                        <div className="d-flex align-items-center">
                            <Button
                                onClick={() => setModal(true)}
                                className="d-flex align-items-center p-1 me-2">
                                <FaPlus />
                            </Button>
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className='p-3'>
                        loading
                    </div>
                ) : (
                    <div className="zr_flight_accordion_container px-2 overflow-y-auto">
                        {(fixedFlights?.length ? fixedFlights : flights)?.length ? (
                            <>
                                <table>
                                    <thead>
                                        <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                            <th className='zr-thead'>Status</th>
                                            <th className='zr-thead'>PNR</th>
                                            <th className='zr-thead'>Booking No</th>
                                            <th className='zr-thead'>Airline</th>
                                            <th className='zr-thead'>From</th>
                                            <th className='zr-thead'>To</th>
                                            <th className='zr-thead'>Travel Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(fixedFlights?.length ? fixedFlights : flights).map((data, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="zr_flight_accordion_row" onClick={() => toggleRow(index, data?._id)} style={{ cursor: 'pointer' }}>
                                                    <td className='d-flex align-items-center'>
                                                        <div style={getColorStatus(data?.status)} />
                                                        <div className="font-size-13 ms-2 mb-1 fw-normal">{FLIGHT_STATUS_MAP[data?.status]?.label ?? "--"}</div>
                                                    </td>
                                                    <td className=''>
                                                        <div className=""> {data.PNR}</div>
                                                    </td>
                                                    <td>
                                                        <div>{data?.bookingNo}</div>
                                                    </td>
                                                    <td>
                                                        <div>{data?.airline}</div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {data?.fromCity || data?.fromCountry ? (
                                                                <div>
                                                                    {data?.fromCity ? `${data?.fromCity}` : null}
                                                                </div>
                                                            ) : <div></div>}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {data?.toCity || data?.toCountry ? (
                                                            <div>
                                                                {data?.toCity ? `${data?.toCity}` : null}
                                                            </div>
                                                        ) : <div></div>}
                                                    </td>
                                                    <td>
                                                        {convertYYYYMMDDtoDDMMMM(data?.departureDate)}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <div>No Flights found.</div>
                        )}
                    </div>
                )}
            </Card>

            {modal && (
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={() => {
                        setEditID("");
                        setModal(false);
                    }}
                    isOpen={modal}
                    modalSizeStyle={{
                        maxHeight: "calc(100vh - 80px)"
                    }}
                >
                    <ModalComponent.Body>
                        <EditFlight
                            tour_id={tour_id}
                            insidePane={true}
                            editId={editId}
                            onSave={() => {
                                setModal(false);
                                fetchFlightUsingTourId(tour_id);
                            }}
                            onDelete={() => {
                                setModal(false);
                                fetchFlightUsingTourId(tour_id);
                            }}
                            formConfig={{
                                showPassengers: false
                            }}
                            prePopulatedData={{
                                passengers
                            }}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            )}
        </>
    );
};

export default observer(FlightGrid);
