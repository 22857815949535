import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { GroupedSelectField } from "../../SelectField/GroupedSelectField";
import { doGET } from "../../../util/HttpUtil";

const GroupedCategorySelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label,
    showErr,
    inline,
    borderNone,
    asLabel,
    plain,
    outline,
}) => {
    const [categories, setcategories] = useState([]);
    const { showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)

    const fetchData = async () => {
        try {
            const [res1, res2, res3] = await Promise.all([
                doGET("/api/doc-category/grid?module=expense-general&rows=-1"),
                doGET("/api/doc-category/grid?module=expense-vehicle&rows=-1"),
                doGET("/api/doc-category/grid?module=expense-employee&rows=-1"),
            ])
            const data = [
                {
                    label: "General",
                    options: res1?.data?.rows ? res1?.data?.rows?.map(e => ({ label: e?.name, value: e?._id })) : [],
                },
                {
                    label: "Vehicle",
                    options: res2?.data?.rows ? res2?.data?.rows?.map(e => ({ label: e?.name, value: e?._id })) : [],
                },
                {
                    label: "Employee",
                    options: res3?.data?.rows ? res3?.data?.rows?.map(e => ({ label: e?.name, value: e?._id })) : [],
                },
            ]
            setcategories(data);
        } catch (error) {
            showError("Error", error)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const renderSelectField = ({ disabled }) => (
        <GroupedSelectField
            style={style}
            isDisabled={isDisabled || disabled}
            className={className}
            onChange={(v) => {
                onChange(v)
            }}
            data={categories}
            required={required}
            value={value}
            grouped
            label={label || (!isDisabled && t("Category"))}
            error={!value ? t(`Please Select ${label || "Category"}`) : ""}
            showErr={showErr}
            plain={plain}
            outline={outline}
            inline={inline}
            borderNone={borderNone}
        />
    );


    return (
        <>
            {asLabel ? (<div>{categories?.find((supplier) => {
                const foundOption = supplier?.options?.find((option) => option._id === value);
                return foundOption?.label;
            })?.label ?? <div style={{ color: "grey" }}>No record Found</div>}</div>) :
                <>
                    {categories.length > 0
                        ? renderSelectField({ disabled: false })
                        : renderSelectField({ disabled: true })}
                </>
            }
        </>
    );
};

export default observer(GroupedCategorySelect);