export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl, space_id) => {
        let url = `/api/tour/grid?`;
        if (sizePerPage) url += `&rows=${sizePerPage}`;
        if (filterUrl) url += `&${filterUrl}`;
        if (page) url += `&page=${page}`
        if (space_id) url += `&space_id=${space_id}`
        return url;
    },
    get: (id) => `/api/tour/${id}/detail`,
    save: `/api/tour/create`,
    delete: (id) => `/api/tour/${id}/delete`,
    bulkDelete: (id) => `/api/tour/delete`,
    update: `/api/tour/update`,
};

export const STRUCTURE = [
    {
        label: "No.",
        filterLabel: "no ",
        filterName: "name",
        type: "text",
    },
    {
        label: "Itinerary Code",
        filterLabel: "Itinerary Code",
        filterName: "code",
        type: "text",
    },
];


export const statusMapping = {
    0: { text: "Pending", color: "#AEB6BF" },
    1: { text: "Confirmed", color: "#2980B9" },
    2: { text: "Ready", color: "#A569BD" },
    3: { text: "Dispatched", color: "#FFBF00" },
    4: { text: "Live", color: "#FF7F50" },
    5: { text: "Completed", color: "#52BE80" },
    6: { text: "Cancelled", color: "#F1948A" },
    7: { text: "Error", color: "#CB4335" },
    8: { text: "Paused", color: "#CCCCFF" },
    9: { text: "Expired", color: "#7B241C" },
    10: { text: "Rejected", color: "#7B241C" },
};