import { useCallback, useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../../store/context/i18nContext";
import PropTypes from "prop-types";
import { DialogContext } from "../../../../store/context/DialogContext";
import BookingService from "../../BookingService";
import { InputField, PhoneNum } from "../../../../components";
import { Button, Input } from "reactstrap";
import { IoSend } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import RemoveLink from "../../../../components/Buttons/RemoveLink";

const OnDemandMessageForTrip = ({ trip, toggleModal }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);

  const [payload, setPayload] = useState({
    trip_id: null,

    content: null,
    receiverList: [
      {
        _id: null,
        email: null,
        phoneNo: {
          countryCode: 91,
          phone: null,
          name: ""
        },
      },
    ],
    customReceiverList: [],
  });



  const updateTripIdInPayload = useCallback(async () => {
    let content;
    try {
      if (trip?._id) {
        content = await getContent(trip._id);
        const passengersname = trip.passengers.map((item) => ({ name: item.name }));
        setPayload({ trip_id: trip._id, content: content, receiverList: passengersname });
      }
    } catch (error) {
      setPayload({ trip_id: trip._id, content: "", receiverList: [] });
    }
  }, [trip?._id]);

  const getContent = async (trip_id) => {
    const content = await BookingService.getMessageContent(trip_id);
    return content;
  };
  useEffect(() => {
    updateTripIdInPayload();
  }, [updateTripIdInPayload]);


  const call = async (e) => {
    e.preventDefault();
    if (!payload.receiverList || payload.receiverList.length === 0) {
      showError(t("Please enter Details"));

      return;
    }
    try {
      const response = await BookingService.sendMessage(payload);
      if (response) {
        toggleModal()
      }

    } catch (error) {
      showError(error);
    }
  };


  useEffect(() => {
    if (payload?.customData) {
      const emailAddresses = payload.customData.map((item) => item.email);
      const phoneNos = payload.customData.map((item) => item.phoneNo);
      setPayload((prevPayload) => ({
        ...prevPayload,
        emails: emailAddresses,
        phoneNos: phoneNos,

      }));
    }
  }, [payload?.customData]);

  const getBooleanValue = (index, field) => {
    return payload?.receiverList?.[index]?.[field] != null || false;
  };

  const getDriverBooleanValue = (field) => {
    return payload?.driver?.[field] != null || false;
  }



  const updateReceiverList = (index, field, value, customer_id) => {
    setPayload((prevPayload) => {
      const newPayload = { ...prevPayload };

      if (!newPayload.receiverList) {
        newPayload.receiverList = [];
      }

      while (newPayload.receiverList.length <= index) {
        newPayload.receiverList.push({});
      }

      const currentValue = newPayload.receiverList[index][field];
      newPayload.receiverList[index] = {
        ...newPayload.receiverList[index],
        [field]: currentValue ? null : value,
        _id: customer_id ? customer_id : null,
      };
      return newPayload;
    });
  };

  const updateDriverList = (field, value) => {
    setPayload((prevPayload) => {
      const newPayload = { ...prevPayload };

      if (!newPayload.driver) {
        newPayload.driver = {};
      }

      newPayload.driver[field] = value;
      newPayload.driver._id = trip?.driver?._id;
      return newPayload;
    });
  };
  const [allPhone, setAllPhone] = useState(false);
  const [allEmail, setAllEmail] = useState(false);

  const toggleAllPhone = () => {
    const newValue = !allPhone;
    setAllPhone(newValue);
    setPayload((prevPayload) => {
      const newPayload = { ...prevPayload };
      if (!newPayload.receiverList) {
        newPayload.receiverList = trip?.passengers?.map(() => ({}));
      }
      newPayload.receiverList = newPayload.receiverList.map((item, index) => ({
        ...item,
        phoneNo: newValue ? trip.passengers[index].phone : null,
        _id: trip.passengers[index].customer_id,
        name: trip.passengers[index].name
      }));

      if (!newPayload.driver) {
        newPayload.driver = {};
      }

      newPayload.driver.phoneNo = newValue ? trip?.driver?.phone : null
      newPayload.driver._id = trip?.driver?._id;


      return newPayload;
    });
  };

  const toggleAllEmail = () => {
    const newValue = !allEmail;
    setAllEmail(newValue);
    setPayload((prevPayload) => {
      const newPayload = { ...prevPayload };
      if (!newPayload.receiverList) {
        newPayload.receiverList = trip.passengers.map(() => ({}));
      }
      newPayload.receiverList = newPayload.receiverList.map((item, index) => ({
        ...item,
        email: newValue ? trip.passengers[index].email : null,
        _id: trip.passengers[index].customer_id,
      }));

      if (!newPayload.driver) {
        newPayload.driver = {};
      }

      newPayload.driver.email = newValue ? trip?.driver?.email : null
      newPayload.driver._id = trip?.driver?._id;
      return newPayload;
    });
  };
  const addCustom = () => {
    setPayload((prevPayload) => {
      const newPayload = { ...prevPayload };
      if (!newPayload.customReceiverList) {
        newPayload.customReceiverList = [];
      }
      newPayload.customReceiverList = [...newPayload.customReceiverList, {}];
      return newPayload;
    });
  };

  const handleCustomChange = (index, field, value) => {
    setPayload((prevPayload) => {
      const newPayload = { ...prevPayload };

      if (!newPayload.customReceiverList) {
        newPayload.customReceiverList = [];
      }

      while (newPayload.customReceiverList.length <= index) {
        newPayload.customReceiverList.push({});
      }

      newPayload.customReceiverList[index] = {
        ...newPayload.customReceiverList[index],
        [field]: value,
      };
      return newPayload;
    });
  };

  const handleRemoveRow = (index) => {
    setPayload((prevPayload) => {
      const newPayload = { ...prevPayload };
      if (newPayload.customReceiverList) {
        newPayload.customReceiverList.splice(index, 1);
      }
      return newPayload;
    });
  };

  return (
    <>
      <table className="table-bordered">
        <thead>
          <tr>
            <th>
              <div>Name</div>
            </th>
            <th>
              <div className="d-flex align-items-center">
                <span>Contact </span>
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center">
                <span>Email</span>
              </div>
            </th>
            {/* <th> Action</th> */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><div className="my-1 ps-1 "><b>Passengers </b></div></td>
            <td>
              <div className=" d-flex align-items-center justify-content-start">
                <Input
                  className="mx-2"
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  value={allPhone}
                  onClick={toggleAllPhone}
                />
                <span className="pt-1"><b className="mt-2">Select All Contacts</b></span>
              </div>
            </td>
            <td>
              <div className=" d-flex align-items-center justify-content-start">
                <Input
                  className="mx-2"
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  value={allEmail}
                  onClick={toggleAllEmail}
                />
                <span className="pt-1"><b>Select All Emails</b></span>
              </div>
            </td>
            {/* <td></td> */}
          </tr>
          {trip?.passengers?.map(
            ({ customer_id, salutation, name, phone, email }, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div>{`${salutation ? salutation : ""} ${name ?? ""}`}</div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Input
                        className="mx-2"
                        type="checkbox"
                        checked={getBooleanValue(index, "phoneNo")}
                        onChange={() => {
                          updateReceiverList(
                            index,
                            "phoneNo",
                            phone,
                            customer_id
                          );
                        }}
                      />
                      <span>
                        +{phone?.countryCode}-
                        {phone?.phone}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Input
                        className="mx-2"
                        type="checkbox"
                        checked={getBooleanValue(index, "email")}
                        onChange={() => {
                          updateReceiverList(
                            index,
                            "email",
                            email,
                            customer_id
                          );
                        }}
                      />
                      <span>{email}</span>
                    </div>
                  </td>
                </tr>
              );
            }
          )}
          {payload?.customReceiverList?.map(({ name, phone, email }, index) => {
            return (
              <tr key={index}>
                <td>
                  <td>
                    <InputField
                      outline
                      placeholder="Name"
                      value={name}
                      onChange={(v) => handleCustomChange(index, "name", v)}
                    />
                  </td>
                </td>
                <td>
                  <PhoneNum
                    placeholder={t("Phone No ")}
                    outline
                    value={phone}
                    label={t("Phone")}
                    onChange={(v) => handleCustomChange(index, "phoneNo", v)}
                  />
                </td>
                <td>
                  <InputField
                    outline
                    placeholder="Email"
                    value={email}
                    onChange={(v) => handleCustomChange(index, "email", v)}
                  />
                </td>
                <td>
                  <div onClick={() => { handleRemoveRow(index) }} style={{ cursor: "pointer" }}>
                    <RemoveLink />
                  </div>
                </td>
              </tr>
            );
          })}
          <tr>
            <div className="my-1 ps-1 "><b>Driver :</b></div>
          </tr>
          {trip?.driver ?
            <tr >
              <td>
                <div>{`${trip?.driver?.salutation ? trip?.driver?.salutation : ""} ${trip?.driver?.name ?? ""}`}</div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  {trip?.driver?.phone && <Input
                    className="mx-2"
                    type="checkbox"
                    checked={getDriverBooleanValue("phoneNo")}
                    onChange={() => {
                      updateDriverList(
                        "phoneNo",
                        trip?.driver?.phone,
                      );
                    }}
                  />}
                  <span>
                    +{trip?.driver?.phone?.countryCode}-
                    {trip?.driver?.phone?.phone}
                  </span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  {trip?.driver?.email && <Input
                    className="mx-2"
                    type="checkbox"
                    checked={getDriverBooleanValue("email")}
                    onChange={() => {
                      updateDriverList(
                        "email",
                        trip?.driver?.email,
                      );
                    }}
                  />}
                  <span>{trip?.driver?.email}</span>
                </div>
              </td>
            </tr> :
            null}
        </tbody>
      </table >
      {payload?.customReceiverList &&
        payload?.customReceiverList.length > 0 && (
          <h2 className="my-2">Additional :</h2>
        )
      }
      <div className="d-flex justify-content-end align-items-center my-2">
        <Button
          onClick={addCustom}
          className="mx-2"
          style={{ height: "28px", padding: "2px 10px 2px 4px" }}
          color="success"
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ margin: "2px", marginTop: '0px' }}
          >
            <div className="me-2">
              <GoPlus size={20} />
            </div>
            <div>
              Additional
            </div>
          </div>
        </Button>
      </div>
      <div className="d-flex">
        {/* <p className="form-control">{payload?.content}</p> */}
        <pre className="form-control">{payload?.content}</pre>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          onClick={call}
          className="mx-2"
          style={{ height: "28px", padding: "2px 10px 2px 4px" }}
          color="success"
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ margin: "2px" }}
          >
            <div className="me-2">
              <IoSend size={16} />
            </div>
            <div>
              Send
            </div>

          </div>
        </Button>
      </div>
    </>
  );
};
OnDemandMessageForTrip.proptype = {
  trip: PropTypes.object,
};

export default OnDemandMessageForTrip;
