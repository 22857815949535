import React, { useContext, useEffect, useState } from 'react'
import { InputField } from '../InputField/InputField'
import { I18nContext } from '../../store/context/i18nContext';
import DateSelectCustom from '../DateTimePickerWithInterval/DateSelectCustom';
import Filter from '../../pages/Filter/Filter';
import { Col, Row } from 'reactstrap';
import SupervisiorAssigner from '../Dropdowns/SupervisiorSelect/SupervisiorAssigner';
import { generateUniqueId } from '../dnd/board/helper';
import { prepareFilterArtifacts } from '../../util/FilterUtil';
import { ThreeBounce } from 'better-react-spinkit';
import { DialogContext } from '../../store/context/DialogContext';

function EditCreateView({ data, onChange, filterStructure = [], loading }) {
    const { t } = useContext(I18nContext);
    const [filterObject, setFilterObject] = useState({})
    const {showMessage, showError} =useContext(DialogContext)

    useEffect(() => {
        if (filterStructure?.length && data?.filterUrl) {
            console.log(new URLSearchParams(data?.filterUrl))
            let { filterObject } = prepareFilterArtifacts(new URLSearchParams(data?.filterUrl), filterStructure)
            console.log(filterObject);
            setFilterObject(filterObject)
        } else {
            setFilterObject({})
        }
    }, [data?.filterUrl, filterStructure])

    //UPDATE THE FUNCTION
    // const onApplyFilter = (filters) => {
    //     const { fromDate, tillDate } = data;
      
    //     let dateRange = '';
      
    //     if (fromDate && tillDate) {
    //       dateRange = `date=btw[${fromDate},${tillDate}]`;
    //     } else if (fromDate) {
    //       dateRange = `date=btw[${fromDate},${fromDate}]`;
    //     } else if (tillDate) {
    //       dateRange = `date=btw[${tillDate},${tillDate}]`;
    //     }
    //     let updatedFilterUrl = filters.filterUrl || '';
    //     updatedFilterUrl = updatedFilterUrl.replace(/date=btw\[.*?\]/g, '').replace(/&{2,}/g, '&').replace(/^&|&$/g, '');

    //     if (dateRange) {
    //       if (updatedFilterUrl) {
    //         updatedFilterUrl += `&${dateRange}`;
    //       } else {
    //         updatedFilterUrl = `${dateRange}`;
    //       }
    //     }

    //     showMessage("Filter Applied");
    //     onChange(prev => ({
    //       ...prev,
    //       filterUrl: updatedFilterUrl
    //     }));
    //   };
      
    const onApplyFilter = (filters) => {
        console.log(filters)
        showMessage("Filter Applied")
        onChange(prev => ({
            ...prev,
            filterUrl: filters
        }))
    }



    return (
        <>
            {loading ?
                <div className='d-flex justify-content-center align-items-center'>
                    <ThreeBounce />
                </div>
                :
                <div>
                    <InputField
                        placeholder={t("View Name")}
                        value={data?.name}
                        label={t("View Name")}
                        onChange={(v) =>
                            onChange(prev => ({
                                ...prev,
                                name: v
                            }))
                        }
                        className={``}

                    />

                    {/* <DateSelectCustom
                        placeholder={("DD-MM-YYYY")}
                        label="From Date"
                        value={data.fromDate}
                        onChange={(v) =>
                            onChange(prev => ({
                                ...prev,
                                fromDate: v
                            }))
                        }
                        className="col-sm-12 col-12"
                    />


                    <DateSelectCustom
                        value={data?.tillDate}
                        placeholder={("DD-MM-YYYY")}
                        label={'Till Date'}
                        onChange={(v) =>
                            onChange(prev => ({
                                ...prev,
                                tillDate: v
                            }))
                        }
                    /> */}

                    <Row className='d-flex align-items-center'>
                        <Col sm={1} >
                            <div>Filters</div>
                        </Col>
                        <Col sm={11}  >
                            <Filter
                                className="mb-2 ms-3 "
                                isRate={false}
                                insidePane={true}
                                value={filterObject}
                                structure={filterStructure}
                                applyFunc={onApplyFilter}
                                searchIcon="bx-check"
                            />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col sm={1} >
                            <div className='fw-normal fs-6'>Supervisors</div>
                        </Col>
                        <Col sm={2} className='position-relative'>
                            <SupervisiorAssigner
                                tooltipId={generateUniqueId()}
                                value={data?.supervisors}
                                onChange={(v) => {
                                    console.log(v)
                                    onChange(prev => ({
                                        ...prev,
                                        supervisors: v
                                    }))
                                }}

                            />
                        </Col>
                    </Row>
                </div>}
        </>
    )
}

export default EditCreateView