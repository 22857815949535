import React, { useState } from 'react';
import "./../Invoice/Fee/style.css"
import AutoSuggestSelect from '../../components/AutoSuggestSelect/AutoSuggestSelect';
import { AirlinesList } from './FlightConstant';

const AirlineAutoSuggest = ({
    value,
    onChange,
    className,
    required,
    noLabel
}) => {
    return (
        <div className={`d-flex ${className}`}>
            <div className='chargeNameAutoSuggest w-100'>
                <AutoSuggestSelect
                    label="Airline"
                    noLabel={noLabel}
                    required={required}
                    data={AirlinesList ?? []}
                    value={value}
                    onChange={(event, { newValue }) => {
                        onChange(newValue)
                    }}
                />
            </div>
            <div style={{ position: 'relative' }}>
                {required && <font color="red">*</font>}
            </div>
        </div>
    );
};

export default AirlineAutoSuggest;