import moment from "moment";
import { Tooltip } from 'react-tooltip';
import { MakeModelSelect, VehicleTypeSelect } from "../../components";
import RateAreaSelect from "../../components/Dropdowns/RateAreaSelect/RateAreaSelect";
import CustomTooltip from "../Booking/components/CustomTooltip";
import VehicleTypeLabel from "../../components/Dropdowns/Entity/VehicleTypeLabel";


export const ClientGridColumns = {
    version: 1,
    columns: [
        {
            title: "Rate No.",
            accessor: "rateNo",
            show: true,
        },
        {
            title: "Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Type",
            accessor: "rateType",
            show: true,
        },
        // {
        //     title: "Rate Name",
        //     show: true,
        //     renderer: (dataObj) => {
        //         return <div><RateNameSelect
        //             label={"Rate Name"}
        //             value={dataObj?.rateName_id}
        //             className="col-sm-3"
        //             plain
        //             asLabel
        //         />
        //         </div>
        //     },
        // },
        // {
        //     title: "Rate Area",
        //     show: true,
        //     renderer: (dataObj) => {
        //         return <div>
        //             <RateAreaSelect
        //                 label={"Rate Area"}
        //                 value={dataObj?.rateAreas}
        //                 className="col-sm-3"
        //                 multi
        //                 plain
        //                 asLabel
        //             />
        //         </div>
        //     },
        // },
        // {
        //     title: "Client Pool",
        //     show: true,
        //     renderer: (dataObj) => {
        //         return <div>
        //             <ClientPoolSelect
        //                 label={"Client Pool"}
        //                 value={dataObj?.clientPools}
        //                 className="col-sm-3"
        //                 multi
        //                 plain
        //                 asLabel
        //             />
        //         </div>
        //     },
        // },
        {
            title: "Vehicle Type",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <VehicleTypeSelect
                        label={"Vehicle Type"}
                        value={dataObj?.vehicleType}
                        className="col-sm-3"
                        plain
                        asLabel
                    />
                    {/* <VehicleTypeLabel id={dataObj?.vehicleType} /> */}

                </div>
            },
        },
        {
            title: "Make Model",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <MakeModelSelect
                        label={"Make Model"}
                        value={dataObj?.makeModel}
                        className="col-sm-3"
                        plain
                        asLabel
                    />
                </div>
            },
        },
        // {
        //     title: "Applies On",
        //     renderer: (dataObj) => {
        //         return (
        //             <div>
        //                 {dataObj.appliesOn === "PER_PAX" ? < i className="bx bx-male font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }}></i > : < i className="bx bx-car font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }} ></i>}
        //             </div>
        //         );
        //     },
        //     show: true,
        // },
        // {
        //     title: "Status",
        //     renderer: (dataObj) => {
        //         return (
        //             < div >
        //                 {dataObj.status === 0 ? "Inactive" : dataObj.status === 1 ? "Active" : null}
        //             </div >
        //         );
        //     },
        //     show: true,
        // },

        {
            title: "Status",
            accessor: "status",
            renderer: (dataObj, rowIndex, pageSize) => {

                return (
                    <div className="d-flex align-items-end">
                        {dataObj?.status == 0 || dataObj?.status === undefined ? (
                            <div>

                                <CustomTooltip>
                                    <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                                        <i id="not-clickable-isBooker-Inactive" className="fas fa-ban font-size-12 mt-1" style={{ color: 'red' }} />
                                    </CustomTooltip.Body>
                                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                        <div>InActive</div>
                                    </CustomTooltip.ToolTip>
                                </CustomTooltip>
                                
               

                            </div>
                        ) : (

                            <div>

                                    <CustomTooltip>
                                        <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                                            <i id="not-clickable-isBooker-active" className="fas fa-check-circle font-size-12" style={{ color: 'green' }} />
                                        </CustomTooltip.Body>
                                        <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                            <div>Active</div>
                                        </CustomTooltip.ToolTip>
                                    </CustomTooltip>

                      
                            </div>
                        )}
                        <span style={{ color: "red" }}>&nbsp;{dataObj.validTill < parseInt(moment().format("YYYYMMDD")) ? "Expired" : ""}</span>
                    </div>

                );
            },

            show: true,
            // required: true,
        },


        {
            title: "For Public",
            renderer: (dataObj, rowIndex, pageSize) => {
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                        {dataObj?.forB2C === true ? (
                            <div>

                                <CustomTooltip>
                                    <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                                        <i id="not-clickable-88-active" className="fas fa-check-circle  font-size-12 mt-1" style={{ color: 'green' }} />
                                    </CustomTooltip.Body>
                                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                        <div>Yes</div>
                                    </CustomTooltip.ToolTip>
                                </CustomTooltip>

                            </div>
                        ) : (
                            <div>--</div>
                        )}
                    </div>
                );
            },
            show: true,
            // required: true,
        },

        {
            title: "Night Charges",
            renderer: (dataObj, rowIndex, pageSize) => {
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                        {dataObj?.forB2C === true ? (
                            <div>
                                <CustomTooltip>
                                    <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                                        <i id="not-clickable-88-active" className="fas fa-check-circle  font-size-12 mt-1" style={{ color: 'green' }} />
                                    </CustomTooltip.Body>
                                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                        <div>Yes</div>
                                    </CustomTooltip.ToolTip>
                                </CustomTooltip>
                            </div>
                        ) : (
                            <div>-</div>
                        )}
                    </div>
                );
            },
            show: true,
            // required: true,
        },

        {
            title: "Area",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <RateAreaSelect
                        label={"Rate Area"}
                        value={dataObj?.rateAreas}
                        className="col-sm-3"
                        multi
                        plain
                        asLabel
                    />
                </div>
            },
        },

        {
            title: "Base Fare",
            accessor: "rateExtn.rateItems",
            renderer: (dataObj) => {
                const baseFareItem = dataObj?.rateExtn?.rateItems?.find(item => item.name === "BaseFare");
                return baseFareItem ? (baseFareItem.value / 100) : "Not found";
            },
            show: true,
        },

        {
            title: "Billing Duration",
            renderer: (dataObj) => {
                return <div>{dataObj?.billingDuration ? dataObj?.billingDuration : "--"}</div>
            },
            show: true,
        },
        // {
        //     title: "Distance Fare",
        //     accessor: "rateExtn.rateItems",
        //     renderer: (dataObj) => {
        //         const baseFareItem = dataObj?.rateExtn?.rateItems?.find(item => item.name === "DistanceFare");
        //         return baseFareItem ? (baseFareItem.value / 100) : "Not found";
        //     },
        //     show: true,
        // }
        // ,
        // {
        //     title: "Night Start Time",
        //     show: true,
        //     renderer: (dataObj) => {
        //         const timeStr = dataObj?.rateExtn?.nightStartTime?.toString();
        //         if (moment(timeStr, 'Hmm', true).isValid()) {
        //             const formattedTime = moment(timeStr, 'Hmm').format('hh:mm A');
        //             return <div>{formattedTime}</div>;
        //         } else {
        //             return <div>--</div>;
        //         }
        //     },
        // }
        // ,
        // {
        //     title: "Night End Time",
        //     show: true,
        //     renderer: (dataObj) => {
        //         const timeStr = dataObj?.rateExtn?.nightEndTime?.toString();
        //         if (moment(timeStr, 'Hmm', true).isValid()) {
        //             const formattedTime = moment(timeStr, 'Hmm').format('hh:mm A');
        //             return <div>{formattedTime}</div>;
        //         } else {
        //             return <div>--</div>;
        //         }
        //     },
        // },
        {
            title: "Validity",
            width: 150,
            renderer: (dataObj) => {
                const validFrom = dataObj.validFrom;
                const validTill = dataObj.validTill;
                return (
                    (validFrom || validTill) ? <div className="d-flex align-items-center">
                        <span>  {validFrom ? (
                            (validFrom.toString().slice(6, 8) +
                                '-' +
                                validFrom.toString().slice(4, 6) +
                                '-' +
                                validFrom.toString().slice(0, 4))
                        ) : "--"}</span>
                        &nbsp;&#8594;&nbsp;
                        <span>
                            {validTill ? (
                                validTill.toString().slice(6, 8) +
                                '-' +
                                validTill.toString().slice(4, 6) +
                                '-' +
                                validTill.toString().slice(0, 4)
                            )
                                : "--"}
                        </span>
                    </div> : "--"
                );
            },
            show: true
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};



export const RateDataGridColumns = {
    version: 1,
    columns: [
        {
            title: "Rate No.",
            accessor: "rateNo",
            show: true,
        },
        {
            title: "Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Type",
            accessor: "rateType",
            show: true,
        },

       
        {
            title: "Vehicle Type",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <VehicleTypeSelect
                        label={"Vehicle Type"}
                        value={dataObj?.vehicleType}
                        className="col-sm-3"
                        plain
                        asLabel
                    />
                </div>
            },
        },
        {
            title: "Make Model",
            show: true,
            renderer: (dataObj) => {
                return <div>
                    <MakeModelSelect
                        label={"Make Model"}
                        value={dataObj?.makeModel}
                        className="col-sm-3"
                        plain
                        asLabel
                    />
                </div>
            },
        },


        {
            title: "Base Fare",
            accessor: "rateExtn.rateItems",
            renderer: (dataObj) => {
                const baseFareItem = dataObj?.rateExtn?.rateItems?.find(item => item.name === "BaseFare");
                return baseFareItem ? (baseFareItem.value / 100) : "Not found";
            },
            show: true,
        },

   
        {
            title: "Validity",
            width: 150,
            renderer: (dataObj) => {
                const validFrom = dataObj.validFrom;
                const validTill = dataObj.validTill;
                return (
                    (validFrom || validTill) ? <div className="d-flex align-items-center">
                        <span>  {validFrom ? (
                            (validFrom.toString().slice(6, 8) +
                                '-' +
                                validFrom.toString().slice(4, 6) +
                                '-' +
                                validFrom.toString().slice(0, 4))
                        ) : "--"}</span>
                        &nbsp;&#8594;&nbsp;
                        <span>
                            {validTill ? (
                                validTill.toString().slice(6, 8) +
                                '-' +
                                validTill.toString().slice(4, 6) +
                                '-' +
                                validTill.toString().slice(0, 4)
                            )
                                : "--"}
                        </span>
                    </div> : "--"
                );
            },
            show: true
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 60,
            show: true,
            required: true,
        },
    ],
};
