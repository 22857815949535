import moment from "moment";
import { FaCopy } from "react-icons/fa";
import toastr from "toastr";
import CustomTooltip from "./components/CustomTooltip";
import './style.css';
import { copyToClipboard } from "../../util/Util";


export const statusMapping = {
    0: { text: "New", color: "#AEB6BF" },
    1: { text: "Confirmed", color: "#2980B9" },
    2: { text: "Ready", color: "#A569BD" },
    3: { text: "Dispatched", color: "#FFBF00" },
    4: { text: "Live", color: "#FF7F50" },
    5: { text: "Completed", color: "#52BE80" },
    6: { text: "Cancelled", color: "#F1948A" },
    7: { text: "Error", color: "#CB4335" },
    8: { text: "Paused", color: "#CCCCFF" },
    9: { text: "Expired", color: "#7B241C" },
    10: { text: "Rejected", color: "#7B241C" },
    19: { text: "Cancelled", color: "#F1948A" },
    20: { text: "Completed", color: "#52BE80" },
    29: { text: "Cancelled", color: "#F1948A" },
    30: { text: "Completed", color: "#52BE80" },
};

export const statusArray = [
    { value: 0, label: "New", backgroundColor: "#AEB6BF", color:"#000", selectable: (v) => { return false } },
    { value: 1, label: "Confirmed", backgroundColor: "#2980B9", color:"#fff", selectable: (v) => { return true } },
    { value: 2, label: "Ready", backgroundColor: "#A569BD", color:"#fff", selectable: (v) => { return false } },
    { value: 3, label: "Dispatched", backgroundColor: "#FFBF00", color:"#000", selectable: (v) => { if (v == 2) return true; return false } },
    { value: 4, label: "Live", backgroundColor: "#FF7F50", color:"#fff", selectable: (v) => { return false } },
    { value: 5, label: "Completed", backgroundColor: "#52BE80", color:"#fff",  selectable: (v) => {  if ([1, 2, 3, 4]?.includes(v)) return true; return false }  },
    { value: 6, label: "Cancelled", backgroundColor: "#F1948A", color:"#fff", selectable: (v) => { if ([0, 1, 2, 3]?.includes(v)) return true; return false } },
    { value: 10, label: "Rejected", backgroundColor: "#7B241C", color:"#fff", selectable: (v) => { if ([0, 1, 2, 3]?.includes(v)) return true; return false } },
    { value: 19, otherValue: 20, label: "Invoiced", backgroundColor: "#207645", color:"#fff", selectable: (v) => { return false } },
    { value: 29, otherValue: 30, label: "Settled", backgroundColor: "#207645", color:"#fff", selectable: (v) => { return false } },
]

export const tripTypeMapping = {
    1: 'Local',
    2: 'Rental',
    3: 'Shared',
    4: 'Transfer',
    5: 'Self Drive',
    6: 'Route',
    7: 'Airport Pick',
    8: 'Airport Drop',
    9: 'Tour Package',
    10: 'Outstation OneWay',
    11: 'Outstation Returning',
    12: 'Lease',
    21:'Route-Standard',
    22:'Route-PickUp',
    23:'Route-Drop',
    24: 'Route-Shuttle'
}



export const GridColumns = ({ insidePane = false }) => {
    return ({
        version: 1,
        columns: [
            {
                title: "Trip No",
                accessor: "tripNo",
                width: 100,
                renderer: (dataObj) => {
                    const statusInfo = statusMapping[dataObj?.status || 0] || { text: "Unknown", color: "#000000" };
                    return <div className="d-flex align-items-center">
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                backgroundColor: statusInfo.color,
                                marginRight: '5px',

                            }}
                        ></div>
                        {dataObj.tripNo}
                    </div>
                },
                show: true,
            },
            {
                title: "Invoice No",
                accessor: "clientInvoiceNo",
                width: 100,
                renderer: (dataObj) => {
                    return <div className="d-flex align-items-center">
                        {dataObj.clientInvoiceNo ? dataObj.clientInvoiceNo : "--"}
                    </div>
                },
                show: true,
            },
            {
                title: "Trip Type",
                accessor: "tripType",
                width: 100,
                renderer: (dataObj) => {
                    return <div>{tripTypeMapping[dataObj?.tripType]}</div>;
                },
                show: true,
            },
            {
                title: "Tags",
                accessor: "tagValues",
                width: 200,
                renderer: (dataObj, rowIndex, pageSize) => {
                    return (!!dataObj?.tags ? <CustomTooltip>
                        <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                            <div
                                className='d-flex'
                            >
                                {dataObj?.tagValues?.map((tag, index) => (
                                    <div onClick={(e) => e.stopPropagation()} key={index} className={` tag-item-inline `} style={{ color: tag?.color, backgroundColor: `${tag.color}22`, fontSize:  11, fontWeight: 500 }}>
                                        {tag.label}
                        
                                    </div>
                                ))}

                            </div>
                        </CustomTooltip.Body>
                        <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                            <div
                                className='d-flex'
                            >
                                {dataObj?.tagValues?.map((tag, index) => (
                                    <div onClick={(e) => e.stopPropagation()} key={index} className={` tag-item-inline `} style={{ color: tag?.color, backgroundColor: `${tag.color}22`, fontSize: 11, fontWeight: 500 }}>
                                        {tag.label}

                                    </div>
                                ))}
                            </div>
                        </CustomTooltip.ToolTip>
                    </CustomTooltip>
                        : <>{"--"}</>
                    );
                },
                show: true,
            },
            {
                title: "DutySlip #",
                accessor: "dutySlipNo",
                width: 80,
                renderer: (dataObj) => (
                    <div>
                        {dataObj.dutySlipNo}
                    </div>
                ),
                show: true,
            },
            {
                title: "Client",
                accessor: "clientName",
                width: 170,
                renderer: (dataObj, rowIndex, pageSize) => {
                    return <div>{dataObj?.client?.client_id ? dataObj?.clientName
                        : <div className="d-flex justify-content-center align-content-center" style={{ fontStyle: "italic" }}>
                            <div className="mx-1 mt-1" style={{background:"#df6463", width:"10px", height:"10px", borderRadius:"50%"}}></div>
                            <div>Walk In</div>
                            </div>}</div>;

                },
                show: true,
            },
            {
                title: "Client Company",
                accessor: "clientCompanyName",
                width: 170,
                renderer: (dataObj, rowIndex, pageSize) => {
                    return <div>{dataObj?.client?.company_id ?

                        <>{dataObj?.clientCompanyName}</>

                        : <span style={{ fontStyle: "italic" }}>--</span>}</div>;

                },

                show: true,
            },
            {
                title: "Requested",
                width: 200,
                accessor: "vehicleTypeName,makeModelName",
                // renderer: (dataObj) => {
                //     return (!!dataObj?.makeModel ?
                //         <MakeModelSelect
                //             asLabel
                //             value={dataObj?.makeModel}
                //             onChange={(v) => { }}
                //         /> : <>{"--"}</>
                //     );
                // },
                renderer: (dataObj, rowIndex, pageSize) => {
                    return ((dataObj?.makeModelName || dataObj?.vehicleTypeName) ? 
                        <CustomTooltip>
                            <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                                {dataObj?.makeModelName ? <>{dataObj?.makeModelName}</> :
                                 dataObj?.vehicleTypeName?<>{dataObj?.vehicleTypeName}</>:<>{"--"}</>
                                }
                            </CustomTooltip.Body>
                            <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                {dataObj?.makeModelName ? <>{dataObj?.makeModelName}</> :
                                dataObj?.vehicleTypeName?<>{dataObj?.vehicleTypeName}</>:<>{"--"}</>
                                }
                            </CustomTooltip.ToolTip>
                        </CustomTooltip>
                        : <>{"--"}</>
                    );
                },
                show: true,
            },
            {
                title: "Passengers",
                accessor: "passengers",
                width: 150,
                renderer: (dataObj, rowIndex, pageSize) => {
                    const passengers = dataObj?.passengers || [];
                    const passengerCount = passengers.length;
                    const renderPassengerInfo = (passenger, index) => (
                        <div className="d-flex align-items-center " key={passenger.customer_id}>
                            <div className="d-flex align-items-center">
                                {index >= 0 && <span className="larger-bullet">&#8226;</span>}
                                <div>{passenger.name}</div>
                            </div>
                            <div style={{ marginLeft: '8px' }}>{passenger.phone?.countryCode ? passenger.phone?.countryCode : ''}-{passenger?.phone?.phone}</div>
                            <div style={{ marginLeft: '8px' }}>{passenger.email}</div>
                        </div>
                    );

                    return (
                        <>
                            {passengerCount === 0 ? (
                                <div>
                                    --
                                </div>
                            ) : passengerCount === 1 ? (
                                <CustomTooltip>
                                    <CustomTooltip.Body style={{ maxWidth: 140, overflowX: 'hidden' }}>  {passengers[0].name}  </CustomTooltip.Body>
                                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                        {renderPassengerInfo(passengers[0])}

                                    </CustomTooltip.ToolTip>
                                </CustomTooltip>
                            ) : (

                                <CustomTooltip>
                                    <CustomTooltip.Body >
                                        <div href="#" id={`not-clickable-pass-m-${dataObj?._id}${dataObj?._id}`} data-tip={passengerCount}
                                            style={{ color: 'blue', textDecoration: 'underline', cursor: "pointer" }}
                                        >           {passengerCount}
                                        </div>
                                    </CustomTooltip.Body>
                                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                        {passengers.map(renderPassengerInfo)}

                                    </CustomTooltip.ToolTip>
                                </CustomTooltip>

                            )}
                        </>
                    );
                },
                show: true,
            },
            {
                title: "Passenger Phone",
                accessor: "passengerPhone",
                width: 200,
                renderer: (dataObj, rowIndex, pageSize) => {
                    const passengers = dataObj?.passengers || [];
                    const passengerCount = passengers.length;
                    let copyText = ""
                    let contentToCopy = ""
                    if (passengerCount !== 0) {
                        copyText = passengers[0]?.phone?.phone ? `${passengers[0]?.phone?.countryCode ? passengers[0]?.phone?.countryCode : ''} - ${passengers[0]?.phone?.phone}` : null;
                        contentToCopy = passengers[0]?.phone?.phone ? `${passengers[0]?.phone?.phone}` : null;
                    }
                    return (
                        <>
                            {passengerCount === 0 ? (
                                <div>
                                    --
                                </div>
                            ) : (
                                <>
                                    {copyText}
                                    {copyText ? <div className="ps-2 cursor-pointer text-black" onClick={() => copyToClipboard(contentToCopy)}><FaCopy /> </div> : null}
                                </>
                            )
                            }
                        </>
                    );
                },
                show: true,
            },
            {
                title: "Driver",
                accessor: "driver",
                width: 200,
                renderer: (dataObj, rowIndex, pageSize) => {
                    return (
                        <>
                            {dataObj?.driver?.name}
                        </>
                    )

                },
                show: true,
            },

            {
                title: "Created By",
                accessor: "createdByName",
                show: true,
                renderer: (dataObj) => {
                  return <div>
                      <>{dataObj?.createdByName}</>
                  </div>;
                },
              },

            {
                title: "Driver Phone",
                accessor: "driverPhone",
                width: 200,
                renderer: (dataObj, rowIndex, pageSize) => {
                    const copyText = dataObj?.driver?.phone?.phone ? `${dataObj?.driver?.phone?.countryCode ? dataObj?.driver?.phone?.countryCode : ''} - ${dataObj?.driver?.phone?.phone}` : null
                    const contentToCopy = dataObj?.driver?.phone?.phone ? `${dataObj?.driver?.phone?.phone}` : null
                    return (
                        <>
                            {copyText}
                            {copyText ? <div className="ps-2 cursor-pointer text-black" onClick={() => copyToClipboard(contentToCopy)}><FaCopy /> </div> : null}
                        </>
                    )

                },
                show: true,
            },

            {
                title: "Start Time",
                accessor: "startTime",
                renderer: (dataObj) => {
                    return <div>{moment.unix(dataObj?.startTime).format("DD-MMM HH:mm")}</div>;
                },
                show: true,
            },
            {
                title: "PickUp",
                accessor: "src.addressLine",
                width: 150,
                renderer: (dataObj, rowIndex, pageSize) => {
                    const address = dataObj?.src?.addressLine || '';
                    const city = dataObj?.src?.city || '';
                    return (
                        <CustomTooltip>
                            <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}> {city} </CustomTooltip.Body>
                            <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>{address}</CustomTooltip.ToolTip>
                        </CustomTooltip>
                    );
                },
                show: true,
            },
            {
                title: "Vehicle",
                accessor: "vehicle",
                width: 100,
                renderer: (dataObj) => {
                    return <div style={{ cursor: 'pointer' }}>{dataObj?.vehicle?.regNo ? dataObj?.vehicle?.regNo : '--'}</div>;
                },
                show: true,
            },

            {
                title: "Distance",
                accessor: "tripExtn.distance",
                width: 100,
                renderer: (dataObj) => {
                    return <div style={{ cursor: 'pointer' }}>{dataObj?.tripExtn?.distance ? `${dataObj?.tripExtn?.distance} KM` : '--'}</div>;
                },
                show: true,
            },
            // TODO : // make supplier singleton
            {
                title: "Supplier",
                accessor: "supplierName",
                width: 200,
                renderer: (dataObj, rowIndex, pageSize) => {
                    return <>
                        {dataObj?.vehicle?.provider_id ? <>{dataObj?.supplierName}</> : "--"}

                    </>
                },
                show: true,
            },



            {
                title: "Status",
                accessor: "status",
                width: 100,
                renderer: (dataObj) => {
                    const statusInfo = statusMapping[dataObj?.status || 0] || { text: "Unknown", color: "#000000" };
                    return <div className="d-flex align-items-center">
                        {/* <div
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: statusInfo.color,
                            marginRight: '5px',

                        }}
                    ></div> */}
                        <div style={{
                            borderRadius: 10,
                            backgroundColor: statusInfo.color,
                            padding: "0px 7px",
                            color: "#fff"

                        }}>{statusInfo.text}</div>
                    </div>
                },
                show: true,
            },

            {
                title: "End Time",
                accessor: "endTime",
                renderer: (dataObj) => {
                    return <div>
                        {(!dataObj?.startTime || !dataObj?.duration) ?
                            '--'
                            : moment.unix(dataObj?.startTime + dataObj?.duration * 60).format("DD-MMM HH:mm")}
                    </div>;
                },
                show: true,
            },
            {
                title: "Destination",
                accessor: "dst.addressLine",
                width: 130,
                renderer: (dataObj, rowIndex, pageSize) => {
                    return (
                        <CustomTooltip>
                            <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>            {dataObj?.dst?.city}</CustomTooltip.Body>
                            <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>            {dataObj?.dst?.addressLine}</CustomTooltip.ToolTip>
                        </CustomTooltip>
                    );
                },
                show: true,
            },




            // // {
            // //     title: "Vendor Company",
            // //     accessor: "",
            // //     renderer: (dataObj) => {
            // //         return <div>
            // //             {dataObj?.vendorCompany_id ? <CompanySelect
            // //                 asLabel
            // //                 value={dataObj?.vendorCompany_id}
            // //                 onChange={(v) => { }}
            // //                 className=""
            // //             /> : '--'}
            // //         </div>;
            // //     },
            // //     show: true,
            // // },


            {
                title: "Compliance  Status",
                width: 150,
                accessor: "complianceStatus",
                renderer: (dataObj) => {
                    let text = "--";
                    let color = "";

                    if (dataObj?.vehicle?.complianceStatus == 1) {
                        text = "Compliant";
                        color = '#52BE80'
                    } else if (dataObj?.vehicle?.complianceStatus == 2) {
                        color = '#CB4335'
                        text = "Not Compliant";
                    }
                    else if (dataObj?.vehicle?.complianceStatus == 3) {
                        text = "Due";
                        color = '#FFBF00'
                    }
                    return <div>
                        <div className="d-flex align-items-center">
                            <div
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: color,
                                    marginRight: '5px',

                                }}
                            ></div>
                            <div>{text ?? "--"}</div>
                        </div>
                    </div>;
                },
                show: true,
            },
            {
                title: "Client Amount",
                accessor: "clientAmount",
                width: 120,
                renderer: (dataObj) => (
                    <div className="ml-auto ms-auto px-2">
                        {dataObj.clientAmount}
                    </div>
                ),
                show: true,
            },
            {
                title: "Supplier Amount",
                accessor: "supplierAmount",
                width: 120,
                renderer: (dataObj) => (
                    <div className="ml-auto ms-auto px-2">
                        {dataObj.supplierAmount}
                    </div>
                ),
                show: true,
            },
            {
                title: "Action",
                accessor: "action",
                fixed: "right",
                width: insidePane ? 110 : 80,
                show: true,
                required: true,
            },
        ],
    })
};
