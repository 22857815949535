import { useContext, useState } from "react";
import { ModalComponent } from "../../components";
import User from "./User";
import { I18nContext } from "../../store/context/i18nContext";
import _ from "lodash";

const SearchUser = ({ userType, value, onChange, onlyIcon, iconStyle, style, hideCheckboxes = false,client_id=null,booker = false }) => {
    const getFilterURL=()=>{
        let filterUrl = ``;
        if(client_id){
            filterUrl += `&client_id=${client_id}`
        }
        if(booker){
            filterUrl += `&isBooker=true`
        }
        return filterUrl
    }

    const [showModal, setShowModal] = useState(false)
    const { t } = useContext(I18nContext);
    return (
        <div>
            <ModalComponent
                position={"top"}
                size={"large"}
                isOpen={showModal}
                onToggle={() => {
                    setShowModal(false)
                }}
            >
                <ModalComponent.Body>
                    <User
                    //    filterUrlProp={client_id ? `client_id=${client_id}` : ""}
                        filterUrlProp={getFilterURL()}
                        hideCheckboxes={hideCheckboxes}
                        label={t(_.capitalize(userType))}
                        insidePane={true}
                        value={value}
                        onSelect={(v) => {
                            onChange(v)
                            setShowModal(false)
                        }}
                    />
                </ModalComponent.Body>
            </ModalComponent>


            <div
                className="d-flex align-items-center justify-content-center"
                onClick={() => { setShowModal(true) }}
                // style={{
                //     backgroundColor:
                // }}
                style={{ ...style }}
            >
                <i style={{ ...iconStyle }} className="mdi mdi-magnify text-dark font-size-18 mx-1" />
                {!onlyIcon && <div>Search {_.capitalize(userType)}</div>}
            </div>

        </div>

    )
};

export default SearchUser;
