import React, { useCallback, useEffect, useRef, useState } from 'react'
import BookingService from './BookingService';
import { TRIP_CHARGES } from './BookingConstant';
import EditBooking from '../BookingsNew/EditBooking';


const getDefaultClientBilling = (trip_id) => {
    return {
        trip_id: trip_id,
        feeRate: {
            rateExtn: {

            }
        },
        fee: {
            feeGroups: [{
                name: TRIP_CHARGES,
            }]
        }
    }
}

function LabelledBooking({editID,tour_id,passengers,fetchBookings}) {
    const [trip, setTrip] = useState({ tripType: 1 });
    const [clientBilling, setClientBilling] = useState({});
    const [supplierBillings, setSupplierBillings] = useState([]);
    const billingRef=useRef(null)

    const [prefetchValue, setPrefetchValue] = useState({
        passengers: passengers,
      });

    useEffect(() => {
        setPrefetchValue({ passengers })
    }, [passengers])

      

    const fetchData = useCallback(async () => {
        let nTrip = null, cBilling = null, sBillings = null;
        try {
            if (editID) {
                const { booking: b, clientBilling: cB, supplierBillings: sB } = await BookingService.fetchTripAndBilling(editID, false);
                if (b) { nTrip = b; }
                if (cB) { cBilling = cB; }
                if (sB) { sBillings = sB }
                setTrip(nTrip || {});
            }
        } catch (e) {
            
        } 
        setClientBilling(cBilling || getDefaultClientBilling(editID));
        setSupplierBillings(sBillings || []);
   
    }, [editID]);

    useEffect(() => {
        fetchData();
    }, [fetchData])

  return (

    <EditBooking
    ref={billingRef}
    detailLoading={false}
    // customerType={customerType}
    // setCustomerType={setCustomerType}

    prefetchValue={prefetchValue}
    tour_id={tour_id}


    editID={editID}
    hasErr={false}
    trip={trip}

    setTrip={setTrip}
     
    clientBilling={clientBilling}
    setClientBilling={setClientBilling}
    supplierBillings={supplierBillings}
    setSupplierBillings={setSupplierBillings}
    // billingUpdated={billingUpdated}
    setBillingUpdated={(v) => {  }}
    postSave={fetchBookings}
    // billingUpdatedObject={billingUpdatedObject}
    // setBillingUpdatedObject={(v) => {
        
    // }}
/>
  )
}

export default LabelledBooking