import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
  BulkUpload,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { UserContext } from "../../store/context/UserContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditHotel from "./EditHotel";
import { STRUCTURE } from "./HotelConstant";
import { GridColumns } from "./HotelGridColumns";
import HotelService from "./HotelService";
import { toJS } from "mobx";

const Hotel = ({ value,
  insidePane,
  multiMode,
  fetchNewData = () => { },
  onSelect = () => { }
}) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/hotel/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false);

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      const res = await HotelService.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : []);
  }, []);

  useEffect(() => {
    setEditID(edit_id);
  }, [edit_id]);

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData?.name ||
      !detailData?.address?.addressLine ||
      !detailData?.address?.country ||
      !detailData?.address?.state ||
      !detailData?.address?.city) {
      setHasErr(true)
      showError(t("Please enter all required fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await HotelService.edit(detailData);
        setLoading(false);
        showMessage(t("Record updated successfully."));
      } else {
        const hotel = await HotelService.save(detailData);
        showMessage(t("Record saved successfully."));
        setLoading(false);
        if (!insidePane) navigate(`/hotel/edit/${hotel}`);
      }
      if (insidePane) {
        setShowDetailPage(false);
        fetchNewData();
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
    setHasErr(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await HotelService.delete(id);
        setLoading(false);
        showMessage("Deleted", "Deleted");
        setShowDetailPage(false);
        if (insidePane) {
          fetchNewData();
        }
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);

      try {
        await HotelService.bulkDelete(selectedIDs);
        setLoading(false);
        showMessage("Deleted", "Deleted Successfully");
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const saveBulk = async (data) => {
    try {
      const account = await HotelService.save(data);
      if (account) {
        return { isUploaded: true, message: "" }
      }
      return { isUploaded: false, message: "Failed unexpectedly" }
    } catch (error) {
      return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }

    }
  };

  const renderLastCol = useCallback(
    (hotel) => {
      return (
        <>
          {userContent?.rights?.includes(3802) &&  // TODO: Check rights for HOTEL
            <EditLink
              onClick={() => {
                if (!insidePane) navigate(`/hotel/edit/${hotel?._id}`);
                setEditID(hotel?._id);
                setShowDetailPage(true);
              }}
            />
          }
          {userContent?.rights?.includes(3803) &&  // TODO: Check rights for HOTEL
            <DeleteLink onClick={(event) => onDelete(event, hotel?._id)} />
          }
          {!multiMode && insidePane ? (
            <CheckButton
              onClick={() => {
                onSelect(toJS(hotel));
              }}
            />
          ) : null}
        </>
      );
    },
    [insidePane, multiMode, userContent]
  );
  return (
    <React.Fragment key={userContent?.rights}>
      <>
        {uploadPopup ? (
          <BulkUpload
            fetchData={() => {
              fetchData(filterURL);
            }}
            show={uploadPopup}
            onClose={() => { setUploadPopup(false) }}
            title={t("Hotel")}
            kind={"Hotel"}
            upload={saveBulk}
          />
        ) : null}

        <Layout
          hideAdd={!userContent?.rights?.includes(3801)}  // TODO: Check rights for HOTEL
          medium
          showDetailPage={showDetailPage}
          backDetailPage={async () => {
            setShowDetailPage(false);
            if (!insidePane) navigate("/hotel");
            setEditID(null);
          }}

          title={t("Hotel")}
          filterValues={filterObject}
          filterStructure={STRUCTURE}
          onApplyFilter={fetchData}
          onAddClick={() => {
            if (!insidePane) navigate(`/hotel/create`);
            setShowDetailPage(true);
            setEditID(null);
          }}
          insidePane={insidePane}
          page={HotelService.page}
          rows={HotelService.rows}
          total={HotelService.total}
          fetch={HotelService.fetch}
        >
          <Layout.ActionMenu>
            <div className="layout-action-menu">
              <DropdownMenu>
                <>
                  {/* <DropdownItem
                    onClick={() => {
                      onBulkDelete();
                    }}
                  >
                    {t("Bulk Delete")}
                  </DropdownItem> */}
                  {/* <DropdownItem onClick={() => setUploadPopup(true)}>
                    {t("Bulk Upload")}
                  </DropdownItem> */}
                  <DropdownItem>Download</DropdownItem>
                </>
              </DropdownMenu>
            </div>
          </Layout.ActionMenu>
          <Layout.Table>
            <DataGrid
              gridLoading={loading}
              data={HotelService.records}
              total={HotelService.total}
              uiPreference="hotel.grid"
              headers={GridColumns}
              onSelectChange={(v) => {
                onSelect(v);
                setSelectedIDs(v);
              }}
              selectedIDs={selectedIDs}
              page={HotelService.page}
              rowsPerPage={HotelService.rowsPerPage}
              onPaginationChange={HotelService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </Layout.Table>

          <Layout.DetailPageTitle>
            {detailData?._id ? t("Edit Hotel") : t("Add Hotel")}
          </Layout.DetailPageTitle>

          <Layout.DetailPageBody>
            <EditHotel
              editId={editID}
              onChange={(v) => {
                setDetailData(v);
              }}
              hasErr={hasErr}
            />
          </Layout.DetailPageBody>

          <Layout.DetailPageFooter>
            {userContent?.rights?.includes(3803) && editID ? (  // TODO: Check rights
              <DeleteButton
                onClick={(e) => onDelete(e, detailData?._id)}
                loading={loading}
              />
            ) : null}
            {/* TODO: Check rights */}
            {userContent?.rights?.includes(3802) && <SaveButton onClick={onSave} loading={loading} />}
          </Layout.DetailPageFooter>
        </Layout>
      </>
    </React.Fragment>
  );
};
export default observer(Hotel);