import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Hotel/HotelConstant";

class HotelSelect {
    hotels = [];
    queue = [];
    isFetching = false;

    constructor() {
        makeAutoObservable(this);
    }

    fetchSingle = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this?.hotels?.length) {
                    const response = await doGET(ENDPOINTS.grid());
                    if (response.status === 200) {
                        this.hotels = response?.data?.rows?.map((v) => ({
                            label: v.name,
                            value: v._id,
                            ...v
                        }));
                    } else {
                        // Handle error
                        this.error = response.data;
                    }
                }

                while (this.queue.length > 0) {
                    const { resolve } = this.queue.shift();
                    resolve();
                }

            } catch (err) {
                this.error = err;
                while (this.queue.length > 0) {
                    const { reject } = this.queue.shift();
                    reject(err);
                }
            } finally {
                this.isFetching = false;
            }
        });
    }

    fetch = async function () {
        if (this.isFetching) {
            return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
        };
        this.isFetching = true;
        this.fetchSingle()
    };
}

const HotelSelectService = new HotelSelect();
export default HotelSelectService;