 

import PropTypes from "prop-types";
import { useCallback,  useContext,  useState } from "react";
import { Card, Col, Row,Button } from "reactstrap"; 
import Fee from '../Fee/Fee'
import "../style.css";
import { isSameObject } from "../../../helpers/general";
import { ThreeBounce } from "better-react-spinkit";
import { InputField } from "../../../components";
import { I18nContext } from "../../../store/context/i18nContext";

const InvoiceItemManual = ({ 
    item,
    onChange, 
    itemIndex,
    status,
    onDelete=()=>{},
    showDeleteButton=false
  }) => {
    const {t}=useContext(I18nContext);
    const handleFeeChange = useCallback((updatedFeeData) => {
        if (updatedFeeData) { 
          onChange({...item,fee:updatedFeeData});
        }
      },
      [item, onChange]
    );
   
    return (
      <Card  className="p-2 pe-0 shadow-md rounded-2"   >
      
        <Row className="d-flex justify-content-between">
          <Col>
            <Row className="d-flex align-items-center">
              {item?.invoiceTrip?.tripNo && (
                <Col>
                  <span className="ms-2">
                    <strong className="font-size-16"> {`Trip No. ${item?.invoiceTrip?.tripNo}`}</strong>
                  </span>
                </Col>
              )}
              {item?.manual && (
                <>
                <Col className="col-3">
                  <span className="ms-2 mt-0 pt-0">
                    <strong className=" bg-light px-2 py-1 rounded-2 fs-6"> Manual</strong>
                  </span>
                </Col>
         
              <Col>
                            <textarea 
                            className="inputfield "
                            placeholder={t("Summary")}
                            rows={4}
                            style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", }}
                            value={item?.summary}
                            onChange={(e) => {
                              onChange({ ...item, summary: e?.target?.value });
                            }}
                            >
                            </textarea>
                            </Col>
                       
{/*                
                <InputField
                  placeholder={t("Summary")}
                  value={item?.summary}
                  label={t("Summary")}
                  onChange={(v) => {
                    onChange({ ...item, summary: v });
                  }}
                  type="text"
                /> */}
               </>
                                 )}

            </Row>
          </Col>
          <Col className="d-flex justify-content-end w-100 m-2">
          {status==="loading"?<ThreeBounce/>:(status==='idle'?null:(<span className={`ms-2 text-${status==='success'?'success':'danger'}`}>{status}</span>))}
          </Col>
   
        </Row>
        <Row className="p-0">
          <Fee
            item={item}
            itemIndex={itemIndex}
            onChange={handleFeeChange}
          />
        </Row>
        {showDeleteButton?<Button className='ms-auto py-1 me-2' onClick={onDelete}>Remove Item</Button>:null}
      </Card >
    );
  };
  InvoiceItemManual.propTypes = {
    item: PropTypes.object,
    onChange: PropTypes.func, 
  };
 

   export default InvoiceItemManual