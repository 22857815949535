
import { useCallback, useContext, useMemo, useState } from "react";
import {
  Button, 
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { InputField, ModalComponent } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import { getValue, isArrayNonEmpty } from "../../util/Util";
import InvoiceItems from "./InvoiceItems"; 
import "./style.css"; 
import { isSameObject } from "../../helpers/general";
import Booking from "../BookingsNew/Booking";
 

const InvoiceExtn = ({
  invoiceExtn,
  onChange,
  tax,
  itemStatuses,
  clientId,
  handleSave,
  invoiceId,
  handleInvoiceBillingCalculateAfterTripUpdate,
  savedStatus,
  paymentStatus
}) => {
  const { t } = useContext(I18nContext);
  const [showBookingGrid,setShowBookingGrid]=useState(false);
  const [selectedTripIds,setSelectedTripIds]=useState([]);

  const isTripItemExist=useMemo(()=>{
    return invoiceExtn?.items?.find(item=>item?.invoiceTrip?.trip_id || !item?.manual)
  },[invoiceExtn?.items])
 
  
  const updateExtension = useCallback(
    (fieldName, newValue) => {
      const invExtn = { ...invoiceExtn } || {
        properties: [{}],
        items: [
          {
            fee: {
              feeGroups: [
                {
                  name: `Group ${invoiceExtn?.items?.length ?? 1}`,
                  charges: [{}],
                  discounts: [{}],
                  taxItem: {
                    taxes: [{}],
                  },
                },
              ],
            },
            invoiceTrip: {},
            manual:true
          },
        ],
      };
      if (fieldName && newValue !== invExtn[fieldName]) {
        invExtn[fieldName] = newValue;
        onChange(invExtn);
      }
    },
    [invoiceExtn, onChange]
  );

  const updateItems = useCallback(
    (items) => {
      if (isArrayNonEmpty(items)) {
        const invExtn = { ...invoiceExtn } || {};
        invExtn["items"] = items; 
          onChange(invExtn);
      }
    },
    [invoiceExtn, onChange]
  );

  const handleAddItem = useCallback(() => {
    const itemArray = Array.isArray(invoiceExtn?.items)
      ? [...invoiceExtn?.items]
      : [];
    const itemArrayLength = itemArray?.length;
    itemArray.push({
      manual : true,
      new:true,
      fee: {
        feeGroups: [
          {
            name: `Group ${itemArrayLength + 1}`,
            charges: [{
              name: "",
              unitCost: 0,
              amount: 0,
              units: 0
            }],
            disounts: [{}],
            taxItem: {
              taxes: [{}]
            },
            subTotal: 0,
            totalDiscounts: 0,
            total: 0,
            totalTaxes: 0
          },
        ],
        subTotal: 0,
        totalDiscounts: 0,
        totalTaxes: 0,
        total: 0
      },
      invoiceTrip: {},
    });
    // updateItems(itemArray);
    onChange(prev=>({
      ...prev,
      invoiceExtn:{
        ...prev?.invoiceExtn,
        items:itemArray
      }
    }))
  }, [invoiceExtn?.items, onChange]);

  const handleSelectedTrip = () => {
    setShowBookingGrid(false);
    if (isArrayNonEmpty(selectedTripIds)) {
      const fetchedItems = selectedTripIds.map(tripId => ({
        invoiceTrip: { trip_id: tripId },
        manual: false,
        new: true
      }));
      onChange(prev => ({
        ...(prev ?? {}),
        invoiceExtn: {
          ...(prev?.invoiceExtn ?? {}),
          items: [...(getValue(prev?.invoiceExtn?.items, [])), ...fetchedItems],
        }
      }));
      setSelectedTripIds([]);
    }
  }
 
  return (
    <>
      <Row className="">

        <InvoiceItems
           items={invoiceExtn?.items} 
           itemStatuses={itemStatuses}
           savedStatus={savedStatus}
          onChange={onChange}
          tax={tax}
          handleSave={handleSave}
          invoiceId={invoiceId}
          handleInvoiceBillingCalculateAfterTripUpdate={handleInvoiceBillingCalculateAfterTripUpdate}
          paymentStatus={paymentStatus}
        />
      </Row>

      <div className="d-flex gap-2" style={{ width: '45.5%' }}>
        {!isTripItemExist ? <Button
          color="light"
          onClick={() => {
            handleAddItem()
          }}
          className="d-flex align-items-center"
        >
          <i className="bx color-blue bxs-plus-circle me-2"></i>
          Add Item
        </Button> :
          (!paymentStatus?<Button
            color="light"
            onClick={() => setShowBookingGrid(prev => !prev)}
            className="d-flex align-items-center"
          >
            <i className="bx color-blue bxs-plus-circle me-2"></i>
            Add Trip Item
          </Button>:null)}
      </div>

      <Modal
        fullscreen
        size="xl"
        isOpen={showBookingGrid}
        toggle={() => {
          setShowBookingGrid(false)
          setSelectedTripIds([]);
        }}
      >
        <ModalHeader toggle={() => {
          setShowBookingGrid(false)
          setSelectedTripIds([]);
        }}
        
        >
          <h5 className="modal-title">Select Trip Item</h5>
        </ModalHeader>

        <ModalBody>
          <Booking
            insidePane={true}
            clientId={clientId}
            clientInvoice_id={"notExists"}
            onSelect={(trip_id) => {
              if(trip_id){
                setSelectedTripIds(prev=>([...prev,trip_id]));
              }
            }}
            defaultFilters={["client.client_id", "clientInvoice_id"]}
            excludedIds={invoiceExtn?.items?.filter(item => item?.invoiceTrip?.trip_id)?.map(item => item?.invoiceTrip?.trip_id) ?? []}
            selectedTripIds={selectedTripIds}
            statuses={[1,2,3,4,5]}
          />
        </ModalBody>
        <ModalFooter>
          <Button className="me-2 mb-2 ms-auto ml-auto" onClick={handleSelectedTrip}>Set Selected</Button>
        </ModalFooter>
      </Modal>

   
    </>
  );
};

export default InvoiceExtn;
