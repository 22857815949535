import React, { useEffect, useState } from 'react'
import BookingService from '../Booking/BookingService';
import CustomTooltip from '../Booking/components/CustomTooltip';
import moment from 'moment';
import EditBooking from '../BookingsNew/EditBooking';
import LabelledBooking from '../Booking/LabelledBooking';
import { ModalComponent } from '../../components';
import { BOOKING_STATUS_MAP } from '../Booking/BookingConstant';
import "./style.css"
import { tripTypeMapping } from '../Booking/BookingGridColumns';
import { Button, Card } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';

function BookingGrid({ passengers = [], getTripRecords = () => { }, tour_id }) {
    const [loading, setLoading] = useState(false);
    // const [isMailModal, setMailModal] = useState(false);
    const [expandedRow, setExpandedRow] = useState({ data_id: "", show: false });
    const [editId, setEditID] = useState("");

    const fetchBookings = async (tour_id) => {
        // setLoading(true);
        try {
            const data = await BookingService.fetch(`tour_id=${tour_id}`);
            getTripRecords(data);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (tour_id) {
            fetchBookings(tour_id);
        }
    }, [passengers]);

    const toggleRow = (id) => {
        setExpandedRow({
            data_id: id,
            show: !expandedRow?.show
        });
    };

    const getColorStatus = (status = 0) => ({
        height: 10,
        width: 10,
        borderRadius: 20,
        backgroundColor: BOOKING_STATUS_MAP[status].color
    })

    return (
        <Card className="pb-3">
            <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                <div>Trips</div>

                <div className="d-flex align-items-center">
                    <Button
                        onClick={() => toggleRow("")}
                        className="d-flex align-items-center p-1 me-2">
                        <FaPlus />
                    </Button>
                </div>
            </div>
            {
                loading ? (
                    <div className='p-3'>
                        loading
                    </div>
                ) : <div className="px-2 overflow-y-auto">
            {BookingService?.records?.length ?
                        <>
                            <table>
                                <tbody>
                                    <tr style={{ borderBottom: "1px solid #EBEBEB" }}>
                                        <th className='zr-thead'>Status</th>
                                        <th className='zr-thead'>Trip No</th>
                                        <th className='zr-thead'>Type</th>
                                        <th className='zr-thead'>Start</th>
                                        <th className='zr-thead'>Vehicle</th>
                                        <th className='zr-thead'>PickUp</th>
                                        <th className='zr-thead'>Drop</th>

                                    </tr>

                                    {BookingService?.records.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="zr_flight_accordion_row" onClick={() => toggleRow(data?._id)} style={{ cursor: 'pointer' }}>
                                                <td className='d-flex align-items-center'>
                                                    <div style={getColorStatus(data?.status)} />
                                                    <div className="font-size-13 ms-2 mb-1 fw-normal">{BOOKING_STATUS_MAP[data?.status].label ?? "-"}</div>
                                                </td>

                                                <td>
                                                    <div className="font-size-13 mb-1 fw-normal">{data?.tripNo ?? "-"}</div>
                                                </td>

                                                <td>
                                                    <div className="font-size-13 mb-1 fw-normal">{tripTypeMapping[data?.tripType] ?? "-"}</div>
                                                </td>

                                                <td>
                                                    <div>{moment(data?.startTime).format("DD-MMM HH:mm")}</div>
                                                </td>
                                                <td>
                                                    <div>{data?.vehicle?.regNo ?? "--"}</div>
                                                </td>

                                                <td>
                                                    <CustomTooltip>
                                                        <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}> {data?.src?.city || ''} </CustomTooltip.Body>
                                                        <CustomTooltip.ToolTip >{data?.src?.addressLine || ''}</CustomTooltip.ToolTip>
                                                    </CustomTooltip>
                                                </td>

                                                <td>
                                                    <CustomTooltip>
                                                        <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}> {data?.dst?.city || ''} </CustomTooltip.Body>
                                                        <CustomTooltip.ToolTip >{data?.dst?.addressLine || ''}</CustomTooltip.ToolTip>
                                                    </CustomTooltip>
                                                </td>
                                            </tr>
                                        </React.Fragment>

                                    ))}
                                </tbody>
                            </table>
                        </> :
                        <>
                            <div>No Trips found .</div>
                        </>
                    }

                    {expandedRow?.show && (
                        <ModalComponent
                            position={"top"}
                            size={"large"}
                            onToggle={() => toggleRow("")}
                            isOpen={expandedRow?.show}
                            modalSizeStyle={{
                                maxHeight: "calc(100vh - 80px)"
                            }}
                        >
                            <ModalComponent.Body>
                                <LabelledBooking
                                    editID={expandedRow?.data_id}
                                    asLabel
                                    tour_id={tour_id}
                                    passengers={passengers}
                                    fetchBookings={()=>fetchBookings(tour_id)}
                                />
                            </ModalComponent.Body>
                        </ModalComponent>
                    )}
                </div>
            }
       </Card>
    );
}

export default BookingGrid