import { makeAutoObservable, toJS } from "mobx";
import { ENDPOINTS, RATE_BASE_ITEMS } from "./BookingConstant";
import { doGET, doPOST, doPUT, doDELETE } from "../../util/HttpUtil";
import { getUiPrefRows } from "../../util/FilterUtil";
import { mapColumnsToAccessor, processApiResponseForFile, setFirstUIPreference } from "../../util/Util";
import { getDaysDifferenceInDays } from "../../util/DateUtil";
import UIPrefService from "../../components/DataGrid/UIPrefService";
import { GridColumns } from "./BookingGridColumns";
class Service {

  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0
  totalRecords = 0;
  places = [];
  rows = 10;
  fieldMap = new Map();
  trip_id = null
  pendingSpplierBillings = [] // contains indexes of pending boillings
  updatedBillings = 0;
  billingEditState={
    supplier:-1,
    client:false
  }
  filterURL="";
  calculatingBilling={

  };

  constructor() {
    makeAutoObservable(this);
  }

  setUpdatedBillings = (v) => {
    this.updatedBillings = v;
  }

  setTripId = (trip_id) => {
    this.trip_id = trip_id;
  }

  setPendingSupplierBillings = (v) => {
    this.pendingSpplierBillings = v;
  }

  getFieldAccessibilityList = async () => {
    if (!this.fieldMap || this.fieldMap.size == 0) {
      const response = await doGET(ENDPOINTS.fieldList);
      if (response?.status == 200) {
        if (response?.data && this.fieldMap.size == 0) {
          const map = new Map();
          response.data.map((item) => {
            if (item && item.fieldName) {
              map.set(item.fieldName, item);
            }
          })
          if (map.size > 0) {
            this.fieldMap = map;
          }
        }
      }
    }
  }
  renderField = (fieldName) => {
    if (!fieldName || !this.fieldMap || this.fieldMap.size === 0) {
      return true;
    }
    const fieldInfo = this.fieldMap.get(fieldName);
    return !(fieldInfo && fieldInfo.render === false);
  }
  readOnly = (fieldName) => {
    if (!fieldName || !this.fieldMap || this.fieldMap.size === 0) {
      return false;
    }
    const fieldInfo = this.fieldMap.get(fieldName);
    return fieldInfo && fieldInfo.readOnly === true;
  }

  fetch = async (filterUrl, excludedIds = null, excludedTripStatuses = null) => {
    const uiPref = "Booking.grid"
    let withColumns = [];

    let uiPrefresponse = UIPrefService?.uiPrefs[uiPref]
      ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPref] } }
      : await doGET('/api/ui-preference/' + uiPref + '/detail');

    let result = await setFirstUIPreference(uiPrefresponse?.data?.value, GridColumns({ insidePane: false })?.columns, uiPref)
    if (result) {
      uiPrefresponse = UIPrefService?.uiPrefs[uiPref]
        ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPref] } }
        : await doGET('/api/ui-preference/' + uiPref + '/detail');
    }
    if (!UIPrefService?.uiPrefs[uiPref])
      UIPrefService?.updatePrefs(uiPref, uiPrefresponse?.data?.value ?? '[]');

    if (uiPrefresponse?.status === 200 && uiPrefresponse?.data?.value) {
      const availableColumns = JSON.parse(uiPrefresponse?.data?.value);
      let columnMap = {};
      columnMap= mapColumnsToAccessor(GridColumns({insidePane:false})?.columns)??{}

      Object.entries(columnMap).forEach(([key, value]) => {
        if (availableColumns?.includes(key)) {
          Array.isArray(value) ? withColumns.push(...value) : withColumns.push(value);
        }
      });
    }


    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows(uiPref);
      this.rowsPerPage = rows ?? 40
    }
    if (filterUrl) {
      if (this.filterOption !== filterUrl) {//reset page to 1 if filter url changes
        this.page = 1;
      }
      this.filterOption = filterUrl;
    }
    let excluded_ids = [];
    let excluded_Statuses = [];
    let QueryURL = "";
    // if (this.sizePerPage) QueryURL += `rows=${this.sizePerPage}&`;
    // if (filterUrl)  QueryURL += `${filterUrl}&`;

    if (excludedIds && excludedIds.length) {
      excluded_ids = excludedIds.join(",");
      QueryURL += `_id=notin[${excluded_ids}]&`;
    }

    if(withColumns?.length) {
      filterUrl += `&withColumns=${(withColumns?? []).join(",")}&`;
    }
    if (excludedTripStatuses && excludedTripStatuses?.length) {
      excluded_Statuses = excludedTripStatuses.join(",");
      QueryURL += `status=notin[${excluded_Statuses}]&`;
    }
    // if (this.page){
    //   QueryURL += `page=${this.page}&`
    // } 
    const response = await doPOST(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl),
      { ...(((excludedIds && excludedIds.length) || (excludedTripStatuses && excludedTripStatuses.length)) && { queryURL: QueryURL }) }
    );
    // const response = await doGET(
    //   ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
    // );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
      return response.data.rows;
    }

    return response.data.rows
  };

  print = async (trip_ids, configuration,invoice_ids) => {
    const obj = { trip_ids: trip_ids || [], invoice_ids:invoice_ids ||[], configuration: configuration || {} };
    const response = await doPOST(ENDPOINTS.print, obj);
    if (response?.status === 200) {
      return processApiResponseForFile(response, "Booking.zip");
    }
    return null;
  };

  save = async (data) => {
    // this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, [data]);
    if (response?.data?.length) {
      return response?.data?.[0]?._id;
    }
  };
  saveAll = async function (data) {
    const response = await doPOST(ENDPOINTS.saveAll, data);
    if (response?.data?.length) {
      return response?.data?.[0];
    }
  };
  edit = async function (data) {
    const response = await doPUT(ENDPOINTS?.update, [data]);
    if (response?.status === 200) {
      this.version += 1
      return response?.data?.[0]?._id;
    }
  };
  editAll = async function (data,fromInvoice=false) {
    const response = await doPUT(`${ENDPOINTS?.updateAll}${fromInvoice ? "?fromInvoice=true" : ""}`, data);
    if (response?.status === 200) {
      this.version += 1
      return response?.data?.[0];
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      this.version = 0
      return response.data ? response.data : response;
    }
  };

  customerGrid = async (filterUrl) => {
    const response = await doGET(ENDPOINTS.customerGrid(filterUrl));
    if (response.status === 200) {
      this.version = 0
      return response.data ? response.data : response;
    }
  };

  getBillings = async (id) => {
    const response = await doGET(ENDPOINTS.getBillings(id));
    if (response.status === 200) {
      return response.data?.rows ? response.data?.rows : response;
    }
  };

  getBillingsByTrips = async (ids) => {
    const response = await doPOST(ENDPOINTS.getBillingsByTrips(), {
      trip_ids: ids
    });
    if (response.status === 200) {
      return response.data?.rows ? response.data?.rows : response;
    }
  };

  editBillings = async (data) => {
    const response = await doPUT(ENDPOINTS?.updateBilling, data);
    if (response.status === 200) {
      return response;
    }
  };

  createBilling = async (data) => {
    // this.records.push(data);
    const response = await doPOST(ENDPOINTS.createBillings, data);
    if (response.status === 200) {
      return response;
    }
  };
  deleteBilling = async (_id) => {
    if (_id) {
      const response = await doDELETE(ENDPOINTS.deleteBilling(_id));
      if (response) {
        return response?.data;
      }
    }
  }

  bulkUpload = async (data) => {
    const response = await doPOST(ENDPOINTS.bulkUpload, data);
    if (response?.data?.length) {
      return response?.data?.[0]?._id;
    }
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.records = this.records.filter(record => record._id !== id);
    }
  };

  bulkDelete = async (ids = []) => {
    const uncheckedIds = ids?.filter(id => id != null);

    const statusKeyMap = {
      no0: "Created", 
      no1: "Confirmed",  
      no2: "Ready",
      no3: "Dispatched", 
      no4: "Live", 
      no5: "Completed", 
      no6: "Cancelled", 
      no7: "Error", 
      no8: "Paused",
      no9: "Expired", 
      no10: "Rejected" 
    };

    for (const id of uncheckedIds) {
      const record = this.records.find(record => record._id === id);
      if (record && [3, 4, 5, 6].includes(record.status)) {
        const statusKey = `no${record.status}`;
        return { err: `can not delete ${record?.tripNo} as its status is ${statusKeyMap[statusKey]}` };
      }
    }

    const response = await doDELETE(ENDPOINTS.bulkDelete(), uncheckedIds);
    if (response.status === 200) {
      this.records = this.records.filter(record => !uncheckedIds.includes(record._id));
    }
  };


  

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);
  };







  createInvoice = async function (client_id, vendor_id, invoiceType, data) {
    await doPOST(
      ENDPOINTS.createInvoice(client_id, vendor_id, invoiceType),
      data
    );
    this.fetch();
  };



  downloadByTemplate = async (filterData, template_id) => {
    try {
      const response = await doGET(
        ENDPOINTS.download(-1, null, this.downloadFilterOption, template_id)
      );
      return response;
    } catch (error) {
      console.error('Error during downloadByTemplate:', error);
    }
  };
  setDownloadFilter = (filterData) => {
    if (filterData) this.downloadFilterOption = filterData;
    else this.downloadFilterOption = "";
  };

  startStop = async function (start, payload) {
    const endPoint = start === true ? ENDPOINTS.startTrip : ENDPOINTS.stopTrip;
    const response = await doPOST(endPoint, payload);
    return response;
  };
  modifyStatus = async function (payload) {
    const response = await doPUT(ENDPOINTS.modifyStatus, payload);
    return response;
  };
  sendMessage = async function (payload) {
    return await doPOST(ENDPOINTS.sendMessage, payload);
  }
  getMessageContent = async function (trip_id) {
    const response = await doGET(ENDPOINTS.getMessageContent(trip_id));
    return response?.data;
  }

  fetchTripBillings = async function (trip_id) {
    let clientBilling = null;
    const supplierBillings = [];
    const [billings] = await Promise.all([
      this.getBillings(trip_id),
    ]);
    if (billings && billings.length) {
      let clientBillingFound = false;
      billings.forEach(billing => {
        if (!billing) return;
        if (!clientBillingFound && !billing.vendor_id) {
          clientBilling = { ...billing };
          clientBillingFound = true;
        } else {
          supplierBillings.push(billing);
        }
      });
    }
    return { clientBilling: clientBilling, supplierBillings: supplierBillings }
  }

  fetchBillingGridFromTripGrid = async function (trip_ids) {
    const allTripBillings = {};
    const [billings] = await Promise.all([
      this.getBillingsByTrips(trip_ids),
    ]);
    if (billings && billings.length) {
      const tripBillings = {};
      for (let i = 0; i < billings.length; i++) {
        const _billing = billings[i];
        if (_billing.trip_id && !tripBillings[_billing.trip_id]) {
          tripBillings[_billing.trip_id] = [];
        }
        if (_billing.trip_id) {
          tripBillings[_billing.trip_id].push(_billing);
        }
      }
      const trip_keys = Object.keys(tripBillings);
      for (let j = 0; j < trip_keys.length; j++) {
        if (trip_keys[j]) {
          let clientBilling = null;
          const supplierBillings = [];
          const billings = tripBillings[trip_keys[j]];
          for (let k = 0; k < billings.length; k++) {
            const billing = billings[k];
            if (billing) {
              if (billing.type === 1) {
                clientBilling = { ...billing };
              } else {
                supplierBillings.push(billing);
              }
            }
          }
          allTripBillings[trip_keys[j]] = {
            clientBilling,
            supplierBillings
          }
        }
      }
    }
    return allTripBillings;
  }

  fetchTripAndBilling = async function (trip_id, isCloning = false) {
    const [booking] = await Promise.all([
      this.get(trip_id),
    ]);
    let clientBilling = null, supplierBillings = null;
    if (!isCloning) {
      ({ clientBilling, supplierBillings } = await this.fetchTripBillings(trip_id));
    }

    return { booking, clientBilling, supplierBillings };
  }
  doBillingCalculations = async function (object) {
    const response = await doPOST(ENDPOINTS.calculateBilling, object);
    return response?.data;
  }
  grid = async function (filterUrl) {
    const response = await doGET(
      ENDPOINTS.grid(null, null, filterUrl)
    );
    return response?.data?.rows;
  }
  postGrid = async function (filterUrl) {
    const response = await doPOST(
      `/api/trip/grid`,
      {queryURL:filterUrl}
    );
    return response?.data?.rows;
  }

  isRateItemExists = (rateItems, itemName) => {
    for (let k = 0; k < rateItems.length; k++) {
      if (rateItems[k].name === itemName) {
        return true;
      }
    }
    return false;
  }

  updateBillingsFeeRate = (billing, customRate) => {
    if (!billing) billing = {};
    if (!billing.feeRate) billing.feeRate = {};
    if (!customRate) {
      return billing.feeRate;
    }
    if (!billing.feeRate.rateExtn) billing.feeRate.rateExtn = {};
    if (!billing.feeRate.rateExtn.rateItems) billing.feeRate.rateExtn.rateItems = [];

    if (billing.fee?.feeGroups?.length > 0) {
      for (let i = 0; i < billing.fee?.feeGroups.length; i++) {
        const feeGroup = billing.fee?.feeGroups[i];
        if (feeGroup?.charges?.length) {
          for (let j = 0; j < feeGroup.charges.length; j++) {
            const charge = feeGroup.charges[j];
            if (charge?.name && RATE_BASE_ITEMS.indexOf(charge?.name) !== -1 && 
              !this.isRateItemExists(billing.feeRate.rateExtn.rateItems, charge?.name)) {
              billing.feeRate.rateExtn.rateItems.push({
                name : charge.name,
                value : charge.unitCost,
              })
            }
          }
        }
      }
    }
    return billing.feeRate;
  }

  updateBillingsForNonTaxable = (local_billing, local_expenses, local_feeRate) => {
    const taxableExpensesList = ["Parking Fare", "Toll Tax", "FasTag", "State Tax", "MCD"];
    const allExpensesList = [...taxableExpensesList, "Other"];

    const isNonTaxable = local_feeRate?.rateExtn?.expensesNonTaxable === true || local_feeRate?.rateExtn?.expensesNonTaxable === "true";
    const taxableExpenses = [];
    const nonTaxableExpenses = [];
    if (local_expenses?.length) {
      for (let i = 0; i < local_expenses.length; i++) {
        const exp = local_expenses[i];
        if (exp) {
          if (taxableExpensesList.indexOf(exp.category) !== -1 && isNonTaxable) {
            nonTaxableExpenses.push(exp);
          } else {
            taxableExpenses.push(exp);
          }
        }
      }
    }
    if (!local_billing) {
      local_billing = { fee: {}, feeRate: local_feeRate };
    }

    if (!local_billing.fee.feeGroups?.length) {
      local_billing.fee.feeGroups = [{
        name: "TRIP CHARGES",
        charges: []
      }];
    }
    if (nonTaxableExpenses.length) {
      let isZeroTaxPresent = false;
      for (let k = 0; k < local_billing.fee?.feeGroups.length; k++) {
        const fg = local_billing.fee?.feeGroups[k];
        if (fg.name === "ZERO TAX") {
          isZeroTaxPresent = true;
          break;
        }
      }
      if (!isZeroTaxPresent) {
        local_billing.fee?.feeGroups.push({
          name: "ZERO TAX",
          charges: []
        });
      }
    } else {
      for (let j = 0; j < local_billing.fee?.feeGroups.length; j++) {
        if (local_billing.fee?.feeGroups[j]?.name === 'ZERO TAX') {
          local_billing.fee?.feeGroups.splice(j, j);
        }
      }
    }

    for (let j = 0; j < local_billing.fee?.feeGroups.length; j++) {
      const feeGroup = local_billing.fee?.feeGroups[j];
      const charges = [];
      for (let k = 0; k < feeGroup.charges?.length; k++) {
        const charge = feeGroup.charges[k];
        if (allExpensesList.indexOf(charge.name) === -1) {
          charges.push(charge);
        }
      }
      if (feeGroup.name === "TRIP CHARGES") {
        for (let k = 0; k < taxableExpenses.length; k++) {
          const t_expense = taxableExpenses[k];
          if (t_expense) {
            charges.push({
              name: t_expense.category,
              units: 100,
              unitCost: t_expense.amount,
              detail: t_expense.detail,
              amount: t_expense.amount,
              ref_id: t_expense._id
            });
          }
        }
      } else if (feeGroup.name === "ZERO TAX") {
        for (let k = 0; k < nonTaxableExpenses.length; k++) {
          const t_expense = nonTaxableExpenses[k];
          if (t_expense) {
            charges.push({
              name: t_expense.category,
              units: 100,
              unitCost: t_expense.amount,
              detail: t_expense.detail,
              amount: t_expense.amount,
              ref_id: t_expense._id
            });
          }
        }
      }
      feeGroup.charges = charges;
    }

    return local_billing;
  }

  getBillingProcessedObjectForSaveUpdate = (trip_id, trip, billing, type, updated) => {
    // if (!updated) {
    //   return null;
    // }
    billing.trip_id = trip_id;
    if (type === "Client") {
      billing.type = 1;
      billing.vendor_id = null;
      billing.vendorCompany_id = null;
      if (trip.client?.client_id) {
        billing.client_id = trip.client?.client_id;
        billing.clientCompany_id = trip.client?.company_id;
      } else {
        billing.client_id = null;
        billing.clientCompany_id = null;
      }
    }
    if (type === "Supplier") {
      billing.type = 2;
      billing.client_id = null;
      billing.clientCompany_id = null;
    }
    if (billing.feeRate?.rateName?.name) {
      billing.feeRate.name = billing.feeRate?.rateName?.name;
    }
    if (type === "Supplier" && !billing.vendor_id) {
      return null;
    }
    if(billing?.feeRate?.isCustomType && billing?.feeRate?.rate_id){
      delete billing.feeRate.rate_id
    }
    delete billing?.feeRate?.isCustomType;

    return billing;
  }

  // value returned in minutes
  findGarageTime = (trip) => {
    if (trip?.tripExtn?.preStartMins && trip?.tripExtn?.postStopMins) {
        return trip?.tripExtn?.preStartMins + trip?.tripExtn?.postStopMins
    } else if (trip?.tripExtn?.preStartMins) {
        return trip?.tripExtn?.preStartMins
    } else if (trip?.tripExtn?.postStopMins) {
        return trip?.tripExtn?.postStopMins
    } else {
        return 0;
    }
  }

  // value returned in kms
  findGarageDistance = (trip) => {
    if (trip?.tripExtn?.preStartKMs && trip?.tripExtn?.postStopKMs) {
      return trip?.tripExtn?.preStartKMs + trip?.tripExtn?.postStopKMs;
    } else if (trip?.tripExtn?.preStartKMs) {
      return trip?.tripExtn?.preStartKMs;
    } else if (trip?.tripExtn?.postStopKMs) {
      return trip?.tripExtn?.postStopKMs;
    } else {
      return 0;
    }
  }

  findTripExtraDuration = (trip, billing) => {
    const duration = trip?.duration ? trip?.duration * 60 : 0;
    const travelDays = this.calcTravelDays(billing?.feeRate?.rateExtn?.rateCycle);
    const allotedMins = billing?.feeRate?.allotedMins ? billing?.feeRate?.allotedMins * 60 : 0;
    let extraDuration =  duration - Math.ceil(allotedMins * travelDays)
    if ([1, 5].includes(billing?.feeRate?.rateExtn?.fareType)) {
      if (trip?.tripExtn?.preStartMins > 0) {
        extraDuration = extraDuration + trip?.tripExtn?.preStartMins * 60;
      }
      if (trip?.tripExtn?.postStopMins > 0) {
        extraDuration = extraDuration + trip?.tripExtn?.postStopMins * 60;
      }
    }
    return extraDuration > 0 ? extraDuration : 0;
  }

  findTripExtraDistance = (trip, billing) => {
    const distance = trip?.tripExtn?.distance ? trip?.tripExtn?.distance : 0;
    const travelDays = this.calcTravelDays(billing?.feeRate?.rateExtn?.rateCycle);
    const allotedKMs = billing?.feeRate?.allotedKMs ? billing?.feeRate?.allotedKMs : 0;
    let extraDistance =  distance - Math.ceil(allotedKMs * travelDays);
    if ([1, 5].includes(billing?.feeRate?.rateExtn?.fareType)) {
      if (trip?.tripExtn?.preStartKMs > 0) {
        extraDistance = extraDistance + trip?.tripExtn?.preStartKMs;
      }
      if (trip?.tripExtn?.postStopKMs > 0) {
        extraDistance = extraDistance + trip?.tripExtn?.postStopKMs;
      }
    }
    return extraDistance > 0 ? extraDistance : 0;
  }

  calcTravelDays = (trip, rateCycle) => {
    if (rateCycle == null) {
      return 1;
    }
    const startTime = trip.startTime;
    const stopTime = trip.duration && trip.duration > 0 ? trip.startTime + trip.duration * 60 : trip.startTime;
    if (rateCycle === 1) {
      if (startTime != null && stopTime != null) {
        return getDaysDifferenceInDays(startTime, stopTime);
      } else {
        return 1;
      }
    }
    if (rateCycle === 2) {
      const minutes = trip.duration && trip.duration > 0 ? trip.duration : 0;
      let days = parseInt(minutes / 1440);
      if (days === 0 || minutes > days * 1440)
        days = days + 1;
      return days;
    }
    return 1;
  }

  updateBillingState=(updatedBillingState)=>{
     this.billingEditState={
      ...toJS(this.billingEditState),
      ...updatedBillingState
     }
  }
  updateFilterUrl=(filterUrl)=>{
    this.filterURL=filterUrl
  }

  resetState = () => {
    
  this.records = [];
  this.STRUCTURE = [];
  this.page = 1;
  this.rowsPerPage = null;
  this.filterOption = "";
  this.total = -1;
  this.version = 0
  this.totalRecords = 0;
  this.places = [];
  this.rows = 10;
  this.fieldMap = new Map();
  this.trip_id = null
  this.pendingSpplierBillings = [] // contains indexes of pending boillings
  this.updatedBillings = 0;
  this.calculatingBilling={};
  }
  updatePage=()=>{
    this.page=1
  }

  toggleCalculatingBilling = (key,value) => {
    this.calculatingBilling[key]=value;
  }
  isBillingCalculationinProgress=()=>{
    return Object.values(this.calculatingBilling).some(value => value === true);
  }
}

const BookingService = new Service();
export default BookingService;
