import { useContext, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { I18nContext } from "../../../../store/context/i18nContext";
import OnDemandMessageForTrip from "./OnDemandMessageForTrip";
import { ModalComponent } from "../../../../components";

const MessageDialog = ({ trip, toggleModal, modal, className = "", }) => {
  const { t } = useContext(I18nContext);
  const handleClose = (e) => {
    toggleModal(e);
  };
  return (
    <>
      <Modal
        position={"top"}
        size={"medium"}
        isOpen={modal}
        onToggle={() => toggleModal()}
        modalSizeStyle={{ height: "500px", width: "1100px" }}
        className={className}
        style={{ width: "700px" }}

      >
        <div className="d-flex justify-content-end mt-3 mx-3"
          style={{ cursor: "pointer", position: "relative" }}
          onClick={handleClose}>
          <i style={{ fontSize: "25px", position: "absolute" }} className="dripicons-cross" /></div>
        <ModalHeader>
          <h1>Send Booking Confirmation</h1>


        </ModalHeader>
        <ModalBody>
          <OnDemandMessageForTrip toggleModal={toggleModal} trip={trip} />
        </ModalBody>
      </Modal>

    </>
  );
};
const ModalToggleButton = ({ t, toggleModal }) => {
  return (
    // <div className="d-flex justify-content-end my-2">
    <Button
      onClick={toggleModal}
      className="w-100"
      style={{ height: "28px", padding: "2px 10px 2px 4px" }}
      color="info"
    >

      {t(`Send Message`)}
    </Button>
    // </div>
  );
};
export default MessageDialog;
