import React, { useCallback, useContext, useEffect, useState } from "react";
import { Row } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET } from "../../util/HttpUtil";
import DocField from "./DocField";

const Documents = ({
  config = { showCategory: false, showExpiry: false, showIssue:false },
  expanded,
  className,
  record_id,
  asLabel,
  module,
  col,
  actionButtons,
  _id,
  onChange = () => { },
  getRecordId = () => { },
}) => {
  const [documents, setDocuments] = useState([]);
  const { showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);

  const fetchDocuments = useCallback(async () => {
    if (!module || !record_id) return;
  
    try {
      const url = `/api/file/grid?module=${module}&record_id=${record_id}${_id ? `&_id=${_id}` : ""}`;
      const response = await doGET(url);
  
      if (response.status === 200) {
        setDocuments(response.data?.rows ?? []);
      }
    } catch (error) {
      showError(error);
    }
  }, [module, record_id, _id]);
  

  useEffect(() => {
    fetchDocuments();
  }, [module, record_id, showError]);

  useEffect(() => {
    if (documents.length === 0) addDocumentCard();
  }, [documents]);

  const addDocumentCard = () => {
    setDocuments((prevDocs) => [
      ...prevDocs,
      { attachment: { name: "", info: "", refNo: "", expanded: true, urls: [] }, expanded: true },
    ]);
  };

  const deleteDocumentCard = (index) => {
    setDocuments((prevDocs) => prevDocs.filter((_, i) => i !== index));
  };

  const updateDocument = (doc, index, value) => {
    setDocuments((prevDocs) =>
      prevDocs.map((d, i) => (i === index ? { ...d, _id: value } : d))
    );
  };

  return (
    <div className={`col-sm-${col} ${className} col-12 mt-1 p-3 pt-0`}>
      <Row>
        {documents.map((doc, index) => (
          <div className="card shadow col-12 mt-1" key={index}>
            <DocField
              config={config}
              expanded={expanded}
              onDelete={() => fetchDocuments()}
              onSubmit={() => fetchDocuments()}
              asLabel={asLabel}
              value={doc?._id}
              onChange={(value) => {
                updateDocument(doc, index, value);
                onChange(doc, index, value);
              }}
              handleAddCard={addDocumentCard}
              handleDeleteCard={() => deleteDocumentCard(index)}
              data={doc}
              record_id={record_id}
              _id={_id || null}
              getRecordId={getRecordId}
              module={module}
              multiple
              documentIndex={index}
              documentLength={documents.length}
              col={col}
              actionButtons={actionButtons}
            />
          </div>
        ))}
      </Row>
    </div>
  );
};

export default Documents;
