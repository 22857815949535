export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            accessor: "name",
            renderer: (dataObj) => {
                return (
                    <div>{dataObj?.name}</div>
                );
            },
            show: true,
        },
        {
            title: "Code",
            renderer: (dataObj) => {
                return (
                    <div>{dataObj?.code}</div>
                );
            },
            accessor: "code",
            show: true,
        },
        {
            title: "Star Rating",
            renderer: (dataObj) => {
                return (
                    <div>{dataObj?.starRating}</div>
                );
            },
            accessor: "starRating",
            show: true,
        },
        {
            title: "Kind",
            renderer: (dataObj) => {
                return (
                    <div>{dataObj?.kind}</div>
                );
            },
            accessor: "kind",
            show: true,
        },
        {
            title: "Country",
            renderer: (dataObj) => {
                return (
                    <div>{dataObj?.address?.country}</div>
                );
            },
            accessor: "country",
            show: true,
        },
        {
            title: "State",
            renderer: (dataObj) => {
                return (
                    <div>{dataObj?.address?.state}</div>
                );
            },
            accessor: "state",
            show: true,
        },
        {
            title: "City",
            renderer: (dataObj) => {
                return (
                    <div>{dataObj?.address?.city}</div>
                );
            },
            accessor: "city",
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};