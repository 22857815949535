import React, { useContext, useEffect, useState } from 'react';
import ModalComponent from '../Modal/Modal';
import { observer } from 'mobx-react-lite';
import viewService from './viewService';
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import EditCreateView from './EditCreateView';
import SaveButton from '../Buttons/SaveButton';
import { DialogContext } from '../../store/context/DialogContext';
import { I18nContext } from '../../store/context/i18nContext';
import { UserContext } from '../../store/context/UserContext';
import { BiPencil } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import { useNavigate, useNavigation } from 'react-router-dom';
import DeleteButton from '../Buttons/DeleteButton';
import { truncate } from 'lodash';

const Views = ({ filterStructure, module = "views" }) => {
  const { showMessage, showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    type: 1,
    fromDate: null,
    tillDate: null,
    module,
    filterUrl: '',
    filterObject: {},
    supervisors: []
  });
  const [editModal, seteditModal] = useState(false);
  const [selectedView,setSelectedView]=useState(null)

  useEffect(() => {
    viewService.fetch(module)
  }, [editModal])

  const [editInfo, setEditInfo] = useState({
    editId: null,
    visible: false
  });


  const navigate= useNavigate()

  useEffect(() => {
    const fetchView = async () => {
      if (!editInfo.editId) return;
      setLoading(true);
      try {
        const response = await viewService.get(editInfo.editId);
        console.log(response,"====")
        console.log(filterStructure)
        //  await viewService.delete(editInfo?.editId)
        setData(response);
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchView();
  }, [editInfo.editId, showError]);

  // console.log(data,"kkk")

  const toggleEditModal = () => {
    // filterUrl=""
    seteditModal(prev => (!prev))
  };

  const onSave = async (e) => {
    e.preventDefault();
    if (!data?.name) {
      showError(t("Please enter all required fields"));
      return;
    }
    console.log(data,"====")
    setLoading(true);
    try {
      if (data?._id) {
        await viewService.edit(data, data?._id);
        // await viewService.delete(data?._id)
        showMessage(t("View updated successfully."));
      } else {
        await viewService.save(data);
        showMessage(t("View saved successfully."));
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };
  const onDelete = async(e)=>{
    setLoading(true)
    try {
      if (data?._id) {
        await viewService.delete(data?._id)
        showMessage(t("View deleted successfully."));
      }
    } catch (error) {
      showError(error);
    }finally{
      setLoading(false)
      setData({})
      toggleEditModal();
      
    }
  }
  return (
    <>
      <div >
        <UncontrolledDropdown>
          <DropdownToggle outline={true}
            style={{
              color: hover ? "black" : "black",
              borderColor: "#dfdfdf",
              backgroundColor: hover ? "inherit" : "inherit"
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)} >
            <div>
              <b> {selectedView? truncate(selectedView, { length: 20 }):t("Views")}</b>
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <>
              {userContent?.rights?.includes(5903) &&
                viewService.records.map((record, index) => {
                  return (
                    <DropdownItem key={index} onClick={async () => {
                      setSelectedView(record?.name);
                      navigate(`?${record?.filterUrl}`)
                    }
                    }>
                      <div className="row d-flex justify-content-between cursor-pointer">
                        <div className="col-7">
                          {record.name}
                        </div>
                        <div className="col-2">
                          <BiPencil onClick={() => {
                            seteditModal(true)
                            setEditInfo({ ...editInfo, editId: record?._id })

                          }} />
                        </div>
                      </div>
                    </DropdownItem>)
                }

                )}

              <DropdownItem onClick={() => {
                seteditModal(true)
                setEditInfo({ ...editInfo, editId: null })
              }}>
                <FaPlus className="me-2" />
                <span className="font-size-14">Create Views</span>

              </DropdownItem>
            </>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <ModalComponent
        position="top"
        // size="full-screen"
        size='lg w-100'
        onToggle={toggleEditModal}
        isOpen={editModal}
      >
        <ModalComponent.Title>
          {editInfo?.editId ? t("Edit View") : t("Create View")}
        </ModalComponent.Title>
        <ModalComponent.Body stretch>
          <div className="d-flex flex-1">
            <Card className="pb-3 w-100 mt-5 pb-5">
              <div className="ps-3 py-2 d-flex align-items-center justify-content-end"></div>
              <div className="px-2">
                <EditCreateView
                  loading={loading}
                  filterStructure={filterStructure}
                  data={data}
                  onChange={setData}
                />
              </div>
            </Card>
          </div>
        </ModalComponent.Body>
        <ModalComponent.Footer>
        <div className='d-flex justify-content-end align-items-center'>
        {data?._id && <DeleteButton zeroXMargin className="me-0" onClick={onDelete} data={data} />}
        <SaveButton zeroXMargin className="save-button me-0" onClick={onSave} data={data} />
    
          </div>

        </ModalComponent.Footer>
      </ModalComponent>
    </>
  );
};

export default observer(Views);