import React, { useEffect, useState } from 'react'
import { getRouteTypeLabel, isPickupOrDropRouteType, TRIP_TYPE } from '../../pages/Booking/BookingConstant';
import AddressField from '../AddressField/AddressField';
import GeneralDropdown from '../Dropdowns/GeneralDropdown';
import { getFullAddress } from '../../util/Util';
import { populatePassengerAddressUsingPickupAndDrop } from '../../pages/BookingsNew/utils/helper';

function RoutePassengerAddressPicker({ tripType, passenger, stops,onChange }) {

    const getValue=()=>{
        if(passenger?.address)return passenger?.address;
        if(TRIP_TYPE['Route-PickUp']===tripType){
            return passenger?.pickup ?? passenger?.residence
        }
        if(TRIP_TYPE['Route-Drop']===tripType){
            return passenger?.drop ?? passenger?.residence
        }
        return passenger?.residence??"";

    }

    return (
        <>
            {[TRIP_TYPE['Route-PickUp'], TRIP_TYPE['Route-Drop']]?.includes(tripType) ? <div className=' d-flex align-items-center'>
                <div style={{ width: 50 }} className='col-1 d-flex  align-items-center me-3'>
                    <h5 style={{ fontWeight: "bold" }} className="text-left mt-2 ms-2 ">
                        <span>{getRouteTypeLabel(tripType)} </span>
                    </h5>
                </div>

                <AddressField 
                    value={getValue()}
                    className={"col-12 col-md-6"}
                    outline
                    onChange={(v) => {
                        onChange( 'address', v);
                    }}
                />
            </div> : null}
            {tripType === TRIP_TYPE['Route-Shuttle'] ? <div className=' d-flex flex-column align-items-start  ms-3 gap-2 mb-3'>
                <div className=" col-12 col-sm-6 d-flex align-items-center gap-3 ">
                    Pickup
                    <GeneralDropdown
                        className="w-100"
                        labelClassName='w-100 rounded-0  overflow-x-hidden'
                        value={getFullAddress(passenger?.pickup) ?? ""}
                        label="Select Pickup Location"
                        data={stops?.map(stop => ({ label: getFullAddress(stop?.address), value: stop?.address }))}
                        onChange={(v) => {
                            // onChange(passengers?.map((pax, i) => (i === index ? { ...pax, pickup: v?.value } : pax)));
                            onChange('pickup', v?.value);
                        }}
                        style={{
                            border:'none',
                            borderBottom:'1px solid #bbb'
                        }}
                    />
                </div>
                <div className="col-12 col-sm-6 d-flex align-items-center gap-3">
                    Drop
                    <GeneralDropdown
                        className="w-100"
                        labelClassName='w-100 rounded-0  overflow-x-hidden'
                        value={getFullAddress(passenger?.drop) ?? ""}
                        label="Select Drop Location"
                        data={stops?.map(stop => ({ label: getFullAddress(stop?.address), value: stop?.address }))}
                        onChange={(v) => {
                            onChange('drop', v?.value);
                        }}
                        style={{
                            border:'none',
                            borderBottom:'1px solid #bbb'
                        }}
                    />
                </div>
            </div> : null}
            {/*   shuttle will have dropdown of selected locations and dropoffs */}
        </>
    )
}

export default RoutePassengerAddressPicker