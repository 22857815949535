import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditTaxes from "./EditTax";
import { STRUCTURE } from "./TaxConstant";
import { GridColumns } from "./TaxGridColumn";
import TaxService from "./TaxService";


const Tax = ({ insidePane, multiMode, onSelect = () => { } }) => {

  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { userContent } = useContext(UserContext);


  const { t } = useContext(I18nContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/tax/create" || edit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await TaxService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
  }, [])

  const onSave = async (e) => {
    e.preventDefault();
    if (!detailData.name) {
      setHasErr(true)
      showError(t("Please enter all fields"));
      return;
    }
    setLoading(true);
    try {
      if (editID) {
        await TaxService.edit(detailData, editID);
        showMessage(t("Tax updated successfully."));
      } else {
        const taxID = await TaxService.save(detailData);
        if (taxID) setEditID(taxID)
        showMessage(t("Tax saved successfully."));
        if (!insidePane) navigate(`/tax/edit/${taxID}`);
      }
      fetchData(filterURL);
    } catch (e) {
      showError(e);
    }
    setHasErr(false)
    setLoading(false);
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);

      try {
        await TaxService.delete(id);
        setLoading(false);
        setShowDetailPage(false)
        showMessage(t("Tax Deleted Successfully"), t("Deleted"));
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await TaxService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), t("Deleted Successfully"));
      } catch (e) {
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((tax) => {
    return (
      <>
        {userContent?.rights?.includes(2302) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/tax/edit/${tax?._id}`);
              setEditID(tax?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {tax?.corp_id && userContent?.rights?.includes(2303) ? <DeleteLink
          onClick={(event) => onDelete(event, tax?._id)}
        /> : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(tax);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, userContent])

  return (
    <>
      <Layout
        medium
        hideAdd={!userContent?.rights?.includes(2301)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/tax");
          setEditID(null);
        }}
         
        title={t("Taxes")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/tax/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={TaxService.page}
        rows={TaxService.rows}
        total={TaxService.total}
        fetch={TaxService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <div class="dropdown-divider"></div>
                <DropdownItem> Download </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={TaxService.records}
              renderLastCol={renderLastCol}
              headers={GridColumns}
              total={TaxService.total}
              uiPreference="tax.grid"
              page={TaxService.page}
              rowsPerPage={TaxService.rowsPerPage}
              selectedIDs={selectedIDs}
              onSelectChange={(v) => {
                onSelect(v)
                setSelectedIDs(v)
              }}
              onPaginationChange={TaxService.onPaginationChange}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Tax") : t("Add Tax")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditTaxes
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>
        <Layout.DetailPageFooter>
          {editID && userContent?.rights?.includes(2303) && detailData?.corp_id ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
          {userContent?.rights?.includes(2302) ? <SaveButton onClick={onSave} loading={loading} /> : null}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Tax);
