import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaPlus } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { InputField } from '../../../components';
import Editor from '../../../components/dnd/forms/itinerary/components/RichTextEditor/Editor/Editor';
import ColumnHeader from '../module/itineraryDetail/ColumnHeader';
import SegmentKindPicker from './Dropdowns/SegmentKindPicker';
import './segments.css';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const Segments = ({ value = [], onChange }) => {
    const [segments, setSegments] = useState([]);
    const [isDetailVisible, setIsDetailVisible] = useState({})

    useEffect(() => {
        if (value?.length) {
            const withIds = value.map((item, index) => ({
                ...item,
                segmentIndex: item.segmentIndex || `item-${index}-${item.day}`,
            }));
            setSegments(withIds);
        }
    }, [value]);

    const onDragEnd = useCallback((result) => {
        if (!result.destination) return;

        const sourceIndex = segments.findIndex(seg => seg.segmentIndex === result.draggableId);
        const destIndex = result.destination.index;

        const reordered = reorder(segments, sourceIndex, destIndex);
        onChange(reordered);
    }, [segments, onChange]);

    const addActivity = (day) => {
        const newActivity = {
            day,
            title: 'New Activity',
            detail: 'Activity details',
            segmentIndex: `new-activity-${Date.now()}`,
            view: false
        };
        const updated = [...segments, newActivity];
        onChange(updated);
    };

    const addDay = () => {
        const lastDay = Math.max(0, ...segments.map(seg => seg.day));
        addActivity(lastDay + 1);
    };

    const updateSegment = (segmentIndex, field, value) => {
        const updated = segments.map(seg => seg.segmentIndex === segmentIndex ? { ...seg, [field]: value } : seg);
        onChange(updated);
    };

    const removeActivity = (segmentIndex) => {
        const updated = segments.filter(seg => seg.segmentIndex !== segmentIndex);
        onChange(updated);
    };

    const groupedByDay = segments.reduce((acc, segment) => {
        (acc[segment.day] = acc[segment.day] || []).push(segment);
        return acc;
    }, {});

    return (
        <DragDropContext onDragEnd={onDragEnd}>

            <ColumnHeader>
                <ColumnHeader.Title>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                        <h4>Activities</h4>
                        <Button onClick={addDay} className="zr_itinerary_addDay d-flex align-items-center py-1"> <FaPlus className='me-2' /> Add Day</Button>

                    </div>

                </ColumnHeader.Title>

                <ColumnHeader.Body maxHeight="calc(100vh - 350px)">
                    {Object.entries(groupedByDay).map(([day, activities], ind) => (
                        <Droppable droppableId={`day-${day}`} key={day}>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="zr_itinerary_day card"
                                >
                                    <div className='d-flex justify-content-between align-items-center w-100 header-text-day'>
                                        <h3 className='flex-1'>Day {day}</h3>
                                    </div>

                                    <div onClick={() => setIsDetailVisible({ ...isDetailVisible, [ind]: !isDetailVisible?.[ind] })} className='d-flex justify-content-between align-items-center mt-2 cursor-pointer'>
                                        <h5 className='mt-1'>Details</h5>
                                        {isDetailVisible?.[ind] ? <TiArrowSortedUp size={20} /> : <TiArrowSortedDown size={20} />}
                                    </div>
                                    <>
                                        {
                                            isDetailVisible?.[ind] ? (
                                                <>
                                                    {activities.map((activity, index) => (
                                                        <Draggable key={activity.segmentIndex} draggableId={activity.segmentIndex} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className="zr_itinerary_activity"
                                                                >

                                                                    <div className=" d-flex align-items-center justify-content-between">
                                                                        {/* <ToggleSelector
                                                    data={[
                                                        { label: ("Basic"), value: false },
                                                        { label: ("Advanced"), value: true },
                                                    ]}
                                                    value={activity?.view ?? false}
                                                    onChange={(selectedValue) => {
                                                        updateSegment(activity.segmentIndex, 'view', selectedValue)
                                                    }}
                                                    zeroMargin

                                                    style={{
                                                        marginTop: "0px",
                                                        width: "200px"
                                                    }}
                                                /> */}

                                                        <div className='d-flex align-items-center'>

                                                          {activity?.view && <SegmentKindPicker
                                                                                value={activity?.kind}
                                                                                onChange={(v) => {
                                                                                    updateSegment(activity.segmentIndex, 'kind', v)
                                                                                }}
                                                                            />}

                                                                            {/* <FaTimes
                                                        onClick={() => removeActivity(activity.segmentIndex)}
                                                        fontSize="14"
                                                        className='ms-2'
                                                    /> */}
                                                                        </div>


                                                                    </div>

                                                                    <InputField
                                                                        className="zr_itinerary_input"
                                                                        outline
                                                                        label="Title"
                                                                        placeholder="Enter Title"
                                                                        value={activity.title}
                                                                        onChange={(e) => updateSegment(activity.segmentIndex, 'title', e)}
                                                                    />

                                                                    <Editor
                                                                        placeholder="Detail"
                                                                        value={activity.detail}
                                                                        onChange={(htmlContent) => { updateSegment(activity.segmentIndex, 'detail', htmlContent) }} // Adjust according to your application's needs
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </>
                                            ) : null
                                        }
                                    </>
                                    {provided.placeholder}
                                    {/* <div className='w-100 d-flex justify-content-end'>  
                                <Button onClick={() => addActivity(parseInt(day))} className="zr_itinerary_addActivity d-flex align-items-center py-1"> <FaPlus className='me-2' /> Add Activity</Button>

                            </div> */}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </ColumnHeader.Body>
            </ColumnHeader>
        </DragDropContext>
    );
};

export default Segments;
