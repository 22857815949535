import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { AddressField, Checkbox, CustomFieldComponent, InputField, SupplierSelect, ToggleSelector } from "../../components";
import MAPComponent from "../../components/Map/Map";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import GarageService from "./GarageService";
import { ThreeBounce } from "better-react-spinkit";
import { CUSTOMEFIELDS_KIND_DATA } from "../../components/CustomField/CustomFieldsConstants";


const EditGarages = ({ editId, onChange = () => { }, hasErr }) => {
  const [center, setCenter] = useState({})
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    garageType:"self"
  });
  const fetch = async () => {
    try {
      let garage = await GarageService.get(editId);
      if (garage) {
        garage.garageType = garage.supplier_id ? "supplier" : "self";
      }
      setData(garage);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    setData({...data,garageType:"self"})
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, GarageService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);

  const handleMarkerDrag = (coordinates) => {
    setCenter(coordinates)
    setData({
      ...data,
      address: {
        ...data?.address,
        lat: center?.lat,
        lng: center?.lng,


      }
    });
  };

  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >

          <Row>
            <Col className="flex-1 col-10 ps-4">
              <ToggleSelector
                data={[
                  { label: "Self", value: "self" },
                  { label: "Supplier", value: "supplier" },
                ]}
                className="w-25"
                style={{ width: "25%", position: "relative", top: "2.5px" }}
                value={data?.garageType}
                onChange={(v) => {
                  setData({ ...data, garageType: v })
                }}
              />

            </Col>
          </Row>



          <Row className="mt-3 mb-3">
          {data?.garageType == "supplier" && <SupplierSelect
            showClear
            value={data?.supplier_id}
            onChange={(v) => setData({ ...data, supplier_id: v?.value })}
            className="col-sm-6"
          />}
            <InputField
              placeholder={t("Garage Name ")}
              value={data?.address?.name}
              required={true}
              label={t("Garage Name")}
              onChange={(v) => setData({ ...data, address: { ...data?.address, name: v } })}
              error={data?.address?.name?.length === 0 ? t("Please enter Garage Name") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
          </Row>
          <Row className="mt-3 mb-3">
            <InputField
              placeholder={t("Garage Code")}
              value={data?.code}
              label={t("Garage Code")}
              onChange={(v) => setData({ ...data, code: v })}
              error={data?.code?.length === 0 ? t("Please enter Garage Code") : ""}
              showErr={hasErr}
              type="text"
              className="col-sm-12"
            />
          </Row>
          <Row className="mt-3 mb-3">

            <Checkbox
              label={t("Self Drive")}
              checked={data?.hasSelfDrive}
              className="col-sm-2"
              onClick={() => {
                setData({
                  ...data,
                  hasSelfDrive: !data?.hasSelfDrive,
                });
              }}
            />
          </Row>
          <Row className=" mb-3">
            <AddressField
              placeholder={t("Address")}
              label={t("Address")}
              value={data?.address}
              onChange={(v) => setData({ ...data, address: { ...v, name: data?.address?.name } })}
              error={data?.address?.length === 0 ? t("Please enter address") : ""}
              showErr={hasErr}
            />
          </Row>



          <CustomFieldComponent
            module={"garage"}
            record_id={data?._id}
            kind={"garage"}
            propList={data?.garageExtn?.properties}
            onChange={(v) =>
              setData({
                ...data,
                garageExtn: {
                  ...data?.garageExtn,
                  properties: v,
                },
              })
            }
          />

          <Row className=" mb-3">
            <MAPComponent
              search
              showMarker={true}
              onDrag={handleMarkerDrag}
            />
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditGarages);
