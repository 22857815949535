import DatePicker from 'react-datepicker'
import React, { useEffect, useState } from 'react'
import "./style.css"
import moment from 'moment';
import { InputField } from '../InputField/InputField';
import { DatePickerInput } from '../InputField/DatePickerInput';
import MaskInput from '../MaskInput';
import { MASK } from '../MaskInput/utils';
import { FaCalendarAlt } from 'react-icons/fa';

const formatTimeStampToDate = (timestamp) => moment.unix(timestamp).toDate();




function DateTimePickerWithInterval(props) {
    const {
        className,
        error,
        value,
        placeholder = "",
        outerDivStyle = {},
        onChange = () => { },
        showTimeSelect = true,
        format="dd-mm-yyyy HH:mm",
        showIcon = false
    } = props;


    const [startDate, setStartDate] = useState();
    const [inputError, setInputError] = useState('');

    useEffect(() => {
        if (value) setStartDate(formatTimeStampToDate(value));
    }, [value]);

    const handleBlur = () => {
        if (!startDate) setInputError(error);
    };

    return (
        <div className={`filterField_input ${className}`} style={{ ...outerDivStyle }}>

            <DatePicker
                selected={value ? startDate : null}
                onChange={(date) => {
                    onChange(moment(date).unix());
                }}
                showTimeSelect={showTimeSelect}
                timeIntervals={15}
                onBlur={handleBlur}
                value={value ? moment.unix(value).toDate() : null}
                placeholderText={placeholder}
                wrapperClassName={`${props?.inline ? "pb-0" : "react-datepicker-wrapper"} w-100`}
                dateFormat="dd-MM-yyyy HH:mm"
                className=' w-100'
                timeFormat="HH:mm"
                // minDate={moment().toDate()}
                // customInput={<InputField {...props} datePicker={true} />}
                customInput={<DatePickerInput  {...props} outline datePicker={true} {...MASK[format]} masking={true} />}
                // customInput={
                //     <MaskInput {...MASK[format]} datePicker={true} {...props} />
                //   }

            />
            {showIcon ? <FaCalendarAlt size={15} className='icon' /> : null}
        </div>
    )
}

export default DateTimePickerWithInterval