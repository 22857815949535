import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import {
    BulkUpload,
    CheckButton,
    DataGrid,
    DeleteButton,
    DeleteLink,
    EditLink,
    Layout,
    SaveButton,
} from "../../components";
import CustomOperations from "../../components/CustomField/CustomOperations";
import StatusDropDown from "../../components/Dropdowns/StatusDropDown/StatusDropDown";
import TagPicker from "../../components/TagSelector/TagPicker";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import PermitService from '../Permit/PermitService';
import EditEmployees from "./EditEmployee";
import { STRUCTURE, activeStatusData, activeStatusMapping } from "./EmployeeConstant";
import { GridColumns } from "./EmployeeGridColumns";
import EmployeeService from "./EmployeeService";
import CallAdmin from "../../components/CallButton";

const Employees = ({ fullHeight, value, insidePane, multiMode, onSelect = () => { } ,filterUrlFromParent=""}) => {

    let { employee_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [uploadPopup, setUploadPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(employee_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/employee/create" || employee_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState(filterUrlFromParent);
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)
    const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
    const { userContent } = useContext(UserContext);

    const fetchData = async (filterUrl) => {
        const appendedFilterUrl = filterUrl && !filterUrl.includes(filterUrlFromParent)
            ? `${filterUrl}&${filterUrlFromParent}`
            : filterUrl || filterUrlFromParent; 
        setFilterURL(appendedFilterUrl);
        if (loading) return;
        try {
            await EmployeeService.fetch(appendedFilterUrl);
        } catch (e) {
            showError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])

    useEffect(() => {
        setEditID(employee_id)
    }, [employee_id])

    const onSave = async (e) => {
        e.preventDefault();
        if (!(detailData.name && detailData?.phoneNo?.phone)) {
            setHasErr(true)
            showError(t("Please enter all fields"));
            return;
        }
        if(detailData?.vehicleType=="self") delete detailData?.employeeOf
        setLoading(true);
        try {
            if (editID) {
                const employee = await EmployeeService.edit(detailData, editID);
                setDetailData(employee);
                setLoading(false);
                showMessage(t("Employee updated successfully."));
            } else {
                const employee = await EmployeeService.save(detailData);

                if (detailData?.permitData?.name && detailData?.permitData?.issuingAuthority) {
                    await PermitService.save({ ...detailData?.permitData, permitOf: employee })
                }
                setLoading(false);
                if (employee) {
                    setEditID(employee);
                }
                showMessage(t("Employee saved successfully."));
                if (!insidePane) navigate(`/employee/edit/${employee}`);
            }
            fetchData(filterURL);
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };

    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await EmployeeService.delete(id);
                setLoading(false);
                showMessage(t("Employee Deleted Successfully"), t("Deleted"));
                setShowDetailPage(false);
                navigate(`/employee`);
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };

    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await EmployeeService.bulkDelete(selectedIDs);
                setLoading(false);
                showMessage(t("Employees Deleted Successfully"), t("Deleted Successfully"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };

    const renderLastCol = useCallback((employee) => {
        return (
            <>
                {userContent?.rights?.includes(1402) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/employee/edit/${employee?._id}`);
                            setEditID(employee?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {userContent?.rights?.includes(1403) ?
                    <DeleteLink
                        loading={loading}
                        onClick={(event) => onDelete(event, employee?._id)}
                    />
                    : null}
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(employee);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])

    const markCompletedFunc = async () => {
        try {
            await SetupProgressService?.edit("employee");
        } catch (error) {

        }
    }

    const saveBulk = async (data) => {
        try {
            const account = await EmployeeService.save(data);
            if (account) {
                return { isUploaded: true, message: "" }
            }
            return { isUploaded: false, message: "Failed unexpectedly" }
        } catch (error) {
            return { isUploaded: false, message: JSON.parse(error?.message)?.customMessage ?? JSON.parse(error?.message)?.message }

        }
    };



    useEffect(() => {
        if (!SetupProgressService?.records) {
            SetupProgressService.fetch()
        }
    }
        , [SetupProgressService?.records])

    return (
        <>
            {uploadPopup ? (
                <BulkUpload
                    fetchData={fetchData}
                    show={uploadPopup}
                    onClose={() => { setUploadPopup(false) }}
                    title={t("Employee")}
                    kind={"Employee"}
                    upload={saveBulk}
                />
            ) : null}
            <Layout
                large
                hideAdd={!userContent?.rights?.includes(1401)}
                showDetailPage={showDetailPage}
                backDetailPage={async () => {
                    setShowDetailPage(false);
                    if (!insidePane) navigate("/employee");
                    setEditID(null);
                }}

                title={t("Employees")}
                filterValues={filterObject}
                filterStructure={STRUCTURE}
                onApplyFilter={fetchData}
                onAddClick={() => {
                    if (!insidePane) navigate(`/employee/create`);
                    setShowDetailPage(true);
                    setEditID(null);
                }}
                insidePane={insidePane}
                page={EmployeeService.page}
                rows={EmployeeService.rows}
                total={EmployeeService.total}
                fetch={EmployeeService.fetch}
            >

                <Layout.OnAlert>
                    {!SetupProgressService?.records?.setUpStatus?.['employee'] && !insidePane ? <Alert color="danger" role="alert">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                Please add all of your employees/drivers here.
                            </div>
                            <div>
                                <Button onClick={markCompletedFunc}>Mark Complete</Button>
                            </div>
                        </div>          </Alert> : <></>}
                </Layout.OnAlert>
                <Layout.ActionMenu>
                    <div className="layout-action-menu">
                        <DropdownMenu>
                            <>
                                {userContent?.rights?.includes(1403) ?
                                <>
                                    <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    </>
                                    : null}
                                <DropdownItem onClick={() => setUploadPopup(true)}>
                                    {t("Bulk Upload")}
                                </DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem> {t("Download")} </DropdownItem>
                                <div class="dropdown-divider"></div>
                                <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
                            </>
                        </DropdownMenu>

                    </div>
                </Layout.ActionMenu>
                <Layout.Table>
                    <DataGrid
                        gridLoading={loading}
                        fullHeight={fullHeight}
                        data={EmployeeService.records}
                        total={EmployeeService.total}
                        uiPreference="employee.grid"
                        headers={GridColumns}
                        selectedIDs={selectedIDs}
                        onSelectChange={(v) => {
                            onSelect(v)
                            setSelectedIDs(v)
                        }}
                        page={EmployeeService.page}
                        rowsPerPage={EmployeeService.rowsPerPage}
                        onPaginationChange={EmployeeService.onPaginationChange}
                        renderLastCol={renderLastCol}
                    />
                </Layout.Table>
                {/* <React.Fragment>
                    {
                        loading ?
                            <div className="d-flex mt-10 justify-content-center align-items-center  flex-1" style={{ zIndex: "9999" }}>
                                <ThreeBounce size={50} color="pink" />
                            </div>
                            :
                            <> */}
                <Layout.DetailPageTitle>
                    <div className="d-flex align-items-center">
                        {detailData?._id ? <div className="d-flex align-items-center">
                            <div>{t("Edit Employee")}</div>
                            <b className="ms-2">{detailData?.name ?? ""}</b>
                        </div> : t("Add Employee")}
                        <TagPicker
                            className="ms-3"
                            kind={"employee"}
                            onChange={(v) => setDetailData({ ...detailData, tags: v })}
                            value={detailData?.tags}
                            onlyValues={true}
                            asLabel
                        />
                        <StatusDropDown
                            status={detailData?.status}
                            className="me-4"
                            statusMappingData={activeStatusMapping}
                            data={activeStatusData}
                            onChange={(v) => {
                                setDetailData({
                                    ...detailData,
                                    status: v?.target?.id,
                                });
                            }}
                        />
                    </div>

                </Layout.DetailPageTitle>

                <Layout.DetailPageBody>
                    <EditEmployees
                        editId={editID}
                        onChange={(v) => {
                            setDetailData(v)
                        }}
                        hasErr={hasErr}
                        fetchData={fetchData}

                    />
                </Layout.DetailPageBody>

                <Layout.DetailPageFooter>
                    {detailData?._id && <CallAdmin textColor="white" driverId={detailData?._id} className="bg-success rounded-2 my-2 px-3 mx-2"/>}
                    {detailData?._id ? (
                        <AuditLogDialog module="Employee" id={detailData?._id} />
                    ) : null
                    }
                    {editID && userContent?.rights?.includes(1403) && detailData?._id ? (
                        <DeleteButton
                            loading={loading}
                            onClick={(e) => onDelete(e, detailData?._id)} />
                    ) : null}
                    {userContent?.rights?.includes(1402) ?
                        <SaveButton onClick={onSave} loading={loading} />
                        : null}
                </Layout.DetailPageFooter>
                {/* </>
                    }
                </React.Fragment> */}

            </Layout>

            {showCustomizationOptions ? <CustomOperations
                isModal
                module="employee"
                isOpen={showCustomizationOptions}
                toggleModal={() => { setShowCustomizationOptions(false) }}
            /> : null
            }
        </>
    );
};
export default observer(Employees);
