import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { CitySelect, CountrySelect, InputField } from "../../../../components";
import DayNightPicker from "../../../../components/dnd/forms/itinerary/components/DayNightPicker";
import { I18nContext } from "../../../../store/context/i18nContext";
import Segments from "../../components/Segments";
import "./style.css";
import { Col, Row } from "reactstrap";

const ItineraryDetail = ({
    data,
    onChange = () => { },
}) => {

    const { t } = useContext(I18nContext);

    return (
        <React.Fragment>
            <div className="row mt-3 mb-3">
                <Row>
                    <Col className="col-6">
                        <CountrySelect
                            outline={true}
                            label="Country"
                            placeholder="Country"
                            value={data?.country}
                            onChange={(v) => onChange({ ...data, country: v?.name })}
                        />
                    </Col>
                    <Col className="col-6">
                        <CitySelect
                            onlyCountry={true}
                            country={data?.country}
                            outline={true}
                            label="Visiting Cities"
                            placeholder="Visiting Cities"
                            value={data?.cities}
                            multi={true}
                            onChange={(v) => onChange({ ...data, cities: v })}
                        />
                    </Col>
                </Row>
                <div className="col-12 mb-2">
                    <InputField
                        label={t("Title")}
                        type="text"
                        value={data?.title}
                        onChange={(v) => {
                            onChange({ ...data, title: v })
                        }}
                        placeholder={t("Title")}
                        outline
                    />

                    {/* <AddressField
                            value={data?.location}
                            outline
                            onChange={(v) => {
                                onChange({ ...data, location: v })
                            }}
                        /> */}
                </div>
                <div className="col-12 d-flex mt-2">
                    <DayNightPicker
                        value={{ days: data?.days ?? 2, nights: data?.nights ?? 1 }}
                        onChange={(v) => onChange({ ...data, days: v?.days, nights: v?.nights })}
                    />
                </div>
                {/* <div className="col-6 d-flex mt-2">
                    <TokenInput
                        label={t("Highlights")}
                        type="text"
                        value={data?.highlights ?? []} // [] array of strings
                        onChange={(v) => {
                            onChange({ ...data, highlights: v })  // v should be [] array of strings
                        }}
                        placeholder={t("Highlights")}
                        outline
                        className="flex-1"
                    />
                </div> */}
            </div>
            {/* <ItineraryPackage 
                value={data}
                onChange={(v) => {
                    onChange(v)
                }}
            
            /> */}

            <div className="row">
                <div className="col-12">
                    <Segments
                        value={data?.itineraryExtn?.segments ?? []}
                        onChange={(v) => {
                            onChange({
                                ...data,
                                itineraryExtn: {
                                    ...data?.itineraryExtn,
                                    segments: v
                                }
                            })
                        }}
                    />
                </div>
                {/* <div className="col-3">
                    <Stays
                        value={data?.itineraryExtn?.stays ?? []}
                        onChange={(v) => {
                            onChange({
                                ...data,
                                itineraryExtn: {
                                    ...data?.itineraryExtn,
                                    stays: v
                                }
                            })
                        }}
                    />
                </div>
                <div className="col-3">
                    <Guides
                        value={data?.itineraryExtn?.guides ?? []}
                        onChange={(v) => {
                            onChange({
                                ...data,
                                itineraryExtn: {
                                    ...data?.itineraryExtn,
                                    guides: v
                                }
                            })
                        }}
                    />
                </div> */}
            </div>
        </React.Fragment >
    );
};

export default observer(ItineraryDetail);
