export const GridColumns = {
    version: 1,
    columns: [
        {
            title: " Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Country",
            accessor: "country",
            show: true,
        },
        {
            title: "Regions",
            accessor: "regions",
            show: true,
            width: 700,
            // required: true,
            renderer: (dataObj) => {
                return (
                    <div className="d-flex align-items-centre">
                        {dataObj?.regions?.map((region, index) => (
                            <div key={index} className="d-flex align-items-centre">
                                {region?.state}:{' '}
                                {region?.cities?.map((city, idx) => (
                                    <span key={idx}>
                                        {city},{' '}
                                    </span>
                                ))}
                            </div>
                        ))}


                    </div>
                )
            },
        },
        {
            title: "City Radius KM",
            accessor: "cityRadiusKM",
            show: true,
        },

        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
