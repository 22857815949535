import { EmployeeSelect, VehicleSelect } from "../../components";
import moment from "moment";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Vehicle",
      accessor: "vehicle_id",
      renderer: (dataObj) => {
        return (
          <div>
            <VehicleSelect
              asLabel
              value={dataObj?.vehicle_id}
              className="col-sm-3"
            />
          </div>
        );
      },
      show: true,
    },

    {
      title: "Driver",
      accessor: "",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.driverDuties?.map((item, index) => {
              return <EmployeeSelect
                asLabel
                showSupplier={false}
                label={"Driver Select"}
                driver
                value={item?.employee_id}
                className="col-sm-3"
              />
            })}
          </div>
        );
      },
      show: true,
    },

    {
      title: "Start Time",
      accessor: "",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.driverDuties?.map((item, index) => {
              const timeStr = item?.start?.toString();
              const formattedTime = moment(timeStr, 'HHmm').format('hh:mm A')
              return <div>
                {formattedTime !== 'Invalid date' ? formattedTime : '--'}
              </div>;
            })}
          </div>
        );
      },
      show: true,
    },

    {
      title: "End Time",
      accessor: "",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.driverDuties?.map((item, index) => {
              const timeStr = item?.end?.toString();
              const formattedTime = moment(timeStr, 'HHmm').format('hh:mm A')
              return <div>
                {formattedTime !== 'Invalid date' ? formattedTime : '--'}

              </div>;
            })}
          </div>
        );
      },
      show: true,
    },
    {
      title: "Start Date",
      accessor: "",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.driverDuties?.map((item, index) => {
              console.log(item?.startDate);
              return <div>
                {item?.startDate != undefined ? (
                  (item?.startDate?.toString())?.slice(6, 8) +
                  '-' +
                  (item?.startDate?.toString())?.slice(4, 6) +
                  '-' +
                  (item?.startDate?.toString())?.slice(0, 4)
                ) : (
                  '--'
                )}

              </div>
            })}
          </div>
        );
      },
      show: true,
    },
    {
      title: "End Date",
      accessor: "",
      renderer: (dataObj) => {
        return (
          <div>
            {dataObj?.driverDuties?.map((item, index) => {
              return <div>
                {/* {item?.endDate != undefined ? (
                  (item?.endDate?.toString())?.slice(6, 8)} + /  {(item?.endDate?.toString())?.slice(4, 6)} + / {(item?.endDate?.toString())?.slice(0, 4)):
                    ('--')} */}

                {item?.endDate != undefined ? (
                  (item?.endDate?.toString())?.slice(6, 8) +
                  '-' +
                  (item?.endDate?.toString())?.slice(4, 6) +
                  '-' +
                  (item?.endDate?.toString())?.slice(0, 4)
                ) : (
                  '--'
                )}
              </div>
            })}
          </div>
        );
      },
      show: true,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
