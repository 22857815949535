import { makeAutoObservable } from "mobx";
import { getUiPrefRows } from "../../util/FilterUtil";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./SupplierConstant";


class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  supplierCache = [];
  queue = [];
  isFetching = false;
  constructor() {
    makeAutoObservable(this);
  }


  fetch = async (filterUrl) => {
    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("Supplier.grid")
      this.rowsPerPage = rows ?? 40
    }
    if (filterUrl) {
      this.filterOption = filterUrl;
    }

    const response = await doPOST(
      ENDPOINTS.grid(), { queryURL: ENDPOINTS.filter(this.page, this.rowsPerPage, filterUrl) }
    );


    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };

  fetchSingle = async () => {
    return new Promise(async (resolve, reject) => {
        try {
          if (!this?.supplierCache?.length) {
            const response = await doPOST(
              ENDPOINTS.grid(), { queryURL: ENDPOINTS.filter(null, null, "&rows=-1") }
            );
            if (response.status === 200) {
              this.supplierCache = response?.data?.rows?.map((v) => {
                return { label: v.name, value: v._id };
              });
              if (this.page === 1) this.total = response?.data?.total;
            } else {
              this.error = response.data;
            }
          }

            while (this.queue.length > 0) {
                const { resolve } = this.queue.shift();
                resolve();
            }

        } catch (err) {
            this.error = err;
            while (this.queue.length > 0) {
                const { reject } = this.queue.shift();
                reject(err);
            }
        } finally {
            this.isFetching = false;
        }
    });
}


  fetchAll = async function (props) {
    if (this.supplierCache.length) {
      return this.supplierCache
    }
    try {
      if (this.isFetching) {
        return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
      };
      this.isFetching = true;
      this.fetchSingle()
    } catch (err) {
      this.error = err;
    }
  };


  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response?.status === 200) {
      this.version += 1;
      this.supplierCache = []
      this.fetch()
      // SupervisorSelectService?.fetch()
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    if (response?.status === 200) {
      this.supplierCache = []
      this.fetch()
      this.fetchAll()
      // SupervisorSelectService?.fetch()
    }
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
      this.supplierCache = []

    }
  };
  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };
}

const Supplierservice = new Service();
export default Supplierservice;
