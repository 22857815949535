import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ThreeBounce } from "better-react-spinkit";
import SupplierLabelService from './SupplierLabelService';

const SupplierLabel = ({ corpType, value, corp_id }) => {
  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSupplier = async () => {
      setLoading(true);
      const supplierData = await SupplierLabelService.fetchSupplier(corpType, corp_id);
      const foundSupplier = supplierData?.find(supp => supp?._id === value);
      setSupplier(foundSupplier);
      setLoading(false);
    };

    fetchSupplier();
  }, [corpType, corp_id, value]);

  if (loading) {
    return <ThreeBounce size={10} color="pink" className="zr_company-loader" />;
  }

  return <div className="zr_company-label">{supplier?.label || '--'}</div>;
};

export default observer(SupplierLabel);
