
import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Suppliers/SupplierConstant";

class Service {
  suppliersCache = [];
  fetchQueue = [];
  loading = false;
  waitingPromises = [];

  constructor() {
    if (Service.instance) {
      return Service.instance;
    }

    makeAutoObservable(this);
    Service.instance = this;
  }

  fetchSupplier = async () => {
    // If data is already cached, return it immediately
    if (this.suppliersCache.length) {
      return this.suppliersCache;
    }

    // If a request is already in progress, add the new request to the waiting promises
    if (this.loading) {
      return new Promise((resolve, reject) => {
        this.waitingPromises.push({ resolve, reject });
      });
    }
    this.loading = true;

    try {
      const response = await doPOST(
        ENDPOINTS.grid(), { queryURL: ENDPOINTS.filter(null, null, "&rows=-1") }
      );
      if (response.status === 200) {
        const formattedEntities = response?.data?.rows?.map((v) => ({
          ...v,
          label: v.name,
          value: v._id,
        }));
        this.suppliersCache = formattedEntities;

        // Resolve all waiting promises with the fetched data
        this.waitingPromises.forEach(({ resolve }) => resolve(formattedEntities));
        this.waitingPromises = []; // Clear the waiting promises queue

        return formattedEntities;
      } else {
        const error = new Error('Failed to fetch suppliers');
        this.waitingPromises.forEach(({ reject }) => reject(error));
        this.waitingPromises = [];
        throw error;
      }
    } catch (error) {
      this.waitingPromises.forEach(({ reject }) => reject(error));
      this.waitingPromises = [];
      throw new Error(error.message);
    } finally {
      this.loading = false;
    }
  };
}

const SupplierLabelService = new Service();
export default SupplierLabelService;
