import React, { useContext, useEffect, useState } from 'react'
import AddressField from '../AddressField/AddressField'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { I18nContext } from '../../store/context/i18nContext';
import AddRemoveButton from '../AddRemoveButton';
import { getFullAddress, getValue } from '../../util/Util';
import SaveButton from '../Buttons/SaveButton';
import DeleteButton from '../Buttons/DeleteButton';

const ShuttleAddressSelector = ({ shuttleLocations = [], onChange = () => { },disabled=false }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useContext(I18nContext);
  const [locations,setLocations]=useState(shuttleLocations);

  const toggleModal = () => setVisible(prev => !prev);

  const addshuttleLocations = () => {
    // onChange([...shuttleLocations, { address: null }])
    setLocations(prev=>([...(getValue(prev,[])), { address: null }]))
  }
  const deleteShuttleLocations = (index) => {
    // onChange(shuttleLocations?.filter((ele, i) => i !== index))
    setLocations(prev=>prev?.filter((ele, i) => i !== index))
  }

  useEffect(() => {
    if (!shuttleLocations?.length) {
      addshuttleLocations();
    }else{
      setLocations(shuttleLocations)
    }
  }, [shuttleLocations])

  return (
    <>
      {/* show list here also with a max height condition and the first will be src and last will be the dest */}
      <div className=''>
        <div className='fs-6 fw-bold'>Shuttle Locations</div>
        <div style={{maxHeight:400}} className='overflow-auto'>
          {locations?.filter(stop=>stop?.address)?.map((ele, index) => (
            <div className='py-2 px-2 border border-dark-text-secondary  rounded-3 my-2  ' key={index}>
              {getFullAddress(ele?.address)}
            </div>
          ))}
        </div>
        {!disabled?<div onClick={toggleModal} className='btn btn-primary py-1 mt-2'>Add Location</div>:null}
      </div>

      <Modal
        toggle={toggleModal}
        isOpen={visible}
        scrollable={true}
        className="modal-lg"
        backdrop="static"
        contentClassName='vh-100'
      >
        <ModalHeader toggle={toggleModal}>
          {t("Add Shuttle Locations")}
        </ModalHeader>

        <ModalBody>
          {locations?.map((ele, index) => (
            <div className='d-flex align-items-center my-2' key={index}>
              <div className='flex-1'>
                <AddressField

                  value={ele?.address}
                  onChange={(v) => {
                    // onChange(shuttleLocations?.map((f, x) => {
                    //   if (index === x)
                    //     return { ...ele, address: v };
                    //   return f;
                    // }))
                    setLocations(prev=>prev?.map((f, x) => {
                      if (index === x)
                        return { ...ele, address: v };
                      return f;
                    }))
                  }}
                />
              </div>
              <AddRemoveButton
                onAdd={addshuttleLocations}
                onRemove={() => deleteShuttleLocations(index)}
                hideAdd={locations?.length - 1 !== index}
                hideSub={locations?.length ==1}
              />
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <SaveButton onClick={()=>{
            onChange(locations);
            toggleModal();
          }} />
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ShuttleAddressSelector