import React, { useContext, useState } from 'react'
import { UserContext } from '../../store/context/UserContext';
import { FaCarRear, FaRegMessage } from 'react-icons/fa6';
import { FaClone, FaUser } from 'react-icons/fa';
import { TfiTwitter } from 'react-icons/tfi';
import DeleteLink from '../Buttons/Icons/deleteButton';
import SideBarService from '../HorizontalLayout/SideBarService';
import { DialogContext } from '../../store/context/DialogContext';
import BookingService from '../../pages/Booking/BookingService';
import { useNavigate } from 'react-router-dom';
import { I18nContext } from '../../store/context/i18nContext';
import ModalComponent from '../Modal/Modal';
import Vehicle from '../../pages/Vehicle/Vehicle';
import Employee from '../../pages/Employee/Employee';
import { checkParrangersAnonymous, fetchDrivers, getDefaultClientBilling } from '../../pages/BookingsNew/utils/helper';
import useBookingManagement from '../../hooks/useBookingManagement';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BiPrinter } from "react-icons/bi";
import BulkPrintConfiguration from '../../pages/Invoice/Component/BulkPrintConfiguration';
import InvoiceForm from '../../pages/Invoice/InvoiceForm';
import MessageDialog from '../../pages/Booking/components/OnDemandMessage/MessageDialog';

function ActionMenu({ booking, onDeleteCallback, onUpdate, customerType, onCloneClick, postSave, compact = false, onPrint = () => { } }) {
    const { userContent } = useContext(UserContext);
    const { t } = useContext(I18nContext)
    const [modal, setModal] = useState(null);
    const navigate = useNavigate();
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [printInvoiceModal, setPrintInvoiceModal] = useState(false);
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);
    const { preSaveUpdateBilling, onDelete } = useBookingManagement();
    const [modalForTripInvoice, setModalForTripInvoice] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const isCloning = () => queryParams.has('clone_trip_id')

    const fetchBillings = async (bookingId) => {
        try {
            const { clientBilling: cB, supplierBillings: sBArray } = await BookingService.fetchTripBillings(bookingId)
            const clientBilling = cB ?? getDefaultClientBilling(bookingId)
            const supplierBillings = sBArray ?? []
            return { clientBilling, supplierBillings };
        } catch (error) {
            throw (error)
        }
    }

    const updateDriver = async (driver) => {
        try {
            if (driver) {
                const updatedTrip = {
                    ...booking,
                    vehicle: {
                        ...booking?.vehicle,
                        _id: vehicle
                    },
                    driver: {
                        ...booking?.driver,
                        _id: driver
                    }
                };
                if (booking?._id) {
                    const tripData = await BookingService.get(booking?._id);
                    updatedTrip.tripExtn = tripData?.tripExtn
                }

                const toSaveTrip = {
                    ...updatedTrip,
                    ...(customerType === "walkIn" && { client: null }),
                    passengers: checkParrangersAnonymous(booking?.passengers)
                };
                setLoading(true);
                //fetch billings and then
                let updatedBooking = toSaveTrip;
                if (booking?._id) {
                    let payload = { trips: [toSaveTrip] };
                    const { clientBilling, supplierBillings } = await fetchBillings(booking?._id);
                    const updatedBillings = preSaveUpdateBilling(toSaveTrip, clientBilling, supplierBillings);
                    payload.billings = updatedBillings
                    updatedBooking = await BookingService.editAll(payload);
                }
                showMessage(t("Record updated successfully"));
                postSave([updatedBooking]);
            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false);
        }
    }

    const updateVehicle = async (vehicle) => {
        try {
            if (vehicle) {
                const selectedDriver = await fetchDrivers(vehicle);
                if (!selectedDriver) {
                    showError("No Driver Found for this vehicle")
                    return
                }
                setDriver(selectedDriver);
                const updatedTrip = {
                    ...booking,
                    vehicle: {
                        ...booking?.vehicle,
                        _id: vehicle
                    },
                    driver: {
                        ...booking?.driver,
                        _id: selectedDriver
                    }
                };
                if (booking?._id) {
                    const tripData = await BookingService.get(booking?._id);
                    updatedTrip.tripExtn = tripData?.tripExtn
                }
                const toSaveTrip = {
                    ...updatedTrip,
                    ...(customerType === "walkIn" && { client: null }),
                    passengers: checkParrangersAnonymous(booking?.passengers)
                };
                setLoading(true);
                let updatedBooking = toSaveTrip
                if (booking?._id) {
                    let payload = { trips: [toSaveTrip] };
                    const { clientBilling, supplierBillings } = await fetchBillings(booking?._id);
                    const updatedBillings = preSaveUpdateBilling(toSaveTrip, clientBilling, supplierBillings);
                    payload.billings = updatedBillings
                    updatedBooking = await BookingService.editAll(payload);
                }
                showMessage(t("Record updated successfully"));
                postSave([updatedBooking]);

            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false);
        }
    }

    const toggleSingleTripModalForInvoice = () => {
        if (booking && booking?._id) {
            if (booking.clientInvoice_id) {
                window.open(`/invoice/edit/${booking.clientInvoice_id}`, "_blank");
            } else {
                setModalForTripInvoice(prev => !prev);
            }
        }
    }

    const showInvoiceCreate = () => {
        return booking != null
            && booking._id
            && booking.status
            && [1, 2, 3, 4, 5, 6, 19, 20, 29, 30]?.includes(booking?.status) && !isCloning()
    }

    const toggleMessageModal = (e) => {
        setShowMessageModal(!showMessageModal);
        e?.stopPropagation();
    };

    return (
        <>
            <div className="d-flex flex-column">
                {booking?.status !== 4 && userContent?.rights?.includes(6003) &&

                    <div className="zr_action_item" onClick={() => setModal("vehicleModal")} >
                        <div className="me-2">
                            <FaCarRear fontSize={12} />
                        </div>
                        {`${booking?.vehicle ? 'Reassign' : 'Assign'}`} Vehicle
                    </div>
                }
                {booking?.status !== 4 && userContent?.rights?.includes(6003) &&

                    <div className="zr_action_item" onClick={() => setModal("driverModal")}>
                        <div className="me-2">
                            <FaUser fontSize={12} />
                        </div>
                        {`${booking?.driver ? 'Reassign' : 'Assign'}`} Driver
                    </div>
                }
                {(booking?.status === 3 || booking?.status === 4) && !compact ? (
                    <div
                        className="zr_action_item"
                        onClick={() => {
                            SideBarService.toggleMap()
                            SideBarService.setModule("trip")
                            SideBarService.setTripId(booking?._id)
                        }}>
                        <TfiTwitter className="me-2 font-size-16" />
                        Track Vehicle
                    </div>
                ) : null
                }

                {
                    booking?._id && !compact ? <>

                        <div className="zr_action_item" onClick={() => onCloneClick(booking)}  >
                            <div className="me-2">
                                <FaClone />
                            </div>
                            Clone Booking
                        </div>
                    </> : null
                }
                {
                    booking?._id && !compact ? <>

                        <div className="zr_action_item" onClick={() => onPrint("DutySlip", booking?._id)}  >
                            <div className="me-2">
                                <BiPrinter size={20} />
                            </div>
                            Print Duty Slip
                        </div>
                    </> : null
                }
                {
                    booking?._id && !compact ? <>
                        {console.log(booking)}
                        <div className="zr_action_item" onClick={() => onPrint("TripSummary", booking?._id)}  >
                            <div className="me-2">
                                <BiPrinter size={20} />
                            </div>
                            Print Trip Summary
                        </div>
                    </> : null
                }
                {booking?.clientInvoiceNo && !compact ?
                    <>

                        <div className="zr_action_item"
                            onClick={(e) => {
                                setPrintInvoiceModal(!printInvoiceModal);
                                e?.stopPropagation();
                            }}  >
                            <div className="me-2">
                                <BiPrinter />
                            </div>
                            Print Invoice
                        </div>
                    </> : null
                }
                {showInvoiceCreate() && !compact ?
                    <>

                        <div className="zr_action_item"
                            onClick={(e) => {
                                toggleSingleTripModalForInvoice();
                                e?.stopPropagation();
                            }}  >
                            <div className="me-2">
                                <BiPrinter />
                            </div>
                            {t(`${!booking?.clientInvoice_id ? "Create Invoice" : "Show Invoice "}`)}
                        </div>
                    </>
                    : null}
                {booking?._id ?
                    <div onClick={toggleMessageModal} className="zr_action_item">
                        <div className="me-2"><FaRegMessage /></div>
                        {t(`Send Message`)}
                    </div>
                    : null}
                {(booking?.status == 0 || booking?.status == 1 || booking?.status == 2 || booking?.status == 3 ||
                    booking?.status == 4 || booking?.status == 5) && userContent?.rights?.includes(6003) &&

                    <div
                        className="zr_action_item"
                        onClick={(event) => onDelete(booking?._id, onDeleteCallback)}
                    >
                        <div className="me-2">
                            <DeleteLink className={"red-important"} />
                        </div>
                        Delete Trip
                    </div>

                }
            </div>
            <BulkPrintConfiguration
                modal={printInvoiceModal}
                setModal={setPrintInvoiceModal}
                invoice_ids={[booking?.clientInvoice_id]}
                loading={loading}
                // onSuccess={() => setSelectedIDs([])}
                setLoading={setLoading}
            />
            <Modal
                toggle={() => setModal(null)}
                isOpen={modal === 'vehicleModal'}
                scrollable={true}
                className="modal-xl zr_action_prevent_modal"
                backdrop="static"
            >
                <ModalHeader toggle={() => setModal(null)}>
                    {t("Vehicle")}
                </ModalHeader>
                <ModalBody>
                    <Vehicle
                        value={vehicle}
                        insidePane={true}
                        onSelect={(val) => {
                            setVehicle(val?._id)
                            setModal(null)
                            updateVehicle(val?._id)
                        }}
                    />
                </ModalBody>
            </Modal>

            <Modal
                scrollable={true}
                className="modal-xl zr_action_prevent_modal"
                toggle={() => setModal(null)}
                isOpen={modal === "driverModal"}
                backdrop="static"
            >
                <ModalHeader toggle={() => setModal(null)}>
                    {t("Employee")}
                </ModalHeader>

                <ModalBody>
                    <Employee
                        value={driver}
                        insidePane={true}
                        onSelect={(val) => {
                            setDriver(val?._id)
                            setModal(null);
                            updateDriver(val?._id)
                        }}
                    />
                </ModalBody>
            </Modal>

            {modalForTripInvoice && showInvoiceCreate() && (
                <Modal
                    isOpen={modalForTripInvoice}
                    toggle={toggleSingleTripModalForInvoice}
                    size="xl"
                    className="modal-xl zr_action_prevent_modal"
                >
                    <ModalHeader toggle={toggleSingleTripModalForInvoice}>
                        Create Invoice
                    </ModalHeader>
                    <ModalBody>
                        <InvoiceForm
                            trip_ids={[booking?._id]}
                            //  validateFeegroup,
                            onSave={(invoicedTripIds) => {
                                BookingService.get(booking?._id).then((trip) => {
                                    postSave([booking]);
                                    setModalForTripInvoice(prev => !prev);
                                }).catch((error) => {
                                    showError(error);
                                });
                            }}
                            concise
                            toggleModal={toggleSingleTripModalForInvoice}
                        />
                    </ModalBody>
                    <div>
                        {/* <SaveButton loading={loading} onClick={handleInvoiceCreate} /> */}
                    </div>
                </Modal>
            )}
            {showMessageModal ? <MessageDialog className="modal-xl zr_action_prevent_modal" trip={booking} modal={showMessageModal} toggleModal={() => { toggleMessageModal() }} /> : null}

        </>
    )
}

export default ActionMenu