import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../../util/HttpUtil";
import { ENDPOINTS } from "../../../pages/Vehicle/VehicleConstant";

class VehicleSelect {
    vehicles = [];
    queue = [];
    isFetching = false;
    page = 1;
    rowsPerPage = 10;
    filterOption = "";
    total = -1;
    vehicleCache={}


    // Vehicle from vehicle_id via caching
    categoryCache = {};
    fetchQueue = {};
    loading = {};


    fetchVehicleFromVehicleId = async(vehicleId)=>
        {
            if(this.categoryCache[vehicleId]){
                return this.categoryCache[vehicleId];
            }
    
            if (this.loading[vehicleId]) {
                return new Promise((resolve, reject) => {
                  if (!this.fetchQueue[vehicleId]) this.fetchQueue[vehicleId] = [];
                  this.fetchQueue[vehicleId].push({ resolve, reject });
                });
              }
              this.loading[vehicleId] = true;
    
              try {
                const response = await doGET(`/api/vehicle/grid?rows=-1`);
                if (response.status === 200) {
                  const categories = response.data.rows.reduce((acc, item) => {
                    acc[item._id] = { ...item, label: item?.regNo+"("+item?.year+")"};
                    return acc;
                  }, {});
          
                  this.categoryCache = { ...this.categoryCache, ...categories };
          
                  while (this.fetchQueue[vehicleId]?.length > 0) {
                    const { resolve } = this.fetchQueue[vehicleId].shift();
                    resolve(this.categoryCache[vehicleId]);
                  }
          
                  return this.categoryCache[vehicleId] || null;
                } else {
                  throw new Error("Failed to fetch vehicles");
                }
              } catch (error) {
                throw new Error(error.message);
              } finally {
                delete this.loading[vehicleId];
              }
    
        }

    


    constructor() {
        makeAutoObservable(this);
    }


    fetchSingle = async (filterUrl) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this?.vehicleCache?.[filterUrl]?.length) {
                    const response = await doPOST(
                        ENDPOINTS.grid(), { queryURL: ENDPOINTS.filter(this.page, this.rowsPerPage, filterUrl) }
                    );
    
                    if (response.status === 200) {
                        const fetchedVehicles = response?.data?.rows;
                        this.total = response?.data?.total;
    
                        if (this.page === 1) {
                            this.vehicles = fetchedVehicles;
                        } else {
                            this.vehicles = [...this.vehicles, ...fetchedVehicles];
                        }
    
                        // Cache the fetched vehicles for the current filterUrl
                        this.vehicleCache = {
                            ...this.vehicleCache,
                            [filterUrl]: fetchedVehicles
                        };
                    } else {
                        this.error = response.data;
                    }
                } else {
                    // If data is cached, resolve the cache without making an API call
                    const cachedVehicles = this.vehicleCache[filterUrl];
                    if (this.page === 1) {
                        this.vehicles = cachedVehicles;
                    } else {
                        this.vehicles = [...this.vehicles, ...cachedVehicles];
                    }
                }

                while (this.queue[filterUrl]?.length > 0) {
                    const { resolve } = this.fetchQueue[filterUrl].shift();
                    resolve(this.vehicleCache[filterUrl]);
                  }
    
                resolve();
            } catch (err) {
                this.error = err;
                reject(err);
            } finally {
                this.isFetching = false;
            }
        });
    };
    
    fetch = async function (filterUrl = "") {
        
        // Handle caching and fetching here
        try {
            if (this.filterOption !== filterUrl) {
                this.page = 1;
                this.total = -1;
            }
            this.filterOption = filterUrl;

            if (this.vehicleCache?.[filterUrl]?.length) { 
                return this.vehicleCache?.[filterUrl]
            }

            if (this.loading[filterUrl]) {
                return new Promise((resolve, reject) => this.queue.push({ resolve, reject }));
            }
            
            this.loading[filterUrl] = true;
           
    
            // if (!this.vehicleCache?.[filterUrl]?.length) {
            //     this.vehicleCache = {}; // Initialize the cache if it doesn't exist
            // }
            await this.fetchSingle(filterUrl);
    
        } catch (err) {
            this.error = err;
        }
    };
    

    onPaginationChange = async (page, rowsPerPage) => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        await this.fetch(this.filterOption);
    };
    pushNewData = (data) => {
        this.vehicles.push(data)
    }

    get = async (id) => {
        const response = await doGET(ENDPOINTS.get(id));
        if (response.status === 200) {
            return response.data
        }
    }
}

const VehicleSelectService = new VehicleSelect();
export default VehicleSelectService;


