import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "reactstrap";
import { AmountField, CitySelect, CompanySelect, CountrySelect, DeleteButton, InputField, ModalComponent, SaveButton } from "../../components";
import CustomDropdown from "../../components/CustomDropDown";
import ActionMenu from "../../components/Dropdowns/ActionMenu/ActionMenu";
import ReminderButton from "../../components/Reminders/ReminderButton";
import TagPicker from "../../components/TagSelector/TagPicker";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import BookingStatusModifier from "../Booking/components/BookingStatusModifier";
import CommunicationButton from "../Communication/CommunicationButton";
import BookingGrid from "./BookingGrid";
import FlightsGrid from "./FlightsGrid";
import GroupTourService from "./GroupTourService";
import StayGrid from "./StayGrid";
import GroupTourSidebarService from "./sideBar/GroupTourSidebarService";
import ChangeSpaceModal from "./sideBar/changeSpaceModal/ChangeSpaceModal";
import PassengersNew from "../Booking/PassengersNew";
import Fee from "../../components/dnd/forms/itinerary/components/SupplierFare/Fee";
import { getValue } from "../../util/Util";
import { doPOST } from "../../util/HttpUtil";
import { ENDPOINTS } from "../Booking/BookingConstant";
import Editor from "../../components/dnd/forms/itinerary/components/RichTextEditor/Editor/Editor";
import { convertItinerarySegmentsToString } from "../../helpers/general";
import Itinerary from "../Itinerary/Itinerary";
import ItineraryService from "../Itinerary/ItineraryService";
import { FaMagnifyingGlass } from "react-icons/fa6";
import moment from "moment";
import DayNightPicker from "../../components/dnd/forms/itinerary/components/DayNightPicker";
import DateSelectCustom from "../../components/DateTimePickerWithInterval/DateSelectCustom";

const TOURTYPE_DATA = ['Event', 'Meeting', 'Conference']


const guests = [
    {
        index: 0,
        anonymous: false,
        salutation: "Mr",
        phone: {
            countryCode: 91,
        },
    },
]

const feeObj = {
    clientFee: {
        flights: 0,
        stays: 0,
        trips: 0
    },
    supplierFee: {
        flights: 0,
        stays: 0,
        trips: 0
    }

}


const EditGroupTour = ({ editId,
    onSave,
    onDelete,
    insidePane,
}) => {
    let navigate = useNavigate();
    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);

    const { showError, showMessage, showConfirm } = useContext(DialogContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [hasErr, setHasErr] = useState(false)
    const [flights, setFlights] = useState([]);
    const [tripIds, setTripIds] = useState([]);
    const [stays, setStays] = useState([]);
    const [changeSpaceModal, setChangeSpaceModal] = useState(false);
    const [addItineraryModal, setAddItineraryModal] = useState(false);
    const [fee,setFee]=useState(feeObj)

    const [showForm, setShowEditForm] = useState({
        visible: false,
        editId: null,
        type: null
    })

    const fetch = async () => {
        if (!editId) return;
        try {
            let data = await GroupTourService.get(editId);
            setData({
                ...data,
                passengers: data?.passengers ? data?.passengers : guests,
            })
        } catch (error) {
            showError(error)
        }
    };

    useEffect(() => {
        setData(null)
        setLoading(true);
        if (editId) { fetch() }
        else {
            setData({
                ...data,
                passengers: data?.passengers ? data?.passengers : guests,
            })
        }
        setLoading(false)
    }, [editId, GroupTourService?.version]);

    useEffect(() => {
        if (data?.startDate && data?.days)
            setData({ ...data, endDate: calculateEndDate(data?.startDate, data?.days) })
    }, [data?.startDate, data?.days])


    const handleTourChange = useCallback((fieldName, fieldValue) => {
        if (fieldName && fieldValue) {
            setData(prev => ({
                ...(prev ?? {}),
                [fieldName]: fieldValue,
            }));
        }
    }, []);


    const handleClientCompanyChange = useCallback(({ company_id, client_id, name }) => {

        const eClient = { ...data?.client } || {};
        if (company_id) {
            eClient["company_id"] = company_id;
        }
        if (client_id) {
            eClient["client_id"] = client_id;
            eClient["company_id"] = null;
        }
        eClient["name"] = name;
        handleTourChange("client", eClient);

    },
        [data?.client, handleTourChange]
    );



    const handleSave = async (e) => {
        e.preventDefault();
        // if (!data?.status && !data?.remark) {
        //     setHasErr(true)
        //     showError(t("Please enter all required fields"));
        //     return;
        // }
        setLoading(true);
        try {
            if (editId) {
                await GroupTourService.edit(data, editId);
                setLoading(false);
                showMessage(t("Record updated successfully."));
            } else {
                const tour = await GroupTourService.save({
                    ...data,
                    space_id: GroupTourSidebarService?.selectedFolder?._id
                });
                setLoading(false);
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/tour/edit/${tour}`);
            }
            await fetch();
            onSave();
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };


    const handleEditSpace = async ({ payload }) => {
        setLoading(true);
        try {
            if (editId) {
                await GroupTourService.edit(payload, editId);
                setLoading(false);
                showMessage(t('Space changed successfully'));
            }
            onSave();
        } catch (e) {
            showError(e);
            setLoading(false);
        }
        setHasErr(false)
    };

    const handleDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await GroupTourService.delete(id);
                setLoading(false);
                onDelete();

                showMessage(t("Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };

    const onStatusChange = async (e, payload) => {
        e.preventDefault();
        if (!editId || !payload?.status) {
            return;
        }
        setLoading(true);
        try {
            const resp = await GroupTourService.edit({ ...data, status: payload.status }, editId);
            if (resp?.data) {
                setData(resp?.data);
            }
            showMessage("Status Changed successfully");
        } catch (e) {
            showError(e);
        } finally {
            setHasErr(false)
            setLoading(false);
        }
    };
    const statusMapping = {
        0: { text: "Pending", color: "#AEB6BF" },
        1: { text: "Confirmed", color: "#2980B9" },
        2: { text: "Ready", color: "#A569BD" },
        3: { text: "Dispatched", color: "#FFBF00" },
        4: { text: "Live", color: "#FF7F50" },
        5: { text: "Completed", color: "#52BE80" },
        6: { text: "Cancelled", color: "#F1948A" },
        7: { text: "Error", color: "#CB4335" },
        8: { text: "Paused", color: "#CCCCFF" },
        9: { text: "Expired", color: "#7B241C" },
        10: { text: "Rejected", color: "#7B241C" },
    };

    const handleChangeSpace = () => {
        setChangeSpaceModal(true)
    };

    const fetchTripBillings=async(ids)=>{
        try {
            const response = await doPOST(ENDPOINTS.getBillingsByTrips(), {
                queryURL: `trip_id=in[${ids}]`,
            });

            if (response?.status === 200 && response?.data?.rows?.length) {
                const clientFees = response?.data?.rows?.filter(f => f?.type == 1);
                const supplierFees = response?.data?.rows?.filter(f => f?.type == 2);
                setFee(prevFee => {
                    prevFee.clientFee.trips = 0;
                    prevFee.supplierFee.trips = 0;
                    return {
                        ...prevFee,
                        clientFee: {
                            ...prevFee?.clientFee,
                            trips: clientFees?.reduce((acc, fee) => acc + getValue(fee?.fee?.total, 0), 0)
                        },
                        supplierFee: {
                            ...prevFee?.supplierFee,
                            trips: supplierFees?.reduce((acc, fee) => acc + getValue(fee?.fee?.total, 0), 0)
                        }
                    }
                })
            }

        } catch (error) {
            console.log(error);
        }

    }

    function calculateEndDate(yyyymmdd, days) {
        const date = moment(yyyymmdd, "YYYYMMDD");
        date.add(days, 'days');
        return date.format("YYYYMMDD");
    }

    const onItinerarySelect = async (it_id) => {
        setLoading(true);
        setAddItineraryModal(false)
        try {
            let itinerary = await ItineraryService.get(it_id);
            setData({
                ...data,
                itinerary: convertItinerarySegmentsToString(itinerary?.itineraryExtn?.segments ?? []),
                title: itinerary?.title,
                days: itinerary?.days,
                nights: itinerary?.nights,
            })
        } catch (error) {
            showError(error)
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            {loading ?
                <ThreeBounce /> :
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <div className="zr_layout-header p-2 d-flex justify-content-between" style={{ flexShrink: 0 }} >
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                {data?._id ? t("Edit Tour") : t("Add Tour")}

                                <TagPicker
                                    className="ms-2"
                                    kind={"tour"}
                                    onChange={(v) => setData(prev => ({
                                        ...prev,
                                        tags: v
                                    }))}
                                    value={data?.tags}
                                />


                                {data?._id ? <BookingStatusModifier
                                    apiMethod={onStatusChange}
                                    currentStatus={data?.status}
                                    trip_id={editId}
                                    statusMapping={statusMapping}
                                /> : null}


                            </div>

                            {editId ? <div className="d-flex align-items-center">

                                <ReminderButton
                                    record_id={editId}
                                    module={"tour"}
                                    className={"me-0"}
                                    space_id={GroupTourSidebarService?.selectedFolder?._id}
                                />

                                <ActionMenu
                                    data={[
                                        { label: 'Change Space', icon: <FaExchangeAlt />, onClick: handleChangeSpace },
                                    ]}
                                    menuPosition="right"
                                    className="zr_action_menu me-2"
                                />

                            </div> : null}



                        </div>
                        {/* <CrossButton  onClick={onBack} style={{position:"relative !important"}}/> */}
                    </div>
                    <Form
                        className="p-3"
                        style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
                    >
                        <Row className="mt-0 mb-3 d-flex flex-column flex-md-row align-items-center">
                            {/* <div className="col-12 col-md-6">
                            <div className="d-flex row col-sm-10">

                                <div className="col-6 pe-0 ">
                                    <ClientSelect
                                        value={data?.client?.client_id}
                                        onChange={(v) => {
                                            handleClientCompanyChange({
                                                client_id: v?.value
                                            });
                                            // vendorCompany_id
                                        }}
                                        innerContainerClassName="input-group-pre"
                                        className={"pe-0 "}
                                        outline={true}
                                    />
                                </div>
                                <div className="col-6 ps-0 ">
                                    <CompanySelect
                                        outline
                                        corpType="client"
                                        value={data?.client?.company_id}
                                        onChange={(v) => {
                                            handleClientCompanyChange({
                                                company_id: v?.value
                                            });

                                        }}
                                        innerContainerClassName="input-group-post"
                                        className={"ps-0 ms-0"}
                                        label="Client Company"
                                        corp_id={data?.client?.client_id}
                                    />
                                </div>
                            </div>
                        </div> */}



                            <div className="col-12 d-flex justify-content-between align-items-center">
                              
                                    <CustomDropdown
                                        className="px-4 mx-0 w-100"
                                        value={data?.tourType}
                                        label="Tour Type"
                                        data={TOURTYPE_DATA}
                                        onChange={(v) => {
                                            setData(prev => ({ ...prev, tourType: v }))
                                        }}
                                    />
                                 

                                <Col sm={12} md={5}>
                                    <CompanySelect
                                        outline
                                        className={"flex-1 w-100"}
                                        style={{ minWidth: "200px" }}
                                        value={data?.vendorCompany_id}
                                        onChange={(v) =>
                                            setData(prev => ({ ...prev, vendorCompany_id: v?.value }))
                                        }
                                        label="Serving Company"
                                    />
                                </Col>

                            </div>
                        </Row>


                        {/* <Row className="mt-3 mb-3">

                        <TokenInput
                            label={t("Activites")}
                            type="text"
                            value={data?.activites ?? ["Click here to edit"]} // [] array of strings
                            onChange={(v) => {
                                setData(prev => ({ ...prev, activites: v }))  // v should be [] array of strings
                                console.log(data)
                            }}
                            placeholder={t("Add Activities")}
                            outline
                            className="col-12 flex-1"
                        />

                    </Row> */}

                        <Row className="mt-3 mb-2">
                            <div><b>Passengers</b></div>
                            <PassengersNew
                                space_id={GroupTourSidebarService?.selectedFolder?._id}
                                hideAnonymousCheck={true}
                                passengers={data?.passengers}
                                onChange={(v) => {
                                    setData({ ...data, passengers: v })
                                }}
                            />
                            {/* <PassengerSelect
                                space_id={GroupTourSidebarService?.selectedFolder?._id}
                                passengers={data?.passengers}
                                onChange={(v) => {
                                    setData({ ...data, passengers: v })
                                }}
                                label={"Select Customer"}
                            /> */}
                        </Row>
                        {/* <Row className="mt-3 mb-3">
                        <BookedBy
                            showPaxCount={false}
                            className="pb-1"
                            emptyDivStyle={{
                                maxWidth: "196.5px",
                                paddingRight: "0px"
                            }}
                            value={data?.bookedBy}
                            onChange={(v) => {
                                setData(prev => ({
                                    ...prev,
                                    bookedBy: v
                                }))
                            }}
                        />
                    </Row> */}
                        {/* {JSON.stringify({ flight: data?.flights })}
                        {JSON.stringify({ stay: data?.stays })} */}
                        <Row>
                            <Col className="col-6 col-lg-3">
                                <CountrySelect
                                    outline={true}
                                    label="Departure Country"
                                    placeholder="Departure Country"
                                    value={data?.departureCountry}
                                    onChange={(v) => setData({ ...data, departureCountry: v?.name, arrivalCountry: v?.name })}
                                />
                            </Col>
                            <Col className="col-6 col-lg-3">
                                <CitySelect
                                    onlyCountry={true}
                                    country={data?.departureCountry}
                                    outline={true}
                                    label="Departure City"
                                    placeholder="Departure City"
                                    value={data?.departureCity}
                                    onChange={(v) => setData({ ...data, departureCity: v?.value, arrivalCity: v?.value })}
                                />
                            </Col>
                            <Col className="col-6 col-lg-3">
                                <CountrySelect
                                    outline={true}
                                    label="Arrival Country"
                                    placeholder="Arrival Country"
                                    value={data?.arrivalCountry}
                                    onChange={(v) => setData({ ...data, arrivalCountry: v?.name })}
                                />
                            </Col>
                            <Col className="col-6 col-lg-3">
                                <CitySelect
                                    onlyCountry={true}
                                    country={data?.arrivalCountry}
                                    outline={true}
                                    label="Arrival City"
                                    placeholder="Arrival City"
                                    value={data?.arrivalCity}
                                    onChange={(v) => setData({ ...data, arrivalCity: v?.value })}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col sm={12} md={6}>
                                <div style={{ backgroundColor: "#EBEBEB" }} className="h3 ps-3 py-2 d-flex align-items-center justify-content-between">
                                    <div>{"Itinerary Details"}</div>
                                    <div className="d-flex align-items-center">
                                        <Button
                                            onClick={() => setAddItineraryModal(true)}
                                            className="d-flex align-items-center p-1 me-2">
                                            <FaMagnifyingGlass />
                                        </Button>
                                    </div>
                                </div>
                                {!data?.itinerary ? null : (
                                    <>
                                        <div className="d-flex align-items-center">
                                            <InputField
                                                outline
                                                style={{ flex: 1 }}
                                                className='me-2'
                                                label="Title"
                                                placeholder="Enter Title"
                                                value={data?.title}
                                                onChange={(e) => setData({ ...data, title: e })}
                                            />
                                            <DayNightPicker
                                                value={{ days: data?.days ?? 2, nights: data?.nights ?? 1 }}
                                                onChange={(v) => setData({ ...data, days: v?.days, nights: v?.nights })}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center">
                                        <DateSelectCustom
                                            className="me-1"
                                            outline={true}
                                            placeholder={("Start Date")}
                                            label="Start Date"
                                            value={data?.startDate}
                                            onChange={(v) => {
                                                setData({ ...data, startDate: v });
                                            }}
                                        />
                                        <DateSelectCustom
                                            className="ms-1"
                                            outline={true}
                                            placeholder={("End Date")}
                                            label="End Date"
                                            value={data?.endDate}
                                            onChange={(v) => {
                                                setData({ ...data, endDate: v });
                                            }}
                                        />
                                        </div>
                                        <Editor
                                            value={data?.itinerary ?? ""}
                                            asLabel={false}
                                            onChange={(v) => setData({ ...data, itinerary: v })}
                                        />
                                    </>
                                )}
                            </Col>
                            <Col sm={12} md={6}>
                                {editId ? <>

                                    <FlightsGrid
                                        passengers={data?.passengers}
                                        selectedFlights={[]}
                                        tour_id={editId}
                                        getFlightRecords={(flights) => {
                                            setFlights(flights);
                                            setFee(prevFeeObj => {
                                                prevFeeObj.clientFee.flights = 0;
                                                prevFeeObj.supplierFee.flights = 0;
                                                return flights?.reduce((prev, curr) => {
                                                    return {
                                                        clientFee: {
                                                            ...prev.clientFee,
                                                            flights: (getValue(prev?.clientFee?.flights, 0) + getValue(curr?.clientFee?.subTotal ?? 0) - getValue(curr?.clientFee?.totalDiscounts, 0) + getValue(curr?.clientFee?.totalTaxes, 0)),
                                                        },
                                                        supplierFee: {
                                                            ...prev.supplierFee,
                                                            flights: (getValue(prev?.supplierFee?.flights, 0) + getValue(curr?.supplierFee?.subTotal ?? 0) - getValue(curr?.supplierFee?.totalDiscounts, 0) + getValue(curr?.supplierFee?.totalTaxes, 0)),

                                                        }
                                                    };
                                                }, prevFeeObj);
                                            });
                                        }}

                                    />

                                    <StayGrid
                                        passengers={data?.passengers}
                                        selectedStays={data?.stays}
                                        tour_id={editId}
                                        getStaysRecords={(stays) => {
                                            setStays(stays);
                                            setFee(prevFeeObj => {
                                                prevFeeObj.clientFee.stays = 0;
                                                prevFeeObj.supplierFee.stays = 0;
                                                return stays?.reduce((prev, curr) => {
                                                    return {
                                                        clientFee: {
                                                            ...prev.clientFee,
                                                            stays: (getValue(prev?.clientFee?.stays, 0) + getValue(curr?.clientFee?.subTotal ?? 0) - getValue(curr?.clientFee?.totalDiscounts, 0) + getValue(curr?.clientFee?.totalTaxes, 0)),
                                                        },
                                                        supplierFee: {
                                                            ...prev.supplierFee,
                                                            stays: (getValue(prev?.supplierFee?.stays, 0) + getValue(curr?.supplierFee?.subTotal ?? 0) - getValue(curr?.supplierFee?.totalDiscounts, 0) + getValue(curr?.supplierFee?.totalTaxes, 0)),

                                                        }
                                                    };
                                                }, prevFeeObj);

                                            });
                                        }}
                                    />

                                    <BookingGrid
                                        passengers={data?.passengers}
                                        getTripRecords={(trips) => {
                                            const tripIds = trips?.map(trip => trip?._id)?.filter(Boolean)
                                            setTripIds(tripIds);
                                            fetchTripBillings(tripIds);
                                        }}
                                        tour_id={editId}
                                    />

                                </> : null}
                            </Col>
                        </Row>

                        <Row className=" mb-3" style={{ marginTop: "1rem" }}>
                            <div>
                                <div style={{ color: "#000", marginBottom: "0.2rem", }}><b>{t("Notes")}</b></div>
                                <textarea
                                    className="inputfield"
                                    rows={5}
                                    style={{ borderRadius: "0.375rem", border: "1px solid #ced4da", outline: "none" }}
                                    value={data?.notes}
                                    onChange={(e) => {
                                        setData({ ...data, notes: e.target.value });
                                    }}
                                >
                                </textarea>
                            </div>
                        </Row>

                        <Row className="justify-content-between d-flex flex-1 gap-2 card p-2">
    
                            <Col className="col-12 col-12 col-sm-3 col-md-4  rounded-2 d-flex align-items-center  ">
                                <div className="zr_normal_fee-label">Total Flights Charges</div>
                                <div className={`flex-grow-1   text-end pe-2 d-flex align-items-center gap-2 justify-content-end fw-bold fs-6`}  >
                                    Rs.
                                    <AmountField
                                        className="zr_normal_fee-field fw-bold"
                                        value={fee?.clientFee?.flights}
                                        type="text"
                                        asLabel={true}
                                    />
                                </div>

                            </Col>

                            <Col className="col-12 col-12 col-sm-3 col-md-4   rounded-2 d-flex align-items-center  ">
                                <div className="zr_normal_fee-label">Total Stay Charges</div>
                                <div className={`flex-grow-1   text-end pe-2 d-flex align-items-center gap-2 justify-content-end fw-bold fs-6`}  >
                                    Rs.
                                    <AmountField
                                        className="zr_normal_fee-field "
                                        value={fee?.clientFee?.stays}
                                        type="text"
                                        asLabel={true}
                                    />
                                </div>
                            </Col>

                            <Col className="col-12 col-12 col-sm-3 col-md-4   rounded-2  d-flex align-items-center ">
                                <div className="zr_normal_fee-label">Total Trips Charges</div>
                                <div className={`flex-grow-1   text-end pe-2 d-flex align-items-center gap-2 justify-content-end fw-bold fs-6`}  >
                                    Rs.
                                    <AmountField
                                        className="zr_normal_fee-field fw-bold"
                                        value={fee?.clientFee?.trips}
                                        type="text"
                                        asLabel={true}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </Form>
                    <div className="zr_layout-footer d-flex justify-content-end align-items-center pe-4" style={{ flexShrink: 0, marginBottom: insidePane ? "20px" : "0px" }}>

                        <div className="d-flex align-items-center">
                            {data?._id && userContent?.rights?.includes(6103) &&
                                <CommunicationButton
                                    tour_id={editId}
                                    customers={data?.passengers}
                                    flights={flights}
                                    stays={stays}
                                />
                            }

                            {editId && userContent?.rights?.includes(6103) && data?._id ?
                                <DeleteButton
                                    loading={loading}
                                    onClick={(e) => handleDelete(e, data?._id)} />
                                : null}

                            {userContent?.rights?.includes(6103) ?
                                <SaveButton
                                    loading={loading}
                                    onClick={handleSave} />
                                : null}

                        </div>
                    </div>
                </div>
            }

            {changeSpaceModal ?
                < ChangeSpaceModal
                    isOpen={changeSpaceModal}
                    selectedSpaceId={data?.space_id}
                    toggleModal={() => { setChangeSpaceModal(false) }}
                    onSave={(space_id) => {
                        handleEditSpace({ payload: { ...data, space_id } })
                        setData({ ...data, space_id })
                    }}
                /> : null}

            {addItineraryModal && (
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={() => setAddItineraryModal(false)}
                    isOpen={addItineraryModal}
                >
                    <ModalComponent.Title>
                        {t("Itineraries")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                        <Itinerary
                            value={""}
                            insidePane={true}
                            multiMode={false}
                            onSelect={onItinerarySelect}
                        />
                    </ModalComponent.Body>
                </ModalComponent>
            )}
        </React.Fragment>
    );
};

export default observer(EditGroupTour);
