import moment from "moment";
import TagPicker from "../../components/TagSelector/TagPicker";
import { renderDate, renderTime } from "../Flight/FlightGridColumns";

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Hotel Name",
      accessor: "hotelName",
      show: true,
      width: 100,
    },
    {
      title: "Booking No",
      accessor: "bookingNo",
      show: true,
      width: 100,
    },

    // {
    //   title: "Customer",
    //   accessor: "customer",
    //   renderer: (dataObj) => (
    //     <div>{dataObj?.customer?.name}</div>
    //   ),
    //   show: true,
    //   width: 100,

    // },
    {
      title: "Guests",
      accessor: "guests",
      renderer: (dataObj) => {
        const guests = dataObj?.guests || [];
        return guests?.map((guest, index) => (
          <div key={index}><span> {" "}{guest?.name},</span> <br/></div>
        ));
      },
      show: true,
      width: 300,

    },

  

    {
      title: "Rooms",
      accessor: "rooms",
      renderer: (dataObj) => {
        const rooms = dataObj?.rooms || [];
        return rooms?.join(", ");
      },
      width: 120,

      show: true,
    },

    {
      title: "Check-In At",
      accessor: "checkInDate",
      renderer: (dataObj) => <div>{renderDate(dataObj.checkInDate ?? "")} {renderTime(dataObj.checkInTime ?? "")}</div> ,
      show: true,
    },

    {
      title: "Check-Out At",
      accessor: "checkInDate",
      renderer: (dataObj) => <div>{renderDate(dataObj.checkOutDate ?? "")} {renderTime(dataObj.checkOutTime ?? "")} </div> ,
      show: true,
    },


    {
      title: "Booked At",
      accessor: "bookedAt",
      renderer: (dataObj) => dataObj?.bookedAt ? <div>{moment().format("DD-MMM-YYYY HH:mm") }</div> : "",
      show: true,
      width: 150,

    },
    // {
    //   title: "Check-In At",
    //   accessor: "checkInAt",
    //   renderer: (dataObj) =>
    //     dataObj.checkInAt
    //       ? moment(dataObj?.checkInAt).format("DD-MMM-YYYY HH:mm")
    //       : "-",
    //   width: 150,
    //   show: true,
    // },
    // {
    //   title: "Check-Out At",
    //   width: 150,
    //   accessor: "checkOutAt",
    //   renderer: (dataObj) =>
    //     dataObj.checkOutAt
    //       ? moment(dataObj?.checkOutAt).format("DD-MMM-YYYY HH:mm")
    //       : "-",
    //   show: true,
    // },
    // {
    //   title: "Fee",
    //   accessor: "fee",
    //   renderer: (dataObj) => (
    //     <div>
    //       {dataObj?.fee?.currency} {dataObj?.fee?.amount}
    //     </div>
    //   ),
    //   show: true,
    // },
    {
      title: "Notes",
      accessor: "notes",
      width: 300,
      show: true,
    },
    {
      title: "Tags",
      accessor: "tags",
      width : 400,

      renderer: (dataObj, rowIndex, pageSize) => {
        return (!!dataObj?.tags ?
          <TagPicker
            kind={"stay"}
            data={dataObj?.tags?.filter(tag => tag && tag?.tag_id)}
          />
          : <span style={{ color: "#ebebeb" }} className="text-muted">{"No Tags Found"}</span>
        );
      },
      show: true,
    },
    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        const statusMap = {
          0: "Planned",
          1: "Booked",
          2: "CheckedIn",
          3: "CheckedOut",
          4: "Cancelled",
        };
        return statusMap[dataObj?.status] || "-";
      },
      show: true,
    },
    

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },

  ],
};

export const sampleData = [{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1627776000000,
  'checkOutDate': 1628121600000,
  'bookedAt': 1627689600000,
  'checkInAt': 1627783200000,
  'checkOutAt': 1628125200000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1629244800000,
  'checkOutDate': 1629590400000,
  'bookedAt': 1629158400000,
  'checkInAt': 1629252000000,
  'checkOutAt': 1629594000000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1626652800000,
  'checkOutDate': 1626998400000,
  'bookedAt': 1626566400000,
  'checkInAt': 1626660000000,
  'checkOutAt': 1627002000000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1629590400000,
  'checkOutDate': 1629676800000,
  'bookedAt': 1629504000000,
  'checkInAt': 1629597600000,
  'checkOutAt': 1629680400000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1625184000000,
  'checkOutDate': 1625443200000,
  'bookedAt': 1625097600000,
  'checkInAt': 1625191200000,
  'checkOutAt': 1625446800000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1624492800000,
  'checkOutDate': 1624665600000,
  'bookedAt': 1624406400000,
  'checkInAt': 1624500000000,
  'checkOutAt': 1624669200000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1626134400000,
  'checkOutDate': 1626566400000,
  'bookedAt': 1626048000000,
  'checkInAt': 1626141600000,
  'checkOutAt': 1626570000000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1627776000000,
  'checkOutDate': 1628121600000,
  'bookedAt': 1627689600000,
  'checkInAt': 1627783200000,
  'checkOutAt': 1628125200000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1627862400000,
  'checkOutDate': 1628035200000,
  'bookedAt': 1627776000000,
  'checkInAt': 1627869600000,
  'checkOutAt': 1628038800000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1628726400000,
  'checkOutDate': 1628899200000,
  'bookedAt': 1628640000000,
  'checkInAt': 1628733600000,
  'checkOutAt': 1628902800000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1628035200000,
  'checkOutDate': 1628380800000,
  'bookedAt': 1627948800000,
  'checkInAt': 1628042400000,
  'checkOutAt': 1628384400000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1625702400000,
  'checkOutDate': 1625788800000,
  'bookedAt': 1625616000000,
  'checkInAt': 1625709600000,
  'checkOutAt': 1625792400000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1624492800000,
  'checkOutDate': 1624665600000,
  'bookedAt': 1624406400000,
  'checkInAt': 1624500000000,
  'checkOutAt': 1624669200000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1626739200000,
  'checkOutDate': 1626825600000,
  'bookedAt': 1626652800000,
  'checkInAt': 1626746400000,
  'checkOutAt': 1626829200000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1626566400000,
  'checkOutDate': 1626825600000,
  'bookedAt': 1626480000000,
  'checkInAt': 1626573600000,
  'checkOutAt': 1626829200000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1624924800000,
  'checkOutDate': 1625184000000,
  'bookedAt': 1624838400000,
  'checkInAt': 1624932000000,
  'checkOutAt': 1625187600000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1628208000000,
  'checkOutDate': 1628640000000,
  'bookedAt': 1628121600000,
  'checkInAt': 1628215200000,
  'checkOutAt': 1628643600000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1624665600000,
  'checkOutDate': 1624752000000,
  'bookedAt': 1624579200000,
  'checkInAt': 1624672800000,
  'checkOutAt': 1624755600000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1625270400000,
  'checkOutDate': 1625356800000,
  'bookedAt': 1625184000000,
  'checkInAt': 1625277600000,
  'checkOutAt': 1625360400000
},
{
  '_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'space_id': '60b8a9c7e4b0a9c7e4b0a9c7',
  'customer': { 'name': 'John Doe' },
  'hotel_id': 'hotel123',
  'guests': [{ 'name': 'Jane Smith' }, { 'name': 'Michael Johnson' }],
  'hotelName': 'Hilton Hotel',
  'rooms': ['101', '102'],
  'fee': { 'amount': 1000, 'currency': 'Rs' },
  'notes': 'Special requests: Non-smoking room',
  'status': 2,
  'checkInDate': 1629158400000,
  'checkOutDate': 1629244800000,
  'bookedAt': 1629072000000,
  'checkInAt': 1629165600000,
  'checkOutAt': 1629248400000
}];

