import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
    CheckButton,
    DataGrid,
    DeleteLink,
    EditLink,
    Layout
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditGroupTour from "./EditGroupTour";
import { STRUCTURE } from "./GroupTourConstant";
import { GridColumns } from "./GroupTourGridColumns";
import GroupTourService from "./GroupTourService";
import GroupTourSidebar from "./sideBar/GroupTourSidebar";
import GroupTourSidebarService from "./sideBar/GroupTourSidebarService";
import "./style.css";
import { TOUR_TOGGLE_DATA } from "../BookingsNew/utils/constants";

const GroupTour = ({ forClone, value, insidePane, multiMode, onSelect = () => { } }) => {
    const { userContent } = useContext(UserContext);
    let { grpTour_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { showConfirm, showError, showMessage } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(grpTour_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/tour/create" || grpTour_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await GroupTourService.fetch(filterUrl);
            setLoading(false);
        } catch (e) {
            showError(e);
            setLoading(false);
        }
    };
    const preFetchGrid = async () => {
        fetchData(filterURL);
      }
    useEffect(() => {
        // if (GroupTourSidebarService.selectedFolder?._id) {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE, insidePane)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
        // }
    }, [GroupTourSidebarService.selectedFolder?._id])


    useEffect(() => {
        setEditID(grpTour_id)
    }, [grpTour_id])

   
    const onDelete = async (event, id) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await GroupTourService.delete(id);
                setLoading(false);
                setShowDetailPage(false);
                showMessage(t("Deleted"), t("Deleted"));
            } catch (e) {
                showError(e);
                setLoading(false);
            }
        }
    };
    const onBulkDelete = async () => {
        if (
            await showConfirm({
                title: t("Do you want to delete all the selected record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            setLoading(true);
            try {
                await GroupTourService.bulkDelete(selectedIDs);
                setLoading(false);
                showMessage(t("Deleted"), t("Deleted Successfully"));
            } catch (e) {
                setLoading(false);
                showError(e);
            }
        }
    };




    return (
        <div className="d-flex">
            {loading ?
                <div className="loading-backdrop">
                    <ThreeBounce size={30} color="pink" />
                </div> : <></>}
            <div style={{ minWidth: "200px" }} className={insidePane ? "" : "page-content"}>
                <GroupTourSidebar module="events" />
            </div>

            <div className="flex-1" style={{ width: "calc(100vw - 250px)" }}>
                <Layout
                    hideAdd={!userContent?.rights?.includes(6101) || GroupTourSidebarService.records.length == 0}
                    large
                    toggleData={TOUR_TOGGLE_DATA(t, "tours")}
                    hideSearch
                    showToggle
                    compact
                    onToggle={(selectedValue) => {
                        if (selectedValue === "tours") {
                            navigate(`/tour`);
                        } else if (selectedValue === "trips") {
                            navigate(`/trips`);
                        } else if (selectedValue === "stay") {
                            navigate("/stay");
                        } else if (selectedValue === "flights") {
                            navigate("/flight");
                        } else if (selectedValue === "guests") {
                            navigate("/guests");
                        } else if (selectedValue === "calender") {
                            navigate("/tours/calender");
                        }
                    }}
                    showDetailPage={showDetailPage}
                    backDetailPage={async () => {
                        setShowDetailPage(false);
                        if (!insidePane) navigate("/tour");
                        setEditID(null);
                    }}
                    title={t("Tours")}
                    filterValues={filterObject}
                    filterStructure={STRUCTURE}
                    onApplyFilter={fetchData}
                    onAddClick={() => {
                        if (!insidePane) navigate(`/tour/create`);
                        setShowDetailPage(true);
                        setEditID(null);
                    }}
                    insidePane={insidePane}
                    page={GroupTourService.page}
                    rows={GroupTourService.rows}
                    total={GroupTourService.total}
                    fetch={GroupTourService.fetch}
                    hideheader={true}
                    hidefooter={true}
                >
                    <Layout.ActionMenu>
                        <div className="layout-action-menu">
                            <DropdownMenu>
                                <>
                                    {userContent?.rights?.includes(5903) ?
                                    <>
                                        <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                                        <div class="dropdown-divider"></div>
                                        </>
                                        : null}
                                    <DropdownItem>{t("Upload")}</DropdownItem>
                                    <div class="dropdown-divider"></div>
                                    <DropdownItem> Download </DropdownItem>
                                </>

                            </DropdownMenu>

                        </div>
                    </Layout.ActionMenu>
                    <Layout.Table>
                        {
                            GroupTourSidebarService.records?.length ?
                                <DataGrid
              gridLoading={loading}
                                    data={[...(GroupTourService.records ?? [])]}
                                    headers={GridColumns}
                                    total={GroupTourService.total}
                                    uiPreference="tour.grid"
                                    onSaveUiPref={preFetchGrid}

                                    onSelectChange={(v) => {
                                        onSelect(v)
                                        setSelectedIDs(v)
                                    }}
                                    selectedIDs={selectedIDs}
                                    page={GroupTourService.page}
                                    rowsPerPage={GroupTourService.rowsPerPage}
                                    onPaginationChange={GroupTourService.onPaginationChange}
                                    renderLastCol={(tour) => {
                                        return (
                                            <>
                                                {/* {userContent?.rights?.includes(6102) && */}
                                                <EditLink
                                                    onClick={() => {
                                                        if (!insidePane)
                                                            navigate(`/tour/edit/${tour?._id}`);
                                                        setEditID(tour?._id);
                                                        setShowDetailPage(true);
                                                    }}
                                                />
                                                {/* }
                                                {userContent?.rights?.includes(6103) ? */}
                                                <DeleteLink
                                                    onClick={(event) => onDelete(event, tour?._id)}
                                                />
                                                {/* : null} */}

                                                {!multiMode && insidePane ? (
                                                    <CheckButton
                                                        onClick={async () => {
                                                            try {
                                                                if (forClone) {
                                                                    if (
                                                                        await showConfirm({
                                                                            title: t("Do you want to clone tour?"),
                                                                            description: t("This will clone tour into lead."),
                                                                        })
                                                                    ) {
                                                                        onSelect(tour?._id);
                                                                        return
                                                                    }
                                                                } else {
                                                                    onSelect(tour?._id);
                                                                }
                                                            } catch (error) {
                                                                console.log(error)
                                                            }
                                                        }}
                                                    />
                                                ) : null}
                                            </>
                                        );
                                    }}
                                /> : <div className="d-flex justify-content-center h3 py-5 my-5">
                                    Create Folder to Create Tour
                                </div>
                        }

                    </Layout.Table>
                
                    <Layout.DetailPageBody>
                        <EditGroupTour
                            onSave={() => { fetchData(filterURL) }}
                            onDelete={() => {
                                setShowDetailPage(false)
                                fetchData(filterURL)
                            }}
                            insidePane={insidePane}
                            onBack={() => {
                                setShowDetailPage(false);
                                if (!insidePane) navigate("/flight");
                                setEditID(null);
                            }}
                            editId={editID}
                        />
                    </Layout.DetailPageBody>

                    {/* <Layout.DetailPageFooter>
                        <div className="d-flex align-items-center">
                            {detailData?._id && userContent?.rights?.includes(6103) && <Button
                                onClick={() => { CommunicationService?.toggleVisibility(editID) }}
                                className="d-flex align-items-center"
                                style={{ height: "28px", padding: "2px 10px 2px 4px" }}
                                color="info"
                            >
                                <IoIosSend className="me-2" style={{ fontSize: '20px' }} />
                                <span>{t(`Send Message`)}</span>
                            </Button>}

                            {editID && userContent?.rights?.includes(6103) && detailData?._id ?
                                <DeleteButton
                                    loading={loading}
                                    onClick={(e) => onDelete(e, detailData?._id)} />
                                : null}

                            {userContent?.rights?.includes(6102) ?
                                <SaveButton
                                    loading={loading}
                                    onClick={onSave} />
                                : null}

                        </div>

                    </Layout.DetailPageFooter> */}
                </Layout>
            </div>
        </div>
    );
};
export default observer(GroupTour);
