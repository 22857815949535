export const ENDPOINTS = {

  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/trip/grid?`;
    if (sizePerPage) url += `&rows=${sizePerPage}`;
    if (filterUrl) url += `&${filterUrl}`;
    if (page) url += `&page=${page}`
    return url;
  },
  get: (id) => `/api/trip/${id}/detail`,
  getBillings: (id) => `/api/billing/grid?rows=-1&page=1&trip_id=${id}`,
  getBillingsByTrips: () => `/api/billing/grid?rows=-1&page=1`,
  save: `/api/trip/create`,
  bulkUpload: `/api/trip/upload`,
  createBillings: `/api/billing/create`,
  deleteBilling: (_id) => `/api/billing/${_id}/delete`,
  delete: (id) => `/api/trip/${id}/delete`,
  bulkDelete: (id) => `/api/trip/delete`,
  customerGrid: (filterUrl) => {
    let url = `/api/customer/grid?`;

    if (filterUrl) {
      url += `${filterUrl}`
    }
    return url;
  },
  update: `/api/trip/update`,
  updateBilling: `/api/billing/update`,
  fieldList: `/api/trip/field/list`,
  paymentsGrid: (trip_id) => {
    return `/api/payment-request/grid?rows=-1&page=1&status=in[1,2]&trip_id=${trip_id}`
  },

  download: (page, rowsPerPage, filterURL, template_id) => {
    let url = `/api/trip/grid?downloadTemplate_id=${template_id}&rows=-1&`;
    if (filterURL) url += filterURL;
    return url;
  },

  createInvoice: (client_id, vendor_id, invoiceType) =>
    `/api/invoice/create/invoice?client=${client_id}&vendor=${vendor_id}&invoiceType=${invoiceType}`,
  startTrip: `/api/trip/start`,
  sendMessage: `/api/message/data/send`,
  getMessageContent: (trip_id) => `/api/message/data/${trip_id}/content`,

  print: `/api/bulk/duty/print`,

  modifyStatus: `/api/trip/status/modify`,
  stopTrip: `/api/trip/stop`,
  calculateBilling: '/api/billing/calculate',
  saveAll: '/api/trip/create-all',
  updateAll: '/api/trip/update-all',

};
export const TRIP_CHARGES = "TRIP CHARGES";

export const TSTRUCTURE = [
  {
    label: "Name",
    description: "description...",
    filterLabel: "Tariff Name",
    filterName: "tariffName",
    type: "text",
  },
  {
    label: "Client",
    description: "description...",
    filterLabel: "Client Name",
    filterName: "client",
    type: "clientSelect",
  },
  {
    label: "Vehicle Type",
    description: "description...",
    filterLabel: "Vehicle Type",
    filterName: "vehicleType",
    type: "vehicleSelect",
  },
  {
    label: "Currency",
    description: "description...",
    filterLabel: "Currency",
    filterName: "currency",
    type: "currencySelect",
  },
  {
    label: "Via City",
    description: "description...",
    filterLabel: "Via City",
    filterName: "city",
    type: "citySelect",
  },
];

export const SELECTSTRUCTURE = {
  bookingType: [
    {
      label: "Local",
      value: 1
    },
    {
      label: "Rental",
      value: 2
    },
    {
      label: "Shared",
      value: 3
    },
    {
      label: "Transfer",
      value: 4
    },
    {
      label: "Self Drive",
      value: 5
    },
    // {
    //   label: "Route",
    //   value: 6
    // },
    {
      label: "Airport Pick",
      value: 7
    },
    {
      label: "Airport Drop",
      value: 8
    },
    {
      label: "Tour Package",
      value: 9
    },
    {
      label: "Outstation OneWay",
      value: 10
    },
    {
      label: "Outstation Returning",
      value: 11
    },
    {
      label: "Lease",
      value: 12
    },
    {
      label: "Route",
      value: 21,
      values:[21,22,23,24]
    },
    // {
    //   label: "Route",
    //   value: 22
    // },
    // {
    //   label: "Route",
    //   value: 23
    // },
    // {
    //   label: "Route",
    //   value: 24
    // },
  ],
  accountType: [
    { label: "Saving", value: "Saving" },
    { label: "Current", value: "Current" },
    { label: "Cash", value: "Cash" },
    { label: "Credit Card", value: "CreditCard" },
    { label: "Debit Card", value: "DebitCard" },
    { label: "Fuel Card", value: "FuelCard" },
  ],
  status: [
    { label: "Active", value: 0 },
    { label: "Inactive", value: 1 },
  ],
  paymentPercent: [
    { label: "UnPaid", value: null },
    { label: "Partially Paid", value: "btw[1,9999]" },
    { label: "Paid", value: "10000" },
  ],
  complianceStatus: [
    { label: "Compliant", value: 1 },
    { label: "Not Compliant", value: 2 },
    { label: "Due", value: 3 },
  ],
  clientInvoice: [
    { label: "Invoiced", value: "exists" },
    { label: "Not Invoiced", value: "notExists" },
  ],
}


export const STRUCTURE = [
  {
    label: "Duty Slip No",
    // description: "description...",
    filterLabel: "Duty Slip No",
    filterName: "dutySlipNo",
    type: "text",
  },
  {
    label: "Invoice No.",
    // description: "description...",
    filterLabel: "Invoice No.",
    filterName: "clientInvoiceNo",
    type: "text",
  },
  {
    label: "Tags",
    // description: "description...",
    filterLabel: "Tags",
    filterName: "tags.tag_id",
    type: "tagsSelect",
  },
  {
    label: "Client",
    // description: "description...",
    filterLabel: "Client",
    filterName: "client.client_id",
    type: "clientSelect",
  },
  // {
  //   label: "Booking Title",
  //   description: "description...",
  //   filterLabel: "Booking Title",
  //   filterName: "title",
  //   type: "text",
  // },
  // {
  //   label: "Driver",
  //   // description: "description...",
  //   filterLabel: "Driver",
  //   filterName: "driver._id",
  //   type: "driverSelect",
  // },

  {
    label: "Driver Name",
    filterLabel: "Driver Name",
    filterName: "driver.name",
    type: "text",
  },


  {
    label: "Driver Phone",
    filterLabel: "Driver Phone",
    filterName: "driver.phone.phone",
    type: "text",
  },
  {
    label: "Trip No",
    // description: "description...",
    filterLabel: "Trip No",
    filterName: "tripNo",
    type: "text",
  },
  {
    label: "Vehicle Reg No",
    // description: "description...",
    filterLabel: "Vehicle No",
    filterName: "vehicle.regNo",
    type: "text",
  },
  {
    label: "Vehicle Supplier",
    // description: "description...",
    filterLabel: "Vehicle Supplier",
    filterName: "vehicle.provider_id",
    type: "supplierSelect",
  },

  {
    label: "Booked By",
    // description: "description...",
    filterLabel: "Booked By",
    filterName: "bookedBy.customer_id",
    type: "userSelect",
  },

  {
    label: "Passenger Name",
    // description: "description...",
    filterLabel: "Passenger Name",
    filterName: "passengers.0.name",
    type: "text",
  },

  {
    label: "Passenger Email",
    // description: "description...",
    filterLabel: "Passenger Email",
    filterName: "passengers.0.email",
    type: "text",
  },

  {
    label: "Passenger Number",
    // description: "description...",
    filterLabel: "Passenger Number",
    filterName: "passengers.0.phone.phone",
    type: "phone",
  },

  {
    label: "Source  City",
    // description: "description...",
    filterLabel: "Source  City",
    filterName: "src.city",
    type: "text",
  },
  {
    label: "Trip Type",
    // description: "description...",
    filterLabel: "Trip type",
    filterName: "tripType",
    type: "select",
    data: SELECTSTRUCTURE.bookingType
  },
  {
    label: "Serving Company",
    // description: "description...",
    filterLabel: "Serving Company",
    filterName: "vendorCompany_id",
    type: "entitySelect",
  },
  {
    label: "Invoice Status",
    // description: "description...",
    filterLabel: "Client Invoice",
    filterName: "clientInvoice_id",
    type: "select",
    data: SELECTSTRUCTURE.clientInvoice
    // type: "check",
    // checked: "exists",
    // notChecked: "notExists"
  },
  {
    label: "Payment Status",
    filterLabel: "Payment Status",
    filterName: "paymentPercent",
    type: "select",
    data: SELECTSTRUCTURE.paymentPercent
  },

  // {
  //   label: "Invoice Status",
  //   description: "description...",
  //   filterLabel: "Invoice Status",
  //   filterName: "status",
  //   type: "select",
  //   // data = 
  // },
];

export const RATE_BASE_ITEMS=[
  "BaseFare",
  "DistanceFare",
  "TimeFare",
  "DriverAllowance",
  "NightCharge",
  "GarageFare"
]

export const ROUTE_TYPE_DATA=[
  { label: "Standard ", value:21 },
  { label: 'Home Pick up', value: 22 },
  { label: 'Home Drop off', value: 23 },
  { label: 'Lease', value: 20 },
  { label: 'Shuttle', value: 24 },
]

export const isRouteType = (type) => [20, 21, 22, 23, 24]?.includes(type);
export const isPickupOrDropRouteType = (type) => [22, 23]?.includes(type);

export const TRIP_TYPE = {
  'Local': 1,
  'Rental': 2,
  'Shared': 3,
  'Transfer': 4,
  'Self Drive': 5,
  'Route': 6,
  'Airport Pick': 7,
  'Airport Drop': 8,
  'Tour Package': 9,
  'Outstation OneWay': 10,
  'Outstation Returning': 11,
  'Lease': 12,
  'Route-Standard': 21,
  'Route-PickUp': 22,
  'Route-Drop': 23,
  'Route-Shuttle': 24,
  'Route-Lease': 20
}

// LATEST BBOKING STATUS MAPPING
export const BOOKING_STATUS_MAP = {
  0: { label: "New", color: "#AEB6BF"},
  1: { label: "Confirmed", color: "#2980B9" },
  2: { label: "Ready", color: "#A569BD" },
  3: { label: "Dispatched", color: "#FFBF00" },
  4: { label: "Live", color: "#FF7F50" },
  5: { label: "Completed", color: "#52BE80" },
  6: { label: "Cancelled", color: "#F1948A" },
  7: { label: "Error", color: "#CB4335" },
  8: { label: "Paused", color: "#CCCCFF" },
  9: { label: "Expired", color: "#7B241C" },
  10: { label: "Rejected", color: "#7B241C" },
  19: { label: "Cancelled", color: "#207645" },
  20: { label: "Completed", color: "#207645" },
  28: { label: "Completed", color: "#95A5A6" },
  29: { label: "Cancelled", color: "#95A5A6" },
  30: { label: "Completed", color: "#95A5A6" }
};


//type 1-standard,2 home-pick-up,2-home-drop-off,4-shuttle
export const getRouteTypeLabel = (type) => {
  switch (type) {
      case 22:
          return 'Home Pickup';
      case 23:
          return 'Home Dropoff';
      case 24:
          return 'Shuttle';
      default:
          return '';
  }
}


