import React, { Children, useCallback, useContext } from "react";
import { Table } from "react-super-responsive-table";
import { useLocation, useNavigate } from "react-router-dom";
import DetailPageModal from "./DetailPage/DetailPageModal";
import "./style.css";
import {
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Label,
  InputGroup,
  Alert
} from "reactstrap";
import { AddButton, Breadcrumb, Filter, ToggleSelector, ClientSelect, DateRange, DownloadButton } from "../../components";
import SelectButton from "../Buttons/Icons/SelectButton";
import AddNewButton from "../Buttons/AddNewButton";
import { I18nContext } from "../../store/context/i18nContext";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from "moment";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LayoutHeaderDropdown from "../Dropdowns/LayoutHeaderDropdown";
import { FaFilter } from "react-icons/fa6";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";
import DownloadIconButton from "../Buttons/DownloadIconButton";
import Views from "../Views";
import Space from "../space/Space";


dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const TimeFilter = [
  { label: "Ascending", value: true },
  { label: "Descending", value: false },
]



const Layout = ({
  compact,
  children,
  insidePane,
  headingCentre,
  classNameForDetailPage,
  styleDetailPageContainer,
  backDetailPage,
  showDetailPage,
  small,
  medium,
  large,
  layoutForm,
  hideTitleBar,
  hideheader,
  hidefooter,
  title,
  breadcrumb,
  onDownloadClick,
  tableCardClass = "",
  customTitleComponent = () => <></>,
  filterStructure,
  filterValues,
  onApplyFilter,
  hideSearch,
  hideAdd,
  onAddClick,
  onAddNewClick,
  onSelectClick,
  customOnAddClick,
  gridLoading,
  showToggle,
  toggleType,
  toggleData,
  showDateRange,
  showClientSelect,
  onToggle,
  clientValue,
  onChangeClient,
  leftChildComponent,
  setData,
  data = [],
  fromDate,
  tillDate,
  setTillDate,
  setFromDate,
  spaceConfig,
  dateRangeClass,
  defaultValue,
  showTimeFilter = false,
  grouping=false,
  showViews = false,
  onTimeSortOrderChange = () => { },
  navigateOnFilter = true,
  module="",
  defaultFilters=[]
}) => {
  let _actionMenu,
    _theadFull,
    _onAlert,
    _tbodyFull,
    _table,
    _detailPageTitle,
    _detailPageBody,
    _detailPageFooter,
    _leftChild;

  const { t } = useContext(I18nContext);
  let navigate = useNavigate();
  const location = useLocation()


  const getCustomTimeFilterValue = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const sortBy = queryParams.get('sortBy');
    const sortAsc = queryParams.get('sortAsc');
    // console.log(sortAsc, sortBy)
    if (!sortBy || sortAsc == 'true') {
      return <PiCaretUpBold />
    }
    return <PiCaretDownBold />

  }, [location.search]);

  Children.forEach(children, (child) => {
    if (child.type === Layout?.ActionMenu) {
      return (_actionMenu = child);
    }
    if (child.type === Layout?.TheadFull) {
      return (_theadFull = child);
    }
    if (child.type === Layout?.TbodyFull) {
      return (_tbodyFull = child);
    }
    if (child.type === Layout?.Table) {
      return (_table = child);
    }
    if (child.type === Layout?.DetailPageTitle) {
      return (_detailPageTitle = child);
    }
    if (child.type === Layout?.DetailPageBody) {
      return (_detailPageBody = child);
    }
    if (child.type === Layout?.DetailPageFooter) {
      return (_detailPageFooter = child);
    }
    if (child.type === Layout?.OnAlert) {
      return (_onAlert = child);
    }
    if (child.type === Layout?.LeftChild) {
      return (_leftChild = child);
    }


  });
  return (
    <React.Fragment>
      <div className={`page-content ${insidePane ? " p-0 m-0 " : " "}`} style={{ backgroundColor: "#FCFCFF" }}>
        {headingCentre}

        <DetailPageModal
          classNameForDetailPage={classNameForDetailPage}
          insidePane={insidePane}
          style={styleDetailPageContainer}
          back={backDetailPage}
          _body={_detailPageBody}
          _title={_detailPageTitle}
          _footer={_detailPageFooter}
          show={showDetailPage}
          small={small}
          hideheader={hideheader}
          hidefooter={hidefooter}
          medium={medium}
          large={large}
          grouping={grouping}
        />
        <Row>
          <Col className={"left-scroll-independent col-md-12"} style={{ height: insidePane ? "calc(100% )" : "calc(100vh - 120px)" }}>
            {_onAlert && (
              <UncontrolledDropdown className="mt-3">
                {_onAlert}
              </UncontrolledDropdown>
            )}
            <div className={`${!insidePane ? "card" : ""} mx-0 pt-1`} style={{ height: layoutForm ? `97%` : "" }}>
              {!hideTitleBar && (
                <CardTitle className="pt-3 ps-4 pe-3 pb-0">
                  <div className={`d-flex flex-column   col-12  mt-3 mt-sm-0
 mb-0`}>
                    <div className="d-flex flex-row gap-3 col-12 justify-content-between">
                      <div className="d-flex flex-1 gap-2 col-12 align-items-center">
                        {/* {title && <Breadcrumb title={title} />} */}
                        {breadcrumb && <Breadcrumb title={breadcrumb} />}
                        {customTitleComponent()}


                        {spaceConfig?.show ? (
                          <Space
                            showEverything={spaceConfig?.showEverything}
                            module={spaceConfig?.module}
                            filterStructure={spaceConfig?.filterStructure}
                            showAsDropdown
                            isCollapsable={spaceConfig?.isCollapsable}
                            toggleCollapse={spaceConfig?.toggleCollapse}
                          />
                        ) : <></>}
                        {showToggle &&
                          <>
                            {filterStructure && (
                              <>
                                {toggleType == "dropdown" ? <LayoutHeaderDropdown
                                  data={toggleData?.module}
                                  value={toggleData?.toggleValue}
                                  onChange={(selectedValue) => { onToggle(selectedValue) }}
                                  style={{
                                    fontSize: '12px',
                                    // minWidth: '300px',
                                    maxWidth: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: compact ? 'max-content' : `calc(${toggleData?.module?.length * 135}px)`
                                  }}
                                /> : <ToggleSelector
                                  style={{
                                    fontSize: '12px',
                                    // minWidth: '300px',
                                    maxWidth: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: compact ? 'max-content' : `calc(${toggleData?.module?.length * 135}px)`
                                  }}
                                  compact
                                  zeroMargin
                                  data={toggleData?.module}
                                  value={toggleData?.toggleValue}
                                  onChange={(selectedValue) => { onToggle(selectedValue) }}
                                />
                                }
                              </>

                            )}
                          </>
                        }

                        {showViews && !insidePane && <div className="ms-1 col-1">
                          <Views filterStructure={filterStructure} module={module}  />
                        </div>
                        }

                        <div className="d-flex gap-2 ">
                          {showDateRange &&
                            <div style={{ minWidth: "220px" }} className={dateRangeClass}>
                              <DateRange
                                defaultValue={defaultValue?.length ? defaultValue : [null, null]}
                                disabled={[false, false]}
                                onChange={(dates) => {
                                  if (dates === null) {
                                    setFromDate(null);
                                    setTillDate(null);
                                  } else {
                                    const formattedFromDate = moment.unix(dates[0]).format('YYYYMMDD');
                                    const formattedTillDate = moment.unix(dates[1]).format('YYYYMMDD');
                                    setFromDate(formattedFromDate);
                                    setTillDate(formattedTillDate);
                                  }
                                }
                                }
                                style={{ padding: '6px ' }}
                              />
                            </div>
                          }
                          {showClientSelect && (
                            <ClientSelect
                              value={clientValue}
                              outline={true}
                              onChange={(v) => onChangeClient(v)}
                              className=" w-100"
                              style={{  zIndex: '4',minWidth:'100px',marginLeft:'10px' }}
                            />
                          )}
                        </div>


                        {showTimeFilter && (
                          <LayoutHeaderDropdown
                            data={TimeFilter}
                            customValue={<div className="d-flex justify-content-between flex-1 align-items-center">
                              <FaFilter />Time
                              <div>
                                {getCustomTimeFilterValue()}
                              </div>
                            </div>}
                            onChange={(selectedValue) => onTimeSortOrderChange(selectedValue)}
                            style={{
                              fontSize: '12px',
                              // minWidth: '300px',
                              maxWidth: '100%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              width: compact ? 'max-content' : `calc(${toggleData?.module?.length * 135}px)`
                            }}
                          />
                        )}



                      </div>

                      <div className="d-flex  ml-auto ms-auto ">
                        {!hideSearch &&
                          <div className="flex-1">
                            {filterStructure && (
                              <Filter
                                isRate={showToggle}
                                className="mb-2 ms-3 "
                                value={filterValues}
                                insidePane={insidePane}
                                structure={filterStructure}
                                applyFunc={onApplyFilter}
                                navigateOnFilter={navigateOnFilter}
                                defaultFilters={defaultFilters}
                              />
                            )}
                          </div>
                        }
                        <div className="d-flex mb-2">
                          {onDownloadClick && (
                            <DownloadIconButton
                              onClick={() => {
                                onDownloadClick();
                              }}
                            />
                          )}
                          {!hideAdd &&
                            <div>
                              {onAddClick && (
                                <AddButton
                                  onClick={() => {
                                    onAddClick();
                                  }}
                                />
                              )}
                            </div>
                          }

                          {onAddNewClick && (
                            <AddNewButton
                              onClick={() => {
                                onAddNewClick();
                              }}
                            />
                          )}
                          {onSelectClick && (
                            <SelectButton
                              onClick={() => {
                                onSelectClick();
                              }}
                            />
                          )}
                          {/* For Template Action Menu */}
                          {customOnAddClick && (
                            <UncontrolledDropdown className="ms-2">
                              <DropdownToggle
                                style={{ padding: "0", color: "transparent" }}
                              >
                                <AddButton
                                  onClick={() => { }}
                                  className={"btn btn-primary"}
                                />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem header>Create Template</DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    customOnAddClick(true);
                                  }}
                                >
                                  For Upload
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    customOnAddClick(false);
                                  }}
                                >
                                  For Download
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                          {_actionMenu && (
                            <UncontrolledDropdown className="ms-2">
                              <DropdownToggle
                                color="secondary"
                                style={{ padding: "0px 1px", height: 30 }}
                              >
                                <div
                                  style={{
                                    display: "inline-block",
                                    color: "primary",
                                  }}
                                >
                                  <i
                                    style={{ fontSize: "24px" }}
                                    className="bx bx-dots-horizontal-rounded"
                                  />
                                </div>
                              </DropdownToggle>
                              {_actionMenu}
                              {/* <div className="layout-action-menu">

                            </div> */}
                            </UncontrolledDropdown>
                          )}
                        </div>
                      </div>

                    </div>

                  </div>
                </CardTitle>
              )}
              <CardBody style={{ height: "100%" }} className={tableCardClass}>
                <>
                  <div className="d-md-flex flex-1">
                    {_leftChild}
                    {/* <div className="d-flex flex-1"> */}
                    <div
                      // className="table-rep-plugin table-responsive mb-0"
                      data-pattern="priority-columns"
                      style={{
                        height: layoutForm ? `calc(100vh - 230px)` : "",
                        width: '100%',
                      }}
                    >

                      {_table}
                      <Table className="table mb-2 striped-table">
                        {_theadFull}
                        {_tbodyFull}
                      </Table>
                    </div>
                    {/* </div> */}
                  </div>
                </>
              </CardBody>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  );
};

Layout.ActionMenu = ({ children }) => <> {children} </>;
Layout.TheadFull = ({ children }) => <> {children} </>;
Layout.TbodyFull = ({ children }) => <> {children} </>;
Layout.Table = ({ children }) => <> {children} </>;
Layout.DetailPageTitle = ({ children }) => <h5 className="mb-0 w-100" style={{ paddingLeft: "9px" }}> {children} </h5>;
Layout.DetailPageBody = ({ children }) => <> {children} </>;
Layout.DetailPageFooter = ({ children }) => <div className=" d-flex justify-content-end"> {children} </div>;
Layout.OnAlert = ({ children }) => <> {children} </>;
Layout.LeftChild = ({ children }) => <> {children} </>;


export default Layout;
