import _isEqual from "lodash/isEqual";
import { toJS } from "mobx";
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { default as ReactTable, default as useTable } from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table/react-table.css";
import {
  CardFooter,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { CrossButton, Pagination, SaveButton } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET, doPUT } from "../../util/HttpUtil";
import ActionMenuModal from "../ActionMenuModal";
import { useOutsideAlerter } from "../dnd/board/helper";
import UIPrefService from "./UIPrefService";
import "./styles.css";
import DataDridUIPreferences from "./DataDridUIPreferences";
import SelectedGridData from "../SelectedGridData";

const ReactTableFixedColumns = withFixedColumns(ReactTable);
const ActionMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideAlerter(dropdownRef, () => {
    setIsOpen(false);
    let tableElements = document.querySelectorAll('.rt-table');
    tableElements[0].style.overflow = "auto";
  });

  return (
    <div>
      <div className="" ref={dropdownRef}>
        <div
          onClick={() => {
            setIsOpen(!isOpen);
            let tableElements = document.querySelectorAll('.rt-table');
            tableElements[0].style.overflow = "visible";
          }}
        >
          <i
            style={{
              cursor: "pointer",
              borderRadius: "5px",
              backgroundColor: isOpen ? "#ccc" : "transparent",
              fontSize: "24px",
              color: "#495057"
            }}
            className="bx bx-dots-horizontal-rounded"
          />
        </div>

        {isOpen && (
          <div className="zr_action_modal">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

const DataGrid = (props) => {
  const {
    data,
    renderLastCol,
    headers,
    onSelectChange,
    selectedIDs = [],
    gridLoading,
    total,
    showActionMenu = () => { return false },
    page,
    rowsPerPage = 40,
    hideCheckboxes = false,
    onPaginationChange,
    uiPreference,
    renderActionMenu,
    allTripBillings,
    fullHeight,
    hidePagination,
    onSaveUiPref = () => { return false },
  }=props;

  const LOADING_DATA = useMemo(() => [{ _id: 1, dataGridLoader: true }, { _id: 2, dataGridLoader: true }, { _id: 3, dataGridLoader: true }, { _id: 4, dataGridLoader: true }], []);
  const memoizedData = useMemo(() => ((gridLoading ? LOADING_DATA : data?.length ? data : [])), [data, gridLoading, LOADING_DATA]);
  const { t } = useContext(I18nContext);
  const { showMessage } = useContext(DialogContext);
  const [gridHeight, setGridHeight] = useState();
  const [showGridConfigModal, setShowGridConfigModal] = useState(false);
  const [fadeClass, setFadeClass] = useState('fade-enter');

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedDataModalVisible, setSelectedDataModalVisible] = useState(false);

  const toggleSelectedModalVisible=()=>setSelectedDataModalVisible(prev=>!prev);
  const [selectedData,setSelectedData]=useState([]);

  const handleValueChange = (newValue) => {
    setSelectedColumns(newValue);
  }

  const setDefaultColumns = (checkBoxColumn) => {
    setUiPrefData({
      ...headers,
      columns: !hideCheckboxes ? [checkBoxColumn, ...headers.columns] : [...headers.columns],
    });
  };

  useEffect(()=>{
    setSelectedData(prev=>prev.filter(d=>selectedIDs.includes(d._id)))
  },[selectedIDs]);


  const prepareUIPref = async (allSelected) => {
    const checkBoxColumn = {
      title: (
        <input
          type="checkbox"
          className="me-2"
          style={{ marginLeft: '4px' }}
          checked={allSelected}
          onChange={() => {
            let allIds = memoizedData.map((d) => {
              return d._id;
            });
            const unselectedIds = Array.from(new Set(selectedIDs?.filter((item) => !allIds.includes(item))));//unselectedIds are ids which are selected on another page not on this page
            //here allselected is coming in opposite form ie if allSelected:true means we have deselected all current page ids ie allIds and vice versa
            const selected=allSelected ? unselectedIds : Array.from(new Set([...selectedIDs, ...allIds]));
            onSelectChange(selected);
            prepareUIPref(!allSelected);
            //setData also
            setSelectedData(prev => {
              return allSelected 
                ? prev?.filter(d => unselectedIds.includes(d._id)) 
                : [
                    ...prev, 
                    ...memoizedData.filter(newItem => !prev.some(prevItem => prevItem._id === newItem._id))
                  ];
            });
          }}
        />
      ),
      accessor: 'check',
      fixed: 'left',
      width: 50,
      show: true,
      required: true,
    };

    try {
      if (headers && headers?.columns) {
        if (uiPreference) {
          const response = UIPrefService?.uiPrefs[uiPreference]
            ? { status: 200, data: { value: UIPrefService?.uiPrefs[uiPreference] } }
            : await doGET('/api/ui-preference/' + uiPreference + '/detail');

          UIPrefService?.updatePrefs(uiPreference, response?.data?.value ?? '[]');

          if (response?.status == 200 && response?.data?.value) {
            const availableColumns = JSON.parse(response?.data?.value);
            if (availableColumns?.length) {// TODO -> will change case here for further GRID PREFERENCE -->true
              const updatedColumns = headers.columns
                .map((column) => ({
                  ...column,
                  show: availableColumns.includes(column.title) || column?.title == 'Action',
                }))
                .sort((a, b) => {
                  const firstIndex = availableColumns.indexOf(a.title);
                  const secondIndex = availableColumns.indexOf(b.title);
                  return firstIndex - secondIndex;
                });

              setSelectedColumns(updatedColumns);
              setUiPrefData({
                ...uiPrefData,
                columns: !hideCheckboxes ? [checkBoxColumn, ...updatedColumns] : [...updatedColumns],
              });
              return;
            } else {
              setDefaultColumns(checkBoxColumn);
              return;
            }
          } else {
            setDefaultColumns(checkBoxColumn);
            return;
          }
        } else {
          setDefaultColumns(checkBoxColumn);
          return;
        }
      } else {
        setUiPrefData({
          ...headers,
          columns: !hideCheckboxes ? [checkBoxColumn] : [],
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkAllSelected = (data, selectedIDs = []) => {
    if (!selectedIDs?.length) {
      return false;
    }

    for (let i = 0; i < data.length; i++) {
      if (!selectedIDs.includes(data[i]._id))
        return false;
    }

    return true;
  }

  const [uiPrefData, setUiPrefData] = useState({ columns: [] });

  useEffect(() => {
    prepareUIPref(checkAllSelected(memoizedData, selectedIDs))
  }, [memoizedData, uiPreference])

  const reSizeTable = () => {
    const divElements = document.getElementsByClassName('ReactTable');
    if (divElements.length > 0) {
      const rect = divElements[0].getBoundingClientRect();
      const newHeight = rect.height + window.innerHeight - rect.bottom - 75;
      const insidePaneHeight = rect.height + window.innerHeight - rect.bottom - 130;
      const tableElements = document.querySelectorAll('.ReactTable .rt-table');
      const tableMaxHeight = tableElements[0]?.scrollHeight + 6;

      const tableHeight = fullHeight ? insidePaneHeight : newHeight > tableMaxHeight ? tableMaxHeight : newHeight;
      if (tableHeight < memoizedData?.length * 42) {
        setGridHeight(tableHeight)
      } else {
        setGridHeight(memoizedData?.length * 42 + 50);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', reSizeTable);
    reSizeTable();
    return () => {
      window.removeEventListener('resize', reSizeTable);
    };
  }, [memoizedData, rowsPerPage, gridLoading, allTripBillings]);

  useEffect(() => {
    setFadeClass('fade-enter-active');
  }, []);

  const renderGridCell = (dataObj, field, renderer, rowIndex, pageSize) => {
    if (field === "check") {
      return renderCheckboxCell(
        {
          dataObj,
          selectedIDs,
          onSelectChange: (newSelectedIDs) => {
            onSelectChange(newSelectedIDs);
            prepareUIPref(newSelectedIDs?.length == data?.length);
          },
          setSelectedData
        });
    }

    if (field === "action") {
      return (
        <div className="d-flex w-100">
          {showActionMenu(dataObj) && (
            <ActionMenuModal>
              {renderActionMenu(dataObj)}
            </ActionMenuModal>
          )}
          {renderLastCol(dataObj)}
        </div>
      )
    } else {
      if (allTripBillings) {
        const d = toJS(dataObj);
        const id = d["_id"];
        const tripBillings = allTripBillings[id];
        if (tripBillings) {
          dataObj["clientAmount"] = tripBillings["clientAmount"];
          dataObj["supplierAmount"] = tripBillings["supplierAmount"];
          dataObj["makeModel"] = tripBillings["makeModel"];
        }
      }

      return dataObj?.dataGridLoader ? <div className="skeleton-loader" /> : renderer ? renderer(dataObj, rowIndex, pageSize) : dataObj[field];
    }
  };

  const getColumns = useCallback(() => {
    return uiPrefData?.columns?.map((column) => {
      return {
        Header: column?.accessor =="check" ? column?.title :   `${t(column.title)}${column.required ? "*" : ""}`,
        accessor: column?.accessor,
        fixed: column.fixed,
        width: column.width,
        show: column.show,
        Cell: (row) => {
          return (
            <div className="height-100 w-100 d-flex align-items-center">
              {renderGridCell(row.original, column.accessor, column.renderer, row?.index, row?.pageSize)}
            </div>
          );
        },
      };
    });
  }, [uiPrefData, allTripBillings, gridLoading]);

  const handleToggleVisibleColumn = (column) => {
    let updatedColumns = uiPrefData?.columns?.map((col) => {
      if (col.accessor === column.accessor) {
        return { ...col, show: !col.show };
      }
      return col;
    });
    setUiPrefData({ ...uiPrefData, columns: updatedColumns });
  };

  const saveGridConfig = async (toSaveColumns) => {
    try {

      let requiredColumns = uiPrefData?.columns
        .filter(col => col?.accessor != "check" && col?.accessor != "action" && col?.required)
        .map(col => col.title);

      let columnsTOSave = toSaveColumns?.map(v => v?.title)

      let emptyCaseColumns = uiPrefData?.columns
      .filter(col => col?.accessor != "check" && col?.accessor != "action")
      .map(col => col.title);

      if(toSaveColumns.length==0){
        columnsTOSave=emptyCaseColumns
      }

      // console.log(requiredColumns, columnsTOSave);
      let body = JSON.stringify([...(columnsTOSave ?? []),...(requiredColumns ?? [])])
      await doPUT("/api/ui-preference/update", { key: uiPreference, value: JSON.stringify(body) });
      UIPrefService?.updatePrefs(uiPreference,body)
      showMessage("Preference updated successfully", "Success");
      setShowGridConfigModal(false);
      prepareUIPref(checkAllSelected(memoizedData, selectedIDs))
      onSaveUiPref()
    } catch (e) {
      console.error(e);
    }
  }

  const { getTableProps } = useTable;

  const getTable = useCallback((...args) => {
    const scrollPosition = 300
    return {
      ...(getTableProps && getTableProps(...args)),
      style: { height: 801, overflow: "auto", scrollTop: scrollPosition },
    };
  }, [getTableProps, gridLoading]);

  const reactTableProps = useMemo(() => {
    return {
      data: memoizedData,
      columns: getColumns(),
      defaultPageSize: memoizedData.length > rowsPerPage ? rowsPerPage : memoizedData.length,
      className: "-striped -highlight",
      showPagination: false,
      getTableProps: getTable,
      sortable: false,
    };
  }, [memoizedData, gridLoading, allTripBillings, rowsPerPage, getColumns, getTable]);

  if (!memoizedData?.length) {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <div className="text-center">
              <div>
              </div>
              <h1 className="display-7  mt-4 mb-3">No records available.</h1>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <>
    <div className={`flex-1 zr_data_grid_${fadeClass}`}>
      <ReactTableFixedColumns key={reactTableProps?.defaultPageSize} {...reactTableProps} style={{ height: gridHeight }} />

      <CardFooter className="p-0 d-flex align-items-center justify-content-end" style={{ backgroundColor: '#FAFAFA' }}>
        {selectedIDs?.length > 0 && <div className="px-2 cursor-pointer btn btn-secondary rounded-5 p-0" onClick={toggleSelectedModalVisible}>{`${selectedIDs?.length} Record${selectedIDs?.length > 1 ? "s" : ""} Selected`}</div>}
    
        {!hidePagination ? <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          total={total}
          uiPreference={uiPreference}
          onChange={onPaginationChange}
        /> : null}
        {(!hidePagination && uiPreference) && <i onClick={() => setShowGridConfigModal(!showGridConfigModal)} className="dripicons-gear h2 mt-2 m-2 px-3" />}


        <DataDridUIPreferences
          data={headers?.columns?.filter(v => ((v?.accessor != "action") && (!v?.required)))}
          isOpen={showGridConfigModal}
          onClose={() => { setShowGridConfigModal(!showGridConfigModal) }}
          onSave={(v) => {
            // console.log(v,"lkjhbk")
            setShowGridConfigModal(!showGridConfigModal)
            saveGridConfig(v);
          }}
          value={selectedColumns?.filter(v => ((v?.accessor != "action") && (!v?.required) && (v?.show)))}
          onChange={handleValueChange}
          title={"Select Columns"}
        />
        {/* <Modal scrollable centered={true} isOpen={showGridConfigModal}>
          <ModalHeader><h4>{t('Show Columns')}</h4></ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column">
              {uiPrefData?.columns?.map((column, idx) => (
                <label className={column.required ? "d-none" : "d-flex align-items-center"} key={idx}>
                  <input
                    type="checkbox"
                    checked={column.show}
                    onChange={() => handleToggleVisibleColumn(column)}
                  />
                  <div className="mx-3 font-weight-bold">{column.title}</div>
                </label>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <SaveButton style={{ height: "36px" }} onClick={saveGridConfig} />
            <CrossButton onClick={() => setShowGridConfigModal(false)} />
          </ModalFooter>
        </Modal> */}
      </CardFooter>
    </div>
    {/* */}
      <Modal isOpen={selectedDataModalVisible} toggle={toggleSelectedModalVisible} fullscreen>
        <ModalHeader toggle={toggleSelectedModalVisible}>Selected Record{selectedIDs?.length > 1 ? "s" : ""}</ModalHeader>
        <ModalBody>
          <SelectedGridData
            selectedIDs={selectedIDs}
            selectedData={selectedData}
            {...props}
            onSelectChange={(id)=>{
              const newSelectedIDs = (selectedIDs ?? []).filter(selected => selected !== id)  ;
              onSelectChange(newSelectedIDs)
              prepareUIPref(newSelectedIDs?.length == data?.length);
              setSelectedData(prev=>prev?.filter(item=>item?._id!==id))
            }}
          />
        </ModalBody>
      </Modal>

    </>
  );
};


const renderCheckboxCell = ({ dataObj, selectedIDs, onSelectChange,setSelectedData }) => (
  <input
    type="checkbox"
    className="ms-2"
    checked={(selectedIDs ?? []).includes(dataObj._id)}
    onChange={() => {
      const newSelectedIDs = (selectedIDs ?? []).includes(dataObj._id)
        ? (selectedIDs ?? []).filter(id => id !== dataObj._id)
        : [...(selectedIDs ?? []), dataObj._id];
        setSelectedData(prev=>{
          const dataWithoutSelected = (prev ?? []).findIndex(item => item?._id === dataObj._id);
          if(dataWithoutSelected!=-1){
            //if present remove it
             return (prev ?? []).filter(item => item?._id !== dataObj._id)
          }
          return [...prev, dataObj];
        })
      onSelectChange(newSelectedIDs);
    }}
  />
);


export default memo(DataGrid, (prev, next) => {
  return _isEqual(prev?.data, next?.data) &&
    _isEqual(prev?.allTripBillings, next?.allTripBillings) &&
    prev?.rowsPerPage == next?.rowsPerPage &&
    prev?.gridLoading == next?.gridLoading &&
    _isEqual(prev?.selectedIDs, next?.selectedIDs);
});
