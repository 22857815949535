import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRandomColor } from '../components/TagSelector/TagPicker';
import TagPickerService from '../components/TagSelector/TagPickerService';
import BookingService from '../pages/Booking/BookingService';
import { checkParrangersAnonymous, formatPassengersSrcDst } from '../pages/BookingsNew/utils/helper';
import { DialogContext } from '../store/context/DialogContext';
import { UserContext } from '../store/context/UserContext';
import { I18nContext } from '../store/context/i18nContext';
import { isRouteType, TRIP_TYPE } from '../pages/Booking/BookingConstant';
import { getValue } from '../util/Util';

const useBookingManagement = () => {
    const { userContent } = useContext(UserContext);
    const { t } = useContext(I18nContext)
    const navigate = useNavigate();
    const { showConfirm, showError, showMessage } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const isCloning = () => queryParams.has('clone_trip_id');

    const validatePassengerDetails = (passengers) => {
        const hasMissingDetails = passengers?.some(passenger => !passenger?.anonymous && !passenger?.name);
        return hasMissingDetails
    };

    const preSaveUpdateBilling = (trip, clientBilling, supplierBillings) => {
        let updatedBillings = [];
        if (clientBilling) {
            const t = BookingService.getBillingProcessedObjectForSaveUpdate(trip?._id && !isCloning() ? trip?._id : null, trip, clientBilling, "Client");
            if (t) updatedBillings.push(t);
        }
        if (supplierBillings?.length) {
            for (let i = 0; i < supplierBillings.length; i++) {
                const t = BookingService.getBillingProcessedObjectForSaveUpdate(trip?._id && !isCloning() ? trip?._id : null, trip, supplierBillings[i], "Supplier");
                if (t) updatedBillings.push(t);
            }
        }
        return updatedBillings;
    }

    const validateTrip = (trip) => {
        if (!trip?.vendorCompany_id) {
            showError(t("Please add serving company"));
            return false;
        }
        if (!trip?.tripType) {
            showError(t("Please add trip type"));
            return false;
        }
        if (!trip?.passengers?.length > 0) {
            showError(t("Please Add Passengers "));
            return false;
        }

        if (validatePassengerDetails(trip?.passengers)) {
            showError(t("Please Enter Passenger Details"));
            return false
        }

        if (!trip?.startTime) {
            showError(t("Please Enter Trip Start Time"));
            return false;
        }
        if (trip?.duration < 0) {
            showError(t(" Trip End Time cannot be less than Trip Start Time"));
            return false;
        }
        if (!trip?.src) {
            showError(t("Please Enter  Pick Up Location"));
            return false;
        }
        if(isRouteType(trip?.tripType)){
            if(!trip?.dst){
                showError(t("Please Enter Destination Location"));
                return false;
            }
            if(!trip?.tripExtn?.routeDNA){
                showError(t("Please Enter Route DNA Details"));
                return false;
            }
            if(!trip?.tripExtn?.routeDNA?.runTimes?.length){
                showError(t("Please Enter Route Run Time Details"));
                return false;
            }
            if(trip?.tripExtn?.routeDNA?.runTimes?.find(runTime => !runTime?.value)){
                showError(t("Please Enter Route Run Time"));
                return false;
            }
        }
        return true;
    }


    const onDelete = async (id, onDeleteCallback = null) => {
       if(loading){
        return;
       }
        if (
            await showConfirm({
                title: t("Do you want to delete record?"),
                description: t("This is an unrecoverable operation."),
            })
        ) {
            try {
                setLoading(true);
                if(id){
                    await BookingService.delete(id);
                }
                showMessage("Deleted", "Deleted");
                onDeleteCallback && onDeleteCallback()
            } catch (e) {
                showError(e);
            } finally {
                setLoading(false);
            }
        }
    };

    const onSave = async ({trip, customerType, clientBilling, supplierBillings, postSave, space_id,fromInvoice, email_id, sms_id, tour_id=null}) => {
        if(loading){
            return;
        }
        const isValid = validateTrip(trip);
        if (!isValid) {
            return;
        }
        const passengers=formatPassengersSrcDst(trip?.passengers,trip?.tripType,trip?.src,trip?.dst);
        trip.passengers=passengers;
        
        const toSaveTrip = {
            ...trip,
            ...(customerType === "walkIn" && { client: null }),
            ...(space_id && {space_id}),
            ...(email_id && {email_id}),
            ...(tour_id && {tour_id}),
            ...(sms_id && {sms_id}),
            passengers: checkParrangersAnonymous(trip?.passengers),
            tripExtn: {
                ...(getValue(trip?.tripExtn, {})),
                ...(isRouteType(trip?.tripType) && {
                    routeDNA: {
                        ...(getValue(trip?.tripExtn?.routeDNA, {})),
                        routeNo: trip?.tripNo,
                        passengers: trip?.passengers,
                        src: trip?.src,
                        dst: trip?.dst,
                        stops: trip?.stops,
                    }
                })
            },
        };

        setLoading(true);
        if (isCloning()) {
            delete toSaveTrip?._id;
            delete toSaveTrip?.version;
            delete toSaveTrip?.tripNo;
            delete toSaveTrip?.createdAt;
            toSaveTrip.billingUpdated = true;
            toSaveTrip.status = 0;
            delete toSaveTrip.createdBy;
            delete toSaveTrip.corp_id;
            delete toSaveTrip.paymentPercent;
            delete toSaveTrip.errorStatus;
            delete toSaveTrip.clientInvoice_id;
            delete toSaveTrip?.clientInvoiceNo;
            delete toSaveTrip.liveTrackingCode;
            delete toSaveTrip.refNo;
            delete toSaveTrip?.tripExtn?._id
            delete toSaveTrip?.tripExtn?.version
        }
        try {
            const updatedBillings = preSaveUpdateBilling(trip, clientBilling, supplierBillings);
            //TODO update billing in separate func and save

            if (trip?._id && !isCloning()) {
                // const bookingID = await  BookingService.edit(toSaveTrip);
                const updatedBooking = await BookingService.editAll({ trips: [toSaveTrip], billings: updatedBillings,billingUpdated:true },fromInvoice);
                showMessage(t("Record Updated successfully"));
                postSave(updatedBooking, 'edit')
            } else {
                // const bookingID = await  BookingService.save(toSaveTrip);
                const billings = isCloning() ? updatedBillings?.map(billing => {
                    const { _id,invoice_id="", ...rest } = billing;  
                    return rest;
                  }) : updatedBillings;

                const updatedBooking = await BookingService.saveAll({ trips: [toSaveTrip], billings,billingUpdated:true });
                //TODO do it in postsave
                showMessage(t("Record saved successfully."));
                postSave(updatedBooking, isCloning() ? 'clone' : 'save')
            }
        } catch (e) {
            showError(e);
        }finally {
            setLoading(false);
        }
    };

    const closeDuty = async (booking, clientBilling, supplierBillings, postSave) => {
        try {
            if(loading)return;

            const isValid = validateTrip(booking);
            if (!isValid) {
                return;
            }
            setLoading(true);
            const filteredTag = TagPickerService?.records?.find(tag => tag.kind === 'trip' && tag?.label?.toLowerCase() === 'verified');
            let tagIds = booking?.tags?.map(tag => tag?.tag_id) ?? [];
            if (filteredTag) {
                if (!tagIds?.includes(filteredTag?._id)) {
                    tagIds = [...tagIds, filteredTag?._id]
                }
            }
            if (!filteredTag) {
                const newTag = {
                    kind: 'trip',
                    color: getRandomColor(),
                    label: 'VERIFIED',
                    group: 'Some Group',
                };
                const response = TagPickerService.save(newTag); // Assume response includes the created tag
                tagIds = [...tagIds, response?.data?._id]
            }
            const toSaveTrip = {
                ...booking,
                status: 5,
                tags: tagIds?.map(tag => ({ tag_id: tag }))
            };
            const updatedBillings = preSaveUpdateBilling(toSaveTrip, clientBilling, supplierBillings);
            const updatedBooking = await BookingService.editAll({ trips: [toSaveTrip], billings: updatedBillings });
            showMessage(t("Duty closed successfully"));
            postSave(updatedBooking);
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }

    //TODO write method here to update a single trip in grid

    const updateBookingInGrid = (updatedBookings, bookings) => {
        return bookings.map(booking => {
            const updatedBooking = updatedBookings.find(b => b._id === booking._id);
            return updatedBooking ? { ...booking, ...updatedBooking } : booking;
        });

    }

    return {
        onDelete,
        onSave,
        preSaveUpdateBilling,
        loading,
        closeDuty,
        updateBookingInGrid
    }
};

export default useBookingManagement;