import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext, useRef } from "react";
import { doGET, doPOST } from "../../util/HttpUtil";
import CityCountrySelect from "./CityCountrySelect";
// import { AutoSuggest } from 'react-autosuggestions';
import Autosuggest from 'react-autosuggest';
import './style.css';
import { I18nContext } from "../../store/context/i18nContext";
import useDebounce from "../../util/Debounce";
import { getValue } from "../../util/Util";

// const allowedPattern = /[^a-zA-Z0-9 _,.&()\%|'\-=:+@/#]/;
const allowedPattern = /[^a-zA-Z0-9 _,.&()\%|'\-=:+@/#–]/;

const getSuggestionValue = suggestion => {
  return suggestion?.value?.addressLine
};

const renderSuggestion = suggestion => {
  return <div>
    {suggestion?.name??""}
  </div>
};

const AddressField = ({
  className,
  value,
  style,
  onChange = () => { },
  label,
  outline,
  cityPlaceHolder,
  addressPlaceHolder,
  inputLargerSize
}) => {

  const [addressLines, setAddressLines] = useState([])
  const [addresses, setAddresses] = useState([])
  const { t } = useContext(I18nContext);

  const findSuggestions = useDebounce(async (key) => {
    try {
      if (key && value?.city && key?.length > 2) {
        // let selectedAddress = addresses.find(a => a?.addressLine === key);
        // if (selectedAddress && selectedAddress?.addressLine) {
        //   setAddresses([]);
        //   setAddressLines([]);
        //   fetchCoordinates(selectedAddress);
        //   return;
        // }

        const response = await doGET(`/api/o/maputil/autocomplete?key=${key}&country=India&city=${value?.city}`);
        if (response.status === 200) {
          if (response?.data?.length) {
            // setAddresses(response?.data);
            setAddressLines(response?.data?.map(address => {
              const addressLine = address?.addressLine ?? '';
              const city = address?.city ?? '';
              const country = address?.country ?? '';

              return {
                name: [addressLine, city, country].filter(Boolean).join(', '),
                value: address
              };
            }));
          }
        } else {
          console.log("Error");
          return []
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  }, 800); 

  const onSuggestionsClearRequested = () => {
     setAddressLines([]);
  };



  const fetchCoordinates = async (selectedAddress) => {
    try {
      if (selectedAddress?.ref_id && selectedAddress?.addressLine) {
        const response = await doPOST(`/api/o/geocode/address/fromId`, selectedAddress)
        if (response.status === 200) {
            return response.data
        }
      }
      return selectedAddress
    } catch (error) {
      console.log("Error", error)
      return selectedAddress
    }
  }
 

  return (
    <div className={className + " d-flex justify-content-center align-items-center "} style={{ ...style, marginTop: 5 }}>
      {outline ? null :
        <div
          style={{ width: "120px", fontSize: 12, fontWeight: "500" }}
        >
          {label ?? t("Address")}
        </div>}
      <div style={{ height: "36px" }} className="input-group flex-1">
        <div className="input-group-prepend col-4" >
          <CityCountrySelect
            style={{ fontSize: '16px' }}
            value={value}
            outline
            placeholder={cityPlaceHolder ?? "City"}
            onChange={(v) => {
              onChange({
                ...value,
                city: v?.value,
                country: v?.country,
                state: v?.state,
                lat: null,
                lng: null,
                // addressLine: v?.addressLine
              })
            }}
          />
        </div>
        <div className={`autosuggestWrapper ${inputLargerSize ? "autosuggestWrapper_larger_size" : ""}`}>
          <label
            htmlFor="addressLine-input"
            style={{
              position: "absolute",
              marginTop: "10px",
              fontSize: "13px",
              fontWeight: "400",
              color: "#7f7f7f",
            }}>
            {!value?.addressLine ? (addressPlaceHolder ?? t("Address Line")) : ""}
          </label>

          <Autosuggest
            suggestions={addressLines}
            onSuggestionsFetchRequested={({ value,reason }) => {
              if(reason==="input-changed"){
                findSuggestions(value)
              }
            }}
            // onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={async(_, { suggestion }) => {
              const address=await fetchCoordinates(suggestion?.value);
               let sanitizedValue = address?.addressLine;
              if (sanitizedValue) {
                if (allowedPattern.test(sanitizedValue)) {
                  sanitizedValue = sanitizedValue.replace(allowedPattern, '');
                }
              }
              onChange({ ...value, ...getValue(address, {}), addressLine: sanitizedValue });
            }}
            inputProps={{
              placeholder: 'Address Line',
              value: value?.addressLine??"",
              onChange: (e,{newValue, method}) => {
                if(method === "type"){
                  onChange({ ...value, addressLine: newValue });
                }
              },
             
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(AddressField);
