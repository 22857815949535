import React, { useCallback, useEffect, useState } from 'react';
import { doGET } from '../../util/HttpUtil';
import Skeleton from 'react-loading-skeleton';
import { FLIGHT_STATUS_MAP } from '../Flight/FlightConstant';
import { STAYS_STATUS_MAP } from '../Stay/StayConstant';
import { BOOKING_STATUS_MAP } from '../Booking/BookingConstant';

const STATUS_MAPS = {
    flight: FLIGHT_STATUS_MAP,
    stay: STAYS_STATUS_MAP,
    booking: BOOKING_STATUS_MAP,
};

const getJourneyStatus = (val, type) => {
    const statusMap = STATUS_MAPS[type];
    if (!statusMap) return;

    const status = statusMap[val];
    return (
        <span className="d-inline-flex align-items-center">
            {status && (
                <span
                    className="rounded-circle me-2"
                    style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: status?.color || '#000000',
                    }}
                />
            )}
            <span className="text-black">
                {status?.label || (['flight', 'booking', 'stay'].includes(type) ? '--' : 'Unknown')}
            </span>
        </span>
    );
};

function TourStatusViewer({ type, id }) {
    const [statusLoading, setStatusLoading] = useState(false);
    const [statusData, setStatusData] = useState();

    const fetchTourStatus = useCallback(async () => {
        if (!id || !type) return;

        setStatusLoading(true);

        try {
            const endpointMap = {
                stay: `/api/stay/grid?tour_id=${id}&sortBy=status&sortAsc=true&rows=1`,
                flight: `/api/flight/grid?tour_id=${id}&sortBy=status&sortAsc=true&rows=1`,
                trip: `/api/trip/grid?tour_id=${id}&sortBy=status&sortAsc=true&rows=1`,
            };

            const response = await doGET(endpointMap[type]);
            const dataArray = response?.data?.rows || [];
            setStatusData(dataArray[0]?.status);
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
            setStatusLoading(false);
        }
    }, [id, type]);

    useEffect(() => {
        fetchTourStatus();
    }, [fetchTourStatus]);

    if (statusLoading) {
        return (
            <div className="d-flex flex-column">
                <Skeleton width={90} height={20} />
            </div>
        );
    }

    return (
        <div>
            {getJourneyStatus(statusData, type === 'trip' ? 'booking' : type)}
        </div>
    );
}

export default TourStatusViewer;
