import React, { useContext } from 'react'
import { InputField, MakeModelSelect, VehicleTypeSelect } from '../../components';
import { BiMinus } from 'react-icons/bi';
import { GoPlus } from 'react-icons/go';
import { multiplyBy10ToPower } from "../../util/Util";
import { I18nContext } from "../../store/context/i18nContext";

const lodash = require('lodash');


const RateItem = ({ rates, item, onChange, addRateItems, deleteRateItems }) => {
    const { t } = useContext(I18nContext);
    const updatedValue = ({ value, index, rateIndex, field }) => {
        onChange({ field, value, index, rateIndex });
    };
    return (
        <div>
            <table className="table-bordered rate" >
                <thead className='text-center'>
                    <tr>
                        <th>Vehicle Type <font color="red">*</font></th>
                        <th>Make model <font color="red">*</font></th>
                        {rates[0]?.rateExtn?.rateItems?.map((rate, index) => {
                            const itemName = typeof rate?.name === 'object' ? rate?.name?.value : rate?.name;
                            if (itemName !== "NightCharge" || (itemName === "NightCharge" && rates[0]?.rateExtn?.nightCharge)) {
                                const camelCaseString = itemName;
                                const formattedRateItemLabel = lodash.replace(camelCaseString, /([a-z])([A-Z])/g, '$1 $2');
                                return (
                                    <th key={index}>{formattedRateItemLabel}</th>
                                );
                            } else {
                                return null;
                            }
                        })}
                        <th style={{ width: "1%" }}>Action</th>
                    </tr>
                </thead>
                <tbody >
                    {rates?.map((rate, index) => ((!item || Number(rate.slabIndex) === item.index) && (
                        <tr
                            key={index}
                        >
                            <td >
                                <VehicleTypeSelect
                                    required={true}
                                    label={"Select Vehicle"}
                                    outline
                                    value={rates[index]?.vehicleType}
                                    onChange={(v) => { updatedValue({ value: v, index: index, field: "vehicleType" }) }}
                                />
                            </td>
                            <td >
                                <MakeModelSelect
                                    totalRows={-1}
                                    outline
                                    value={rates[index]?.makeModel}
                                    onChange={(v) => { updatedValue({ value: v, index: index, field: "makeModel" }) }}
                                // className="col-12 col-sm-6 col-md-4"
                                />
                            </td>
                            {rate?.rateExtn?.rateItems?.map((rateItem, rateIndex) => {
                                const itemName = typeof rateItem?.name === 'object' ? rateItem?.name?.value : rateItem?.name;

                                if (itemName !== "NightCharge" || (itemName === "NightCharge" && rate?.rateExtn?.nightCharge)) {
                                    const payloadValue = rateItem?.value / multiplyBy10ToPower(1, 2);

                                    return (
                                        <td key={rateIndex}>
                                            <InputField
                                                placeholder={t(`${typeof rateItem?.name === 'object' ? rateItem?.name?.label : rateItem?.name}`)}
                                                value={payloadValue}
                                                onChange={(v) => {
                                                    updatedValue({
                                                        value: multiplyBy10ToPower(v, 2),
                                                        index: index, field: itemName, rateIndex: rateIndex
                                                    });
                                                }}
                                                noLabel={true}
                                                type="text"
                                            />
                                        </td>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                            <td style={{ width: "1%" }}>
                                <div
                                    style={{
                                        marginLeft: "5px",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: item ? "center" : "space-between"
                                    }}
                                >
                                    <div
                                        className="btn btn-outline-dark d-flex align-items-center"
                                        style={{
                                            border: "1px solid #707070",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            padding: "1px",
                                            width: "fit-content"
                                        }}

                                    >
                                        <BiMinus onClick={() => deleteRateItems(index, item?.index)} fontSize="16" />
                                    </div>
                                    {!item && <div
                                        className="btn btn-outline-dark d-flex align-items-center"
                                        style={{
                                            border: "1px solid #707070",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            padding: "1px",
                                            width: "fit-content"

                                        }}

                                    >
                                        <GoPlus onClick={() => {
                                            addRateItems()
                                        }} fontSize="16" />
                                    </div>}
                                </div>
                            </td>
                        </tr>
                    )))}
                    {item &&
                        <tr>
                            <td colSpan={7} style={{ borderRight: "none" }}>
                            </td>
                            <td className='add_button_width'>
                                <div
                                    style={{
                                        marginLeft: "5px",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                    }}
                                    className="d-flex justify-content-center"
                                >
                                    <div
                                        className="btn btn-outline-dark d-flex align-items-center"
                                        style={{
                                            border: "1px solid #707070",
                                            borderRadius: "50%",
                                            cursor: "pointer",
                                            padding: "1px",
                                            width: "fit-content"

                                        }}

                                    >
                                        <GoPlus onClick={() => {
                                            console.log(item);
                                            addRateItems(item?.index)
                                        }} fontSize="16" />
                                    </div>
                                </div>
                            </td>

                        </tr>}
                </tbody>
            </table>
        </div>
    )
}

export default RateItem