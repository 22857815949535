import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
import Hotel from "../../../pages/Hotel/Hotel";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import HotelSelectService from "./HotelSelectService";
import { UserContext } from "../../../store/context/UserContext";

const HotelSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  asLabel,
  plain,
  outline,
  wrapping,
  ...props
}) => {
  const [hotels, setHotels] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { userContent } = useContext(UserContext);
  const { t } = useContext(I18nContext)

  const fetchHotels = async () => {
    try {
      await HotelSelectService.fetch();
      if (value == "selectFirst") {
        onChange(toJS(HotelSelectService.hotels)[0])
      }
      setHotels(toJS(HotelSelectService.hotels));
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (HotelSelectService.hotels.length === 0 && userContent?.owner_id && userContent?.adminOf === userContent?.owner_id) {
      fetchHotels();
    }
  }, [HotelSelectService.hotels, userContent?.owner_id]);

  useEffect(() => {
    if (value == "selectFirst") {
      onChange(toJS(HotelSelectService.hotels)[0])
    }
    setHotels(toJS(HotelSelectService.hotels));
  }, [HotelSelectService.hotels]);




  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectHotel = (selectedValue) => {
    onChange(multi ? selectedValue[0] : selectedValue);
    closeModal();
  };

  const selectHotels = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Hotels")} />
      )}
      className={className}
      onChange={onChange}
      data={hotels}
      required={required}
      value={value}
      label={t(`${label || 'Hotels'}`)}
      error={!value ? t(`Please Select ${label || "Hotels"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={(e) => {
          e.stopPropagation()
          if (!isDisabled) {
            openModal()
          }
        }} />
      )}
      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      style={style}
      isDisabled={isDisabled}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Hotels")} />
      )}
      className={className}
      onChange={selectHotels}
      data={hotels}
      required={required}
      value={value ? hotels.filter((c) => value?.includes(c?.value)) : ""}
      label={t("Hotels")}
      error={!value ? t(`Please Select ${label || "Hotels"}`) : ""}
      showErr={showErr}
      plain={plain}
      outline={outline}
      RightComponent={() => (
        <SearchButton onClick={(e) => {
          e.stopPropagation()
          if (!isDisabled) {
            openModal()
          }
        }} />
      )}
    />
  );

  return (
    <>
      {asLabel ? multi ?
        value?.length > 0 ?
          (<div>
            {wrapping ?
              <div>
                {value?.slice(0, wrapping).map((selectedValue, index) => (
                  <span key={selectedValue}>
                    {hotels.find((hotel) => hotel.value === selectedValue)?.label || ""}
                    {index < wrapping - 1 && ", "}
                  </span>
                ))}
                {value.length > wrapping &&
                  ` + ${value.length - wrapping} `
                }
              </div>
              :
              value?.map((selectedValue, index) => (
                <span key={selectedValue}>
                  {hotels.find((hotel) => hotel.value === selectedValue)?.label || ""}
                  {index < value.length - 1 && ", "}
                </span>
              ))
            }
          </div>) : <div style={{ color: "grey" }}>
            {userContent?.owner_id && userContent?.adminOf !== userContent?.owner_id ? `Self` : `No Record Found`}</div>


        : (<div>{hotels?.find((hotel) => hotel?.value == value)?.label ?? <div style={{ color: "grey" }}>{userContent?.owner_id && userContent?.adminOf !== userContent?.owner_id ? `Self` : `No Record Found`}</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"medium"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Hotels")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Hotel
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectHotels : selectHotel}
                  fetchNewData={() => {
                    HotelSelectService.hotels = []
                  }}
                />
              </ModalComponent.Body>
            </ModalComponent>
          )}

          {hotels.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: false })}
        </>
      }
    </>
  );
};

export default observer(HotelSelect);