import React, { useContext } from 'react';
import { Card, CardFooter, Col } from 'reactstrap';
import { AmountField, InputField, TaxSelect } from '../../../components';
import { DialogContext } from '../../../store/context/DialogContext';
import { I18nContext } from '../../../store/context/i18nContext';
import "../style.css";
import { toJS } from 'mobx';

function InvoiceFee({ invoice, onChange, tax, setTax }) {
    const { showError } = useContext(DialogContext);
    const { t } = useContext(I18nContext);

    const updateTax = async (v) => {
        try {
            if (!v) {
                return;
            }
            setTax({ ...v, updated: true })
            if (!invoice?._id) {
                // const inv = await Invoiceservice.calculate({ ...invoice, taxItem: { tax_id: v?._id, taxes: v?.items }, });
                // if(!_.isEqual(inv, invoice)){
                //     onChange(inv)
                // }
            } else {
                onChange({ ...invoice, taxItem: { tax_id: v?._id, taxes: toJS(v?.items) } })
            }
        } catch (error) {
            showError(error)
        }

    }

    return (
        <Card className="">
            <div className="d-flex w-100 justify-content-between pt-1 px-2 align-items-center">
                 <b className='col-12 col-md-7'>Advance</b> 
                <AmountField
                    label={"Advance"}
                    className="zr_normal_fee-field col-12 col-md-5"
                    value={invoice?.advance}
                    onChange={(v) => onChange({ advance: v })}
                    type="text"
                />
            </div>
            <div className="">
                <div className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Sub Total</b>
                    <b>{((invoice?.subTotal ?? 0) / 100).toFixed(2)}</b>
                </div>
                <div className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Non Taxable Amount</b>
                    <b>{((invoice?.nonTaxableAmount ?? 0) / 100).toFixed(2)}</b>
                </div>
                <div className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Total Discounts</b>
                    <b>{((invoice?.totalDiscounts ?? 0) / 100).toFixed(2)}</b>
                </div>
                <div className="d-flex w-100  justify-content-between pt-1 ps-2 pe-2">
                    <Col className="col-7">
                        <b>Tax</b>
                    </Col>
                    <Col className="col-5">
                        <TaxSelect
                            outline
                            noLabel
                            value={invoice?.taxItem?.tax_id}
                            onChange={updateTax}
                            className="m-0"
                            selectZeroTax={false}
                        />
                    </Col>
                </div>

                {invoice.taxItem?.taxes?.map((item) => {
                    return (
                        <div className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                            <b>{item.name} &nbsp;
                                {item?.percent ? `(` + (item?.percent / 100).toFixed(2) + `%)` : ""}
                                &nbsp;
                                {item?.baseAmount ? `(` + (item?.baseAmount / 100).toFixed(2) + `/-)` : ""}
                            </b>
                            <b>{((item?.amount ?? 0) / 100).toFixed(2)}</b>
                        </div>)
                })
                }

                <div className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Total Taxes</b>
                    <b>{((invoice?.totalTaxes ?? 0) / 100).toFixed(2)}</b>
                </div>
                {invoice.taxRoundedUp ?
                <div className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Tax Round Up</b>
                    <b>{((invoice?.taxRoundedUp ?? 0) / 100).toFixed(2)}</b>
                </div>:null
                }

                <CardFooter className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Total</b>
                    <b>{((invoice?.total ?? 0) / 100).toFixed(2)}</b>
                </CardFooter>
                {invoice.totalRoundedDown ?
                <CardFooter className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Round Value</b>
                    <b>{((invoice?.totalRoundedDown ?? 0) / 100).toFixed(2)}</b>
                </CardFooter>
                :""}
                <CardFooter className="d-flex w-100 justify-content-between pt-1 ps-2 pe-2">
                    <b>Amount Payable</b>
                    <b>{((invoice?.amountPayable ?? 0) / 100).toFixed(2)}</b>
                </CardFooter>
            </div>
        </Card>
    )
}

export default InvoiceFee