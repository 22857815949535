import React, { useContext, useEffect, useRef, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import Select from 'react-select';
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";



const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

export const SelectField = (props) => {
  const {
    wrapperStyle,
    grouping=false,
    data,
    asLabel,
    inline,
    className,
    containerStyle,
    suggestion,
    innerContainerStyle,
    isDisabled,
    onClickLeft,
    LeftComponent,
    placeholder,
    label,
    noLabel,
    showBottomBar,
    // bottomBarChildren,
    minMenuWidth,
    changeOptionsData,
    renderFunc,
    showClear,
    onMenuScrollToBottom,
    plain,
    onInputChange,
    required,
    inlineWithoutLabel,
    RightComponent,
    onClickRight,
    showErr,
    error,
    flexible,
    AddEntityComponent,
    onChange = () => { },
    style,
    innerContainerClassName,
    containerClassName,
    onBlur,
    inputValue,
    inputGroup,
    outline,
    InputGroupChild,
    filterOption,
    rightComponentStyle,
    dropDownMenuWidth,
    formatOptionLabel,
    inputGroupClass,
    menuPlacement = "auto",
    zeroMargin,
    editId,
    hideDropdown
  } = props;

  const { t } = useContext(I18nContext);
  const selectRef = useRef(null);
  const { showError } = useContext(DialogContext)

  const [state, setState] = useState({
    isOpen: false
  })
  const height = inline ? 25 : grouping ? 30 : 24;
  const [onFocus, setOnFocus] = useState(false);
  const [showAddComponent, setShowAddComponent] = useState(false);
  const [value, setValue] = useState(
    data?.find((d) => (d?.value ?? "")?.toString() === (props?.value ?? " ")?.toString()) ?? ""
  );

  const { isOpen } = state;



  const toggleOpen = (preventDefault = false, isOpen) => {
    if (state.isOpen && onBlur && !preventDefault) {
      onBlur()
    }
    if (!state.isOpen && onFocus && !preventDefault) {
      onFocus()
    }
    setState(state => ({ ...state, isOpen: isOpen?.contains ? isOpen?.value : !state.isOpen }));
  }




  useEffect(() => {
    if (props.shouldFocus && data?.length && !props?.value) {
      setTimeout(() => {
        if (!props?.value) {
          toggleOpen(false, { contains: true, value: false });
        } else {
          toggleOpen();
        }
      }, 0);
    }
  }, [props.shouldFocus, data?.length, props?.value]);

  useEffect(() => {
    if ( data?.length && !props?.valueLabel) {
      if ((props?.value || props?.value == 0)) {
        let selected = data?.find((d) => d.value === props?.value || d === props?.value);
        if (selected) {
          setValue(selected);
        }
      }
      else if (!props.valueLabel) setValue("")
    }
  }, [props?.value, data]);

  useEffect(() => {
    try {
      if (props.valueLabel && data.length && !value) {
        const selected = data.find((d) => d.label.toLowerCase() === props.valueLabel.toLowerCase());
        if (selected) {
          setValue(selected);
          onChange(selected);
        }
      }
    } catch (error) {
      console.log("valueLabel", props.valueLabel)
      console.log(error)
    }

  }, [props.valueLabel, data]);

  const handleKeyDownWhenFocused = (e) => {
    if (e.key === 'Enter' ) {
      e.preventDefault();
      toggleOpen(true);
    }
  };

  useEffect(() => {
    if (props?.value !== value?.value && data?.length && !props?.valueLabel) {
      if (!value) {
        (value == 0) ? onChange(0) : onChange("")
      } else onChange(value);
    }

    if (props?.value !== value?.value && data?.length && props?.valueLabel) {
      if (value) {
        onChange(value);
      }
    }
  }, [value]);

  const clearSelection = (e) => {
    e.stopPropagation();
    selectRef.current?.clearValue();
  };


  if (asLabel) {
    return <div>{value.label}</div>;
  }

  return (
    <div className="position-relative" >
      {/* div className="position-relative" */}
      {/* <Button onClick={toggleOpen}  >
        {value ? `State: ${value.label}` : 'Select a State'}
      </Button> */}
      <div
        style={{
          ...wrapperStyle,
          ...style,
        }}
        className={`${inline ? "" : "selectfield__wrapper "} ${className} cursor-pointer cursor-style`}
      >

        <div
          style={containerStyle}
          className={
            ` ${!(plain || outline || inline) ? "d-flex justify-content-center align-items-center " : " "}      selectfield__container ` +
            (plain && !onFocus ? "selectfield__plain " : "") +
            containerClassName
          }
        >
          {!(inline || plain || outline) && (
            <div style={{ width: !!zeroMargin ? "fit-content" : "120px", fontSize: 12, fontWeight: "500", marginRight: !!zeroMargin ? 5 : 0 }}  >
              {(label)}
              {required && <font color="red">*</font>}
            </div>
          )}
          {suggestion && (
            <b className="selectfield__suggestion">{suggestion}</b>
          )}

          <div tabIndex="0" role="button" onKeyDown={handleKeyDownWhenFocused} onClick={(e) => {
            !isDisabled && toggleOpen(false)
          }} id="x1" className={`selectfield__innercontainer${outline ? "_outline" : ""} d-flex  flex-1 ` + (inline ? "bg-white " : "") + innerContainerClassName}
            style={{
              ...innerContainerStyle,
              backgroundColor: isDisabled ? "hsl(0, 0%, 95%)" : "",
            }}
          >
            {LeftComponent && (
              <div id="x2"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onClickLeft) {
                    onClickLeft(e)
                  }

                }}
                className={onClickLeft ? "component_pointer Selectfield__leftComponent" : "selectfield__leftComponent"}
                style={{ height: inline ? "19px" : "28px" }}
              >
                < LeftComponent />
              </div>
            )}

            <div className={InputGroupChild && value?.label ? `input-group  w-100 selectfield x1 ${grouping?"flex-nowrap":""}  `  : inline ? "input-group" : "selectfield x1"} id="x5">

              {(inline && !inlineWithoutLabel) && (
                <div style={{ height, border: "none", fontSize: 12, }} className="input-group-text"  >
                  {t(label)}
                </div>
              )}
              {InputGroupChild && (
                <div style={{ border: "none", fontSize: 11, overflow: 'hidden',backgroundColor : "transparent" }} className="input-group-text grouped-input-select-group  "  >
                  < InputGroupChild />
                </div>
              )}


              {!plain && !noLabel && !inline && (inline || outline || plain) && (
                <label
                  onClick={!isDisabled && toggleOpen}
                  style={isDisabled ? { backgroundColor: "hsl(0, 0%, 95%)" } : {}}
                  className={
                    value || onFocus
                      ? "selectfield__label selectfield__notempty_input"
                      : "selectfield__label"
                  }
                >
                  {t(label)}
                  {required && <font color="red">*</font>}
                </label>
              )}
              <div className={`px-${outline ? "0" : "2"} select-custom-label d-flex flex-column  justify-content-center ${InputGroupChild && value?.label ? "ps-2" : ""}`}
                style={{
                  maxHeight:  height,
                  minHeight: height,
                  height: height,
                  ...(hideDropdown && {width : "max-content"}),
                  ...(inline && { fontSize: 12,   minWidth: 60, })
                }}>
                {value?.label ?? ""}
                {/* <div className="selectfield__value custom-label-value">{value?.label??""}</div> */}
              </div>
            </div>
            {!hideDropdown ? <div className="me-1" style={{ maxHeight: height, minHeight: height, height: height }}>
              <RiArrowDropDownLine size={inline ? 35 : 25} viewBox={`0 0 ${inline ? 10 : height} ${inline ? 35 : 24}`} />
            </div> : hideDropdown && !value?.label ? <div className="me-1" style={{ maxHeight: height, minHeight: height, height: height }}>
              <RiArrowDropDownLine size={inline ? 35 : 25} viewBox={`0 0 ${inline ? 10 : height} ${inline ? 35 : 24}`} />
            </div> : null}
            {RightComponent && !inline && (
              <div id="x6"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onClickRight) {
                    onClickRight(e)
                  }

                }}
                style={{ position: "relative", height: inline ? "19px" : "28px", ...(rightComponentStyle ? rightComponentStyle : {}) }}
                className={onClickRight ? "component_pointer Selectfield__rightComponent" : "selectfield__rightComponent"}
              >
                < RightComponent />
              </div>
            )}


            {isOpen ? <div style={styles.menu} onClick={(e) => e.stopPropagation()}>
              <Select
                autoFocus
                ref={selectRef}
                components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                menuIsOpen
                options={data}
                placeholder="Search..."
                // styles={selectStyles}
                tabSelectsValue={false}
                value={value}

                formatOptionLabel={formatOptionLabel}
                {...(filterOption && { filterOption: filterOption })}
                changeOptionsData={changeOptionsData}
                optionComponent={renderFunc}
                menuPlacement={"bottom"}
                menuPosition={'auto'}
                onMenuScrollToBottom={onMenuScrollToBottom}
                onInputChange={onInputChange}
                setValue={inputValue}
                isClearable={showClear && !plain && !inline}

                onFocus={() => {
                  if (!isOpen) toggleOpen(true)
                  if (props?.onFocus) props?.onFocus();
                }}
                onBlur={() => {
                  if (isOpen) toggleOpen(true)
                  if (onBlur) onBlur();
                }}
                onChange={(v) => {
                  // setState(state => ({ ...state, isOpen: !state.isOpen }));
                  (v ? setValue(v) : setValue(""))
                  toggleOpen(false)
                }}
                className={InputGroupChild ? "form-control px-0 py-0 border-0" : !inline ? "selectfield__select" : "form-control px-0 py-0 rounded-right-1"}
                isDisabled={isDisabled || false}

                {...(props?.getOptionLabel && { getOptionLabel: props?.getOptionLabel })} //for custom label in value field

                styles={
                  InputGroupChild ?
                    stylesForInputGroupChild :
                    inline ?
                      stylesForInline :
                      inputGroup ?
                        stylesForInputGroup :
                        stylesForMain(minMenuWidth)}

              />
            </div> : null}
          </div>

          {error && showErr && (<em className="selectfield__error">{error}</em>)}

        </div>

        {flexible && (<button onClick={() => setShowAddComponent(true)}>Add Entity</button>)}
      </div>

      {showAddComponent && flexible && AddEntityComponent && (
        <section
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "white",
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "1500",
          }}
        >
          < AddEntityComponent setShowAddComponent={setShowAddComponent} />
        </section>
      )}

      {/* {isOpen ? <div style={styles.menu}>
        <Select
          autoFocus
          ref={selectRef}
          components={{ DropdownIndicator: null, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          menuIsOpen
          options={data}
          placeholder="Search..."
          // styles={selectStyles}
          tabSelectsValue={false}
          value={value}

          formatOptionLabel={formatOptionLabel}
          {...(filterOption && { filterOption: filterOption })}
          changeOptionsData={changeOptionsData}
          optionComponent={renderFunc}
          menuPlacement={"bottom"}
          menuPosition={'auto'}
          onMenuScrollToBottom={onMenuScrollToBottom}
          onInputChange={onInputChange}
          setValue={inputValue}
          isClearable={showClear && !plain && !inline}

          onFocus={() => {
            if (!isOpen) toggleOpen(true)
            if (props?.onFocus) props?.onFocus();
          }}
          onBlur={() => {
            if (isOpen) toggleOpen(true)
            if (onBlur) onBlur();
          }}
          onChange={(v) => {
            // setState(state => ({ ...state, isOpen: !state.isOpen }));
            (v ? setValue(v) : setValue(""))
            toggleOpen(false)
          }}
          className={InputGroupChild ? "form-control px-0 py-0 border-0" : !inline ? "selectfield__select" : "form-control px-0 py-0 rounded-right-1"}
          isDisabled={isDisabled || false}

          {...(props?.getOptionLabel && { getOptionLabel: props?.getOptionLabel })} //for custom label in value field

          styles={
            InputGroupChild ?
              stylesForInputGroupChild :
              inline ?
                stylesForInline :
                inputGroup ?
                  stylesForInputGroup :
                  stylesForMain}

        />
      </div> : null} */}
    </div>
  );
}



const styles = {
  menu: {
    backgroundColor: '#FFF',
    borderRadius: 4,
    boxShadow: `0 0 0 1px #bbb, 0 4px 4px #bbb`,
    marginTop: 8,
    position: 'absolute',
    zIndex: 9999999,
    top: 40,
  }
}


const stylesForMain = (minMenuWidth) => {
  return {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      height: 24,
      maxHeight: 24,
      minHeight: 24,
      backgroundColor: "transparent !important",
      minWidth: minMenuWidth ?? 200,
      margin: 10
    }),
    singleValue: (base) => ({ ...base, backgroundColor: "transparent !important" }),
    indicatorsContainer: (base) => ({ ...base, height: 22 }),
    valueContainer: (base) => ({ ...base, padding: 0, paddingLeft: "calc(1rem - 3px)", lineHeight: "14px", backgroundColor: "transparent !important" }),
    placeholder: (base) => ({ ...base, paddingLeft: 0, backgroundColor: "transparent !important" }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
      color: state.selectProps.menuColor,
    }),
  }
};


const stylesForInputGroupChild = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    height: 27,
    maxHeight: 27,
    minHeight: 27,
    fontSize: 12,
    minWidth: 200, margin: 10
  }),
  groupHeading: (base) => ({
    ...base,
    color: '#808080',
    backgroundColor: "#EBEBEB",
    padding: "10px",
    margin: "0px",
    fontSize: 10
  }),
  singleValue: (base) => ({ ...base, fontSize: 12 }),
  indicatorsContainer: (base) => ({ ...base, height: 22, paddingTop: 10, }),
  valueContainer: (base) => ({
    ...base,
    padding: 6, paddingLeft: "calc(1rem - 3px)", lineHeight: "14px", fontSize: 11.5,
    backgroundColor: "#fff"
  }),
  placeholder: (base) => ({ ...base, paddingLeft: 0 }),
  // menu: (provided, state) => ({
  //   ...provided,
  //   width: "100%",
  //   color: state.selectProps.menuColor,
  // }),
};

const stylesForInputGroup = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    height: 24,
    maxHeight: 24,
    minHeight: 24,
    minWidth: 200, margin: 10
  }),
  singleValue: (base) => ({ ...base, }),
  indicatorsContainer: (base) => ({ ...base, height: 22 }),
  valueContainer: (base) => ({ ...base, padding: 0, paddingLeft: "0px", lineHeight: "14px", backgroundColor: "#fff" }),
  placeholder: (base) => ({ ...base, paddingLeft: 0 }),
  // menu: (provided, state) => ({
  //   ...provided,
  //   width: "130%",
  //   minWidth: 'max-content',
  //   color: state.selectProps.menuColor,
  // }),
};

const stylesForInline = {
  control: (base) => ({
    ...base,
    border: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: "none",
    backgroundColor: "#fff",
    minHeight: "25px",
    height: "25px",
    minWidth: 200, margin: 10
    // width:'100%'
  }),
  container: (base) => ({
    ...base,
    backgroundColor: "#fff",
    border: "none",
  }),
  placeholder: (base) => ({
    ...base,
    backgroundColor: "#fff",
    fontSize: 12
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "18px",
    backgroundColor: "#fff",
    minWidth: 100,
    padding: "0 6px",
  }),
  // menu: (provided, state) => ({
  //   ...provided,
  //   zIndex: 999
  // }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    backgroundColor: "#fff",
    // height: "10px",
    padding: 0,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "18px",
    backgroundColor: "#fff",
  }),
};


