import React, { useState, useEffect } from 'react'
import { Card, Col, Row } from 'reactstrap'
import ToggleSelector from '../Toggle/ToggleSelector'
import { DateSelect } from '..'
import MonthPicker from '../Dropdowns/MonthSelect/MonthPicker'
import moment from 'moment'
import _ from 'lodash';
import "./TripStats.css"
import IndicativeStats from './IndicativeStats'
import DashboardService from '../../pages/Dashboard/DashboardService'
import Skeleton from 'react-loading-skeleton'

function TripPaymentCard() {
    const [selectedDate, setSelectedDate] = useState(parseInt(moment().format('YYYYMMDD')));
    const [selectedMonth, setSelectedMonth] = useState(parseInt(moment().format('YYYYMM')));
    const [toggle, setToggle] = useState('monthly');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const group1Statuses = [1, 2, 3, 4, 5];
    const group2Statuses = [19, 20, 29, 30];

    const calculateRevenue = (data) => {
        const sumTotalRevenueByStatus = (statuses) =>
            data
                .filter(({ _id }) => statuses.includes(_id.status))
                .reduce((sum, { total_fee }) => sum + total_fee, 0);

        return [
            sumTotalRevenueByStatus(group1Statuses),
            sumTotalRevenueByStatus(group2Statuses)
        ];
    };

    const calculateCount = (data) => {
        const sumTotalCountByStatus = (statuses) =>
            data
                .filter(({ _id }) => statuses.includes(_id?.status))
                .reduce((sum, { count }) => sum + (count || 0), 0);

        return [
            sumTotalCountByStatus(group1Statuses),
            sumTotalCountByStatus(group2Statuses)
        ];
    };

    const fetchPayments = async ({ startDate, endDate }) => {
        if (loading) return;
        setLoading(true);
        try {

            

            const paymentsForPaymentsRecievedbody = {
                groupBy: {status: "status" },
                ...(toggle === 'daily'
                    ? { filters: { tripDate: startDate || endDate } }
                    : { btwFilters: { tripDate: [startDate, endDate] } }),
                inFilters: {status: [1, 2, 3, 4, 5, 19, 20, 29, 30] },
            };
            const recievedPaymentsResponse = await DashboardService.fetchTripStats(paymentsForPaymentsRecievedbody);
            const countArray = calculateCount(recievedPaymentsResponse)

            const revenuePaymentResponse = await DashboardService.fetchTripRevenueStats(paymentsForPaymentsRecievedbody)
            const revenueArray = calculateRevenue(revenuePaymentResponse);

            setData(prev => ({
                ...prev,
                recievedPayments: countArray[1],
                pendingPayments: countArray[0],
                pendingRevenue: revenueArray[0],
                receivedRevenue: revenueArray[1]
            }));
        } catch (error) {
            console.error("Error fetching trips:", error);
        }
        finally {
            setLoading(false);
        }

    };
    
    useEffect(() => {
        if (toggle === 'daily') {
            fetchPayments({ startDate: selectedDate, endDate: selectedDate });
        } else {
            const year = Math.floor(selectedMonth / 100);
            const month = selectedMonth % 100;
            const from = parseInt(moment(`${year}-${month}-01`).startOf('month').format('YYYYMMDD'));
            const till = parseInt(moment(`${year}-${month}-01`).endOf('month').format('YYYYMMDD'));
            fetchPayments({ startDate: from, endDate: till });
        }
    }, [selectedDate, selectedMonth, toggle]);

    return (
        <Col sm={12}>
            <Card className="py-2 px-4 flex-1" style={{ height: "fit-content" }}>
                <div className="zr_trip_stats">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="font-size-14 my-2 d-flex align-items-center">
                            <h2><b>{"Trips Payment"}</b></h2>
                        </div>

                        <div className="d-flex align-items-center">
                            <ToggleSelector
                                style={{ marginRight: "10px" }}
                                data={[
                                    { label: "Daily", value: "daily" },
                                    { label: "Monthly", value: "monthly" },
                                ]}
                                zeroMargin
                                value={toggle}
                                compact
                                tabClassName={"px-2"}
                                onChange={setToggle}
                            />

                            {toggle === 'daily' ? (
                                <DateSelect
                                    value={selectedDate}
                                    onChange={setSelectedDate}
                                    type="text"
                                    noLabel
                                    className={"my-0"}
                                />
                            ) : (
                                <MonthPicker
                                    value={selectedMonth}
                                    onChange={setSelectedMonth}
                                    type="text"
                                    noLabel
                                    className={"my-0"}

                                />
                            )}
                        </div>
                    </div>

                    {loading ?
                        <div className="d-flex justify-content-center p-4">
                            <Skeleton height={30} width={700} className='text-crm-greyish font-size-12' />
                            
                        </div>
                        : <Row className='mt-2'>
                            <Col className="col-sm-12">
                                <Row className="zr_progress_stats d-flex row h-120">
                                    <div className='h-100'>
                                        <IndicativeStats
                                            data={[Math.round((data?.pendingRevenue / 100)) ?? 0,
                                            Math.round(data?.receivedRevenue / 100) ?? 0]}
                                            configs={[{
                                                left: {
                                                    title: <Col>
                                                        <span className='font-size-14 fw-semibold'>Pending</span>
                                                    </Col>,
                                                    Currency: "INR",
                                                    subHeading: <Col>
                                                        <span className='fw-semibold font-size-20 greyish-info'>{data?.pendingPayments} </span>Trips
                                                    </Col>
                                                },
                                                right: {
                                                    title: <Col>
                                                        <span className='font-size-14 fw-semibold'>Received</span>
                                                    </Col>,
                                                    Currency: "INR",
                                                    subHeading: <Col>
                                                        <span className='fw-semibold font-size-20 greyish-info'> {data?.recievedPayments} </span> Trips
                                                    </Col>
                                                },
                                                top: {
                                                    title: <Col className="text-center font-size-14 fw-normal">Total</Col>,
                                                    Currency: "INR"
                                                }
                                            }]}
                                        />
                                    </div>

                                </Row>
                            </Col>
                        </Row>}
                </div>
            </Card>
        </Col>
    )
}

export default TripPaymentCard