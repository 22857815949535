import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, Form, Row } from "reactstrap";
import { CountrySelect, TimeZoneSelect, InputField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import VehicleTypeCount from "./component/VehicleTypeCount";
import CorporateService from "./CorporateService";
import './component/react-customize-token-input.css';
import { UserContext } from "../../store/context/UserContext";

const EditCorporate = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);


  const { userContent, populateUserContent } = useContext(UserContext);
  const fetch = async () => {
    try {
      let garage = await CorporateService.get(editId);
      setData(garage);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, CorporateService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data, data?.website, data?.console]);


  const handleTokenValuesChange = (newValues, dataKey) => {
    const currentValues = Array.isArray(data[dataKey]) ? data[dataKey] : [];
    const uniqueValues = Array.from(new Set([...newValues]));
    setData({
      ...data,
      [dataKey]: uniqueValues,
    });
  };


  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">
            <div className="col-12 col-md-6">
              <CountrySelect
                showErr={hasErr}
                value={data?.country}
                className="mt-sm-0 mt-3"
                onChange={(v) =>
                  setData({
                    ...data,
                    // address: {
                    //   ...data.address,
                    //   country: v.value,
                    //   state: null,
                    //   city: null,
                    // },
                    country: v.value
                  })
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <TimeZoneSelect
                placeholder={t("Timezone")}
                value={data?.timeZone}
                // label={t("Timezone")}
                onChange={(v) => setData({ ...data, timeZone: v.value })}
                type="text"
                className=" mt-sm-0 mt-3"
              />
            </div>
          </Row>

          <Row className="mt-3 mb-4">
            <div className="col-12 col-md-6">
              <InputField
                label={t("Android App")}
                type="text"
                value={data?.corporateExtn?.androidApp}
                onChange={(e) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, androidApp: e } })}

                placeholder={t("Android App")}
              />

            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={t("iOS App")}
                type="text"
                value={data?.corporateExtn?.iosApp}
                onChange={(e) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, iosApp: e } })}
                placeholder={t("iOS App")}
              />
            </div>
          </Row>

          <Row className="mt-3 mb-4">
            <div className="col-12 col-md-6">
              <InputField
                label={t("Android Driver App")}
                type="text"
                value={data?.corporateExtn?.androidDriverApp}
                onChange={(e) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, androidDriverApp: e } })}

                placeholder={t("Android Driver App")}
              />

            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={t("iOS Driver App")}
                type="text"
                value={data?.corporateExtn?.iosDriverApp}

                onChange={(e) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, iosDriverApp: e } })}

                placeholder={t("iOS Driver App")}
              />
            </div>
          </Row>

          <Row className="mt-3 mb-4">
            <div className="col-12 col-md-6">
              <InputField
                label={t("Android Admin App")}
                type="text"
                value={data?.corporateExtn?.androidAdminApp}
                onChange={(e) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, androidAdminApp: e } })}

                placeholder={t("Android Admin App")}
              />

            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={t("iOS Admin App")}
                type="text"
                value={data?.corporateExtn?.iosAdminApp}
                onChange={(e) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, iosAdminApp: e } })}

                placeholder={t("iOS Admin App")}
              />
            </div>
          </Row>

          <Row className="mt-3 mb-4">
            <div className="col-12 col-md-6">
              <InputField
                label={t("Website")}
                type="text"
                value={data?.website}
                onChange={(e) => {
                  setData({ ...data, website: e });
                }}
                placeholder={t("Website")}
              />
              {/* <TokenInput
                tokenValues={Array.isArray(data?.website) ? data?.website : []}
                onTokenValuesChange={(newValues) => handleTokenValuesChange(newValues, 'website')}
                className="token-input-style overflow-hidden"
              /> */}
            </div>
            <div className="col-12 col-md-6">
              <InputField
                label={t("Console")}
                type="text"
                value={data?.console}
                onChange={(e) => {
                  setData({ ...data, console: e });
                }}
                placeholder={t("Console")}
              />
              {/* <TokenInput
                tokenValues={Array.isArray(data?.console) ? data?.console : []}
                onTokenValuesChange={(newValues) => handleTokenValuesChange(newValues, 'console')}
                className="token-input-style overflow-hidden"
              /> */}
            </div>
            <div className="d-flex col-12 col-md-6 my-3">
              <div className="col-2" style={{ fontWeight: '500' }}>Domain </div>
              <div className="ms-4 col-10 justify-content-start"><span style={{ fontWeight: 700 }}>{data?.domain}.zoyride.com</span></div>
            </div>
          </Row>



          <Card className="p-3" style={{ fontSize: "14px" }}>
            <Row className="mt-3">


              <div className="d-flex col-sm-6 mt-sm-0">
                <div style={{ width: "150px" }}>Name</div>
                <div>{data?.name ? data?.name : "--"}</div>
              </div>
              <div className="d-flex col-sm-6 mt-sm-0 ">
                <div style={{ width: "150px" }}>Full Name</div>
                <div>{data?.fullName ? data?.fullName : "--"}</div>
              </div>


            </Row>
            <Row className="mt-3">

              <div className="d-flex col-sm-6 mt-sm-0">
                <div style={{ width: "150px" }}>{t("Unique ID")}</div>
                <div>{data?.unique_id ? data?.unique_id : "--"}</div>
              </div>
              <div className="d-flex col-sm-6 mt-sm-0">
                <div style={{ width: "150px" }}>{t("Corporate Type")}</div>
                <div>{data?.corpType == 1 ? "Consumer Corporate" : (data?.corpType == 2) ? "Transporter Corporate" : "--"}</div>
              </div>
            </Row>
            <Row className="mt-3">


              <div className="d-flex col-sm-6 mt-sm-0 mt-3">
                <div style={{ width: "150px" }}>
                  {t("Status")}
                </div>
                <div>
                  {data?.status == 0 ? "New" : (data?.status == 1 ? "Active" : "InActive")}
                </div>
              </div>
              <div className="d-flex col-sm-6 mt-sm-0 mt-3">
                <div style={{ width: "150px" }}>
                  {t("Logo")}
                </div>
                <div >
                  <img style={{ height: "50px", width: "50px" }} src={data?.logo} />
                </div>
              </div>

            </Row>

            <Row className="mt-3">


              <div className="d-flex col-sm-6 mt-sm-0 mt-3">
                <div style={{ width: "150px" }}>
                  {t("Establish Year")}
                </div>
                <div>
                  {data?.corporateExtn?.establishYear ? data?.corporateExtn?.establishYear : '--'}
                </div>
              </div>
              <div className="d-flex col-sm-6 mt-sm-0 mt-3">
                <div style={{ width: "150px" }}>
                  {t("Registeration No")}
                </div>
                <div>
                  {data?.corporateExtn?.regNo ? data?.corporateExtn?.regNo : "--"}
                </div>
              </div>
            </Row>
            <Row className="mt-3 mb-3">
              <div className="d-flex col-sm-6 mt-sm-0 mt-3">
                <div style={{ width: "150px" }}>
                  {t("Employee Count")}
                </div>
                <div>
                  {data?.corporateExtn?.employeeCount ? data?.corporateExtn?.employeeCount : "--"}
                </div>
              </div>
              <div className="d-flex col-sm-6 mt-sm-0 mt-3">
                <div style={{ width: "150px" }}>
                  {t("Service Required")}
                </div>
                <div>

                  {data?.corporateExtn?.serviceRequired ? data?.corporateExtn?.serviceRequired.join(', ') : "--"}
                </div>
              </div>
              {/*  <InputField
              placeholder={t("Employee Count")}
              value={data?.corporateExtn?.employeeCount}
              label={t("Employee Count")}
              // onChange={(v) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, employeeCount: v } })}
              error={data?.corporateExtn?.employeeCount < 0 ? t("Please enter Employee Count") : ""}
              showErr={hasErr}
              type="number"
              className="col-sm-6 mt-sm-0 mt-3"
              isDisabled
            />

            <SelectFieldMultiple
              data={SERVICEREQUIRED}
              showErr={hasErr}
              label="Service Required"
              placeholder="Service Required"
              value={
                SERVICEREQUIRED?.filter((c) => data?.corporateExtn?.serviceRequired?.includes(c?.value))
              }
              multi={true}
              country={data?.country}
              className="col-sm-6 mt-sm-0 mt-3"
              isDisabled
              onChange={(v) => setData({ ...data, corporateExtn: { ...data?.corporateExtn, serviceRequired: v.map((c) => c?.value) } })}
            /> */}
            </Row>

          </Card>

          <VehicleTypeCount
            // onChange={(v) => {
            //   setData({
            //     ...data,
            //     corporateExtn: {
            //       ...data?.corporateExtn,
            //       vehicleTypeCount: v,
            //     },
            //   })
            // }
            // }
            // onChange={(v) => {
            //   setData({
            //     ...data,
            //     corporateExtn: {
            //       ...data?.corporateExtn,
            //       vehicleTypeCount: v?.reduce((acc, curr) => {
            //         const key = Object.keys(curr)[0] ?? "";
            //         const value = curr[key] ?? 0;
            //         acc[key] = parseInt(value, 10);
            //         return acc;
            //       }, {})
            //     },
            //   });
            // }}
            // values={
            //   Object.entries(data?.corporateExtn?.vehicleTypeCount).map(([key, value]) => ({
            //     [key.toLowerCase()]: value
            //   }))?.length ?
            //     data?.corporateExtn?.vehicleTypeCount.map(vehicleType => {
            //       const key = getKey(vehicleType) ?? ""
            //       return { key, value: vehicleType[key] ?? "" }
            //     })
            //     : []
            // }
            values={data?.corporateExtn?.vehicleTypeCount}
          />
        </Form>
      }
    </React.Fragment >
  );
};

export default observer(EditCorporate);
