export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/hotel/grid?`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`;
        url += `&sortBy=time&sortAsc=false`
        return url;
    },
    get: (id) => `/api/hotel/${id}/detail`,
    save: "/api/hotel/create",
    delete: (id) => `/api/hotel/${id}/delete`,
    bulkDelete: "/api/hotel/delete",
    update: "/api/hotel/update",
};

export const SELECTSTRUCTURE = {
    kinds: [
        { label: "Hotel", value: "Hotel" },
        { label: "Hostel", value: "Hostel" },
        { label: "Motel", value: "Motel" },
        { label: "PG", value: "PG" },
        { label: "Villa", value: "Villa" },
        { label: "Apartment", value: "Apartment" },
        { label: "Shack", value: "Shack" },
        { label: "Tent", value: "Tent" },
        { label: "AirBnB", value: "AirBnB" },
    ],
    starRatings: [
        { label: "0", value: 0 },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
    ],
    // STANDARD, DELUXE, SUPER_DELUXE, LUXURY, SUIT
    roomTypes: [
        { label: "Standard", value: "STANDARD" },
        { label: "Deluxe", value: "DELUXE" },
        { label: "Super Deluxe", value: "SUPER_DELUXE" },
        { label: "Luxury", value: "LUXURY" },
        { label: "Suit", value: "SUIT" },
    ]
}

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Name",
        filterName: "name",
        type: "text",
    },
    {
        label: "Code",
        filterLabel: "Code",
        filterName: "code",
        type: "text",
    },
    {
        label: "Rating",
        filterLabel: "Rating",
        filterName: "starRating",
        type: "select",
        data: SELECTSTRUCTURE.starRatings,
    },
    {
        label: "Kind",
        filterLabel: "Kind",
        filterName: "kind",
        type: "select",
        data: SELECTSTRUCTURE.kinds,
    },
    {
        label: "City",
        filterLabel: "City",
        filterName: "address.city",
        type: "text",
        // type: "citySelect",
    },
];