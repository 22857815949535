import moment from "moment";

export function timeStampToDateYYYYMMDD(timestamp) {
    console.log(moment.unix(timestamp))
    return parseInt(moment.unix(timestamp).format("YYYYMMDD"))
}

export function getDaysDifferenceInDays(startTime, endTime) {
    const sd =  moment(startTime*1000).startOf('day').unix();
    const ed =  moment(endTime*1000).endOf('day').unix();
    return Math.round((ed - sd) / 86400);
}

export function convertYYYYMMDDtoDDMMMM(yyyymmdd) {
    if (yyyymmdd)
        return moment(yyyymmdd, 'YYYYMMDD').format('DD MMM');
    else return "--"
}