export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, supplier_id = null) => {
    let url = `/api/expense/grid?`;
    if (supplier_id) url += `supplier_id=${supplier_id}&`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    url += `&sortBy=time&sortAsc=false`
    return url;
  },
  get: (id) => `/api/expense/${id}/detail`,
  save: `/api/expense/create`,
  delete: (id) => `/api/expense/${id}/delete`,
  update: `/api/expense/update`,
};

export const SELECTSTRUCTURE = {
  type: [
    { label: "Vehicle", value: "vehicle" },
    { label: "General", value: "general" },
    { label: "Employee", value: "employee" },
  ],
  status: [
    { label: "New", value: "1" },
    { label: "Partially Paid", value: "2" },
    { label: "Paid", value: "3" },
    { label: "Cancelled", value: "4" },
  ]
};

export const STRUCTURE = [
  {
    label: "Supplier",
    filterLabel: "Supplier",
    filterName: "supplier_id",
    type: "supplierSelect",
  },
  {
    label: "Type",
    filterLabel: "Type",
    filterName: "type",
    type: "select",
    data: SELECTSTRUCTURE.type
  },
  {
    label: "Company",
    filterLabel: "Company",
    filterName: "company_id",
    type: "entitySelect",
  },
  {
    label: "Vehicle",
    filterLabel: "Vehicle",
    filterName: "vehicle_id",
    type: "vehicleSelect",
  },
  {
    label: "Status",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: SELECTSTRUCTURE.status
  },
  {
    label: "Invoice No.",
    filterLabel: "Invoice No.",
    filterName: "invoiceNo",
    type: "text",
  },
  {
    label: "Category",
    filterLabel: "Category",
    filterName: "category_id",
    type: "groupCategorySelect",
  },
];
