import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Entity from "../../../pages/Company/Company";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
import CompanyService from "./../../../pages/Company/CompanyService";

const CompanySelect = ({
  corpType, //self/supplier/client
  style,
  isDisabled,
  className,
  onChange = () => { },
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  plain,
  asLabel,
  outline,
  valueLabel = false,
  noLabel,
  corp_id,
  displayAll,
  hideWhenSingle,
  noChecks,
  selectFirst,
  ...props
}) => {
  const [entities, setEntities] = useState([]);
  const [modal, setModal] = useState(false);
  const { t } = useContext(I18nContext)

  const fetchCompanies = useCallback(async () => {
    // this is done to populate data only if corp_id present if fetching not self companies
    if ((corpType === "supplier" || corpType === "client") && !corp_id) {
      return;
    }
    const entity = await CompanyService.fetchAll({ valueLabel, corp_id, corpType });
    if (entity?.length) {
      setEntities(entity);
    }
  }, [corp_id, corpType, valueLabel])

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const openModal = () => {
    setModal(true);
  };


  useEffect(() => {
    if (corpType === "self" && entities.length === 1 && !value) {
      onChange(entities[0]);
    }
  }, [corpType, entities, value, onChange]);


  const handleValue = () => {
    for (const entity of entities) {
      if (entity?.value === value || (corp_id && (entity?.client_id === corp_id))) {
        onChange(entity);
        break;
      }
    }
  };

  useEffect(() => {
    if (corp_id && entities?.length && !isDisabled && !value) {
      handleValue()
    }
  }, [entities, corp_id, value, isDisabled])

  useEffect(() => {
    if (corp_id && entities?.length && !isDisabled && selectFirst) {
      onChange(entities[0])
    }
  }, [entities, corp_id, isDisabled])


  const closeModal = () => {
    setModal(false);
  };

  const selectEntity = (selectedValue) => {
    const companyObj = entities.find(obj => obj._id === selectedValue);
    companyObj.value = selectedValue

    onChange(
      (multi ? { value: selectedValue[0] } : companyObj)
    );
    closeModal();
  };

  const selectEntities = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const renderSelectField = () => (
    <SelectField
      style={style}
      isDisabled={isDisabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Entities")} />
      )}
      plain={plain}
      outline={outline}
      noLabel={noLabel}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={entities}
      required={required}
      value={value ? value : (selectFirst ? (entities && entities[0]?._id) : null)}
      label={label || (!isDisabled && t("Company"))}
      error={!value ? t(`Please Select ${label || "Company"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={(e) => {
          e.stopPropagation()
          if (!isDisabled) {
            openModal()
          }
        }} />
      )}
      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage")} />
      )}
      // showBottomBar={true}
      className={className}
      onChange={selectEntities}
      data={entities}
      plain={plain}
      noLabel={noLabel}
      outline={outline}
      value={value ? entities.filter((c) => (value?.length ? value : [])?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Companies"}`) : ""}
      required={required}
      label={t("Select Companies")}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  if (hideWhenSingle && entities && entities.length == 1) {
    if (value != entities[0]?._id)
      onChange(entities[0])
    return null;
  } else if (!noChecks && entities && entities.length > 1 && !value) {
    onChange(entities[0]);
    return null;
  }

  return (
    <>
      {asLabel ?

        displayAll ? <div>{entities?.length ? entities.map((entity, index) => <span>{entity?.label} {index < entities.length - 1 && ", "}</span>) : <div className="text-muted">No Companies</div>} </div> : multi ? value?.length > 0 ?

          (<div> {value?.map((selectedValue, index) => (
            <span key={selectedValue}>
              {entities.find((entity) => entity.value === selectedValue)?.label}
              {index < value.length - 1 && ", "}
            </span>
          ))}</div>) : <div style={{ color: "grey" }}>--</div>
          : (<div>{entities?.find((entity) => entity?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>) :
        <>
          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Company")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Entity
                  value={value}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectEntities : selectEntity}
                  {...(corpType === "client" && {
                    callingType: "client",
                    client_id: corp_id,
                    hideFilterBar: true
                  })}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}
         {
            entities?.length > 0 ? ( 
                multi ? (
                  renderSelectFieldMultiple({ disabled: false })
                ) : (
                  renderSelectField()
                )
            ) : (
              valueLabel ? (
                <ThreeBounce size={10} color="pink" />
              ) : (
                renderSelectField({ disabled: true })
              )
            )
          }


        </>

      }

    </>
  );
};

export default observer(CompanySelect);
