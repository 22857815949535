import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TaskCard from './TaskCard';
import "./index.css";

const DraggableBox = ({ value, onChange = () => { }, showArrows }) => {
    const [columns, setColumns] = useState(value);

    useEffect(() => {
        setColumns(value);
    }, [JSON.stringify(value)]);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            onChange({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems,
                },
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            onChange({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems,
                },
            });
        }
    };

    const moveItem = ({ item, currentIndex, tomoveIndex }) => {
        currentIndex = currentIndex[0];
        tomoveIndex = tomoveIndex[0];
        const sourceColumn = columns[currentIndex];
        const destColumn = columns[tomoveIndex];
        const sourceItems = [...sourceColumn?.items];
        const destItems = [...destColumn?.items];
        const itemIndex = sourceItems?.indexOf(item);
        if (itemIndex > -1) {
            const [removed] = sourceItems?.splice(itemIndex, 1);
            destItems.push(removed);
            onChange({
                ...columns,
                [currentIndex]: {
                    ...sourceColumn,
                    items: sourceItems,
                },
                [tomoveIndex]: {
                    ...destColumn,
                    items: destItems,
                },
            });
        }
    };

    const renderDroppableColumn = (columnId, column, index) => (
        <div className='drag-column d-flex justify-content-center' key={columnId}>
            <Droppable droppableId={columnId}>
                {(provided) => (
                    <div className='drag-box-grid' ref={provided.innerRef} {...provided.droppableProps}>
                        <div className='drag-title'>{column.title}</div>
                        <div className='drag-list'>
                            {column?.items?.map((item, index2) => (
                                <TaskCard
                                    key={item}
                                    item={item}
                                    index={index2}
                                    showArrows={showArrows}
                                    left={index > 0}
                                    right={index < Object.entries(columns).length - 1}
                                    moveLeft={() => moveItem({ item, currentIndex: Object.entries(columns)[index], tomoveIndex: Object.entries(columns)[index - 1] })}
                                    moveRight={() => moveItem({ item, currentIndex: Object.entries(columns)[index], tomoveIndex: Object.entries(columns)[index + 1] })}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    </div>
                )}
            </Droppable>
        </div>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className='d-flex '>
                {Object.entries(columns).map(([columnId, column], index) => renderDroppableColumn(columnId, column, index))}
            </div>
        </DragDropContext>
    );
};

export default DraggableBox;
