import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
// import EditStates from "./EditState";
import { STRUCTURE } from "./LeaveConstant";
import { GridColumns } from "./LeaveGridColumn";
import LeaveService from "./LeaveService";
import EditLeave from "./EditLeave";
import { UserContext } from "../../store/context/UserContext";
import TagPicker from "../../components/TagSelector/TagPicker";
import { addDays, differenceInDays, format } from "date-fns";
import { parseDateFromInt } from "../../util/Util";

const Leave = ({ insidePane }) => {
  let { edit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);

  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(edit_id);
  const [showDetailPage, setShowDetailPage] = useState(
    insidePane ? false : window.location.pathname === "/leave/create" || edit_id
  );
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await LeaveService.fetch(filterUrl);
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(
      queryParams,
      STRUCTURE
    );
    setFilterURL(filterUrl);
    setFilterObject(filterObject);
    fetchData(filterUrl);
  }, []);

  useEffect(() => {
    setEditID(edit_id)
  }, [edit_id])

  const onSave = async (e) => {
    if (
      (editID && !detailData?.date) || 
      (!editID && !detailData?.startDate) ||
      !detailData?.remark || 
      !detailData?.employee_id 
    ) {
      showError(t("Please enter all fields"));
      return;
    }
  
    if (!editID && detailData?.startDate > detailData?.endDate) {
      showError(t("Start date can't be greater than End date"));
      return;
    }
  
    e.preventDefault();
    setLoading(true);
    try {
      if (editID) {
        await LeaveService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Record updated successfully."));
      } else {
        const startDate = parseDateFromInt(detailData?.startDate);
        const endDate = detailData?.endDate ? parseDateFromInt(detailData?.endDate) : startDate;
        const totalDays = differenceInDays(endDate, startDate);
  
        delete detailData?.startDate;
        delete detailData?.endDate;
  
        for (let i = 0; i <= totalDays; i++) {
          const currentDate = addDays(startDate, i);
          const formattedDate = format(currentDate, 'yyyyMMdd');
          const newData = { ...detailData, date: formattedDate };
          await LeaveService.save(newData);
        }
  
        showMessage(t("Record saved successfully."));
        setEditID(null);
        setShowDetailPage(false)
        if (!insidePane) navigate(`/leave`);
      }
      fetchData(filterURL);
    } catch (e) {
      setLoading(false);
      navigate('/leave');
      showError(e);
    }
    finally{
      setLoading(false);
      navigate('/leave');
    }
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await LeaveService.delete(id);
        setLoading(false)
        showMessage("Deleted", "Deleted");
        setShowDetailPage(false)
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    }
  };

  const renderLastCol = useCallback((leave) => {
    return (
      <>
        {userContent?.rights?.includes(4702) &&
          <EditLink
            onClick={() => {
              if (!insidePane) navigate(`/leave/edit/${leave?._id}`);
              setEditID(leave?._id);
              setShowDetailPage(true);
            }}
          />
        }
        {userContent?.rights?.includes(4703) ?

          <DeleteLink
            onClick={(event) => onDelete(event, leave?._id)}
          />
          : null}
        {/* <DeleteLink
          onClick={(event) => onDelete(event, leave?._id)}
        /> */}
      </>
    );
  }, [insidePane, userContent])

  return (
    <>
      <Layout
        small
        hideAdd={!userContent?.rights?.includes(4701)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/leave");
          setEditID(null);
        }}
         
        title={t("Leave")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/leave/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={LeaveService.page}
        rows={LeaveService.rows}
        total={LeaveService.total}
        fetch={LeaveService.fetch}
      >
        <Layout.Table>
          <>
            <DataGrid
              gridLoading={loading}
              data={LeaveService.records}
              total={LeaveService.total}
              uiPreference="leave.grid"
              headers={GridColumns}
              selectedIDs={selectedIDs}
              onSelectChange={setSelectedIDs}
              page={LeaveService.page}
              rowsPerPage={LeaveService.rowsPerPage}
              onPaginationChange={LeaveService.onPaginationChange}
              renderLastCol={renderLastCol}
            />
          </>
        </Layout.Table>

        <Layout.DetailPageTitle>
          <div className="me-2">
            {detailData?._id ? t("Edit Leave") : t("Add Leave")}
          </div>
          <div className="d-flex align-items-center" style={{ display: 'inline-flex', alignItems: 'center', marginTop: "8px" }}>
            <TagPicker
              className="ms-2"
              kind={"leave"}
              onChange={(v) => setDetailData({ ...detailData, tags: v })}
              value={detailData?.tags}
            />
          </div>
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditLeave
            editId={editID}
            onChange={(v) => {
              setDetailData(v);
            }}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {userContent?.rights?.includes(4703) && detailData?._id ? (
            <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
          ) : null}
          {userContent?.rights?.includes(4702) ? <SaveButton onClick={onSave} loading={loading} /> : null}
        </Layout.DetailPageFooter>
      </Layout>
    </>
  );
};
export default observer(Leave);
