import React from 'react';
import ReactInputMask from 'react-input-mask';

const InputFieldWrapper = ({ masking, ...props }) => {
  if (masking) {
    return <ReactInputMask {...props} />;
  }
  return <input {...props} />;
};

export default InputFieldWrapper;
