import { TbSortAscending, TbSortDescending } from "react-icons/tb";

export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/invoice/grid?`;
    if (sizePerPage) url += `&rows=${sizePerPage}`;
    if (filterUrl) url += `&${filterUrl}`;
    if (page) url += `&page=${page}`;
    return url;
  },
  get: (id) => `/api/invoice/${id}/detail`,
  saveNew: `/api/invoice/create`,
  save: `/api/invoice/create`,
  delete: (id) => `/api/invoice/${id}/delete`,
  // sendMessage: `/api/message/data/send`,
  bulkDelete: (id) => `/api/invoice/delete`,
  taxChange: () => `/api/invoice/tax/update`,
  update: `/api/invoice/update`,
  getInvoiceNumber: `/api/invoice/next/invoiceNo`,
  getClient: (id) => `/api/client/${id}/detail`,
  calculate: `/api/invoice/calculate`,

  downloadCoversheet: '/api/invoice/download/coversheet?=null',

  print: `/api/invoice/print`,
  validateInvoiceNo: `/api/invoice/invoiceNo/validate`,

  download: (page, rowsPerPage, filterURL, template_id) => {
    let url = `/api/invoice/grid?downloadTemplate_id=${template_id}&rows=-1&withExtn=true`;
    if (filterURL) url += `&${filterURL}`;
    return url;
  },

  getinvoiceConfig(vendorId) {
    const baseEndpoint = "/api/invoice-config/grid?";
    if (vendorId) {
      return `${baseEndpoint}vendorInfo.company_id=${vendorId}`;
    } else {
      return baseEndpoint;
    }
  },
};

export const SELECTSTRUCTURE = {
  bookingType: [
    {
      label: "Rental",
      value: 1
    },
    {
      label: "Shared",
      value: 2
    },
    {
      label: "Self Drive",
      value: 3
    },
    {
      label: "Shuttle",
      value: 4
    },
    {
      label: "Route",
      value: 5
    },

  ],
  paymentTerms: [
    { label: "Due on Receipt", value: "Due on Receipt" },
    { label: "Net 7", value: "Net 7" },
    { label: "Net 15", value: "Net 15" },
    { label: "Net 30", value: "Net 30" },
    { label: "Net 60", value: "Net 60" },
    { label: "End of Month", value: "End of Month" },
    { label: "End of Next Month", value: "End of Next Month" },
    { label: "Custom Due Date", value: "Custom Due Date" },
  ],
  paymentStatus: [
    { label: "Unpaid", value: 0 },
    { label: "Paid", value: 1 },
    { label: "Partial", value: 2 },
    { label: "Partial Wrote off", value: 3 },
    { label: "Full Wrote off", value: 4 },
  ],
  invoiceStatus: [
    { label: "Created", value: 1 },
    { label: "Ready", value: 2 },
    { label: "Deleted", value: 3 },
  ],
  bookingStatus: [
    { label: "Dispatched", value: "Dispatched" },
    { label: "On Journey", value: "On Journey" },
    { label: "Completed", value: "Completed" },
    { label: "Completed Offline", value: "Completed Offline" },
    { label: "Didn't Run", value: "Didn't Run" },
  ],
};

export const STRUCTURE = [
  {
    label: "Invoice No.",
    filterLabel: "Invoice No.",
    filterName: "invoiceNo",
    type: "text"
  },
  {
    label: "Client",
    filterLabel: "Client",
    filterName: "client.org_id",
    icon: "bx bx-badge-check ",
    type: "clientSelect"
  },
  {
    label: "Serving Company",
    filterLabel: "Serving Company",
    filterName: "vendorCompany_id",
    type: "entitySelect"
  },
  {
    label: "Status",
    filterLabel: "Status",
    filterName: "status",
    type: "select",
    data: [
      { label: "Created", value: 1 },
      { label: "Ready", value: 2 },
      // { label: "Deleted", value: 3 },
    ],
  },
  {
    label: "Payment Status",
    filterLabel: "Payment Status",
    filterName: "paymentStatus",
    type: "select",
    data: [
      { label: "Unpaid", value: 0 },
      { label: "Partial paid", value: 1 },
      { label: "Paid", value: 2 },
      { label: "Partial Wrote off", value: 3 },
      { label: "Full Wrote off", value: 4 },
    ],
  },
  {
    label: "Sorting",
    filterLabel: "Sorting",
    filterName: "sorting",
    type: "sorting",
    sidx: [
      { label: "Invoice Date", value: "invoiceDate",  },
      { label: "Invoice No", value: "invoiceNo",  },
    ],
    sord: [
      { label: <div className="d-flex align-items-center "><TbSortAscending className="me-2" size={20} /> Ascending </div> , value: "true" },
      { label: <div className="d-flex align-items-center "><TbSortDescending className="me-2"  size={20} /> Descending </div> , value: "false" },
    ]
  },

];


export const toggleData = [
  { label: "Corporate Client", value: "corporateClient" },
  { label: "Adhoc", value: "adhoc" },
]

export const selectFieldData = [{ label: "Single invoice for all selected trips", value: 1 }, { label: "Separate invoice for all selected trips", value: 2 }]

export const statusData = [
  {
      label: "Created",
      value: 1,
      color: "green",
      renderer: () => (
          <div
              style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                  marginRight: "5px",
              }}
          ></div>
      ),
  },
  {
      label: "Ready",
      value: 2,
      color: "orange",
      renderer: () => (
          <div
              style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "green",
                  marginRight: "5px",
              }}
          ></div>
      ),
  },
]

export const  paymentStatusSelectData =[
  {
    label: "Unpaid",
    value: 0,
    color: "red",
    renderer: () => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "red",
          marginRight: "5px",
        }}
      ></div>
    ),
  },
  {
    label: "Partial Paid",
    value: 1,
    color: "orange",
    renderer: () => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "orange",
          marginRight: "5px",
        }}
      ></div>
    ),
  },
  {
    label: "Paid",
    value: 2,
    color: "green",
    renderer: () => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "green",
          marginRight: "5px",
        }}
      ></div>
    ),
  },
  {
    label: "Partial Wrote off",
    value: 3,
    color: "skyBlue",
    renderer: () => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "skyBlue",
          marginRight: "5px",
        }}
      ></div>
    ),
  },
  {
    label: "Full Wrote off",
    value: 4,
    color: "red",
    renderer: () => (
      <i
        id="not-clickable-isBooker-active"
        className="fas fa-check-circle font-size-12 pe-1"
        style={{ color: "green" }}
      />
    ),
  },
]