import { GrStatusGood } from "react-icons/gr";

export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/flight/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/flight/${id}/detail`,
  save: `/api/flight/create`,
  delete: (id) => `/api/flight/${id}/delete`,
  bulkDelete: (id) => `/api/flight/delete`,
  update: `/api/flight/update`,
};

export const STRUCTURE = [
  {
    label: "Flight No",
    filterLabel: "Flight No",
    filterName: "flightNo",
    type: "text",
    description: "Filter by Flight No",
    icon: " uil-user-circle font-size-16 ",
  },
  {
    label: "Airline",
    filterLabel: "Airline",
    filterName: "airline",
    description: "Filter by Airline",
    type: "text",
    icon: " uil-plane font-size-16 ",
  },
  {
    label: "Date",
    filterLabel: "Date",
    filterName: "date",
    type: "date",
    description: "Filter by Date",
    icon: "uil-calender font-size-16 "
  },
  {
    label: "Time",
    filterLabel: "Time",
    filterName: "time",
    type: "timeSelect",
    description: "Filter by Time",
    icon: "uil-calender font-size-16 "
  },
  {
    label: "Booking No",
    filterLabel: "Booking No",
    filterName: "bookingNo",
    type: "text",
    description: "Filter by Booking No",
    icon: "uil-plane font-size-16 "
  },
  // {
  //   label: "From City",
  //   filterLabel: "From City",
  //   filterName: "fromCity",
  //   type: "citySelect",
  //   description: "Filter by City",
  //   icon: "uil-home font-size-16 "
  // },
  {
    label: "From Country",
    filterLabel: "From Country",
    filterName: "fromCountry",
    type: "countrySelect",
    description: "Filter by Country",
    icon: "uil-home font-size-16 "
  },
  // {
  //   label: "To City",
  //   filterLabel: "To City",
  //   filterName: "toCity",
  //   type: "citySelect",
  //   description: "Filter by City",
  //   icon: "uil-home font-size-16 "
  // },
  {
    label: "From Country",
    filterLabel: "To Country",
    filterName: "toCountry",
    type: "countrySelect",
    description: "Filter by Country",
    icon: "uil-home font-size-16 "
  },
  {
    label: "Booked At",
    filterLabel: "Booked At",
    filterName: "bookedAt",
    type: "dateTimeSelect",
    description: "Filter by Booked At",
    icon: "uil-home font-size-16 "
  },
  {
    label: " Status",
    filterLabel: " Status",
    filterName: "status",
    description: "Filter by  status",
    type: "select",
    iconComponent: <GrStatusGood className="font-size-14" />,
    data: [
      { label: "Booked", value: 1 },
      { label: "Cancelled", value: 2 },
      { label: "Delayed", value: 3 },
    ],
  },
];
export const SELECTSTRUCTURE = {
  airlines: [
    { label: "Air India", value: "Air India" },
    { label: "Indigo", value: "Indigo" },
    { label: "Qatar Airways", value: "Qatar Airways" },
  ],
  status: [
    { label: "Booked", value: 1 },
    { label: "Cancelled", value: 2 },
    { label: "Delayed", value: 3 },
  ],
}
export const FLIGHT_STATUS_MAP = {
  0: { label: "Planned", color: "#2980B9" },
  1: { label: "Booked", color: "#2980B9" },
  2: { label: "Cancelled", color: "#F1948A" },
  3: { label: "Delayed", color: "#CB4335" },
}

export const FLIGHT_TOGGLE_DATA = (t) => ({
  module: [
    { label: t("Planned"), value: 0 },
    { label: t("Booked"), value: 1 },
    { label: t("Cancelled"), value: 2 },
    { label: t("Travelled"), value: 3 },
  ],
})

export const AirlinesList = [
  {
    "label": "SpiceJet",
    "value": "SpiceJet",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/e/e8/SpiceJet_Logo.svg"
  },
  {
    "label": "AirAsia India",
    "value": "AirAsia India",
    "logo": "https://upload.wikimedia.org/wikipedia/en/e/e6/AirAsia_New_Logo.svg"
  },
  {
    "label": "GoAir",
    "value": "GoAir",
    "logo": "https://upload.wikimedia.org/wikipedia/en/9/99/Go_First_Logo.svg"
  },
  {
    "label": "Alliance Air",
    "value": "Alliance Air",
    "logo": "https://upload.wikimedia.org/wikipedia/en/1/14/Alliance_Air_Logo.svg"
  },
  {
    "label": "Air India Express",
    "value": "Air India Express",
    "logo": "https://upload.wikimedia.org/wikipedia/en/4/49/Air_India_Express_Logo.svg"
  },
  {
    "label": "American Airlines",
    "value": "American Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c5/American_Airlines_logo_2013.svg"
  },
  {
    "label": "Delta Air Lines",
    "value": "Delta Air Lines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/0/05/Delta_Air_Lines_Logo.svg"
  },
  {
    "label": "United Airlines",
    "value": "United Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/f/f5/United_Airlines_logo_2019.svg"
  },
  {
    "label": "Southwest Airlines",
    "value": "Southwest Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/f/f1/Southwest_Airlines_logo_2014.svg"
  },
  {
    "label": "Lufthansa",
    "value": "Lufthansa",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/8/8b/Lufthansa_Logo_2018.svg"
  },
  {
    "label": "Air France",
    "value": "Air France",
    "logo": "https://upload.wikimedia.org/wikipedia/en/a/a1/Air_France_Logo.svg"
  },
  {
    "label": "British Airways",
    "value": "British Airways",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/d/d3/British_Airways_Logo.svg"
  },
  {
    "label": "Emirates",
    "value": "Emirates",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Emirates_logo.svg"
  },
  {
    "label": "Qatar Airways",
    "value": "Qatar Airways",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/7/76/Qatar_Airways_logo.svg"
  },
  {
    "label": "Singapore Airlines",
    "value": "Singapore Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/2/21/Singapore_Airlines_Logo.svg"
  },
  {
    "label": "Cathay Pacific",
    "value": "Cathay Pacific",
    "logo": "https://upload.wikimedia.org/wikipedia/en/3/3e/Cathay_Pacific_logo.svg"
  },
  {
    "label": "Japan Airlines",
    "value": "Japan Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/8/81/Japan_Airlines_Logo.svg"
  },
  {
    "label": "ANA (All Nippon Airways)",
    "value": "ANA",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/3/3a/All_Nippon_Airways_logo.svg"
  },
  {
    "label": "Korean Air",
    "value": "Korean Air",
    "logo": "https://upload.wikimedia.org/wikipedia/en/8/8e/Korean_Air_logo.svg"
  },
  {
    "label": "Turkish Airlines",
    "value": "Turkish Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/4/4d/Turkish_Airlines_logo.svg"
  },
  {
    "label": "Qantas",
    "value": "Qantas",
    "logo": "https://upload.wikimedia.org/wikipedia/en/1/19/Qantas_Logo.svg"
  },
  {
    "label": "Etihad Airways",
    "value": "Etihad Airways",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/4/42/Etihad_Airways_Logo_2.svg"
  },
  {
    "label": "Aeroflot",
    "value": "Aeroflot",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/e/e7/Aeroflot_logo.svg"
  },
  {
    "label": "Air Canada",
    "value": "Air Canada",
    "logo": "https://upload.wikimedia.org/wikipedia/en/c/cb/Air_Canada_Logo.svg"
  },
  {
    "label": "KLM Royal Dutch Airlines",
    "value": "KLM",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/b/b8/KLM_logo.svg"
  },
  {
    "label": "Swiss International Air Lines",
    "value": "Swiss",
    "logo": "https://upload.wikimedia.org/wikipedia/en/c/c1/Swiss_International_Air_Lines_Logo_2011.svg"
  },
  {
    "label": "Malaysia Airlines",
    "value": "Malaysia Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/e/e4/Malaysia_Airlines_logo.svg"
  },
  {
    "label": "Thai Airways",
    "value": "Thai Airways",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/a/aa/Thai_Airways_International_Logo.svg"
  },
  {
    "label": "Ethiopian Airlines",
    "value": "Ethiopian Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/5/55/Ethiopian_Airlines_logo.svg"
  },
  {
    "label": "South African Airways",
    "value": "South African Airways",
    "logo": "https://upload.wikimedia.org/wikipedia/en/3/3e/South_African_Airways_logo.svg"
  },
  {
    "label": "Air India",
    "value": "Air India",
    "logo": "https://upload.wikimedia.org/wikipedia/en/1/12/Air_India_Logo.svg"
  },
  {
    "label": "Vistara",
    "value": "Vistara",
    "logo": "https://upload.wikimedia.org/wikipedia/en/0/0d/Vistara_logo.svg"
  },
  {
    "label": "IndiGo",
    "value": "IndiGo",
    "logo": "https://upload.wikimedia.org/wikipedia/en/4/43/IndiGo_Logo.svg"
  },
  {
    "label": "China Southern Airlines",
    "value": "China Southern Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/0/01/China_Southern_Airlines_logo.svg"
  },
  {
    "label": "China Eastern Airlines",
    "value": "China Eastern Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/f/f7/China_Eastern_logo.svg"
  },
  {
    "label": "Air China",
    "value": "Air China",
    "logo": "https://upload.wikimedia.org/wikipedia/en/1/1b/Air_China_Logo.svg"
  },
  {
    "label": "EVA Air",
    "value": "EVA Air",
    "logo": "https://upload.wikimedia.org/wikipedia/en/6/6b/Eva_Air_logo.svg"
  },
  {
    "label": "Asiana Airlines",
    "value": "Asiana Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/e/eb/Asiana_Airlines_Logo.svg"
  },
  {
    "label": "Philippine Airlines",
    "value": "Philippine Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/0/02/Philippine_Airlines_Logo.svg"
  },
  {
    "label": "Vietnam Airlines",
    "value": "Vietnam Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/1/14/Vietnam_Airlines_logo.svg"
  },
  {
    "label": "Garuda Indonesia",
    "value": "Garuda Indonesia",
    "logo": "https://upload.wikimedia.org/wikipedia/en/0/09/Garuda_Indonesia_Logo.svg"
  },
  {
    "label": "Pakistan International Airlines",
    "value": "Pakistan International Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/c/ca/Pakistan_International_Airlines_Logo.svg"
  },
  {
    "label": "Saudi Arabian Airlines",
    "value": "Saudi Arabian Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Saudia_logo.svg"
  },
  {
    "label": "Alitalia",
    "value": "Alitalia",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/c/cc/Alitalia_Logo.svg"
  },
  {
    "label": "Austrian Airlines",
    "value": "Austrian Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/0/0e/Austrian_Airlines_Logo.svg"
  },
  {
    "label": "Finnair",
    "value": "Finnair",
    "logo": "https://upload.wikimedia.org/wikipedia/en/1/12/Finnair_Logo.svg"
  },
  {
    "label": "SAS Scandinavian Airlines",
    "value": "SAS Scandinavian Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/a/a4/Scandinavian_Airlines_logo.svg"
  },
  {
    "label": "Iberia",
    "value": "Iberia",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/2/20/Iberia_logo_2013.svg"
  },
  {
    "label": "Aer Lingus",
    "value": "Aer Lingus",
    "logo": "https://upload.wikimedia.org/wikipedia/en/e/e9/Aer_Lingus_Logo.svg"
  },
  {
    "label": "Air New Zealand",
    "value": "Air New Zealand",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/5/5b/Air_New_Zealand_logo.svg"
  },
  {
    "label": "El Al",
    "value": "El Al",
    "logo": "https://upload.wikimedia.org/wikipedia/en/4/45/El_Al_Logo.svg"
  },
  {
    "label": "LATAM Airlines",
    "value": "LATAM Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/e/ea/LATAM_Airlines_logo.svg"
  },
  {
    "label": "Gol Transportes Aéreos",
    "value": "Gol Transportes Aéreos",
    "logo": "https://upload.wikimedia.org/wikipedia/en/7/7b/Gol_Logo.svg"
  },
  {
    "label": "Avianca",
    "value": "Avianca",
    "logo": "https://upload.wikimedia.org/wikipedia/en/4/4a/Avianca_Logo_2013.svg"
  },
  {
    "label": "Copa Airlines",
    "value": "Copa Airlines",
    "logo": "https://upload.wikimedia.org/wikipedia/en/7/79/Copa_Airlines_logo.svg"
  },
  {
    "label": "Aeroméxico",
    "value": "Aeroméxico",
    "logo": "https://upload.wikimedia.org/wikipedia/en/9/9f/Aeromexico_Logo.svg"
  },
  {
    "label": "TAP Air Portugal",
    "value": "TAP Air Portugal",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/c/c5/TAP_Portugal_logo.svg"
  },
  {
    "label": "Royal Air Maroc",
    "value": "Royal Air Maroc",
    "logo": "https://upload.wikimedia.org/wikipedia/en/0/05/Royal_Air_Maroc_Logo.svg"
  },
  {
    "label": "Kenya Airways",
    "value": "Kenya Airways",
    "logo": "https://upload.wikimedia.org/wikipedia/en/f/f2/Kenya_Airways_logo.svg"
  },
  {
    "label": "EgyptAir",
    "value": "EgyptAir",
    "logo": "https://upload.wikimedia.org/wikipedia/en/0/0b/EgyptAir_logo.svg"
  }
]