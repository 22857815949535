import CustomTooltip from "../Booking/components/CustomTooltip";

export const GridColumns = {
    version: 1,
    columns: [
        {
            title: "Name",
            accessor: "name",
            show: true,
        },
        {
            title: "Type",
            accessor: "rateType",
            show: true,
        },
        {
            title: "Distance",
            renderer: (dataObj) => {
                return <div>
                    {dataObj?.allotedKMs} KM
                </div>;
            },
            show: true,
        },
        {
            title: "Slab Wise",
            renderer: (dataObj, rowIndex, pageSize) => {
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: -5 }}>
                        {dataObj?.isSlabRate === true ? (
                            <div>

                                <CustomTooltip>
                                    <CustomTooltip.Body style={{ maxWidth: 190, overflowX: 'hidden' }}>
                                        <i id="not-clickable-88-active" className="fas fa-check-circle  font-size-12 mt-1" style={{ color: 'green' }} />
                                    </CustomTooltip.Body>
                                    <CustomTooltip.ToolTip position={rowIndex == (pageSize - 1) ? "top" : ""}>
                                        <div>Yes</div>
                                    </CustomTooltip.ToolTip>
                                </CustomTooltip>

                            </div>
                        ) : (
                            <div>--</div>
                        )}
                    </div>
                );
            },
            show: true
        },
        {
            title: "Time",
            renderer: (dataObj) => {
                return <div className="d-flex align-items-center">
                    <div className="me-1">{dataObj?.allotedMins} Mins</div> /
                    <div className="ms-1">{Math.floor(dataObj?.allotedMins / 60)} Hrs</div>
                </div>;
            },
            show: true,
        },
        {
            title: "Applies On",
            renderer: (dataObj) => {
                return (
                    <div>
                        {dataObj.appliesOn === "PER_PAX" ? < i className="bx bx-male font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }}></i > : < i className="bx bx-car font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }} ></i>}
                    </div>
                );
            },
            show: true,
        },
        {
            title: "Action",
            accessor: "action",
            fixed: "right",
            width: 90,
            show: true,
            required: true,
        },
    ],
};
