import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Garage from "../../../pages/Garage/Garage";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
import GarageSelectService from "./GarageSelectService";
import Skeleton from "react-loading-skeleton";
import SupplierLabel from "../Entity/SupplierLabel";

const GarageSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  required,
  value,
  label,
  module,
  showErr,
  outline,
  inline,
  borderNone,
  filterUrlFromParent,
  multi,
      ...props 

}) => {
  const [garages, setGarages] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)
  const [loading,setLoading] =useState(false);

  const fetchGarages = async () => {
    if (GarageSelectService.garages?.length === 0) {
      try {
        setLoading(true);
        await GarageSelectService.fetch();
      } catch (error) {
        showError("Error", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateGarages = () => {
    const filteredGarages = toJS(GarageSelectService.garages).filter(
      (garage) =>
        !garage.hasOwnProperty("supplier_id") ||
        garage.supplier_id === filterUrlFromParent?.supplier_id
    );

    setGarages(
      filteredGarages.map((garage) => ({
        ...garage,
        value: garage._id,
        label: (
          <div className="d-flex flex-column h-100 justify-content-between">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <b className="text text-dark">
                  {garage?.address?.name ?? "--"} ({garage?.code})
                </b>
              </div>
            </div>
            {garage?.supplier_id ? (
              <SupplierLabel value={garage?.supplier_id} />
            ) : (
              <div>Self</div>
            )}
          </div>
        ),
        menuLabel: (
          <div className="d-flex flex-column h-100">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <b className="text text-dark">
                  {garage?.address?.name ?? "--"} ({garage?.code})
                </b>
              </div>
              <div className="text-dropzone-dark-grey">
                {garage?.address?.city ?? "--"}, {garage?.address?.state ?? "--"},{" "}
                {garage?.address?.country ?? "--"}
              </div>
            </div>
            {garage?.supplier_id ? (
              <div className="d-flex align-items-center justify-content-between mt-1">
                <SupplierLabel value={garage?.supplier_id} />
              </div>
            ) : (
              <div>Self</div>
            )}
          </div>
        ),
      }))
    );
  };

  useEffect(() => {
    fetchGarages();
  }, []);

  useEffect(() => {
    updateGarages();
  }, [filterUrlFromParent, GarageSelectService.garages]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectGarage = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectGarages = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const formatOptionLabel = ({ label, menuLabel }, { context }) => {
    return context === "menu" ? menuLabel : label;
  };

  const filterOption = useCallback((option, inputValue) => {
    return (
      !inputValue ||
      option.data?.code?.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data?.address.city?.toLowerCase().includes(inputValue.toLowerCase())
    );
  }, []);

  const renderSelectField = ({ disabled }) => (
    <SelectField
      filterOption={filterOption}
      style={style}
      grouping={true}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Garages")} />
      )}
      outline={outline}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={garages}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Garage"))}
      error={!value ? t(`Please Select ${label || "Garage"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      formatOptionLabel={formatOptionLabel}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      {...props}

    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      filterOption={filterOption}
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Garages")} />
      )}
      outline={outline}
      // showBottomBar={true}
      className={className}
      onChange={selectGarages}
      data={garages}
      value={value ? garages.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Garages"}`) : ""}
      required={required}
      label={label || (!isDisabled && t("Select Garages"))}
      showErr={showErr}
      RightComponent={() => <SearchButton onClick={openModal} />}
    />
  );

  return (
    <>
      {modal && (
        <ModalComponent
          position={"top"}
          size={"large"}
          onToggle={closeModal}
          isOpen={modal}
        >
          <ModalComponent.Title>{t("Garage")}</ModalComponent.Title>

          <ModalComponent.Body>
            <Garage
              value={value}
              insidePane={true}
              multiMode={multi}
              onSelect={multi ? selectGarages : selectGarage}
            />
          </ModalComponent.Body>
        </ModalComponent>
      )}
      {loading ? (
        <Skeleton className="mt-4" />
      ) : garages.length > 0 ? (
        multi ? (
          renderSelectFieldMultiple()
        ) : (
          renderSelectField({ disabled: false })
        )
      ) : (
        renderSelectField({ disabled: true })
      )}
    </>
  );
};

export default observer(GarageSelect);
