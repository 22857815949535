import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Employee from "../../../pages/Employee/Employee";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, ModalComponent, SearchButton } from "../../index";
import EmployeeSelectService from "./EmployeeSelectService";
import useDebounce from "../../../util/Debounce";
import SupplierLabel from "../Entity/SupplierLabel";

const EmployeeSelect = ({
  style,
  isDisabled,
  className,
  showClear,
  onChange,
  required,
  value,
  label,
  showErr,
  inline,
  borderNone,
  multi,
  guide,
  driver,
  guard,
  sales,
  outline,
  asLabel,
  showSupplier=true,
  innerContainerClassName,
  placeholder,
  ...props
}) => {

  const [employees, setEmployees] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const updateEmployees = () => {
    setEmployees(
      toJS(EmployeeSelectService.employees)?.map((employee) => {
        if (
          (driver && employee?.isDriver) ||
          (sales && employee?.isSales) ||
          (guide && employee?.isGuide) ||
          (guard && employee?.isGuard) ||
          (!driver && !guard && !guide && !sales)
        ) {
          return {
            ...employee,
            value: employee._id,
            // label: `${employee.name} ( +${employee?.phoneNo?.countryCode} ${employee?.phoneNo?.phone})`
            label: <div className="d-flex flex-column h-100">
            <div className="d-flex align-items-center justify-content-between text-secondary">
              <b>{employee.name}</b>
              <div className="text-secondary">+{employee?.phoneNo?.countryCode} {employee?.phoneNo?.phone}</div>
            </div>
            {showSupplier && (employee?.employeeOf ? <div className="d-flex align-items-center justify-content-between">
              <SupplierLabel className={"mt-1"} value={employee?.employeeOf} /> 
            </div> :"Self")}
          </div>
          };
        }
        return null;
      }).filter(Boolean)
    );
  };

  const fetchEmployees = async () => {
    try {
      await EmployeeSelectService.fetch();
      updateEmployees()
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    if (EmployeeSelectService.employees.length === 0) fetchEmployees();
  }, []);

  useEffect(() => {
    updateEmployees()
  }, [EmployeeSelectService.employees]);

  const fetchAndUpdateEmployee = async (id) => {
    try {
      const employee = employees.find(employee => employee._id === id)
      if (!employee) {
        const employee = await EmployeeSelectService.get(id);
        EmployeeSelectService.pushNewData(employee);
        updateEmployees();
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    if (value) {
      fetchAndUpdateEmployee(value)
    }
  }, [value]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectEmployee = (selectedValue) => {
    let selectedEmployee = toJS(selectedValue)
    const employee = employees.find(employee => employee._id === selectedEmployee?._id)
    if (!employee) {
      EmployeeSelectService.pushNewData(selectedEmployee);
      updateEmployees();
    }
    onChange({ ...selectedEmployee, value: selectedEmployee?._id });
    closeModal();
  };

  const selectEmployees = (selectedValues) => {
    //TODO
    onChange(selectedValues.map((c) => c?.value ?? c));
  };

  const fetchMoreData = () => {
    console.log("reached end")
    const page = EmployeeSelectService.page + 1;
    const rowsPerPage = EmployeeSelectService.rowsPerPage;
    const total = EmployeeSelectService.total;
    console.log({ page, total, rowsPerPage })
    if (page > 0 && page <= (total % rowsPerPage === 0 ? (total / rowsPerPage) : (total / rowsPerPage) + 1)) {
      EmployeeSelectService.onPaginationChange(page, rowsPerPage);
    }
  }

  const fetchDataOnSearch = useDebounce((value) => {
    const filterUrl = `name=${value}`;
    EmployeeSelectService.fetch(filterUrl);
  }, [500]
  );

  const filterOption = (option, searchText) => {
    return option?.data?.name?.toLowerCase().includes(searchText.toLowerCase())
  }

  const renderSelectField = ({ disabled }) => (
    <SelectField
      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Employees")} />
      )}
      onMenuScrollToBottom={fetchMoreData}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={employees}
      showClear={showClear}
      required={required}
      value={value}
      label={label || ((!isDisabled && !value) && t("Select Employees"))}
      error={!value ? t(`Please Select ${label || "Employee"}`) : ""}
      showErr={showErr}
      inline={inline}
      outline={outline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      onInputChange={fetchDataOnSearch}
      filterOption={filterOption}
      innerContainerClassName={value ?innerContainerClassName:``}
      // getOptionLabel={option => `${option.name} ( +${option?.phoneNo?.countryCode} ${option?.phoneNo?.phone})`}
      {...props}
    />
  );

  const renderSelectFieldMultiple = () => (
    <SelectFieldMultiple
      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Employees")} />
      )}
      // showBottomBar={true}
      className={className}
      onMenuScrollToBottom={fetchMoreData}
      outline={outline}
      onChange={selectEmployees}
      data={employees}
      value={value ? employees.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Employees"}`) : ""}
      required={required}
      label={label || ((!isDisabled && !value)&& t("Select Employees"))}
      showErr={showErr}
      onInputChange={fetchDataOnSearch}
      innerContainerClassName={value ?innerContainerClassName:``}
      RightComponent={() => (
        <SearchButton  onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {asLabel
        ? multi ?
          value?.length > 0 ?
            <div>
              {value?.map((selectedValue, index) => {
                const employee = employees.find((employee) => employee.value == selectedValue);
                return (
                  <span key={selectedValue}>
                    {employee ? employee.label : 'Unknown Employee'}
                    {index < value.length - 1 && ", "}
                  </span>
                );
              })}
            </div>
            : <div style={{ color: "grey" }}>--</div>
          : (<div>{employees?.find((employee) => employee?.value == value)?.label ?? <div style={{ color: "grey" }}>--</div>}</div>) :
        <>

          {modal && (
            <ModalComponent
              position={"top"}
              size={"large"}
              onToggle={closeModal}
              isOpen={modal}
            >
              <ModalComponent.Title>
                {t("Employee")}
              </ModalComponent.Title>

              <ModalComponent.Body>
                <Employee
                  value={value}
                  filterUrlFromParent={`isDriver=true`}
                  insidePane={true}
                  multiMode={multi}
                  onSelect={multi ? selectEmployees : selectEmployee}
                />
              </ModalComponent.Body>

            </ModalComponent>
          )}


          {employees.length > 0
            ? multi
              ? renderSelectFieldMultiple()
              : renderSelectField({ disabled: false })
            : renderSelectField({ disabled: true })}
        </>
      }

    </>

  );
};

export default observer(EmployeeSelect);
