export const ENDPOINTS = {
  grid: () => {
    let url = `/api/customer/grid?`;
    return url;
  },

  filter: (page, sizePerPage, filterUrl, client_id) => {
    let url = ``;
    if (sizePerPage) url += `&rows=${sizePerPage}`;
    if (filterUrl) url += `&${filterUrl}`;
    if (client_id) url += `&client_id=${client_id}`;
    if (page) url += `&page=${page}`
    return url;
  },


  commentGrid: (userId) => `/api/comment/grid?by=${userId}`,
  // get: (id,client_id) => `/api/customer/${id}/detail`,
  get: (id, client_id) => {
    let url = `/api/customer/${id}/detail`;
    if (client_id) {
      url += `?client_id=${client_id}`;
    }
    return url;
  }
  ,
  delete: (id) => `/api/customer/${id}/delete`,
  save: `/api/customer/create`,
  update: `/api/customer/update`,
  bulkDelete: (id) => `/api/customer/delete`,
  trip : (id,date) => `/api/trip/grid?passengers.customer_id=${id}&rows=5&page=1&sortBy=tripDate&sortAsc=false&tripDate=gt[${date}]`,
  flight :(id,date) =>`/api/flight/grid?passengers.customer_id=${id}&page=1&sortBy=tripDate&sortAsc=false&departureDate=gt[${date}]`, //TODO append &row=5 
  stays: (id,date) => `/api/stay/grid?guests.customer_id=${id}&rows=5&page=1&sortBy=tripDate&sortAsc=false`   //TODO append date adn checkInDate=gt[${data}]
};


export const SELECTSTRUCTURE = {
  status: [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ],
}


export const STRUCTURE = [

  {
    label: "User Name",
    filterLabel: "User Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "User Email",
    filterLabel: "User Email",
    filterName: "email",
    type: "text",
  },
  {
    label: "User Department",
    filterLabel: "User Department",
    filterName: "dept",
    type: "text",
  },
  {
    label: "User Designation",
    filterLabel: "User Designation",
    filterName: "designation",
    type: "text",
  },
  {
    label: "User Status",
    filterLabel: "User Status",
    filterName: "status",
    type: "select",
    data: SELECTSTRUCTURE?.status
  },
  {
    label: "Phone Number",
    filterLabel: "Phone Number",
    filterName: "phoneNo.phone",
    description: "Filter by phone number",
    type: "text",
    icon: "mdi mdi-phone  font-size-16 ",
  },
  // {
  //   label: "User Phone Number",
  //   filterLabel: "User Phone Number",
  //   filterName: "phoneNo",
  //   type: "select",
  //   data: SELECTSTRUCTURE?.phoneNo
  // }
];
