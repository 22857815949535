

import React, { useContext, useEffect, useState } from 'react';
import { default as ReactTable, default as useTable } from "react-table";
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { I18nContext } from '../../store/context/i18nContext';
import { CiCircleMinus } from "react-icons/ci";

 
const ReactTableFixedColumns = withFixedColumns(ReactTable);
const { getTableProps } = useTable;


const renderGridCell = (dataObj, field, renderer, rowIndex, pageSize,onSelectChange) => {
    if (field === "action") {
      return (
        <div className="d-flex w-100 cursor-pointer" onClick={()=>{
            onSelectChange(dataObj?._id)
        }}> 
           <CiCircleMinus size={20} color='primary'/>
         </div>
      )
    }  
    return  renderer ? renderer(dataObj, rowIndex, pageSize) : dataObj[field];
  };

const SelectedGridData = ({ selectedIDs, selectedData,onSelectChange, ...props }) => {
    const [columns,setColumns]=useState([]);
    const {t}=useContext(I18nContext);

    const formatData=()=>{
       setColumns(()=>{
        return props?.headers?.columns?.map((column) => {
            return {
              Header: column?.title,
              accessor: column?.accessor,
              fixed: column.fixed,
              width: column.width,
              show: column.show,
              Cell: (row) => {
                return (
                  <div className="height-100 w-100 d-flex align-items-center">
                   {renderGridCell(row.original, column.accessor, column.renderer, row?.index, row?.pageSize,onSelectChange)}
                  </div>
                );
              },
            };
          })
       });
    }

    useEffect(()=>{
        formatData()
    },[props?.headers])


    return (
        <ReactTableFixedColumns
            data={selectedData??[]}
            showPagination={false} 
            columns={columns} 
            className="-striped -highlight"
            pageSize={selectedData?.length ?? 0} 
            minRows={0}
            getTableProps={  (...args) => {
                const scrollPosition = 200
                return {
                  ...(getTableProps && getTableProps(...args)),
                  style: { maxHeight: '85vh', overflow: "auto", scrollTop: scrollPosition },
                };
              }}
            getTheadProps={() => {
                return {
                  style: {
                    height: '50px',
                    backgroundColor: '#f8f9fa',  
                  },
                };
              }}
              getTrProps={() => {
                return {
                  style: {
                    height: '50px', 
                   },
                };
              }}
            
             
             
        />
    );
};

export default SelectedGridData;
