import React from 'react'
import { isRouteType, ROUTE_TYPE_DATA, TRIP_TYPE } from '../../pages/Booking/BookingConstant';
import ToggleSelector from '../Toggle/ToggleSelector';
import { Card, CardBody } from 'reactstrap';
import ShuttleAddressSelector from '../ShuttleAddressSelector';
import ShuttleScheduler from '../ShuttleScheduler';

const RouteCard = (props) => {
    const { trip, updateTrip } = props;

    return (
        <>
            {isRouteType(trip?.tripType) ? <Card className="px-3">
                <div className=" col-12 col-md-6">
                    <ToggleSelector
                        data={ROUTE_TYPE_DATA}
                        disabled={trip?._id}
                        className=" "
                        style={{ position: "relative", top: "2.5px" }}
                        value={trip?.tripType}
                        onChange={(selectedValue) => {
                            // setTrip((prev) => ({ ...prev, routeType: selectedValue }));
                            updateTrip({ tripType: selectedValue })
                        }}
                    />
                </div>
            </Card> : null}

            {isRouteType(trip?.tripType) && TRIP_TYPE['Route-Shuttle'] === trip?.tripType ? <Card className="mb-2">  
                <CardBody className='px-3 py-3 col-12 col-sm-6'>
                    <ShuttleAddressSelector
                        // disabled={trip?._id}
                        shuttleLocations={trip?.stops}
                        onChange={(values) => {
                            updateTrip({ stops: values, src: values?.length && values[0]?.address, dst: values?.length && values[values?.length - 1]?.address })
                        }}
                    />
                </CardBody>
            </Card> : null}
            {isRouteType(trip?.tripType)   ? <Card className="mb-2">   
                <CardBody className='px-3 py-3 col-12 col-sm-6'>
                    <ShuttleScheduler
                        // disabled={trip?._id}
                        onChange={(updatedData) => {
                            updateTrip({ 
                                tripExtn: {
                                    ...trip?.tripExtn,
                                    ...updatedData
                                }
                             })
                        }}
                        schedule={trip?.tripExtn?.routeDNA}
                    />
                </CardBody>
            </Card> : null}
        </>
    )
}

export default RouteCard