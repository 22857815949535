import { useContext, useState } from "react";
import Checkbox from "../../../../../../pages/Offers/checkbox";
import { I18nContext } from "../../../../../../store/context/i18nContext";
import AmountField from "../../../../../AmountField/AmountField";
import FeeGroup from "./FeeGroup"; // Assuming FeeGroup is in the same directory
import "./index.scss";

const Fee = ({ label, showDetail, onChange, fee, outline }) => {
  const { t } = useContext(I18nContext);
  const [isAdvancedView, setIsAdvancedView] = useState(fee?.feeGroups?.length ? true : false);

  const handleSubTotalChange = (value) => {
    onChange({ ...fee, subTotal: value });
  };

  const handleTotalTaxesChange = (value) => {
    onChange({ ...fee, totalTaxes: value });
  };


  const handleTotalDiscountChange = (value) => {
    onChange({ ...fee, totalDiscounts: value });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h3>{label ?? "Fee"}</h3>
        {/* <Checkbox
          placeholder={t("Advanced View")}
          checked={isAdvancedView}
          value={isAdvancedView}
          required={true}
          label={t("Advanced View")}
          onClick={() => setIsAdvancedView(!isAdvancedView)}
          type="text"
          className="col-2 my-2 align-content-end"
        /> */}
        <label onClick={() => setIsAdvancedView(!isAdvancedView)} className="cursor-pointer advanced_view_link">{isAdvancedView ? t("Hide Advanced View") : t("Show Advanced View")}</label>
      </div>

      {isAdvancedView ? (
        <FeeGroup
          showDetail={showDetail}
          onChange={(updatedFeeData) => {
            onChange({ ...fee, feeGroups: updatedFeeData });
          }}
          feeGroups={fee?.feeGroups}
        />
      ) : (
        <div className="d-flex align-items-center zr_normal_fee-row">
          <div className="zr_normal_fee-label">Sub Total</div>
          <AmountField
            outline={outline}
            className="zr_normal_fee-field"
            value={fee?.subTotal}
            onChange={(v) => handleSubTotalChange(parseInt(v))}
            type="text"
          />
          <span className="zr_normal_fee-icon">-</span>
          <div className="zr_normal_fee-label">Discounts</div>
          <AmountField
            outline={outline}
            className="zr_normal_fee-field"
            value={fee?.totalDiscounts}
            onChange={(v) => handleTotalDiscountChange(parseInt(v))}
            type="text"
          />
          <span className="zr_normal_fee-icon">+</span>
          <div className="zr_normal_fee-label">Total Taxes</div>
          <AmountField
            outline={outline}
            className="zr_normal_fee-field"
            value={fee?.totalTaxes}
            onChange={(v) => handleTotalTaxesChange(parseInt(v))}
            type="text"
          />
          <span className="zr_normal_fee-icon">=</span>
          <div className="zr_normal_fee-total">
            Total {(((isNaN(fee?.subTotal) ? 0 : fee?.subTotal) + (isNaN(fee?.totalTaxes) ? 0 : fee?.totalTaxes) - (isNaN(fee?.totalDiscounts) ? 0 : fee?.totalDiscounts)) / 100)?.toFixed(2)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Fee;
